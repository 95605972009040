import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../form-components/TextInput';
import { withTranslation } from 'react-i18next';

class LoginForm extends React.Component {
    render() {
        return (
            <div>
                <Field name="login" placeholder={this.props.t('Input login')} component={TextInput} />
                <Field name="password" placeholder={this.props.t('Input password')} type='password' component={TextInput} />
            </div>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    const { t } = props;
    if (!values.login || values.login.trim() === '') {
        errors.login = t('Field couldn\'t be empty');
    }
    if (!values.password || values.password.trim() === '') {
        errors.password = t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm({
    form: 'LoginForm',
    validate
})(LoginForm);

export default connect(null)(withTranslation()(form));