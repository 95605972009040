import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { confirmable } from "react-confirm";
import "./Dialogs.scss";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";

class AlertDialog extends React.Component {
    render() {
        const {
            okLabel = "OK",
            title,
            alert,
            show,
            proceed,
            enableEscape = true,
            t,
        } = this.props;

        return (
            // <div className="static-modal">
            <Modal
                centered
                show={show}
                onHide={() => proceed()}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
            >
                <div className="dialogs-block">
                    <Modal.Header className="bolt" closeButton></Modal.Header>
                    <div className="dialogs-block__content">
                        <Modal.Body>{t(alert)}</Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="blue-btn btn btn-primary drugs-button"
                                onClick={() => proceed()}
                            >
                                {okLabel}
                            </Button>
                        </Modal.Footer>
                    </div>
                </div>
            </Modal>
            // </div>
        );
    }
}

export default withTranslation()(confirmable(AlertDialog));
