import React from "react";
import { PAGE_SIZE } from "../../../redux/actions/actions";

export class PagesBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {searchTotal, searchPage, changePage} = this.props;
        let pages = [];

        const totalPages = Math.ceil(searchTotal / PAGE_SIZE);
        pages = [...Array(totalPages).keys()].map(p => {
            if (p < 4 || p > totalPages - 5) return p;
            if (p - searchPage < 4 && p - searchPage > -4) return p;
            return '...';
        });

        pages = pages.filter((p, i) => i === 0 || pages[i - 1] !== '...');

        if (pages.length > 1) {
            return <div className="page-block" >
                {pages.map(page => {
                    if (page === '...') {
                        return <div className="page-skip" key="...">...</div>
                    }
                    return (
                        <div className={"page-button" + (page === searchPage ? " page-current" : "")} key={page} onClick={() => changePage(page)}>
                            {page + 1}
                        </div>);
                })}
            </div>
        } else {
            return <></>
        }
        
    }
}