import i18n from "i18next";
import { LoadingSubject } from "../models/loading-state.enum";
import { getAlert } from "./actions";
import { authFetch } from "./vd-auth";
import { loadingEnd, loadingStart } from "./loading-actions";
import config from "../../config";
import { getFrequencies } from "./auth-actions";

function topDoctorData() {
    return config.url;
}

export const PATIENT_MEDICINE_LIST = "PATIENT_MEDICINE_LIST";

export const _patientMedicineList = (medicines) => {
    return {
        type: PATIENT_MEDICINE_LIST,
        medicines,
    };
};

export const PATIENT_MEDICINE_ITEM = "PATIENT_MEDICINE_ITEM";

export const _patientMedicineItem = (medicine) => {
    return {
        type: PATIENT_MEDICINE_ITEM,
        medicine,
    };
};

export const getPatientMedicineList = () => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicineList));

    try {
        const response = await authFetch(
            getState,
            dispatch,
            topDoctorData() + "preparations-by-user?_limit=1000&_sort=id:asc",
            "GET",
            null,
            { Accept: "application/json", "Content-Type": "application/json" },
            "&token="
        );

        // console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            // console.log('json', json);
            dispatch(_patientMedicineList(json));
            dispatch(getFrequencies());
        } else if (response.status === 401) {
            //dispatch(signOut(token));
        } else {
            dispatch(
                getAlert("Unknown error. Please, check internet connection")
            );
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(getAlert("Unknown error. Please, check internet connection"));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicineList));
    }
};

export const getPatientMedicine = (id) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicine));

    try {
        const response = await authFetch(
            getState,
            dispatch,
            topDoctorData() + "my-preparations/" + id,
            "GET",
            null,
            { Accept: "application/json", "Content-Type": "application/json" },
            "?token="
        );
        // console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            // console.log('json', json);
            dispatch(_patientMedicineItem(json));
        } else if (response.status === 401) {
            //dispatch(signOut(token));
        } else {
            dispatch(
                getAlert("Unknown error. Please, check internet connection")
            );
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(getAlert("Unknown error. Please, check internet connection"));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicine));
    }
};

export const createPatientMedicine =
    (medicine, success) => async (dispatch, getState) => {
        dispatch(loadingStart(LoadingSubject.PatientMedicine));

        try {
            const response = await authFetch(
                getState,
                dispatch,
                topDoctorData() + "preparations-by-user",
                "POST",
                medicine,
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                "?token="
            );

            if (response.status === 200) {
                const json = await response.json();
                // console.log('json', json);
                dispatch(getPatientMedicineList());
                success && success();
            } else if (response.status === 401) {
                //dispatch(signOut(token));
            } else {
                dispatch(
                    getAlert("Unknown error. Please, check internet connection")
                );
                //dispatch(errorPatientDoctorList(json.code, token));
            }
        } catch (e) {
            dispatch(
                getAlert("Unknown error. Please, check internet connection")
            );
        } finally {
            dispatch(loadingEnd(LoadingSubject.PatientMedicine));
        }
    };

export const deletePatientMedicine = (id) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicine));

    try {
        const response = await authFetch(
            getState,
            dispatch,
            topDoctorData() + "preparation-by-user/" + id,
            "DELETE",
            null,
            { Accept: "application/json", "Content-Type": "application/json" },
            "?token="
        );

        // console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            // console.log('json', json);
            dispatch(getPatientMedicineList());
        } else if (response.status === 401) {
            //dispatch(signOut(token));
        } else {
            dispatch(
                getAlert("Unknown error. Please, check internet connection")
            );
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(getAlert("Unknown error. Please, check internet connection"));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicine));
    }
};

export const updatePatientMedicine =
    (medicine, success) => async (dispatch, getState) => {
        dispatch(loadingStart(LoadingSubject.PatientMedicine));

        try {
            const response = await authFetch(
                getState,
                dispatch,
                topDoctorData() + "preparations-by-user",
                "PUT",
                medicine,
                {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                "?token="
            );

            if (response.status === 200) {
                const json = await response.json();
                // console.log('json', json);
                dispatch(getPatientMedicineList());
                success && success();
            } else if (response.status === 401) {
                //dispatch(signOut(token));
            } else {
                dispatch(
                    getAlert("Unknown error. Please, check internet connection")
                );
                //dispatch(errorPatientDoctorList(json.code, token));
            }
        } catch (e) {
            dispatch(
                getAlert("Unknown error. Please, check internet connection")
            );
        } finally {
            dispatch(loadingEnd(LoadingSubject.PatientMedicine));
        }
    };
