import React from "react";

// reactstrap components
import {
    CardBody,
    CardHeader,
    Nav
} from "reactstrap";
import "./Account.scss";
import { connect } from "react-redux";
import { accountTab, addAccountData } from "./account-container";
import { logoutUser, getMyPreparations, addMyPreparation, deletePreparation } from "./../../../redux/actions/auth-actions";
import { getProfile, editProfile, updateProfile, changePassword } from "./../../../redux/actions/vd-auth";
import debounce from "lodash.debounce";
import config from "../../../config";
import { withTranslation } from "react-i18next";
import { CenterLoader } from "../Loader/Loader";
import { LoadingSubject } from "../../../redux/models/loading-state.enum";

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.title}</div>;

// core components
class Account extends React.Component {
    constructor(props) {
        super(props);
        let value = "";
        this.state = {
            activeTab: 'overview',
            drugModal: false,
            profileInput: {firstName: '', lastName: '', birthdate: '', sex: '', specialization: '', medicalInstitution: '', oldPassword: '', password: '', password2: ''},
            drugInput: {drug: '', dose: '', frequency: '', startDate: '', endDate: '', status: ''},
            suggestions: [],
            value
        };

        this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 400);
    }

    componentDidMount() {
        const authToken = ((this.props.auth || {}).userToken || {}).auth;
        if (authToken) {
            this.props.getProfile();
            this.props.getMyPreparations();
        }
    }

    async getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();

        let request = await fetch(`${config.url}search-medical-products/?_q=${inputValue}`, {
            method: "GET",
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('az.drugs.token')).jwt}`
            }
        });
        let data = await request.json();
        // console.log('data', data);

        return data;
    }

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    getSuggestionValue = suggestion => {
        // console.log(suggestion.id);
        this.setState({...this.state, drugInput: {...this.state.drugInput, drug: suggestion.id}});
        return suggestion.title
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        let suggestions = this.getSuggestions(value);
        suggestions.then((sugs) => {
            this.setState({
                suggestions: sugs,
            });
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({activeTab: tab});
    };

    setDrugModal = modal => {
        this.setState({...this.state, drugModal: modal});
    }

    onDrugFormSubmit = e => {
        e.preventDefault();
        this.props.addMyPreparation(this.state.drugInput);
        this.setDrugModal(false);
    }

    drugInputChange = e => {
        this.setState({...this.state, drugInput: {...this.state.drugInput, [e.target.name]: e.target.value}});
    }

    onProfileFormSubmit = e => {
        e.preventDefault();
        const {profileInput} = this.state;
        if (!profileInput.firstName) delete profileInput.firstName;
        if (!profileInput.lastName) delete profileInput.lastName;
        if (!profileInput.birthdate) delete profileInput.birthdate;
        if (!profileInput.sex) delete profileInput.sex;
        if (!profileInput.specialization) delete profileInput.specialization;
        if (!profileInput.medicalInstitution) delete profileInput.medicalInstitution;
        if (!profileInput.password) {
            delete profileInput.password;
            delete profileInput.password2;
            delete profileInput.oldPassword;
        } else if (profileInput.password !== profileInput.password2) {
            return alert('Passwords are incorrect');
        }
        //profileInput.email = this.props.authData.user.email;
        //profileInput.username = this.props.authData.user.username;
        if (profileInput.password && profileInput.oldPassword && profileInput.password === profileInput.password2) {
            this.props.changePassword((this.props.auth.userToken || {}).auth, {oldpassword: profileInput.oldPassword, password: profileInput.password});
        } 
         this.props.updateProfile({ ...profileInput, id: this.props.auth.profile.id });
        if (this.props.loading.subjects.has(LoadingSubject.SaveUploadProfile)) {
            return (
                <CenterLoader className="loading-search-results"></CenterLoader>
            )
        }
    }

    profileInputChange = e => {
        e.preventDefault()
        // console.log(e)
        this.setState({...this.state, profileInput: {...this.state.profileInput, [e.target.name]: e.target.value || this.props.authData.user[e.target.name] } })
    }

    onChangeSuggestion = (event, { newValue }) => {
        this.setState({value: newValue});
    };

    render() {
        let { activeTab, drugModal, suggestions, value } = this.state;
        const {lang, dictionary, authData, auth, t, loading} = this.props;
        const {professional, role} = (authData || {}).user || {};

        let mainTitle = dictionary.account[activeTab].mainTitle[lang];
        // let mainTitle = "Title";
        const authToken = ((auth || {}).userToken || {}).auth;

        if (!authToken) {
            this.props.history.push('/drugs/home')
        }

        return (
            <>
                <CardHeader className="bg-transparent border-0">
                    <h3 style={{ marginBottom: '-20px' }} className="first-level-title">{mainTitle}</h3>
                </CardHeader>
                <CardBody>
                    <Nav tabs className="nav-tabs-custom">
                        {accountTab("overview", activeTab, this.toggle, dictionary.account.overview.title[lang])}
                        {accountTab("editDetails", activeTab, this.toggle, dictionary.account.editDetails.title[lang])}
                        {accountTab("follows", activeTab, this.toggle, dictionary.account.follows.title[lang])}
                        {/* {(role || {}).type !== 'authenticated' ? accountTab("follows", activeTab, this.toggle, dictionary.account.follows.title[lang]) : ''} */}
                        {/* {(role || {}).type === 'authenticated' && !professional ? accountTab("myDrugs", activeTab, this.toggle, dictionary.account.myDrugs.title[lang]) : ''} */}
                        {/* {accountTab("logout", activeTab, this.toggle, dictionary.account.logout.title[lang])}                */}
                    </Nav>
                    <div className="account-content">
                        {addAccountData(
                            activeTab,
                            this.toggle,
                            this.props,
                            drugModal,
                            this.setDrugModal,
                            this.onProfileFormSubmit,
                            this.profileInputChange,
                            this.onDrugFormSubmit,
                            this.drugInputChange,
                            suggestions,
                            value,
                            this.onChangeSuggestion,
                            this.getSuggestionValue, 
                            renderSuggestion, 
                            this.onSuggestionsFetchRequested, this.onSuggestionsClearRequested)}
                    </div>
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.vdAuthReducer,
    authData: state.authReducer.authData,
    myPreparations: state.authReducer.myPreparations,
    frequencies: state.authReducer.frequencies,
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    loading: state.loadingReducer
});

const mapDispatchToProps = {
    logoutUser,
    getProfile,
    editProfile,
    updateProfile,
    changePassword,
    getMyPreparations,
    addMyPreparation,
    deletePreparation
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Account));
