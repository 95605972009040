import config from '../../config';
import { GET_MEDICAL_PRODUCTS, COUNT_MEDICAL_PRODUCTS, CREATE_MEDICAL_PRODUCT, EDIT_MEDICAL_PRODUCT, SEARCH_DRUGS, GET_ICD, GET_CP } from './action-types';
import { errorAction, getAlert } from './actions';

const queryHeaders = {
    'Content-type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('usertoken')}` || null
};

// checkNumber
export const isNumber = n => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

// Medical products
const _getMedicalProducts = medicalProducts => ({
    type: GET_MEDICAL_PRODUCTS,
    payload: medicalProducts
});

export const getMedicalProducts = start => async dispatch => {
    try {
        const res = await fetch(`${config.url}medical-products?_limit=20&_start=${start}`, {
            method: 'GET',
            headers: queryHeaders
        });
        const response = await res.json();
        if (response.error) {
            console.error('ошибка', response.error);
            return dispatch(errorAction(response));
        }
        return dispatch(_getMedicalProducts(response));
    } catch (err) {
        console.error(err.message);
    }
};

// count medical products
const _countMedicalProducts = leafletsCount => ({
    type: COUNT_MEDICAL_PRODUCTS,
    payload: leafletsCount
})

export const countMedicalProducts = () => async dispatch => {
    try {
        const res = await fetch(`${config.url}medical-products/count`, {
            method: 'GET',
            headers: queryHeaders
        });
        const response = await res.json();
        if (response.error) {
            console.error(response.error);
            return dispatch(errorAction(response))
        }
        return dispatch(_countMedicalProducts(response));
    } catch (err) {
        console.error(err.message);
    }
};



// Create medical product
export const _createProduct = medicalProduct => ({
    type: CREATE_MEDICAL_PRODUCT,
    payload: medicalProduct
});

export const createProduct = () => async dispatch => {
    try {
        const res = await fetch(`${config.url}medical-products`, {
            method: 'POST',
            headers: queryHeaders
        });
        const response = await res.json();
        if (response.error) {
            console.error(response.error);
            return dispatch(errorAction(response));
        }
        return dispatch(_createProduct(response));
    } catch (err) {
        console.error(err.message);
    }
};

// edit medical product
const _editMedicalProduct = medicalProduct => ({
    type: EDIT_MEDICAL_PRODUCT,
    payload: medicalProduct
});

export const editMedicalProduct = (id, medicalProduct) => async dispatch => {
    try {
        const res = await fetch(`${config.url}full-medical-product/${id}`, {
            method: 'PUT',
            headers: queryHeaders,
            body: medicalProduct ? JSON.stringify(medicalProduct) : JSON.stringify({})
        });
        const response = await res.json();
        if (response.error) {
            console.error(response.error);
            return dispatch(errorAction(response));
        }
        if (medicalProduct) dispatch(getAlert('successSave', 'success'));
        return dispatch(_editMedicalProduct(response));
    } catch (err) {
        dispatch(getAlert(err.message, 'danger'));
        console.error(err.message);
    }
}

//search drug
const _searchDrugs = drug => ({
    type: SEARCH_DRUGS,
    payload: drug
});

export const searchDrugs = string => dispatch => (
    fetch(`${config.url}drugs?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json()).then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_searchDrugs(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const searchICD = string => async dispatch => {
    try {
        const res = await fetch(`${config.url}icd-10-codes?code_contains=${string}`, {
            method: 'GET',
            headers: queryHeaders
        });
        if (res.ok) {
            const response = await res.json();
            dispatch({
                type: GET_ICD,
                payload: response
            });
        }
    } catch (err) {
        console.error(err.message);
    }
}

export const searchCP = string => async dispatch => {
    try {
        const res = await fetch(`${config.url}cp-classes?code_contains=${string}`, {
            method: 'GET',
            headers: queryHeaders
        });
        if (res.ok) {
            const response = await res.json();
            dispatch({
                type: GET_CP,
                payload: response
            });
        }
    } catch (err) {
        console.error(err.message);
    }
}

export const updateRenderHtml = id => async dispatch => {
    await fetch(`${config.url}update-render/${id}`, {
        method: 'PUT',
        headers: queryHeaders
    });
}