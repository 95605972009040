import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import "./News.scss";
import { getNewsData } from "../../../redux/actions/actions";
import config from "../../../config";
import { Link } from "react-router-dom";
import hedelix from "../Main/img/hedelix_04-09-2012.webp";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import moment from "moment";
import { withTranslation } from "react-i18next";
import AdsBlock from "../AdsBlock/AdsBlock";

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            popularRequests: [],
        };

        window.addEventListener("resize", () => {
            this.setState({
                number_of_columns: window.innerWidth > 768 ? 3 : 2,
            });
        });
    }

    componentDidMount() {
        console.log("this.", this.props.match);
        this.props.getNewsData(
            this.props.match.params.id,
            this.props.match.params.type
        );
    }

    render() {
        const { lang, dictionary, newsData, t } = this.props;
        let data = {
            header: {
                ru: "Информация",
                az: "Məlumat",
            },
        };

        const getCurrent = (date) => {
            const currentDate = moment(date).format("DD.MM.YYYY");
            return currentDate === moment().format("DD.MM.YYYY")
                ? t("Today")
                : currentDate;
        };

        return (
            newsData && (
                <div className="news_container">
                    <CardHeader className="bg-white border-0 padding-0 display-block">
                        <h1 className="mainTitleNews">
                            {newsData.title ? newsData.title[lang] : ""}
                        </h1>
                        <div>
                            <span className="news_date">
                                {getCurrent(newsData.Datetime)}
                            </span>
                            <Link
                                className="link"
                                to={
                                    "/drugs/news/_/types=" +
                                    (newsData.Type
                                        ? newsData.Type.toLowerCase()
                                        : "")
                                }
                            >
                                <span className="news_type">
                                    {t(newsData.Type)}
                                </span>
                            </Link>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {newsData.text ? (
                            <div className="last-news-main">
                                <div className="last-news-main__news">
                                    {/* <img
                                    className="article-image"
                                    src={newsData.image ? `${config.url}${newsData.image.url}` : undefined}
                                    alt={newsData.image ? newsData.image.name : ""}
                                /> */}
                                    <p
                                        className="article-content"
                                        dangerouslySetInnerHTML={{
                                            __html: newsData.text[lang],
                                        }}
                                    />
                                    <div className="main_content">
                                        <CardHeader className="bg-white border-0 display-block">
                                            <h1 className="mb-0 news_title">
                                                {
                                                    dictionary.allNews
                                                        .popularRequests[lang]
                                                }
                                            </h1>
                                        </CardHeader>
                                        <div className="news_body">
                                            <div className="popular_requests_container">
                                                <PopularRequests type="news" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='last-news-main__sda'> */}
                                <AdsBlock />
                                {/* </div> */}
                            </div>
                        ) : (
                            ""
                        )}
                    </CardBody>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
    newsData: state.drugReducer.newsData,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getNewsData: (articleId, Type) => {
            dispatch(getNewsData(articleId, Type));
        },
    };
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(News)
);
