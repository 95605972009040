import React from "react";
import { connect } from "react-redux";
import "./AdsBlock.scss";
import config from "./../../../config";


class Ads extends React.Component {
    render() {
        const { ads, size } = this.props;

        const sized = ads.filter(a => a.size_type === size || !a.size_type);
        const selectedAd = sized && sized.length ? sized[Math.floor(Math.random() * sized.length)]: null;

        if(!selectedAd) {
            return <div></div>;
        }

        const mobileImage = selectedAd.mobile_image ? config.url + selectedAd.mobile_image.url : null;
        const bigImage = selectedAd.big_image ? config.url + selectedAd.big_image.url : null;

        return (
            <div className="sda_placeholder">
                {selectedAd && bigImage && <>
                    {selectedAd.url && <a href={selectedAd.url}>
                        <img src={bigImage} />
                    </a>}
                    {!selectedAd.url && <>
                        <img src={bigImage} />
                    </>}
                </>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    ads: state.adsReducer.ads
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
