import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

export default function (
    object,
    treeBranch,
    openHandler,
    closeHandler,
    lang,
    searchMP,
    foundDrugs,
    searchResults,
    propName,
    getChildren,
    childrenData,
    drugDetails
) {
    const renderTree = (nodes) => {
        let tree = [];
        for (let i = 0; i < nodes.length; i++) {
            if (
                (!Array.isArray(nodes[i][propName]) ||
                    nodes[i][propName].length === 0) &&
                treeBranch[nodes[i].parent.id] &&
                !nodes[i].done
            ) {
                console.log("nodes[i]", nodes[i]);
                nodes[i].done = true;
                searchMP(nodes[i]);
            }
            tree.push(
                <ul key={nodes[i].id} className="classes_page__tree">
                    <li>
                        <label
                            onClick={() => {
                                if (!childrenData[nodes[i].id]) {
                                    getChildren(nodes[i].id);
                                }
                                treeBranch[nodes[i].id]
                                    ? closeHandler(nodes[i].id)
                                    : openHandler(nodes[i].id);
                            }}
                        >
                            {nodes[i][propName].length > 0 ? (
                                <>
                                    <span>
                                        {treeBranch[nodes[i].id] ? (
                                            <RemoveIcon />
                                        ) : (
                                            <AddIcon />
                                        )}
                                    </span>
                                    {nodes[i].code}{" "}
                                    {nodes[i].description &&
                                        ` - ${nodes[i].description[lang]}`}
                                </>
                            ) : (
                                <>
                                    {nodes[i][propName].length === 0 ? (
                                        nodes[i].description ? (
                                            <>
                                                {nodes[i].code}
                                                <Link
                                                    to={`/categories-search-result/${drugDetails}=${
                                                        drugDetails ===
                                                        "atcCode"
                                                            ? nodes[i].code
                                                            : nodes[i].id
                                                    }`}
                                                >
                                                    {` - ${nodes[i].description[lang]}`}
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <Link
                                                    to={`/categories-search-result/${drugDetails}=${
                                                        drugDetails ===
                                                        "atcCode"
                                                            ? nodes[i].code
                                                            : nodes[i].id
                                                    }`}
                                                >
                                                    {`${nodes[i].code}`}
                                                </Link>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            {nodes[i].code}{" "}
                                            {nodes[i].description &&
                                                ` - ${nodes[i].description[lang]}`}
                                        </>
                                    )}
                                </>
                            )}
                        </label>
                        {treeBranch[nodes[i].id] &&
                        Array.isArray(childrenData[nodes[i].id])
                            ? renderTree(childrenData[nodes[i].id])
                            : null}
                    </li>
                </ul>
            );
        }
        return tree;
    };

    return (
        <div className="classes_page__classification">{renderTree(object)}</div>
    );
}
