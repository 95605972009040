import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane,
    Card, CardBody, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { editMarketingAuthorisation, searchMarketingAuthorisations, getMarketingAuthorisationsById, addingNewMarketingAuthorisation } from '../../../../redux/actions/adminActions';


const PLMarketingAuthorisations = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [languageTab, setTab] = useState('az');
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    const [tmpMarketingAuthorisation, setTmpMarketingAuthorisation] = useState({
        az: '', ru: '', id: null,
        holder: { az: '', ru: '' },
        authorisationNumber: '',
        old: {id: null, az: '', ru: ''}
    });
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [suggest, setSuggest] = useState(false);
    let marketingAuthorisationIdsArray = [];
    const defaultTmp = {
        az: '', ru: '', id: null,
        holder: { az: '', ru: '' },
        authorisationNumber: '',
        old: {id: null, az: '', ru: ''}
    };

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    };
    
    // mapStateToProps
    const {marketingAuthorisationsById, marketingAuthorisationsSearchResult} = useSelector(state => ({
        marketingAuthorisationsById: state.adminReducer.marketingAuthorisationsById,
        marketingAuthorisationsSearchResult: state.adminReducer.marketingAuthorisationsSearchResult
    }));

    const dispatch = useDispatch();

    // создание массива ингредиентных id
    if (patientLeaflet && patientLeaflet.marketingAuthorisation) {
        patientLeaflet.marketingAuthorisation.map(man => man.marketing_authorisation ? marketingAuthorisationIdsArray.push(man.marketing_authorisation.id) : '');
    }

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchMarketingAuthorisations(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    useEffect(() => {
        if (patientLeaflet) {
            dispatch(getMarketingAuthorisationsById(marketingAuthorisationIdsArray));
        }
    }, [patientLeaflet]);
    
    // кнопка создания нового ингредиента
    // открытие модального окна
    const addMarketingAuthorisationButtonHandler = () => {
        setTmpMarketingAuthorisation(defaultTmp); // вычищаем хранилище
        setSearchString(null); // вычищаем поисковую строку
        setModal(true);
    }

    const addNewMarketingAuthorisation = () => {
        if (tmpMarketingAuthorisation.az) {
            dispatch(addingNewMarketingAuthorisation(tmpMarketingAuthorisation, patientLeaflet));
            setModal(false);
            //props.toggleSave(true);
        }
    }

    // кнопка редактирования существующего ингредиента
    // открытие модального окна
    const editMarketingAuthorisationButtonHandler = index => {
        setTmpMarketingAuthorisation({
            az: marketingAuthorisationsById[index].name.az,
            ru: marketingAuthorisationsById[index].name.ru,
            id: null,
            holder: marketingAuthorisationsById[index].holder ? {...marketingAuthorisationsById[index].holder} : {az: '', ru: ''},
            authorisationNumber: marketingAuthorisationsById.authorisationNumber,
            old: {az: marketingAuthorisationsById[index].name.az, ru: marketingAuthorisationsById[index].name.ru, id: marketingAuthorisationsById[index].id}
        }); // присваиваем к хранилищу текущий ингредиент
        setSearchString(null); // вычищаем поисковую строку
        setEditModal(true);
        setActiveIndex(index);
    }

    const editCurrentMarketingAuthorisation = () => {
        dispatch(editMarketingAuthorisation(activeIndex, tmpMarketingAuthorisation, patientLeaflet));
        setEditModal(false);
        setActiveIndex(null);
    }

    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({marketingAuthorisation: patientLeaflet.marketingAuthorisation.filter((man, i) => i !== index)});
        props.toggleSave(true);
    };

    const selectMarketingAuthorisation = marketingAuthorisation => {
        setTmpMarketingAuthorisation({...tmpMarketingAuthorisation, ...marketingAuthorisation.name, id: marketingAuthorisation.id, authorisationNumber: marketingAuthorisation.authorisationNumber})
    }

    return (
        <Row>
            <Col xs='12' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        {patientLeaflet.marketingAuthorisation ? 
                            patientLeaflet.marketingAuthorisation.map((marketingAuthorisationI, key) => MarketingAuthorisationElement({
                                lang, languageTab, dictionary, key, marketingAuthorisationI, marketingAuthorisationById: marketingAuthorisationsById ? marketingAuthorisationsById[key] : null,
                                editMarketingAuthorisationButtonHandler, deleteButtonHandler
                            })) : ''}
                        <Button onClick={addMarketingAuthorisationButtonHandler} className='mt-2' size='lg' block color='primary'><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.addMarketingAuthorisation[lang]} </Button>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}</ModalHeader>
                <ModalBody>{MarketingAuthorisationAddEditElement({
                    lang, languageTab, dictionary,
                    searchString, setSearchString, 
                    tmpMarketingAuthorisation, setTmpMarketingAuthorisation,
                    suggest, selectMarketingAuthorisation,
                    marketingAuthorisationsSearchResult})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewMarketingAuthorisation}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpMarketingAuthorisation(defaultTmp); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editModal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}</ModalHeader>
                <ModalBody>{MarketingAuthorisationAddEditElement({
                    lang, languageTab, dictionary,
                    searchString, setSearchString, 
                    tmpMarketingAuthorisation, setTmpMarketingAuthorisation,
                    suggest, selectMarketingAuthorisation,
                    marketingAuthorisationsSearchResult})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={editCurrentMarketingAuthorisation}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpMarketingAuthorisation(defaultTmp); setEditModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

const MarketingAuthorisationElement = props => {
    const {lang, languageTab, dictionary, key, marketingAuthorisationI, marketingAuthorisationById, deleteButtonHandler, editMarketingAuthorisationButtonHandler} = props;

    return (
        <Row className='mt-2' key={key}>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <FontAwesomeIcon className='float-right pointer' icon={faTrash}
                                onClick={deleteButtonHandler.bind(this, key)}/>
                            <FontAwesomeIcon className='float-right mr-3 pointer' icon={faEdit}
                                onClick={editMarketingAuthorisationButtonHandler.bind(this, key)} />
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchMarketingAuthorisationAz_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={marketingAuthorisationById ? marketingAuthorisationById.name.az : ''}
                                    />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.holder[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        disabled
                                        data={marketingAuthorisationById && marketingAuthorisationById.holder && marketingAuthorisationById.holder.az ? marketingAuthorisationById.holder.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchMarketingAuthorisationRu_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={marketingAuthorisationById ? marketingAuthorisationById.name.ru : ''}
                                    />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.holder[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        disabled
                                        data={marketingAuthorisationById && marketingAuthorisationById.holder && marketingAuthorisationById.holder.ru ? marketingAuthorisationById.holder.ru : ''} />  
                                </TabPane>
                            </TabContent>
                            <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.authorisationNumber[lang]}`}</Label>
                            <Input
                                type='text'
                                autoComplete='off'
                                id={`searchMarketingAuthorisationNumber_${key}`}
                                placeholder={''}
                                disabled
                                value={marketingAuthorisationById ? marketingAuthorisationById.authorisationNumber : ''}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

const MarketingAuthorisationAddEditElement = props => {
    const {
        lang, languageTab, dictionary,
        searchString, setSearchString, 
        tmpMarketingAuthorisation, setTmpMarketingAuthorisation,
        suggest, selectMarketingAuthorisation,
        marketingAuthorisationsSearchResult
    } = props;

    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchMarketingAuthorisationAz`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            searchString !== null ? searchString :
                                            tmpMarketingAuthorisation.az ? tmpMarketingAuthorisation.az : ''
                                        }
                                        onChange={e => {
                                            setTmpMarketingAuthorisation({...tmpMarketingAuthorisation, az: e.target.value});
                                            setSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && marketingAuthorisationsSearchResult ? marketingAuthorisationsSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectMarketingAuthorisation(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.holder[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTmpMarketingAuthorisation({
                                                ...tmpMarketingAuthorisation, holder: {
                                                    ...tmpMarketingAuthorisation.holder, az: data
                                                }});
                                        }}
                                        data={tmpMarketingAuthorisation && tmpMarketingAuthorisation.holder ? tmpMarketingAuthorisation.holder.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='text'
                                        id={`marketingAuthorisationRu`}
                                        placeholder=''
                                        value={tmpMarketingAuthorisation ? tmpMarketingAuthorisation.ru : ''}
                                        onChange={e => {
                                            setTmpMarketingAuthorisation({...tmpMarketingAuthorisation, ru: e.target.value})
                                        }} /> 
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.holder[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTmpMarketingAuthorisation({
                                                ...tmpMarketingAuthorisation, holder: {
                                                    ...tmpMarketingAuthorisation.holder, ru: data
                                                }});
                                        }}
                                        data={tmpMarketingAuthorisation && tmpMarketingAuthorisation.holder ? tmpMarketingAuthorisation.holder.ru : ''} />  
                                </TabPane>
                            </TabContent>
                            <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.authorisationNumber[lang]}`}</Label>
                            <Input
                                type='text'
                                autoComplete='off'
                                id={`searchMarketingAuthorisationNumber`}
                                placeholder={''}
                                value={tmpMarketingAuthorisation ? tmpMarketingAuthorisation.authorisationNumber : ''}
                                onChange={e => setTmpMarketingAuthorisation({...tmpMarketingAuthorisation, authorisationNumber: e.target.value})}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default PLMarketingAuthorisations;