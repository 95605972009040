export const AZ_ALPHABET = [
    "A",
    "B",
    "C",
    "Ç",
    "D",
    "E",
    "Ə",
    "F",
    "G",
    "Ğ",
    "H",
    "X",
    "I",
    "İ",
    "J",
    "K",
    "Q",
    "L",
    "M",
    "N",
    "O",
    "Ö",
    "P",
    "R",
    "S",
    "Ş",
    "T",
    "U",
    "Ü",
    "V",
    "Y",
    "Z",
    "0-9",
];
export const RU_ALPHABET = [
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Ё",
    "Ж",
    "З",
    "И",
    "Й",
    "К",
    "Л",
    "М",
    "Н",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Ш",
    "Щ",
    "Ъ",
    "Ы",
    "Ь",
    "Э",
    "Ю",
    "Я",
    "0-9",
];

export const NUMBERS_0_9 = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
