import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane
} from 'reactstrap';


const PLHowToStoreIt = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [howToStoreIt, setHowToStoreIt] = useState(patientLeaflet.howToStoreIt ? {...patientLeaflet.howToStoreIt} : {az: '', ru: ''});
    const [languageTab, setTab] = useState('az');

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }
    
    const submitButtonHandler = () => {
        props.changeLeaflet({howToStoreIt: {...howToStoreIt, ru: howToStoreIt.ru || howToStoreIt.az}});
        props.toggleSave(true);
    }
    
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                                <Label className='mt-2' for='whatIsItAz'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToStoreIt.title[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setHowToStoreIt({...howToStoreIt, az: data});
                                    }}
                                    data={howToStoreIt.az} />
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label className='mt-2' for='whatIsItRu'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToStoreIt.title[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setHowToStoreIt({...howToStoreIt, ru: data});
                                    }}
                                    data={howToStoreIt.ru} />
                            </TabPane>
                        </TabContent>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

export default PLHowToStoreIt;