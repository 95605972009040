import React, { useState, useEffect } from 'react';
import {Form, FormGroup, Container, Row, Col, Input, Label, Button} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { authorisation, getUserData } from '../../../redux/actions/userAction';
import { Redirect } from 'react-router-dom';

const AdminLogin = props => {
    
    const {lang, dictionary, token, userData, authorized} = useSelector(state => ({
        dictionary: state.dictionaryReducer,
        lang: state.languageReducer.language,
        token: state.userReducer.token,
        userData: state.userReducer.userData,
        authorized: state.userReducer.authorized
    }));

    const [loginData, setData] = useState({
        username: null,
        password: null
    });

    const dispatch = useDispatch();

    const formSubmitHandler = event => {
        event.preventDefault();
        dispatch(authorisation(loginData.username.toLowerCase(), loginData.password));
    }
    
    useEffect(() => {
        if (token && !userData) dispatch(getUserData(token));
    });

    if (!authorized) return(
        <Container>
            <Row className='mt-5'>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <Form onSubmit={formSubmitHandler}>
                        <FormGroup>
                            <Label>{dictionary.login.username[lang]}</Label>
                            <Input type="text" 
                                defaultValue={loginData.username}
                                onChange={e => setData({...loginData, username: e.target.value})} 
                                placeholder={dictionary.login.username[lang]} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{dictionary.login.password[lang]}</Label>
                            <Input type="password"
                                defaultValue={loginData.password}
                                onChange={e => setData({...loginData, password: e.target.value})} 
                                placeholder={dictionary.login.password[lang]} />
                        </FormGroup>
                        <Button type="submit" color="success">{dictionary.login.singIn[lang]}</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
    else return (
        <Redirect to={`/admin`} />
    )
}

export default AdminLogin;