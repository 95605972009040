import {
    GET_ATC,
    GET_ATC_ID,
    GET_CP,
    GET_CP_ID,
    GET_ICD,
    GET_ICD_ID,
} from "../actions/action-types";

const INITIAL_STATE = {
    atcCodes: [],
    icdCodes: [],
    cpCodes: [],
    children: { CP: {}, ICD: {}, ATC: {} },
};

export function classifications(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ATC:
            return { ...state, atcCodes: payload };
        case GET_ICD:
            return { ...state, icdCodes: payload };
        case GET_CP:
            return { ...state, cpCodes: payload };
        case GET_ATC_ID:
            return {
                ...state,
                children: {
                    ...state.children,
                    ATC: {
                        ...state.children.ATC,
                        [payload.id]: [...payload.children],
                    },
                },
            };
        case GET_ICD_ID:
            return {
                ...state,
                children: {
                    ...state.children,
                    ICD: {
                        ...state.children.ICD,
                        [payload.id]: [...payload.children],
                    },
                },
            };
        case GET_CP_ID:
            return {
                ...state,
                children: {
                    ...state.children,
                    CP: {
                        ...state.children.CP,
                        [payload.id]: [...payload.children],
                    },
                },
            };
        default:
            return state;
    }
}
