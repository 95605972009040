import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import "./DropDownSelect.scss";
import { WithTranslation, withTranslation } from "react-i18next";

class MiniDropDownSelect extends React.Component {
    render() {
        const {
            input: { onChange, onBlur, value },
            label,
            valuesForDropdown,
            onClose,
            type,
            selectedValue,
            meta: { error, touched, valid },
            _className,
            name,
            placeholder,
            t,
        } = this.props;

        const errorClassName = !valid && touched ? "error" : "";

        if (valuesForDropdown && valuesForDropdown.length) {
            valuesForDropdown.sort((a, b) =>
                (a.label || "").localeCompare(b.label)
            );
        }

        let sel;

        if (type === "multiselect") {
            const splited = (value || "").split(",");
            const options = valuesForDropdown.map((o) => ({
                id: o.value,
                name: o.label,
            }));

            sel = (
                <Multiselect
                    options={options}
                    selectedValues={options.filter(
                        (o) => splited.indexOf(o.id.toString()) >= 0
                    )}
                    placeholder={placeholder}
                    onSelect={(v) => onChange(v.map((o) => o.id).join(","))}
                    onRemove={(v) => onChange(v.map((o) => o.id).join(","))}
                    displayValue="name"
                />
            );
        } else {
            sel = (
                <select
                    className={errorClassName}
                    name={name}
                    onChange={onChange}
                >
                    <option
                        key={"not-selected"}
                        selected={!value && value !== 0}
                        value={""}
                    >
                        {t("Not selected")}
                    </option>
                    {valuesForDropdown &&
                        valuesForDropdown.map((i) => {
                            return (
                                <option
                                    key={i.value}
                                    selected={
                                        i.value === value ||
                                        (value && i.value === value.id)
                                    }
                                    value={i.value}
                                >
                                    {i.label}
                                </option>
                            );
                        })}
                </select>
            );
        }

        return (
            <div
                className={
                    "mini-text-input-component reg-input-styles" +
                    " " +
                    _className
                }
            >
                <p>{label}</p>
                {sel}
                {!valid && touched ? (
                    <p
                        className={
                            "text-input-component__error" +
                            " " +
                            this.props.className
                        }
                    >
                        {error}
                    </p>
                ) : null}
            </div>
        );
    }
}

export default withTranslation()(MiniDropDownSelect);
