import React from "react";
import config from "../../../config";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

export class PopularRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            number_of_columns: window.innerWidth > 768 ? 4 : 2,
            popularRequests: [],
        };

        this.onResize = this.onResize.bind(this);
        window.addEventListener("resize", this.onResize);
    }

    onResize() {
        this.setState({
            number_of_columns: window.innerWidth > 768 ? 4 : 2,
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    async componentDidMount() {
        let request = await fetch(
            `${config.url}popular-search-results?_sort=requestNumber:desc&type=${this.props.type}&_limit=40`
        );
        let data = await request.json();

        this.setState({
            popularRequests: data,
        });
    }

    render() {
        const requests = this.state.popularRequests || [];

        let number_of_columns = this.state.number_of_columns;
        let number_of_rows = Math.ceil(requests.length / number_of_columns);
        let rows = [];
        for (let i = 0; i < number_of_rows; i++) {
            let columns = [];
            for (let f = 0; f < number_of_columns; f++) {
                let current_request = requests[i * number_of_columns + f];
                if (current_request) {
                    columns.push(
                        <Col key={`${i}/${f}`}>
                            {/* there i cut /types=${this.props.result || this.props.type} */}
                            <Link
                                to={`/drugs/search-results/${current_request.request}`}
                            >
                                {current_request.request}
                            </Link>
                        </Col>
                    );
                } else {
                    columns.push(<Col key={`${i}/${f}`}></Col>);
                    break;
                }
            }
            rows.push(<Row key={`${i}`}>{columns}</Row>);
        }
        return rows;
    }
}
