import config from '../../config';
import moment from "moment";
import { authFetch } from "./vd-auth";
import { DictionaryTypesEnum } from "../models/dictionary-types.enum";
//import { getIndicatorsInfo } from "./indicator-actions";
import i18n from "i18next";
import { setLanguage } from './simpleActions';

export const FONT_SIZE = 'FONT_SIZE';

const _fontSize = fontSize => {
    return {
        type: FONT_SIZE,
        fontSize,
    };
};

export const fontSize = fontSize => dispatch => {
    localStorage.setItem('az.ezgil.dermanlarim.fontSize', fontSize ? fontSize.toString() : '13');
    // console.log('fontSize: ', fontSize);
    dispatch(_fontSize(fontSize));
};

export const language = language => async(dispatch, getState) => {
    if (!language) {
        return;
    }
    moment.locale(language);
    localStorage.setItem('az.ezgil.dermanlarim.language', language);
    dispatch(setLanguage(language));
    i18n.changeLanguage(language);
    let tokens = (getState().auth || {}).userToken;

    if (!tokens || !tokens.auth) {
        tokens = JSON.parse(localStorage.getItem("az.ezgil.dermanlarim.token") || '{}');

        if (!tokens || !tokens.auth) return;
    }

    const response = await authFetch(getState, dispatch, config.auth + 'changeLanguage', 'POST', { language });
};