import React from "react";
import { connect } from "react-redux";
import "./WithoutConditions.scss";
import config from "../../../config";
import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import DrugsCategories from "../Elements/DrugsCategories";
import { withTranslation } from "react-i18next";

class WithoutConditions extends React.Component {
    constructor(props) {
        super(props);
        this.catchSearchLetter = this.catchSearchLetter.bind(this);
        this.state = {
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            news: [],
        };
    }
    componentDidMount = () => {
        fetch(`${config.url}rsses`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ news: data });
            });
    };

    catchSearchLetter(searchLetter) {
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    render() {
        const { dictionary, lang, t } = this.props;

        return (
            <div className="without_conditions_page_container">
                <div className="without_conditions_page">
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">{t("OTC drugs")}</h1>
                    </div>
                    <div className="drugs-description">
                        {t("without_conditions_body")}{" "}
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {t("Search the OTC Recommendations Database")}
                        </h1>
                    </div>
                    <div className="drugs-description search_card">
                        <SearchField searchTypes="withoutPrescription"></SearchField>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pharmaCompanies.alphabetTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        <div className="alphabet-block-withoutcondition alphabet_block">
                            {alphabeticalLoop(
                                lang,
                                letterJsx,
                                this.catchSearchLetter,
                                undefined,
                                this.props.lettersCount
                            )}
                            <button
                                onClick={() =>
                                    this.props.history.push(
                                        "/drugs/advanced-search"
                                    )
                                }
                                className="alphabet_button advanced-search-alphabet-button live-button"
                                value={dictionary.advancedSearch[lang]}
                            >
                                {dictionary.advancedSearch[lang]}
                            </button>
                        </div>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.withoutConditions.infoTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.withoutConditions.infoBody[lang]}
                    </div>
                    <DrugsCategories />
                    <div className="main_content_sda_wrapper">
                        <div className="main_content">
                            <div className="bg-white border-0">
                                <h1 className="mb-0 drugs-header">
                                    {
                                        dictionary.withoutConditions
                                            .popularRequestsTitle[lang]
                                    }
                                </h1>
                            </div>
                            <div className="without_conditions_body">
                                <PopularRequests type="without_conditions" />
                            </div>
                        </div>
                    </div>
                </div>
                <AdsBlock></AdsBlock>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
});

export default connect(mapStateToProps)(withTranslation()(WithoutConditions));
