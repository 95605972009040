import { GET_LETTERS_COUNT } from "../actions/action-types";
import { errorAction } from "../actions/actions";
import config from "../../config";

const INITIAL_STATE = {
    alphabet: {
        en: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
        ],
        ru: [
            "А",
            "Б",
            "В",
            "Г",
            "Д",
            "Е",
            "Ё",
            "Ж",
            "З",
            "И",
            "Й",
            "К",
            "Л",
            "М",
            "Н",
            "О",
            "П",
            "Р",
            "С",
            "Т",
            "У",
            "Ф",
            "Х",
            "Ц",
            "Ч",
            "Ш",
            "Щ",
            "Ъ",
            "Ы",
            "Ь",
            "Э",
            "Ю",
            "Я",
        ],
        az: [
            "A",
            "B",
            "C",
            "Ç",
            "D",
            "E",
            "Ə",
            "F",
            "G",
            "Ğ",
            "H",
            "X",
            "I",
            "İ",
            "J",
            "K",
            "Q",
            "L",
            "M",
            "N",
            "O",
            "Ö",
            "P",
            "R",
            "S",
            "Ş",
            "T",
            "U",
            "Ü",
            "V",
            "Y",
            "Z",
        ],
    },
    lettersCount: [],
};

const searchByLetterReducer = (state = INITIAL_STATE, action) => {
    if (action.type === GET_LETTERS_COUNT) {
        return { ...state, lettersCount: action.payload };
    }
    return state;
};

export default searchByLetterReducer;
