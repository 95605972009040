import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import "./DrugsCategories.scss";

class DrugsCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            popularRequests: [],
        };

        window.addEventListener("resize", () => {
            this.setState({
                number_of_columns: window.innerWidth > 768 ? 3 : 2,
            });
        });
    }

    getCategories = (categories) => {
        let number_of_columns = this.state.number_of_columns;
        let number_of_rows = Math.ceil(categories.length / number_of_columns);
        let rows = [];
        for (let i = 0; i < number_of_rows; i++) {
            let columns = [];
            for (let f = 0; f < number_of_columns; f++) {
                let current_category = categories[i * number_of_columns + f];
                if (current_category) {
                    columns.push(
                        <Col key={`${i}/${f}`}>
                            {!current_category.disabled && (
                                <Link to={current_category.link}>
                                    {current_category.text}
                                </Link>
                            )}
                            {current_category.disabled && (
                                <span>{current_category.text}</span>
                            )}
                        </Col>
                    );
                } else {
                    columns.push(<Col key={`${i}/${f}`}></Col>);
                    break;
                }
            }
            rows.push(<Row key={`${i}`}>{columns}</Row>);
        }
        return rows;
    };

    render() {
        const { t } = this.props;

        let menu = [
            {
                link: "/drugs/side_effects",
                text: t("Drugs' undesirable effects"),
            },
            {
                link: "/drugs/dosage_recomendations",
                text: t("Drugs' dosage"),
            },
            {
                link: "/drugs/pregnancy_and_lactation",
                text: t("Pregnancy warnings"),
            },
            {
                link: "/drugs/lactation",
                text: t("Breastfeeding warnings"),
            },
            {
                link: "/drugs/treatment_options",
                text: t("Treatment type"),
                disabled: true,
            },
            {
                link: "/drugs/classes",
                text: t("Drugs' classes"),
            },
            {
                link: "/drugs/pharma_companies",
                text: t("Drugs by manufacture"),
            },
            {
                link: "/drugs/veterenary_drugs",
                text: t("Veterinary drugs"),
                disabled: true,
            },
            {
                link: "/drugs/without_conditions",
                text: t("OTC drugs"),
            },
            // {
            //     link: "/drugs/Международная база данных лекарств",
            //     text: "Международная база данных лекарств",
            //     disabled: true,
            // },
        ];

        return (
            <>
                <div className="bg-white border-0 display-block">
                    <h1 className="mb-0 drugs-header">
                        {t("Drugs by categories")}
                    </h1>
                </div>
                <div className="drugs-description">
                    <div className="categories_container">
                        {this.getCategories(menu)}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(DrugsCategories);
