import React, {  } from "react";

import "./Loader.scss";

class Loader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <img className="pill-loader" src={require("./img/loader.webp")}></img>
        );
    }
}

export class CenterLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'pill-center-loader ' + (this.props.className || '')}>
                <Loader></Loader>
            </div>
        );
    }
}

export default Loader;
