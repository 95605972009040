import React from "react";
import { Link } from "react-router-dom";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import { connect } from "react-redux";

import "./SideEffects.scss";

import config from "../../../config";
import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import moment from 'moment';
import { withTranslation } from "react-i18next";
import DrugsCategories from "../Elements/DrugsCategories";

class SideEffects extends React.Component {
    constructor(props) {
        super(props);
        this.catchSearchLetter = this.catchSearchLetter.bind(this);
        this.state = {
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            news:[]
        };

        window.addEventListener("resize", () => {
            this.setState({
                number_of_columns: window.innerWidth > 768 ? 3 : 2
            })
        })
    }

    componentDidMount = () => {
        fetch(`${config.url}rsses`).then((response) => {
            return response.json();
        }).then((data) => {this.setState({news: data})});
    }
    catchSearchLetter(searchLetter) {
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }    

    render() {
        const { t, dictionary, lang } = this.props;
        const date = moment(this.props.Datetime);

        return (
            <div className="side_effects_container">
                <div className="side_effects_page">
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.sideEffects.sideEffectsTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.sideEffects.sideEffectsBodyStart[lang]}{" "}
                        {/* <a href="https://www.fda.gov/safety/">
                        https://www.fda.gov/safety/
                    </a>
                    {dictionary.sideEffects.sideEffectsBodyEnd[lang]}
                    {" 1-800-FDA-1088 (1-800-332-1088)."} */}
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.sideEffects.searchTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description search_card">
                        <SearchField searchTypes="sideeffect"></SearchField>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.sideEffects.alphabetTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        <div className="alphabet-block-side-effect alphabet_block">
                            {alphabeticalLoop(
                                lang,
                                letterJsx,
                                this.catchSearchLetter,
                                undefined,
                                this.props.lettersCount
                            )}
                            <button
                                onClick={() => this.props.history.push("/drugs/advanced-search")}
                                className="alphabet_button advanced-search-alphabet-button live-button"
                                value={dictionary.advancedSearch[lang]}
                            >
                                {dictionary.advancedSearch[lang]}
                            </button>
                        </div>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.sideEffects.infoTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.sideEffects.infoBody[lang]}
                    </div>
                    <DrugsCategories />
                    <div>
                        <div className="main_content">
                            <div className="bg-white border-0 display-block">
                                <h1 className="mb-0 side_effects_title">
                                    {dictionary.sideEffects.popularRequestsTitle[lang]}
                                </h1>
                            </div>
                            <div className="side_effects_body drugs-description">
                                <PopularRequests type="undesirable" result="sideeffect" />
                            </div>
                        </div>
                        <div className="side_effects_news">
                            {this.state.news.map((el) => (
                                <div className='last-news-content'>
                                <Link to={`/drugs/one_news/${el.id}`} className='news-title'>
                                <div dangerouslySetInnerHTML={{ __html: el.title[lang] }}></div>
                                </Link>
                                <div dangerouslySetInnerHTML={{ __html: el.miniText[lang] }}></div>
                                <p className='news-date'>{el.Datetime && moment(el.Datetime) ? moment(el.Datetime).format('DD.MM.YYYY') :  t("Empty date")} <Link to={'/'}>Medical</Link></p>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="side_effects_page__sda">
                    <AdsBlock component={<div></div>}></AdsBlock> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
});

export default withTranslation()(connect(mapStateToProps)(SideEffects));
