import React from "react";

import { CardBody, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { getSearchResult } from "../../../redux/actions/actions";
import { SEARCH_RESULT_ARRAY } from "../../../redux/actions/action-types";
import { CenterLoader } from "../Loader/Loader";
import { PagesBlock } from "../SearchCommon/PagesBlock";

class SearchList extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getSearchResult(
            this.props.params.id,
            this.props.params.params,
            0,
            this.props.params.autoC
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(this.props.params) !==
            JSON.stringify(prevProps.params)
        ) {
            this.componentDidMount();
            //this.props.history.go(0);
        }
    }

    render() {
        const {
            searchResults,
            title,
            itemRender,
            searchTotal,
            loading,
            searchPage,
            lang,
            dictionary,
            params,
        } = this.props;
        return (
            <>
                {title && (
                    <CardHeader className="bg-transparent border-0 clearBottomPadding">
                        <h1 className="main-title-search-results">
                            {title +
                                " " +
                                (searchTotal &&
                                !loading.subjects.has(SEARCH_RESULT_ARRAY)
                                    ? searchTotal
                                    : "...")}
                        </h1>
                    </CardHeader>
                )}
                <div style={{ padding: "25px" }}>
                    {loading.subjects.has(SEARCH_RESULT_ARRAY) && (
                        <CenterLoader className="loading-search-results"></CenterLoader>
                    )}
                    {!loading.subjects.has(SEARCH_RESULT_ARRAY) &&
                        searchResults &&
                        searchResults.length &&
                        searchResults.map(
                            (item, index) => itemRender(item, index),
                            this
                        )}

                    {!loading.subjects.has(SEARCH_RESULT_ARRAY) &&
                        searchResults &&
                        searchResults.length === 0 && (
                            <h3> {dictionary.searchNotFound[lang]} </h3>
                        )}

                    {!!searchTotal &&
                        searchTotal > 1 &&
                        !loading.subjects.has(SEARCH_RESULT_ARRAY) && (
                            <PagesBlock
                                searchTotal={searchTotal}
                                searchPage={searchPage}
                                changePage={(page) =>
                                    this.props.getSearchResult(
                                        this.props.params.id,
                                        this.props.params.params,
                                        page
                                    )
                                }
                            />
                        )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    searchResults: state.drugReducer.searchResultArray,
    searchPage: state.drugReducer.searchPage,
    searchTotal: state.drugReducer.searchTotal,
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
    loading: state.loadingReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getSearchResult: (pattern, params, page, autoC) => {
            dispatch(getSearchResult(pattern, params, page, autoC));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
