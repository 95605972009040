import React from "react";
import { Link } from "react-router-dom";
import {
    Navbar,
    Container,
    NavLink,
    UncontrolledCollapse,
    Row,
    Col,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { authorized, _auth } from "../../../redux/actions/userAction";

const AdminNavbar = (props) => {
    const dispatch = useDispatch();

    // const { dictionary, lang } = useSelector(
    //     (state) => ({
    //         dictionary: state.dictionaryReducer,
    //         lang: state.languageReducer.language
    //     })
    // );

    // const changeLanguageHandler = (lg) => {
    //     dispatch(setLanguage(lg));
    // };

    const logoutHandler = () => {
        dispatch(_auth(null));
        dispatch(authorized(false));
        localStorage.removeItem("usertoken");
        localStorage.removeItem("autorized");
    };

    return (
        <Navbar
            className="navbar-top navbar-dark"
            expand="md"
            id="navbar-main">
            <Container>
                <NavLink
                    className="h1 mt--1 mb-0 text-greyish text-uppercase d-lg-inline-block"
                    to="/admin"
                    tag={Link}>
                    {props.brandText}
                </NavLink>
                {/* 
                <NavLink
                    className="h4 mb-0 text-greyish d-lg-inline-block pointer"
                    onClick={changeLanguageHandler.bind(this, "az")}>
                    Azərbaycanca
                </NavLink>
                <NavLink
                    className="h4 mb-0 text-greyish d-lg-inline-block pointer"
                    onClick={changeLanguageHandler.bind(this, "ru")}>
                    Русский
                </NavLink> */}

                <button
                    className="navbar-toggler"
                    id="navbar-collapse-main">
                    <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse
                    navbar
                    toggler="#navbar-collapse-main">
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            <Col className="collapse-close" xs="6">
                                <button
                                    className="navbar-toggler"
                                    id="navbar-collapse-main">
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>
                    {/* <Nav className="ml-auto mt-2" navbar>
                        <NavItem>
                            <NavLink
                                className="nav-link-icon"
                                to="/user/profile"
                                tag={Link}>
                                <span className="nav-link-inner--text">
                                    {dictionary.userComponent.profile[lang]}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="ml-2 mt--2 p-2 border rounded">
                            <NavLink
                                className="nav-link-icon"
                                onClick={logoutHandler}
                                to="/login"
                                tag={Link}>
                                <span className="nav-link-inner--text">
                                    {dictionary.userComponent.exit[lang]}
                                </span>
                            </NavLink>
                        </NavItem>
                    </Nav> */}
                </UncontrolledCollapse>
            </Container>
        </Navbar>
    );
};

export default AdminNavbar;
