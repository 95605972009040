import React, {useState, useEffect} from 'react';
import {
    Button, Col, Row, Label, Input, Card, CardBody, Nav, NavItem, NavLink,
    TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter,
    ListGroup, ListGroupItem, Form, FormGroup
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {searchExcipients, getExcipientsById, addExcipients} from '../../../../redux/actions/adminActions'

const PLExcipients = props => {
    const {lang, dictionary, patientLeaflet} = props;
    // переключатель языков для полей
    const [languageTab, setTab] = useState('az');
    // открыть или закрыть модалку создания
    const [modal, setModal] = useState(false);
    // эта хрень нужна во время редактирования, чтобы мы знали какой ингредиент именно мы пытаемся редактировать
    const [activeIndex, setActiveIndex] = useState();
    // показать и скрывать выпадашку
    const [suggest, setSuggest] = useState(false);
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    // здесь будут храниться поля нового ингредиента перед сохранением
    const [tmpExcipient, setTmpExcipient] = useState({
        az: '', ru: '',
        categoryAz: '', categoryRu: '',
        id: '', 
        Caption: ''
    });
    // здесь будут храниться поля отредактированных ингредиентов перед сохранением
    const [tmpExcipientArray, setTmpExcipientArray] = useState([]);
    // это чтобы понять изменился ли лефлет, нужен для ингредиентов и прочего
    //const prevLeaflet = useRef();
    //массив айдишек
    let excipientsIds = [];

    // mapStateToProps
    const {excipientSearchResult, excipientsById} = useSelector(state => ({
        excipientSearchResult: state.adminReducer.excipientSearchResult,
        excipientsById: state.adminReducer.excipientsById
    }));

    const dispatch = useDispatch();

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }

    // кнопка создания нового ингредиента
    // открытие модального окна
    const addExcipientButtonHandler = () => {
        setTmpExcipient({
            az: '', ru: '',
            categoryAz: '', categoryRu: '',
            id: '', 
            Caption: ''}); // вычищаем хранилище
        setSearchString(''); // вычищаем поисковую строку
        setModal(true);
    }

    // выбрать ингредиент из выпадашки
    // возможно эта хрень отредактируется
    const selectExcipient = exc => {
        setTmpExcipient({...tmpExcipient,
            Caption: exc.Caption,
            az: exc.name.az,
            ru: exc.name.ru,
            id: exc.id
        });
        setSuggest(false);
    }

    // создание массива ингредиентных id
    if (patientLeaflet && patientLeaflet.excipients) {
        patientLeaflet.excipients.map(exc => exc.excipient ? excipientsIds.push(exc.excipient.id) : '');
    }
    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchExcipients(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    useEffect(() => {
        if (patientLeaflet) {
            dispatch(getExcipientsById(excipientsIds));
        }
    }, [patientLeaflet]);

    // добавление нового ингредиента
    const addNewExcipient = () => {
        let newExcipients = [];
        const excAz = tmpExcipient.az.split(',');
        const excRu = tmpExcipient.ru.split(',');
        excAz.map((exc, i) => {
            newExcipients.push({
                category: tmpExcipient.categoryAz ? {
                    az: tmpExcipient.categoryAz.trim() || null,
                    ru: tmpExcipient.categoryRu.trim() || tmpExcipient.categoryAz.trim() || null
                } : null,
                excipient: {
                    az: exc.trim(),
                    ru: excRu[i] ? excRu[i].trim() : exc.trim()
                }
            })
        });
        dispatch(addExcipients(newExcipients, patientLeaflet));
        setModal(false);
        //props.toggleSave(true);
    }
    
    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({
            excipients: patientLeaflet.excipients.filter((exc, i) => i !== index)
        });
        props.toggleSave(true);
    }

    return (
        <div>
            <Form style={{width: '100%'}}>
                <Row>
                    <Col xs='12' className='pt-0 pl-5 pb-5'>
                        <Row>
                            <Col xs='10'>
                                {excipientsById ? patientLeaflet.excipients.map((excipientI, key) => ExcipientElement({
                                    key, lang, dictionary, excipientsById, excipientI, 
                                    languageTab, excipientSearchResult, deleteButtonHandler, selectExcipient,
                                    activeIndex, setActiveIndex, searchString, setSearchString, tmpExcipient, setTmpExcipient,
                                    suggest, setSuggest, tmpExcipientArray, setTmpExcipientArray})) : ''}
                                <Button onClick={addExcipientButtonHandler} color="primary" size="lg" block><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.patientLeafletEditPage.excipients.addButton[lang]}</Button>
                            </Col>
                            <Col xs='2'>
                                <Nav tabs vertical pills className='lang-changer'>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'az' })}
                                            onClick={() => { toggleLang('az'); }}>
                                            Az
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'ru' })}
                                            onClick={() => { toggleLang('ru'); }}>
                                            Ru
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.excipients.newExcipients[lang]}</ModalHeader>
                <ModalBody>{CreateExcipientsElement({lang, dictionary, languageTab, tmpExcipient, setTmpExcipient})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewExcipient}>{dictionary.adminDrugs.patientLeafletEditPage.excipients.addExcipientsButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpExcipient({}); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const ExcipientElement = props => {
    const {
        languageTab, deleteButtonHandler,
        dictionary, lang, key, excipientI,
        excipientsById, excipientSearchResult,
        searchString, setSearchString,
        tmpExcipient, setTmpExcipient,
        activeIndex, setActiveIndex,
        suggest, setSuggest, selectIngredient, tmpExcipientArray, setTmpExcipientArray} = props;
    return (
        <Row className='mt-2' key={key}>
            <FormGroup className='admin' style={{width: '100%'}}>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Row className='pt-2'>
                                <Col xs={12}>
                                {deleteButtonHandler ? 
                                    <FontAwesomeIcon 
                                        className='float-right close-button'
                                        icon={faTrash}
                                        onClick={deleteButtonHandler.bind(this, key)}/> : ''}
                                    <TabContent className='pt-2' activeTab={languageTab}>
                                        <TabPane tabId='az'>
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.categoryAz[lang]}</Label>
                                            <Input
                                                type='text'
                                                name='category'
                                                id={`categoryAz_${key}`}
                                                onFocus={() => {}}
                                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                                defaultValue={excipientI && excipientI.category ? excipientI.category.az : ''}
                                                onChange={(e) => {
                                                    if (excipientI) {
                                                        let dataArray = [];
                                                        dataArray = [...tmpExcipientArray];
                                                        dataArray[key] = {...dataArray[key], categoryAz: e.target.value};
                                                        setTmpExcipientArray(dataArray);
                                                    } else setTmpExcipient({...tmpExcipient, categoryAz: e.target.value})}}
                                                 />
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientAz[lang]}</Label>
                                            <Input
                                                type='search'
                                                autoComplete='off'
                                                name='search'
                                                id={`searchExcipientAz_${key}`}
                                                placeholder={dictionary.search[lang]}
                                                onFocus={() => {if (searchString && activeIndex !== key) setSearchString(null)}}
                                                value={
                                                    searchString !== null && activeIndex === key ? searchString :
                                                    excipientI ?
                                                    (tmpExcipientArray && tmpExcipientArray[key] && tmpExcipientArray[key].searchResult ?
                                                    tmpExcipientArray[key].searchResult.name.az :
                                                    excipientsById[key] && activeIndex !== key ? excipientsById[key].name.az : '') :
                                                    tmpExcipient ? tmpExcipient.az : ''
                                                }
                                                onChange={(e) => {
                                                    setActiveIndex(key);
                                                    setSearchString(e.target.value);
                                                    if (!excipientI) setTmpExcipient({...tmpExcipient, az: e.target.value});
                                                }} />
                                            <ListGroup>
                                                {suggest && excipientSearchResult && activeIndex === key ? excipientSearchResult.map(result => (
                                                    <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                        if (excipientI) {
                                                            let dataArray = [];
                                                            dataArray = [...tmpExcipientArray];
                                                            dataArray[key] = {...dataArray[key], searchResult: result};
                                                            setTmpExcipientArray(dataArray);
                                                            setSearchString(null);
                                                            setSuggest(false);
                                                        } else {
                                                            selectIngredient(result);
                                                            setSearchString(null);
                                                        }
                                                    }} tag="a" action>{result.Caption}</ListGroupItem>
                                                )) : ''}
                                            </ListGroup>
                                        </TabPane>
                                        <TabPane tabId='ru'>
                                            <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                            <Input
                                                type='text'
                                                name='category'
                                                id={`categoryRu_${key}`}
                                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                                defaultValue={excipientI && excipientI.category ? excipientI.category.ru : ''}
                                                onChange={(e) => {
                                                    if (excipientI) {
                                                        let dataArray = [];
                                                        dataArray = [...tmpExcipientArray];
                                                        dataArray[key] = {...dataArray[key], categoryRu: e.target.value};
                                                        setTmpExcipientArray(dataArray);
                                                    } else setTmpExcipient({...tmpExcipient, categoryRu: e.target.value})}} />
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                            <Input
                                                type='search'
                                                name='search'
                                                id={`searchIngredientRu_${key}`}
                                                placeholder='search placeholder'
                                                onFocus={() => {if (searchString && activeIndex !== key) setSearchString(null)}}
                                                value={excipientI ?
                                                    (tmpExcipientArray && tmpExcipientArray[key] && tmpExcipientArray[key].searchResult ?
                                                    tmpExcipientArray[key].searchResult.name.ru :
                                                    excipientsById[key] && activeIndex !== key ? excipientsById[key].name.ru : '') : 
                                                    tmpExcipient ? tmpExcipient.ru : ''}
                                                onChange={(e) => {
                                                    if(!excipientI) setTmpExcipient({...tmpExcipient, ru: e.target.value})}} />   
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </FormGroup>
        </Row>
    )
}

const CreateExcipientsElement = props => {
    const {
        languageTab, dictionary, lang, 
        tmpExcipient, setTmpExcipient
    } = props;
    return (
        <Row className='mt-2'>
            <FormGroup className='admin' style={{width: '100%'}}>
                <Col xs={12}>
                    <TabContent className='pt-2' activeTab={languageTab}>
                        <TabPane tabId='az'>
                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.categoryAz[lang]}</Label>
                            <Input
                                type='text'
                                name='category'
                                id={`categoryAz`}
                                onFocus={() => {}}
                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                onChange={(e) => {
                                    setTmpExcipient({...tmpExcipient, categoryAz: e.target.value})}}
                                />
                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientAz[lang]}</Label>
                            <Input
                                type='textarea'
                                name='excipientAz'
                                id={`excipientAz`}
                                placeholder={dictionary.search[lang]}
                                onChange={(e) => {
                                    setTmpExcipient({...tmpExcipient, az: e.target.value});
                                }} />
                        </TabPane>
                        <TabPane tabId='ru'>
                            <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                            <Input
                                type='text'
                                name='category'
                                id={`categoryRu`}
                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                onChange={(e) => {
                                    setTmpExcipient({...tmpExcipient, categoryRu: e.target.value})}} />
                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                            <Input
                                type='textarea'
                                name='search'
                                id={`searchIngredientRu`}
                                placeholder='search placeholder'
                                onChange={(e) => {
                                    setTmpExcipient({...tmpExcipient, ru: e.target.value})}} />   
                        </TabPane>
                    </TabContent>
                </Col>
            </FormGroup>
        </Row>
    )
}

export default PLExcipients;