import React from "react";
import {
    CardBody,
    CardHeader,
    Col,
    NavLink,
    Row,
} from "reactstrap";
import "./Login.scss";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { loginUser } from "./../../../redux/actions/auth-actions";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identifier: null,
            password: null,
        };
        this.goToRegistration = this.goToRegistration.bind(this);
        this.goToForgot = this.goToForgot.bind(this);
        this.goToAccount = this.goToAccount.bind(this);
        this.changeidentifierInput = this.changeidentifierInput.bind(this);
        this.changePasswordInput = this.changePasswordInput.bind(this);
    }

    goToRegistration() {
        this.props.history.push("/drugs/register");
    }

    goToForgot() {
        this.props.history.push("/drugs/forgot");
    }

    goToAccount() {
        this.props.history.push("/drugs/account");
    }

    changeidentifierInput(event) {
        this.setState({ identifier: event.target.value });
    }

    changePasswordInput(event) {
        this.setState({ password: event.target.value });
    }

    render() {
        const { dictionary, lang } = this.props;
        const { identifier, password } = this.state;
        if (this.props.authData) {
            this.props.history.push('/drugs/account')
        }

        return (
            <>
                <CardHeader className="bg-transparent border-0 bottom-margin-del login_header">
                    <h3 className="main-title-sign-in">
                        {dictionary.loginComponent.login[lang]}
                    </h3>
                </CardHeader>
                <CardBody className="bottom-margin-del login_body">
                    <Row>
                        <Col md="4"><p>{dictionary.loginComponent.enterLogin[lang]}</p></Col>
                        <Col>
                            <input
                                className="inpusts-sign-in"
                                placeholder=""
                                onChange={this.changeidentifierInput}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4"><p>{dictionary.loginComponent.enterPassword[lang]}</p></Col>
                        <Col>
                            <input
                                type="password"
                                className="inpusts-sign-in"
                                placeholder=""
                                onChange={this.changePasswordInput}
                            />
                        </Col>
                    </Row>

                    <div className="reg_button_wrapper">
                        <Link to={`/drugs/forgot`}>
                            {dictionary.account.editDetails.didYouForget[lang]}
                        </Link>
                    </div>

                    <div className="reg_button_wrapper">
                        <NavLink
                            className="reg_button"
                            onClick={() => {
                                if (identifier && password) {
                                    this.props.loginUser({ identifier: identifier.toLowerCase(), password: password });
                                } else {
                                    alert("Check fields!");
                                }
                            }}
                        >
                            <p>{dictionary.loginComponent.loginInSystem[lang]}</p>
                        </NavLink>
                    </div>
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    authData: state.authReducer.authData,
});

const mapDispatchToProps = {
    loginUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
