import React from "react";
import { CardHeader, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import "./PharmaCompanies.scss";

import config from "../../../config";
import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import moment from 'moment';
import DrugsCategories from "../Elements/DrugsCategories";

class PharmaCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.catchSearchLetter = this.catchSearchLetter.bind(this);
        this.state = {
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            news:[]
        };
    }
    componentDidMount = () => {
        fetch(`${config.url}rsses`).then((response) => {
            return response.json();
        }).then((data) => {this.setState({news: data})});
    }

    catchSearchLetter(searchLetter) {
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    render() {
        const { dictionary, lang } = this.props;

        return (
            <div className="pharma_companies_page_container">
                <div className="pharma_companies_page">
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pharmaCompanies.pharmaCompaniesTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.pharmaCompanies.pharmaCompaniesBody[lang]}{" "}
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pharmaCompanies.searchTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description search_card">
                        <SearchField searchTypes="professional,pharma_companies"></SearchField>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pharmaCompanies.alphabetTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        <div className="alphabet-block-pharma alphabet_block">
                            {alphabeticalLoop(
                                lang,
                                letterJsx,
                                this.catchSearchLetter,
                                undefined,
                                this.props.lettersCount
                            )}
                            <button
                                onClick={() => this.props.history.push("/drugs/advanced-search")}
                                className="alphabet_button advanced-search-alphabet-button live-button"
                                value={dictionary.advancedSearch[lang]}
                            >
                                {dictionary.advancedSearch[lang]}
                            </button>
                        </div>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pharmaCompanies.infoTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.pharmaCompanies.infoBody[lang]}
                    </div>
                    <DrugsCategories />
                    <div className="main_content_sda_wrapper">
                        <div className="main_content">
                            <div className="bg-white border-0">
                                <h1 className="mb-0 drugs-header">
                                    {dictionary.pharmaCompanies.popularRequestsTitle[lang]}
                                </h1>
                            </div>
                            <div className="pharma_companies_body">
                                <PopularRequests type="pharma_companies" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pharma_companies__sda">
                   <AdsBlock></AdsBlock> 
                </div>
            </div>
            
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
});

export default connect(mapStateToProps)(PharmaCompanies);
