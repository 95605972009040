import React from 'react';
import {Row, Col, Pagination, PaginationItem, PaginationLink} from 'reactstrap';

const AdminPagination = props => {
    const {page, lastPage} = props;
    return(
        <Row>
            <div style={{margin: '0 auto'}}>
                <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                        <PaginationLink previous href={`?page=1`} />
                    </PaginationItem>
                    {(() => {
                        const paginations = [];
                        if ((lastPage - page) < 4) {
                            for(let p = lastPage - 4; p <= lastPage; p++) {
                                if (p > 0) {
                                    p === page ? 
                                    paginations.push(<PaginationItem active key={p}>
                                        <PaginationLink href={`?page=${p}`}>
                                            {p}
                                        </PaginationLink>
                                    </PaginationItem>) :
                                    paginations.push(<PaginationItem key={p}>
                                        <PaginationLink href={`?page=${p}`}>
                                            {p}
                                        </PaginationLink>
                                    </PaginationItem>)
                                }
                            }
                            return paginations;
                        } else {
                            const start = page > 1 ? page - 1 : page;
                            for(let p = start; p < start + 3; p++) {
                                p === page ? 
                                paginations.push(<PaginationItem active key={p}>
                                    <PaginationLink href={`?page=${p}`}>
                                        {p}
                                    </PaginationLink>
                                </PaginationItem>)
                                : paginations.push(<PaginationItem key={p}>
                                    <PaginationLink href={`?page=${p}`}>
                                        {p}
                                    </PaginationLink>
                                </PaginationItem>)
                            }
                            paginations.push(<PaginationItem key={lastPage - 1}>
                                <div style={{padding: 3}}>...</div>
                            </PaginationItem>);
                            paginations.push(<PaginationItem key={lastPage}>
                                <PaginationLink href={`?page=${lastPage}`}>
                                    {lastPage}
                                </PaginationLink>
                            </PaginationItem>)
                            return paginations;
                        }
                    })()}
                    <PaginationItem>
                        <PaginationLink next href={`?page=${lastPage}`} />
                    </PaginationItem>
                </Pagination>
            </div>
        </Row>
    )
}

export default AdminPagination;