const INITIAL_STATE = {

}


const footerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default footerReducer;