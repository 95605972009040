import { CREATE_MEDICAL_PRODUCT, COUNT_MEDICAL_PRODUCTS, GET_MEDICAL_PRODUCTS, EDIT_MEDICAL_PRODUCT, SEARCH_DRUGS, GET_CP, GET_ICD } from "../actions/action-types";

const initialState = {
    medicalProduct: null,
    newProduct: null,
    medicalProductsCount: 0,
    medicalProducts: [],
    drugResult: [],
    cpCodeResult: [],
    icdCodeResult: []
}

const medicalProductsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CREATE_MEDICAL_PRODUCT:
            return {...state, newProduct: action };
        case COUNT_MEDICAL_PRODUCTS:
            return {...state, medicalProductsCount: payload }
        case GET_MEDICAL_PRODUCTS:
            return {...state, medicalProducts: payload }
        case EDIT_MEDICAL_PRODUCT:
            return {...state, medicalProduct: payload }
        case SEARCH_DRUGS:
            return {...state, drugResult: payload }
        case GET_CP:
            return {...state, cpCodeResult: payload }
        case GET_ICD:
            return {...state, icdCodeResult: payload }
        default:
            return state;
    }
}

export default medicalProductsReducer;