export const LoadingSubject = {
    Initial: 0,
    SignIn: 1,
    SignOut: 2,
    SignUp: 3,
    VerifyEmail: 4,
    VerifyPhone: 5,
    RestorePswd: 6,
    UserById: 7,
    ChangePswd: 8,
    UpdateProfile: 9,
    DoctorList: 10,
    DoctorListNextPage: 11,
    Doctor: 12,
    ClinicList: 13,
    ClinicListNextPage: 14,
    Clinic: 15,
    DoctorRating: 16,
    PatientDoctorList: 17,
    SavePatientDoctor: 18,
    UpdatePatientDoctor: 19,
    GetPatientDoctor: 20,
    DeletePatientDoctor: 21,
    PatientClinicList: 22,
    PatientClinicListNextPage: 23,
    SavePatientClinic: 24,
    UpdatePatientClinic: 25,
    GetPatientClinic: 26,
    DeletePatientClinic: 27,
    SaveSchedulingItem: 28,
    SaveMultipleSchedulingItem: 29,
    SchedulingItems: 30,
    CountSchedulingItem: 31,
    DeleteSchedulingItem: 32,
    SaveSchedulingItemRecord: 33,
    SchedulingItemRecords: 34,
    SchedulingItemRecordsFull: 35,
    CountSchedulingItemRecord: 36,
    ApprovingSchedulingItemRecord: 37,
    SaveDevice: 38,

    InitiateCall: 39,

    PatientSurveyList: 40,
    PatientSurvey: 41,
    PatientConsultationHistoryList: 42,
    PatientConsultationHistory: 43,
    PatientAllergyList: 44,
    PatientAllergy: 45,
    PatientNoteList: 46,
    PatientNote: 47,
    PatientAnalyzeList: 48,
    PatientAnalyze: 49,
    PatientDoctorAccessList: 50,
    PatientDoctorAccess: 51,
    PatientMedicineList: 52,
    PatientMedicine: 53,
    PatientVaccinationList: 54,
    PatientVaccination: 55,
    DutyItem: 56,

    CreateClinicComment: 57,
    CreateDoctorComment: 58,
    CreateClinicRating: 59,
    CreateDoctorRating: 60,

    RefreshToken: 61,
    NewDoctor: 62,
    NewDoctor1: 63,
    NewDoctor2: 64,

    Messages: 65,
    MessagesFull: 66,
    IndicatorsInfo: 67,
    IndicatorValues: 68,
    IndicatorValuesNext: 69,
    SaveIndicator: 70,
    IndicatorsChart: 71,
    DoctorPrice: 72,
    Payment: 73,
    Dictionaries: 74,
    ProfileValuesNext: 75,
    ProfileValues: 76,
    SaveProfileValue: 77,
    DeleteProfileValue: 78,
    OneMedicineDetail: 79,
    SaveUploadProfile: 80,
}