import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../form-components/TextInput';
import { withTranslation } from 'react-i18next';

class VerifyAccountForm extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Field name="code" placeholder={t('Input code')} component={TextInput} />
            </div>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    const { t } = props;
    if (!values.code || values.code.trim() === '') {
        errors.code = t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm({
    form: 'VerifyAccountForm',
    validate
})(VerifyAccountForm);

export default connect(null)(withTranslation()(form));
