import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import VDDropdown from '../Layouts/dropdowns';
import './faq.scss';


const items = {
    en: [
        {
            title: 'Who can use teleconsultation?',
            text: '<p>Teleconsultation is suitable for anyone above 2 years' +
                'old and seeking treatment for non-emergency illnesses. ' +
                'Please note that your user account cannot be used by another person.</p>' +
                '<p>For patients below 16 years old, please ensure you have a guardian ' +
                '(who must be at least 21 years old) present with you during the teleconsultation.' +
                ' The guardian has to show his/her valid ID (eg. şəxsiyyət vəsiqəsi, pasport, etc) ' +
                'to the doctor at the start of the teleconsultation.</p>'
        },
        {
            title: 'What are the charges for a teleconsultation?',
            text: 'Video Doktor charges AZN__ for a 30-minute teleconsultation' +
                ' (inclusive of medication delivery). Beyond 15 minutes, the doctor' +
                ' may request for an additional payment for another 15-minute extension. Medication charges apply.'
        },
        {
            title: 'What conditions are suitable for teleconsultation?',
            text: '<p>' +
                'There are many common conditions (non-emergency in nature) that can be treated via teleconsultation including but not limited to these:' +
                '</p>' +
                '<ul>' +
                '<li>Cough & Cold</li>' +
                '<li>Sore Throat</li>' +
                '<li>Constipation & Diarrhoea</li>' +
                '<li>Skin Irritation & Rashes</li>' +
                '<li>Acne</li>' +
                '<li>Headache & Migraine</li>' +
                '<li>Urinary Tract Infections</li>' +
                '<li>Menstrual Issues</li>' +
                '<li>Gastric problem</li>' +
                '<li>Cold sores</li>' +
                '<li>Muscle aches</li>' +
                '<li>Heel pain</li>' +
                '<li>Conjunctivitis without visual disturbances</li>' +
                '<li>Eyelid problems</li>' +
                '<li>Gout</li>' +
                '<li>Well-controlled chronic illnesses that have already been routinely followed up</li>' +
                '<li>Others: travel health, sexual health, review of health screening results</li>' +
                '</ul>' +
                '<p>The doctor will make a reasonable diagnosis where possible from the presenting history, vital signs or other clinical parameters where available, investigation or imaging results and recommend treatment or referral to the appropriate level of care if necessary.</p>'
        },
        {
            title: 'What conditions are NOT suitable for teleconsultation?',
            text: '<p>Emergency health issues are NOT suitable for teleconsultation. Please seek immediate medical assistance at the nearest Emergency Clinic (Təcili Tibbi Yardım stansiyası və ya xəstəxanası) or call 103 for an ambulance.</p>' +
                '<p>This teleconsultation service is also not for mental health conditions.</p>' +
                '<p>The following conditions are also NOT suitable for teleconsultation:</p>' +
                '<ul>' +
                '<li>Visual disturbances – double vision or blurred vision or loss of vision</li>' +
                '<li>Dizziness and imbalance</li>' +
                '<li>Chest pain</li>' +
                '<li>Breathing difficulties and asthma</li>' +
                '<li>Abdominal pain with fever</li>' +
                '<li>Diarrhoea with black stools</li>' +
                '<li>Fever for more than 3 days</li>' +
                '<li>Weakness or numbness of limbs</li>' +
                '<li>Urinary or bowel incontinence</li>' +
                '<li>Pregnancy complications</li>' +
                '<li>Any injuries</li>' +
                '<li>Bleeding from any wounds/injuries</li>' +
                '<li>1st consultation on chronic illnesses</li>' +
                '</ul>'
        },
        {
            title: 'Can I choose a doctor for the teleconsultation?',
            text: '<p>Yes, you can select a doctor for teleconsultation via “Search Doctor” (by keying in the doctor’s name or clinic), subject to his/her availability.</p>' +
                '<p>You may also choose a doctor that you have previously seen on Video Doktor if he/she is saved on your ‘Favourite Providers’ list. You can add a provider onto your favourite list by selecting the ‘heart’ icon in the appropriate doctor’s profile.</p>'
        },
        {
            title: 'What is Doctor World?',
            text: 'Video Doktor is an integrated healthcare digital platform providing medical convenience at your fingertips. You can use the app (available on Apple App Store and Google Play Store) to:' +
                '<ul>' +
                '<li>Video-consult a doctor (available 24/7 daily)</li>' +
                '<li>Schedule medical appointments</li>' +
                '<li>Manage health records</li>' +
                '<li>Browse health insurance offerings</li>' +
                '<li>Purchase healthcare products and services</li>' +
                '</ul>' +
                '<p>It is a secure platform for doctors to dispense medical care with ease and for patients to receive treatment with confidence and in privacy.</p>'
        }
    ],
    az: [
        {
            title: 'Dermanlarim.az Portalı nədir?',
            text: '<p>Klinik sınaqlardan keçdikləri andan etibarən Səhiyyə Nazirliyi və ya ABŞ və ya Avropa Dərman Agentliklərinin təsdiqindən sonra bazara çıxarılana qədər 4000-dən çox dərmanı əhatə edirik. Əhatə dairəsinə aşağıdakılar daxildir:</p>' + 
                '<ul>' + 
                    '<li>MedlinePlus, ClinicalTrials.gov, DailyMed və Diyetik Əlavə Etiket Verilənlər Bazası və LactMed kimi Milli Tibb Kitabxanasının (NLM) ev sahibliyi etdiyi qaynaqlar və Drugs @ FDA, FDA Maddə Qeyd Sistemi və HIVInfo kimi xaricdəki mənbələr.</li>' +
                    '<li>USAN (Amerika Birləşmiş Ştatları qəbul edilmiş ad) və ya INN (Beynəlxalq mülkiyyətçi olmayan ad) kimi rəsmi ümumi ad statuslu dərmanlar.</li>' +
                    '<li>NLM MeSH Farmakoloji Fəaliyyət məlumatlarından çıxarılan kateqoriyalara sahib olan dərmanlar.</li>' + 
                '</ul>'
        },
        {
            title: 'Üst menyu ilə axtarış qutusu arasındakı fərq nədir?',
            text: '<p>Səhifələrin yuxarı hissəsindəki aşağı açılan menyu, Xəbərlər və Xüsusiyyətlər kimi ümumi dərman ehtiyatlarına, mənbələrə kateqoriyaya görə və Səhiyyə Nazirliyi, Səhiyyə Nazirliyinin ASK, EMA və FDA kimi digər hökumət mənbələrinə rəhbərlik edir. Müəyyən bir saytda ayrı bir pəncərədə açılacaq xüsusiyyətlərdən istifadə edə bilərsiniz.</p>' +
                '<p>Bir səhifənin ortasındakı axtarış qutusu, eyni zamanda bu dərman haqqında məlumat üçün bu mənbələrdən seçilmiş bir qrupda axtarış aparmaq üçün daxil etdiyiniz dərman adından istifadə edir. Dərmanın kateqoriyasında da axtarış apara bilərsiniz. Həm narkotik adı axtarışları, həm də kateqoriya axtarışları sizə Ajax texnologiyasından istifadə edərək yazarkən axtarış üçün təkliflər verəcəkdir. Bu təkliflər dərman adındakı ilk 3 simvoldan və kateqoriyadakı ilk iki simvoldan sonra başlayacaq.</p>' +
                '<p>Nəticələr səhifəsində bir dərman üçün mövcud olan bütün mənbələr sadalanır və hər nəticə yeni bir pəncərədə açılacaqdır.</p>'
        },
        {
            title: 'Axtarış nəticələri səhifəsində nə mövcuddur?',
            text: '<p>Axtarış nəticələri səhifəsində dərmanın adı (lər) i, dərmanın hərəkətlərinin xülasəsi və əlavə məlumatın harada tapılacağı verilmişdir.</p>' +
                '<p>Dərman adı ümumiyyətlə bir dərmanın rəsmi ümumi adıdır. Ümumi adın mənbəyi varsa, mötərizədə yazılır. Aşağıda ümumi ad növlərindən bəzilərinin qısaltması verilmişdir.</p>' +
                '<p>* INN Beynəlxalq Nomenklatur Adı.</p>' + 
                '<p>Dərman adları tez-tez istifadə göstəricisi vermək üçün qurulur. Məsələn, Diazepamda (Valium) olduğu kimi “azepam” sonu, dərmanın Valium üçün gözlədiyiniz kimi anksiyete əleyhinə fəaliyyət göstərdiyini göstərir. Bu ümumi ad qaynaqlarının bir dərman dərsi ilə bitməsinin uyğunluğunu göstərən bir cədvələ baxa bilərsiniz.</p>' +
                '<p>Axtarış nəticələri səhifəsindəki kateqoriya, müəyyən bir dərman üçün ümumi istifadəni təmin edir və NLM MeSH Farmakoloji Fəaliyyəti (PA) Sahəsindən əldə edilir. </p>' + 
                '<p>Diazepam üçün bir nümunə: </p>' + 
                '<p>- Təşviş əleyhinə və ya anksiolitiklər</p>' + 
                '<p>Müəyyən bir dərman üçün bir və ya daha çox kateqoriya göstərilirsə, bu NLM MeSH Farmakoloji Fəaliyyət dərmanı kateqoriyasına daxil olan digər dərmanları müəyyən etmək üçün seçilmiş bir kateqoriyada axtarış edə bilərsiniz. Bu kateqoriyaların siyahısı və onların tərifləri məlumat düyməsini seçməklə görünə bilər. Müəyyən bir kateqoriya seçilsə, məlumat düyməsini tərifini göstərəcəkdir.</p>' +
                '<p>Təsvir dərmanın nə olduğunu və nə etdiyinin xülasəsidir. Təsvir ümumiyyətlə NLM MeSH sənədinin Qeyd sahəsindən götürülür. Diazepam üçün bir nümunə:</p>' +
                '<p>“Antikonvulsant, anksiyolitik, sedativ, əzələ gevşetici və amnezik xüsusiyyətləri olan və uzun müddət fəaliyyət göstərən bir Benzodiazepin. Fəaliyyətləri GABA fəaliyyətinin artırılması ilə vasitəçilik edir. Şiddətli narahatlıq pozğunluqlarının müalicəsində, yuxusuzluğun qısa müddətli müalicəsində hipnotik, sakitləşdirici və premedikant, antikonvulsant kimi və alkoqol çəkilmə sindromunun idarə edilməsində istifadə olunur. (Martindale\'den, Əlavə Farmakopeya, 30-cu nəşr, s589) ”</p>' +
                '<p>Müəyyən bir dərman üçün bir və ya daha çox kateqoriya göstərilirsə, bu NLM MeSH Farmakoloji Fəaliyyət dərmanı kateqoriyasına daxil olan digər dərmanları müəyyən etmək üçün seçilmiş bir kateqoriyada axtarış edə bilərsiniz. Bu kateqoriyaların siyahısı və onların tərifləri məlumat düyməsini seçməklə görünə bilər. Müəyyən bir kateqoriya seçilsə, məlumat düyməsini tərifini göstərəcəkdir.</p>' +
                '<p>MedlinePlus və DailyMed, axtardığınız xüsusi dərmanla əlaqəli məlumatlara sahib olduqları təqdirdə bir dərmanın istifadəsinin xülasələrini təqdim edirlər.</p>'
        },
        {
            title: 'Sualım üçün hansı mənbələrin ən uyğun olduğunu haradan bilim?',
            text: '<p>Bir axtarış apardıqdan sonra nəticələr səhifəsində bir sıra hiperlink mənbələri görünəcəkdir. Bu, maraqlandığınız dərman üçün bu mənbələri axtarmağın nəticələridir. Siçanınızı köprü üzərindən tutduğunuz zaman alınan hər bir mənbəyə kömək balonu açılır. Bu kömək həmin mənbənin ümumi əhatə dairəsini təsvir edir.</p>'
        },
        {
            title: 'Axtarışlarımda qısaltmalardan istifadə edə bilərəmmi?',
            text: '<p>Dərman adından başlayan və ya dərman adı olan adları tapmaq üçün ulduz (*) istifadə edə bilərsiniz. Məsələn, ibuprofen  "ibuprofen" ifadəsi ilə başlayan dərmanlar tapır. Dərman adına yerləşdirilmiş mətni tapmaq üçün terminin hər iki ucunda və ya hər iki ucunda bir ulduz istifadə edə bilərsiniz. Məsələn, adlarının bir hissəsi olaraq sona çatan bu penisilini ehtiva edən dərmanları tapmaq üçün  silin * istifadə olunur. Bu vəziyyətdə birdən çox cavab alırsınız və bu siyahıdan fərdi dərmanları seçə bilərsiniz.</p>'
        },
        {
            title: 'Dermanlarim.az-da axtarışım heç bir şey almasa nə edər?',
            text: '<p>Dərman konsepsiyanızı bir neçə yolla tapmağa çalışırıq. Bir "Adla" dərman adı axtarışında, daxil etdiyiniz ad, ' +
             'faylda olanlara əsasən yazarkən tez-tez təkliflərlə nəticələnəcəkdir. Bunlardan birini seçə bilərsiniz və axtarış qutusuna qoyulacaq,' + 
             ' burada axtarış üçün Git düyməsini basa bilərsiniz. Təklifləri bəyənmirsinizsə, istədiyinizi yazıb birbaşa axtarış edə bilərsiniz.</p>' + 
             '<p>Heç bir cavab almazsa, axtarış termininiz bir dərman adını axtarmaq üçün analiz ediləcək və axtarışa veriləcək. Məsələn, “aspirin haqqında məlumata ehtiyacım var”' +
             ' kimi bir cümlə daxil etsəniz, “aspirin” adlı dərman adı tapılıb axtarılacaqdır. Çıxışı diqqətlə nəzərdən keçirin,' + 
             ' çünki İngilis sözləri də sizin üçün maraqlı olmayan bir dərmanın sinonimi ola bilər.</p>' +
             '<p>Bu analiz uğursuz olarsa, orfoqrafiya yoxlayıcısı mövcud olduqda adını və təsvirini göstərən təkliflər verəcəkdir. ' + 
             'Bunlardan seçim etməkdə diqqətli olun, çünki bənzər təsiri olmayan bəzi dərmanların adları səslənir və eyni görünür. Nəhayət, ' + 
             'hələ bir cavab yoxdursa, MedlinePlus-da axtarış imkanı verilir.</p>' + 
             '<p>“SSRI” (Selektiv Serotonin Yenidən Alma İnhibitorları üçün) və ya ' +
             '“Xərçəng Müalicəsi” kimi ümumi kateqoriyalar “Kateqoriya üzrə” dərman portalı axtarışına daxil edilə bilər. ' + 
             'Yazarkən bir kateqoriyanın standart axtarış nümayişi üçün təkliflər verilə bilər (ön səhifədəki nümunələrə baxın). ' + 
             'NLM MedlinePlus sistemində, Narkotik Məlumat Portalında heç bir şey əldə etmədikləri təqdirdə, bu şərtləri axtarmaq üçün bir fürsət verilə bilər.</p>' + 
             '<p>Bütün bunlar uğursuz olarsa, ola bilər ki, dərman adınızı əhatə etməyək və fərqli bir dəyişiklik sınayasınız.</p>'
        },
        {
            title: 'Dərman əlavə etmək üçün bir təklif edə bilərəmmi?',
            text: '<p>Təklifləri e-poçtla qəbul etməkdən məmnun olardıq. Bizə info@dermanlarim.az elektron poçt ünvanına göndərin. Dərmanın adını, istifadəsini və sahib olduğunuz digər hər hansı bir məlumatı verin. Təklif olunan bütün dərmanlar Dərman Məlumat Portalının əhatə dairəsinə uyğun olmalıdır.</p>'
        },
        {
            title: 'Kateqoriyaları necə axtarıram?',
            text: '<p>Kateqoriya sahəsi, MeSH Farmakoloji Fəaliyyəti (PA) ' +
                'lüğətindən götürülmüş məlumatları ehtiva edir. MeSH faylından və NLM tərəfindən əlavə' +
                'edilmiş bir sıra çapraz istinadlara malikdir. Bunları “Kateqoriya üzrə” radio düyməsini istifadə edərək axtara bilərsiniz.</p>' +
                '<p>Bir dərman qeydini götürdükdən sonra, adın altındakı qutuda siyahıya alına bilən kateqoriyalara tıklayaraq axtara bilərsiniz. Bunlar bu şəkildə təsnif edilən digər narkotik qeydlərini alacaq.</p>' + 
                '<p>Ümumi dərman sinifləri, bir dərmanın ümumi ad kökü istifadə edilərək də araşdırıla bilər. Bu sapların cədvəli mövcuddur. Lorazepam kimi antioksidant dərmanlarına uyğun gələn dərman adlarını tapmaq üçün  azepam  kimi kəsmə üçün ulduzlardan istifadə edərək ad axtarış qutusunda axtarıla bilər.</p>'
        }
    ],
    ru: [
        {
            title: 'Кто может воспользоваться телеконсультацией?',
            text: '<p>Телеконсультация подходит всем лицам старше 2-х лет, нуждающимся в лечении неотложных заболеваний. Пожалуйста, обратите внимание на то, что ваша учетная запись пользователя не может использоваться кем-либо еще.</p>' +
                '<p>Для пациентов младше 16 лет, пожалуйста, убедитесь, что во время телеконсультации присутствует лицо, осуществляющее уход (как минимум в возрасте 21-го года). В начале телеконсультации опекун должен предъявить врачу действующий документ, удостоверяющий его / ее личность (например, удостоверение личности, паспорт и др.).</p>'
        },
        {
            title: 'Сколько стоит телеконсультация?',
            text: '<p>Стоимость 30-минутной телеконсультации посредством Видео Доктора составляет __ AZN (включая доставку лекарств). После 15-ти минут, врач может взимать дополнительную плату за 15-минутное продление сеанса. За лекарства взимается плата.</p>'
        },
        {
            title: 'Какие ситуации подходят для телеконсультации?',
            text: '<p>Многие из наиболее распространенных состояний, включая, помимо прочего, следующие, можно лечить с помощью телеконсультации (те, что не считаются по своему характеру неотложными):</p>' +
                '<ul>' +
                '<li>Кашель и простуда</li>' +
                '<li>Боль в горле</li>' +
                '<li>Запор и диарея</li>' +
                '<li>Раздражение кожи и сыпь</li>' +
                '<li>Акне (угревая сыпь)</li>' +
                '<li>Головные боли и мигрени</li>' +
                '<li>Инфекции мочеиспускательного канала</li>' +
                '<li>Менструальные проблемы</li>' +
                '<li>Желудочные проблемы</li>' +
                '<li>Герпес</li>' +
                '<li>Мышечные боли</li>' +
                '<li>Боль в пятке</li>' +
                '<li>Конъюнктивит без нарушений зрения</li>' +
                '<li>Проблемы с глазным веком</li>' +
                '<li>Подагра</li>' +
                '<li>Хронические заболевания, которые уже находятся под обычным плановым наблюдением и хорошо контролируются</li>' +
                '<li>Другое: здоровье путешественников, сексуальное здоровье, анализ результатов скрининга здоровья</li>' +
                '</ul>' +
                '<p>На основании полученного анамнеза, основных показателей состояния организма или других клинических параметров, результатов обследования или визуализации, по возможности, врач поставит соответствующий диагноз и даст рекомендации по лечению или, при необходимости, направит в соответствующие медицинские службы.</p>'
        },
        {
            title: 'Какие ситуации НЕ подходят для телеконсультации?',
            text: '<p>Неотложные проблемы со здоровьем НЕ подходят для телеконсультации. Для получения неотложной медицинской помощи, пожалуйста, позвоните в ближайшую станцию ​​ или больницу Скорой Медицинской Помощи или позвоните по номеру 103, чтобы вызвать машину скорой помощи.</p>' +
                '<p>Эта услуга телеконсультации также не используется при проблемах с психическим здоровьем.</p>' +
                '<p>Также НЕ подходят для телеконсультации следующие ситуации:</p>' +
                '<ul>' +
                '<li>Нарушения зрения - двоение в глазах, затуманенное зрение или потеря зрения</li>' +
                '<li>Головокружение и нарушение равновесия</li>' +
                '<li>Боль в грудной клетке</li>' +
                '<li>Проблемы с дыханием и бронхиальная астма.</li>' +
                '<li>Боль в животе сопровождаемая лихорадкой</li>' +
                '<li>Диарея с черным стулом</li>' +
                '<li>Лихорадка, сохраняющаяся более 3 дней</li>' +
                '<li>Слабость или онемение в конечностях</li>' +
                '<li>Недержание мочи или кала</li>' +
                '<li>Осложнения беременности</li>' +
                '<li>Любые повреждения (травмы)</li>' +
                '<li>Кровотечение из-за любых ран / травм</li>' +
                '<li>1-я консультация по хроническим заболеваниям</li>' +
                '</ul>'
        },
        {
            title: 'Могу ли я выбрать врача для телеконсультации?',
            text: '<p>Да, в зависимости от наличия, вы можете выбрать врача для телеконсультации через опцию «Выберите врача» (вписав имя врача или название клиники).</p>' +
                '<p>Вы также можете выбрать врача, которого вы посещали в Видео Доктор ранее и которого оставили в своем списке «Избранные специалисты здравоохранения». Вы можете добавить медицинского работника в свой список избранных, выбрав символ «Сердце» в профиле соответствующего врача.</p>'
        },
        {
            title: 'Что такое Доктор World?',
            text: '<p>Видео Доктор - это интегрированная медицинская цифровая платформа, которая предоставляет медицинские услуги в одно касание. Вы можете использовать приложение для следующих целей (доступно в Apple App Store и Google Play Store):</p>' +
                '<ul>' +
                '<li>Видеоконсультация с врачом (доступна круглосуточно и без выходных)</li>' +
                '<li>Планирование времени посещения врача</li>' +
                '<li>Управление медицинскими записями</li>' +
                '<li>Изучение предложений по медицинскому страхованию</li>' +
                '<li>Покупка медицинских товаров и услуг</li>' +
                '</ul>' +
                '<p>Это надежная платформа позволяющая врачам, комфортно оказывать медицинскую помощь, а для пациентам – получать безопасное и конфиденциальное лечение.</p>'
        }
    ]
}

class FAQ extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            item: items[this.props.language]
        }
    }

    componentDidUpdate(prev) {
        if (prev.language !== this.props.language) {
            this.setState({ item: items[this.props.language] })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className='faq'>
                <div className='faq-container'>
                    <p className='faq__title'>{t('Frequently Asked Questions')}</p>
                    <div className='faq__block'>
                        <VDDropdown title={t('Frequently Asked Questions')} items={this.state.item} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    language: state.languageReducer.language,
})

export default connect(mapStateToProps)(withTranslation()(FAQ));