import { ALERT, ERROR, SET_LANGUAGE, SET_FIELDS_VALUE } from "./action-types";
import i18n from "i18next";

export const setLanguage = language => {
    i18n.changeLanguage(language);
    return {
        type: SET_LANGUAGE,
        payload: language
    }
};

export const setFieldsValue = fields => ({
    type: SET_FIELDS_VALUE,
    payload: fields
});

export const showAlert = (message, type) => ({
    type: ALERT,
    payload: { message, type }
});

export const setErrorMessage = message => ({
    type: ERROR,
    payload: message
})