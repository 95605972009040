import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import "./MobileApp.scss";
import { NavLink } from "react-router-dom";

class MobileApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { lang, dictionary } = this.props;

        return (
            <>
                <CardHeader className="bg-white border-0 padding-0">
                    <h1 className="drugs-header">
                        {dictionary.mobileAppComponent.title[lang]}
                    </h1>
                </CardHeader>
                <CardBody>
                    <p>{dictionary.mobileAppComponent.description[lang]}</p>
                    <div className="download-app-markets">
                        <NavLink className="download-app" to="*">
                            <img
                                src={require("./img/button-android-large.webp")}
                                alt="googlePlay"
                            />
                        </NavLink>
                        <NavLink className="download-app" to="*">
                            <img
                                src={require("./img/button-apple-large.webp")}
                                alt="appStore"
                            />
                        </NavLink>
                    </div>
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
});

export default connect(mapStateToProps, null)(MobileApp);
