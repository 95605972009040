import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Col, Row,
    Label, Input,
    Card, CardBody,
    Nav, NavItem, NavLink,
    TabContent, TabPane,
    Modal, ModalHeader, ModalBody, ModalFooter,
    ListGroup, ListGroupItem, 
    Form, FormGroup
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {searchCoatings, getCoatingsById, addCoatings} from '../../../../redux/actions/adminActions'

const PLCoatings = props => {
    const {lang, dictionary, patientLeaflet} = props;
    // переключатель языков для полей
    const [languageTab, setTab] = useState('az');
    // открыть или закрыть модалку создания
    const [modal, setModal] = useState(false);
    // эта хрень нужна во время редактирования, чтобы мы знали какой ингредиент именно мы пытаемся редактировать
    const [activeIndex, setActiveIndex] = useState();
    // показать и скрывать выпадашку
    const [suggest, setSuggest] = useState(false);
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    // здесь будут храниться поля нового ингредиента перед сохранением
    const [tmpCoating, setTmpCoating] = useState({
        az: '', ru: '',
        categoryAz: '', categoryRu: '',
        id: '', 
        Caption: ''
    });
    // здесь будут храниться поля отредактированных ингредиентов перед сохранением
    const [tmpCoatingArray, setTmpCoatingArray] = useState([]);
    // это чтобы понять изменился ли лефлет, нужен для ингредиентов и прочего
    //const prevLeaflet = useRef();
    //массив айдишек
    let coatingsIds = [];

    // mapStateToProps
    const {coatingSearchResult, coatingsById, authorized} = useSelector(state => ({
        coatingSearchResult: state.adminReducer.coatingSearchResult,
        coatingsById: state.adminReducer.coatingsById,
        authorized: state.userReducer.authorized
    }));

    const dispatch = useDispatch();

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }

    // кнопка создания нового ингредиента
    // открытие модального окна
    const addCoatingButtonHandler = () => {
        setTmpCoating({
            az: '', ru: '',
            categoryAz: '', categoryRu: '',
            id: '', 
            Caption: ''}); // вычищаем хранилище
        setSearchString(''); // вычищаем поисковую строку
        setModal(true);
    }

    // выбрать ингредиент из выпадашки
    // возможно эта хрень отредактируется
    const selectCoating = coat => {
        setTmpCoating({...tmpCoating,
            Caption: coat.Caption,
            az: coat.name.az,
            ru: coat.name.ru,
            id: coat.id
        });
        setSuggest(false);
    }

    // создание массива ингредиентных id
    if (patientLeaflet && patientLeaflet.coatings) {
        patientLeaflet.coatings.map(coat => coat.coating ? coatingsIds.push(coat.coating.id) : '');
    }
    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchCoatings(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    useEffect(() => {
        if (patientLeaflet) {
            dispatch(getCoatingsById(coatingsIds));
        }
    }, [patientLeaflet]);

    // добавление нового ингредиента
    const addNewCoating = () => {
        let newCoatings = [];
        const coatAz = tmpCoating.az.split(',');
        const coatRu = tmpCoating.ru.split(',');
        coatAz.map((coat, i) => {
            newCoatings.push({
                category: tmpCoating.categoryAz ? {
                    az: tmpCoating.categoryAz.trim() || null,
                    ru: tmpCoating.categoryRu.trim() || tmpCoating.categoryAz.trim() || null
                } : null,
                coating: {
                    az: coat.trim(),
                    ru: coatRu[i] ? coatRu[i].trim() : coat.trim()
                }
            })
        });
        dispatch(addCoatings(newCoatings, patientLeaflet));
        setModal(false);
        //props.toggleSave(true);
    }
    
    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({
            coatings: patientLeaflet.coatings.filter((coat, i) => i !== index)
        });
        props.toggleSave(true);
    }

    if (!authorized || authorized === 'false') return (<Redirect to={`/login/`} />)
    else return (
        <div>
            <Form style={{width: '100%'}}>
                <Row>
                    <Col xs='12' className='pt-0 pl-5 pb-5'>
                        <Row>
                            <Col xs='10'>
                                {coatingsById ? patientLeaflet.coatings.map((coatingI, key) => CoatingElement({
                                    key, lang, dictionary, coatingsById, coatingI, 
                                    languageTab, coatingSearchResult, deleteButtonHandler, selectCoating,
                                    activeIndex, setActiveIndex, searchString, setSearchString, tmpCoating, setTmpCoating,
                                    suggest, setSuggest, tmpCoatingArray, setTmpCoatingArray})) : ''}
                                <Button onClick={addCoatingButtonHandler} color="primary" size="lg" block><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.patientLeafletEditPage.coatings.addButton[lang]}</Button>
                            </Col>
                            <Col xs='2'>
                                <Nav tabs vertical pills className='lang-changer'>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'az' })}
                                            onClick={() => { toggleLang('az'); }}>
                                            Az
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'ru' })}
                                            onClick={() => { toggleLang('ru'); }}>
                                            Ru
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.coatings.newCoatings[lang]}</ModalHeader>
                <ModalBody>{CreateCoatingsElement({lang, dictionary, languageTab, tmpCoating, setTmpCoating})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewCoating}>{dictionary.adminDrugs.patientLeafletEditPage.coatings.addCoatingsButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpCoating({}); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const CoatingElement = props => {
    const {
        languageTab, deleteButtonHandler,
        dictionary, lang, key, coatingI,
        coatingsById, coatingSearchResult,
        searchString, setSearchString,
        tmpCoating, setTmpCoating,
        activeIndex, setActiveIndex,
        suggest, setSuggest, selectIngredient, tmpCoatingArray, setTmpCoatingArray} = props;
    let ruField = '';
    return (
        <Row className='mt-2' key={key}>
            <FormGroup className='admin' style={{width: '100%'}}>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Row className='pt-2'>
                                <Col xs={12}>
                                {deleteButtonHandler ? 
                                    <FontAwesomeIcon 
                                        className='float-right close-button'
                                        icon={faTrash}
                                        onClick={deleteButtonHandler.bind(this, key)}/> : ''}
                                    <TabContent className='pt-2' activeTab={languageTab}>
                                        <TabPane tabId='az'>
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.categoryAz[lang]}</Label>
                                            <Input
                                                type='text'
                                                name='category'
                                                id={`categoryAz_${key}`}
                                                onFocus={() => {}}
                                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                                defaultValue={coatingI && coatingI.category ? coatingI.category.az : ''}
                                                onChange={(e) => {
                                                    if (coatingI) {
                                                        let dataArray = [];
                                                        dataArray = [...tmpCoatingArray];
                                                        dataArray[key] = {...dataArray[key], categoryAz: e.target.value};
                                                        setTmpCoatingArray(dataArray);
                                                    } else setTmpCoating({...tmpCoating, categoryAz: e.target.value})}}
                                                 />
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientAz[lang]}</Label>
                                            <Input
                                                type='search'
                                                autoComplete='off'
                                                name='search'
                                                id={`searchCoatingAz_${key}`}
                                                placeholder={dictionary.search[lang]}
                                                onFocus={() => {if (searchString && activeIndex !== key) setSearchString(null)}}
                                                value={
                                                    searchString !== null && activeIndex === key ? searchString :
                                                    coatingI ?
                                                    (tmpCoatingArray && tmpCoatingArray[key] && tmpCoatingArray[key].searchResult ?
                                                    tmpCoatingArray[key].searchResult.name.az :
                                                    coatingsById[key] && activeIndex !== key ? coatingsById[key].name.az : '') :
                                                    tmpCoating ? tmpCoating.az : ''
                                                }
                                                onChange={(e) => {
                                                    setActiveIndex(key);
                                                    setSearchString(e.target.value);
                                                    if (!coatingI) setTmpCoating({...tmpCoating, az: e.target.value});
                                                }} />
                                            <ListGroup>
                                                {suggest && coatingSearchResult && activeIndex === key ? coatingSearchResult.map(result => (
                                                    <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                        if (coatingI) {
                                                            let dataArray = [];
                                                            dataArray = [...tmpCoatingArray];
                                                            dataArray[key] = {...dataArray[key], searchResult: result};
                                                            setTmpCoatingArray(dataArray);
                                                            setSearchString(null);
                                                            setSuggest(false);
                                                        } else {
                                                            selectIngredient(result);
                                                            setSearchString(null);
                                                        }
                                                    }} tag="a" action>{result.Caption}</ListGroupItem>
                                                )) : ''}
                                            </ListGroup>
                                        </TabPane>
                                        <TabPane tabId='ru'>
                                            <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                            <Input
                                                type='text'
                                                name='category'
                                                id={`categoryRu_${key}`}
                                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                                defaultValue={coatingI && coatingI.category ? coatingI.category.ru : ''}
                                                onChange={(e) => {
                                                    if (coatingI) {
                                                        let dataArray = [];
                                                        dataArray = [...tmpCoatingArray];
                                                        dataArray[key] = {...dataArray[key], categoryRu: e.target.value};
                                                        setTmpCoatingArray(dataArray);
                                                    } else setTmpCoating({...tmpCoating, categoryRu: e.target.value})}} />
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                            <Input
                                                type='search'
                                                name='search'
                                                id={`searchIngredientRu_${key}`}
                                                placeholder='search placeholder'
                                                onFocus={() => {if (searchString && activeIndex !== key) setSearchString(null)}}
                                                value={coatingI ?
                                                    (tmpCoatingArray && tmpCoatingArray[key] && tmpCoatingArray[key].searchResult ?
                                                    tmpCoatingArray[key].searchResult.name.ru :
                                                    coatingsById[key] && activeIndex !== key ? coatingsById[key].name.ru : '') : 
                                                    tmpCoating ? tmpCoating.ru : ''}
                                                onChange={(e) => {
                                                    if(!coatingI) setTmpCoating({...tmpCoating, ru: e.target.value})}} />   
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </FormGroup>
        </Row>
    )
}

const CreateCoatingsElement = props => {
    const {
        languageTab, dictionary, lang, 
        tmpCoating, setTmpCoating
    } = props;
    return (
        <Row className='mt-2'>
            <FormGroup className='admin' style={{width: '100%'}}>
                <Col xs={12}>
                    <TabContent className='pt-2' activeTab={languageTab}>
                        <TabPane tabId='az'>
                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.categoryAz[lang]}</Label>
                            <Input
                                type='text'
                                name='category'
                                id={`categoryAz`}
                                onFocus={() => {}}
                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                onChange={(e) => {
                                    setTmpCoating({...tmpCoating, categoryAz: e.target.value})}}
                                />
                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientAz[lang]}</Label>
                            <Input
                                type='textarea'
                                name='coatingAz'
                                id={`coatingAz`}
                                placeholder={dictionary.search[lang]}
                                onChange={(e) => {
                                    setTmpCoating({...tmpCoating, az: e.target.value});
                                }} />
                        </TabPane>
                        <TabPane tabId='ru'>
                            <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                            <Input
                                type='text'
                                name='category'
                                id={`categoryRu`}
                                placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                onChange={(e) => {
                                    setTmpCoating({...tmpCoating, categoryRu: e.target.value})}} />
                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                            <Input
                                type='textarea'
                                name='search'
                                id={`searchIngredientRu`}
                                placeholder='search placeholder'
                                onChange={(e) => {
                                    setTmpCoating({...tmpCoating, ru: e.target.value})}} />   
                        </TabPane>
                    </TabContent>
                </Col>
            </FormGroup>
        </Row>
    )
}

export default PLCoatings;