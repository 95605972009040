import React, {useState} from 'react';
import {Col, Row, Button, 
    Collapse, Label, Input, Table,
    Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem,
    Card, CardBody, CardHeader, CardTitle, CardText, CardFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const MPPharmaceuticalParticulars = props => {
    const {lang, dictionary, medicalProduct} = props;
    const [Incompatibilities, setIncompatibilities] = useState(
        (medicalProduct.pharmaceuticalParticulars || {}).Incompatibilities || [])
    const [ShelfLife, setShelfLife] = useState(
        (medicalProduct.pharmaceuticalParticulars || {}).ShelfLife || {az: '', ru: ''}
    )
    const [SpecialPrecautionsForStorage, setSpecialPrecautionsForStorage] = useState(
        (medicalProduct.pharmaceuticalParticulars || {}).SpecialPrecautionsForStorage || []
    )
    const [NatureAndContentsOfContainer, setNatureAndContentsOfContainer] = useState(
        (medicalProduct.pharmaceuticalParticulars || {}).NatureAndContentsOfContainer || {az: '', ru: ''}
    )
    const [SpecialPrecautionsForDisposalAndOtherHandling, setSpecialPrecautionsForDisposalAndOtherHandling] = useState(
        (medicalProduct.pharmaceuticalParticulars || {}).SpecialPrecautionsForDisposalAndOtherHandling || {az: '', ru: ''}
    )
    const submitButtonHandler = () => {
        Incompatibilities.map(e => {if (e.newElem) delete e.newElem; delete e.id});
        SpecialPrecautionsForStorage.map(e => {if (e.newElem) delete e.newElem; delete e.id});
        
        props.changeProduct({
            pharmaceuticalParticulars: {...medicalProduct.pharmaceuticalParticulars,
                Incompatibilities,
                ShelfLife,
                SpecialPrecautionsForStorage,
                NatureAndContentsOfContainer,
                SpecialPrecautionsForDisposalAndOtherHandling
            }
        });
        props.toggleSave(true);
    }

    return (
        <Row>
            <Col xs='12'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
            <ExcipientsElement lang={lang} excipients={medicalProduct.pharmaceuticalParticulars.listOfExcipients} 
                labelText={dictionary.adminDrugs.medicalProductEditPage.listOfExcipients} />
            <ArrayTextElement array={Incompatibilities} setArray={setIncompatibilities}
                lang={lang} dictionary={dictionary} 
                labelText={dictionary.adminDrugs.medicalProductEditPage.incompatibilities} />
            <LongTextElement text={ShelfLife} setText={setShelfLife} lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.shelfLife} />
            <ArrayTextElement array={SpecialPrecautionsForStorage} setArray={setSpecialPrecautionsForStorage}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.specialPrecautionsForStorage} />
            <RichTextElement
                text={NatureAndContentsOfContainer} setText={setNatureAndContentsOfContainer}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.natureAndContentsOfContainer} />
            <RichTextElement
                text={SpecialPrecautionsForDisposalAndOtherHandling} setText={setSpecialPrecautionsForDisposalAndOtherHandling}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.specialPrecautionsForDisposalAndOtherHandling} />
        </Row>
    )
}

const ExcipientsElement = props => {
    const {lang, labelText, excipients} = props;
    const [collapse, showCollapse] = useState(false);

    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                {excipients.map((e, i) => (
                    <Row key={i} className='mt-2 mb-2'>
                        <Col xs='12'>
                            <Label> {i + 1}. {((e.excipient || {}).name || {})[lang] || ''} </Label>
                        </Col>
                    </Row>
                ))}
            </Collapse>
        </Col>
    ) 
}

const RichTextElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.az = data;
                                setText({...text});
                            }}
                            data={text.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.ru = data;
                                setText({...text});
                            }}
                            data={text.ru || ''} />
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}

const LongTextElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <Input
                            type='textarea'
                            defaultValue={text.az || ''}
                            onChange={ e => {
                                text.az = e.target.value;
                                setText({...text});
                            }} />
                    </Col>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <Input
                            type='textarea'
                            defaultValue={text.ru || ''}
                            onChange={ e => {
                                text.ru = e.target.value;
                                setText({...text});
                            }} />
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}


const ArrayTextElement = props => {
    const {array, setArray, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);

    let max;
    if (array.length > 0) max = Math.max.apply(Math, array.map(e => e.id));
    else max = -1;

    const addButtonHandler = () => {
        setArray(old => [...old, {id: max + 1, az: '', ru: '', newElem: true}])
    }

    const deleteButtonHandler = id => {
        // console.log(array);
        setArray(arr => arr.filter(e => e.id !== id));
    }

    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                    {array.map(el => (
                        <Card key={el.id}>
                            <CardBody>
                                <Row className='mt-2 mb-2'>
                                    <Col xs='6'>
                                    <Label> {labelText[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                                    <Input
                                        type='textarea'
                                        defaultValue={el.az || ''}
                                        onChange={e => {
                                            el.az = e.target.value;
                                            setArray(array);
                                        }} />
                                </Col>
                                    <Col xs='6'>
                                    <Label> {labelText[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                                    <Input
                                        type='textarea'
                                        defaultValue={el.ru || ''}
                                        onChange={e => {
                                            el.ru = e.target.value;
                                            setArray(array);
                                        }} />
                                </Col>
                                    <Col xs='12'>
                                    <Button color='danger' onClick={deleteButtonHandler.bind(this, el.id)}>Delete</Button>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    ))}
                <Row>
                    <Button color='primary' onClick={addButtonHandler}>Add new</Button>
                </Row>
            </Collapse>
        </Col>
    )
}

export default MPPharmaceuticalParticulars;