import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import config from "../../../config";
import AdsBlock from "../AdsBlock/AdsBlock";
import "./DrugsPopular.scss";

class DrugsPopular extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            popularRequests: []
        };

        window.addEventListener("resize", () => {
            this.setState({
                number_of_columns: window.innerWidth > 768 ? 3 : 2
            })
        })
    }

    fetchPopularRequests = async () => {
        let request = await fetch(
            `${config.url}popular-search-results?_sort=requestNumber:desc&type=drugs&_limit=40`,
        );
        let data = await request.json();
        // console.log(data);
        this.setState({
            popularRequests: data
        })
    }

    componentDidMount = () => {
        this.fetchPopularRequests()
    }

    getPopularRequests = (requests) => {
        let number_of_columns = this.state.number_of_columns;
        let number_of_rows = Math.ceil(requests.length / number_of_columns);
        let rows = [];
        for (let i = 0; i < number_of_rows; i++) {
            let columns = [];
            for (let f = 0; f < number_of_columns; f++) {
                let current_request = requests[i * number_of_columns + f];
                if (current_request) {
                    columns.push(
                        <Col key={`${i}/${f}`}>
                            <Link to={`/drugs/search-results/${current_request.request}/types=professional`}>
                                {current_request.request}
                            </Link>
                        </Col>
                    );
                } else {
                    columns.push(
                        <Col key={`${i}/${f}`}>
                        </Col>
                    );
                    break;
                }
            }
            rows.push(<Row key={`${i}`}>{columns}</Row>);
        }
        return rows;
    };

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <>
                <div className="main_content_sda_wrapper">
                    <div className="content_block">
                        <div className="bg-white border-0 display-block">
                            <h1 className="mb-0 drugs-header">
                                {dictionary.drugs_and_medications.popularRequests[lang]}
                            </h1>
                        </div>
                        <div className="drugs-description">
                            <div className="popular_requests_container">
                                {this.getPopularRequests(this.state.popularRequests)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default connect(mapStateToProps)(DrugsPopular);
