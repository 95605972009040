import React, {useState, useEffect} from 'react';
import {Col, Row, Button, 
    Collapse, Label, Input, Table,
    Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem,
    Card, CardBody, CardHeader, CardTitle, CardText, CardFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {useDispatch, useSelector} from 'react-redux';
import { searchCP, searchDrugs, searchICD } from '../../../../redux/actions/adminMedicalProductActions';

const MPClinicalParticulars = props => {
    const {lang, dictionary, medicalProduct} = props;
    const [therapeutic, setTherapeutic] = useState((medicalProduct.clinicalParticulars || {}).therapeuticIndications || {az: '', ru: ''});
    const [posologyAndMethod, setPosologyAndMethod] = useState({
        generalText: ((medicalProduct.clinicalParticulars || {}).posologyAndMethod || {}).generalText || {az: '', ru: ''},
        adults: ((medicalProduct.clinicalParticulars || {}).posologyAndMethod || {}).adults || {az: '', ru: ''},
        elderly: ((medicalProduct.clinicalParticulars || {}).posologyAndMethod || {}).elderly || {az: '', ru: ''},
        children: ((medicalProduct.clinicalParticulars || {}).posologyAndMethod || {}).children || {az: '', ru: ''}
    });
    const [contraindications, setContraindications] = useState((medicalProduct || {}).contraindications || {az: '', ru: ''});
    const [specialWarnings, setSpecialWarnings] = useState((medicalProduct || {}).specialWarningsAndPrecautionsForUse || {az: '', ru: ''});
    const [interactionWithOther, setInteractionWithOther] = useState(
        (medicalProduct.clinicalParticulars || {}).interactionWithOther || []);
    const [pregnancy, setPregnancy] = useState((medicalProduct.clinicalParticulars || {}).useDuringPregnancyAndLactation || {az: '', ru: ''});
    const [effects, setEffects] = useState((medicalProduct.clinicalParticulars || {}).Effects || {az: '', ru: ''});
    const [overdose, setOverdose] = useState((medicalProduct.clinicalParticulars || {}).overdose || {az: '', ru: ''});
    const [icd, setIcd] = useState((medicalProduct.clinicalParticulars || {}).icd_10_codes || []);
    const [cp, setCp] = useState((medicalProduct.clinicalParticulars || {}).cp_class || []);
    
    const submitButtonHandler = () => {
        for(let i of icd) i = i.id;
        for(let c of cp) c = c.id;
        props.changeProduct({
            clinicalParticulars: {...medicalProduct.clinicalParticulars,
                therapeuticIndications: therapeutic,
                posologyAndMethod,
                contraindications,
                interactionWithOther,
                specialWarningsAndPrecautionsForUse: specialWarnings,
                useDuringPregnancyAndLactation: pregnancy,
                Effects: effects,
                overdose,
                undesirableEffects: [],
                icd_10_codes: icd,
                cp_class: cp
            }
        });
        props.toggleSave(true);
    }

    return (
        <Row>
            <Col xs='12'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
            <RichTextElement
                text={therapeutic} setText={setTherapeutic}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsTherapeuticIndications} />
            <PosologyAndMethodElement
                text={posologyAndMethod} setText={setPosologyAndMethod}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyAndMethod} />
            <RichTextElement
                text={contraindications} setText={setContraindications}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsContraindications} />
            <RichTextElement
                text={specialWarnings} setText={setSpecialWarnings}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsSpecialWarnings} />
            <InteractionWithOtherElement
                text={interactionWithOther} setText={setInteractionWithOther}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsInteractionWithOther} />
            <RichTextElement
                text={pregnancy} setText={setPregnancy}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPregnancy} />
            <RichTextElement
                text={effects} setText={setEffects}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsEffects} />
            <UndesirableEffectsElement 
                text={(medicalProduct.clinicalParticulars || {}).undesirableEffects}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsUndesirableEffects} />
            <RichTextElement
                text={overdose} setText={setOverdose}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsOverdose} />
            <CodesElement text={icd} setText={setIcd} lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.icd} icd={true} />
            <CodesElement text={cp} setText={setCp} lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.cp} icd={false} />
        </Row>
    )
}

const RichTextElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.az = data;
                                setText({...text});
                            }}
                            data={text.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.ru = data;
                                setText({...text});
                            }}
                            data={text.ru || ''} />
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}

const PosologyAndMethodElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyGeneral[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.generalText.az = data;
                                setText({...text});
                            }}
                            data={text.generalText.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyGeneral[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.generalText.ru = data;
                                setText({...text});
                            }}
                            data={text.generalText.ru || ''} />
                    </Col>
                </Row>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyChildren[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.children.az = data;
                                setText({...text});
                            }}
                            data={text.children.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyChildren[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.children.ru = data;
                                setText({...text});
                            }}
                            data={text.children.ru || ''} />
                    </Col>
                </Row>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyAdults[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.adults.az = data;
                                setText({...text});
                            }}
                            data={text.adults.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyAdults[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.adults.ru = data;
                                setText({...text});
                            }}
                            data={text.adults.ru || ''} />
                    </Col>
                </Row>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyElderly[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.elderly.az = data;
                                setText({...text});
                            }}
                            data={text.elderly.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsPosologyElderly[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.elderly.ru = data;
                                setText({...text});
                            }}
                            data={text.elderly.ru || ''} />
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}

const InteractionWithOtherElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [modal, setOpen] = useState(false);
    const [collapse, showCollapse] = useState(false);
    
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                {text.map(inter => <InteractionDrugElement setText={setText} interaction={inter} lang={lang} dictionary={dictionary}/>)}
                <Button color='primary' onClick={() => setOpen(true)}> {dictionary.adminDrugs.medicalProductEditPage.addDrug[lang]} </Button>
            </Collapse>
            {<InteractionAddModalElement 
                lang={lang} dictionary={dictionary} 
                modal={modal} setOpen={setOpen} setText={setText} />}
        </Col>
    )
}

const InteractionDrugElement = props => {
    const {lang, dictionary, interaction, setText} = props;
    const [modal, setOpen] = useState(false);
    
    const deleteInteractionHandler = index => {
        setText(old => old.filter(e => e.drug.Caption !== index));
    }

    return (
        <>
            <Card className='mt-2 mb-2'>
                <CardHeader>{((interaction.drug || {}).name || {})[lang]}</CardHeader>
                <CardBody>
                    <CardText dangerouslySetInnerHTML={{__html: (interaction.interaction || {})[lang]}} />
                </CardBody>
                <CardFooter>
                    <Button color='warning' onClick={() => setOpen(!modal)}> {dictionary.adminDrugs.medicalProductEditPage.editDrug[lang]} </Button>
                    <Button color='danger' onClick={deleteInteractionHandler.bind(this, (interaction.drug || {}).Caption)}> {dictionary.adminDrugs.medicalProductEditPage.deleteDrug[lang]} </Button>
                </CardFooter>
            </Card>
            {<InteractionEditModalElement 
                lang={lang} dictionary={dictionary} 
                interaction={interaction} modal={modal} setOpen={setOpen} />}
        </>
    )
}

const InteractionEditModalElement = props => {
    const {lang, dictionary, interaction, modal, setOpen} = props;
    const tempInitialState = {
        drug: {
            Caption: '',
            name: {
                az: '',
                ru: ''
            }
        },
        interaction: {
            az: '',
            ru: ''
        }
    };
    const [temp, setTemp] = useState({...tempInitialState});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    const dispatch = useDispatch();

    const {drugResult, errorMessage} = useSelector(state => ({
        drugResult: state.medicalProductsReducer.drugResult,
        errorMessage: state.errorReducer
    }));

    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchDrugs(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const saveButtonHandler = () => {
        if (interaction) {
            interaction.interaction = temp.interaction;
            interaction.drug = temp.drug;
        }
        setOpen(false);
    }

    return (
        <Modal isOpen={modal}>
            <ModalHeader>{(((interaction || {}).drug || {}).name || {})[lang]}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label> Drug az </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={searchString || (((interaction || {}).drug || {}).name || {}).az || ((temp.drug || {}).name || {}).az || ''}
                            placeholder={dictionary.search[lang]}
                            onChange={e => {
                                setSearchString(e.target.value);
                                temp.drug.name.az = e.target.value;
                                setTemp({...temp});
                            }}
                        />
                        <ListGroup>
                            {suggest && drugResult ? drugResult.map(result => (
                                <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                    temp.drug = result;
                                    setTemp({...temp});
                                    setSearchString(null);
                                }} tag="a" action>{result.Caption}</ListGroupItem>
                            )) : ''}
                        </ListGroup>
                    </Col>
                    <Col>
                        <Label> Drug ru </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={((temp.drug || {}).name || {}).ru || (((interaction || {}).drug || {}).name || {}).ru || ''}
                            onChange={e => {
                                temp.drug.name.ru = e.target.value;
                                setTemp({...temp});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label> Interaction az </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                temp.interaction.az = data;
                                setTemp(temp);
                        }}
                        data={(temp.interaction || {}).az || ((interaction || {}).interaction || {}).az || ''} />
                    </Col>
                    <Col>
                        <Label> Interaction ru </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                temp.interaction.ru = data;
                                setTemp(temp);
                        }}
                        data={(temp.interaction || {}).ru || ((interaction || {}).interaction || {}).ru || ''} />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={saveButtonHandler} > {dictionary.adminDrugs.saveButton[lang]} </Button>
                <Button color="danger" onClick={() => {setTemp({...tempInitialState}); setSearchString(''); setOpen(false)}}> {dictionary.adminDrugs.cancelButton[lang]} </Button>
            </ModalFooter>
        </Modal>
    )
}

const InteractionAddModalElement = props => {
    const {lang, dictionary, modal, setOpen, setText} = props;
    const tempInitialState = {
        drug: {
            Caption: '',
            name: {
                az: '',
                ru: ''
            }
        },
        interaction: {
            az: '',
            ru: ''
        }
    };
    const [temp, setTemp] = useState({...tempInitialState});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    const dispatch = useDispatch();

    const {drugResult, errorMessage} = useSelector(state => ({
        drugResult: state.medicalProductsReducer.drugResult,
        errorMessage: state.errorReducer
    }));

    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchDrugs(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const saveButtonHandler = () => {
        temp.drug.Caption = temp.drug.name.az;
        setText(old => [...old, temp]);
        setOpen(false);
        setTemp({...tempInitialState});
        setSearchString('');
    }

    return (
        <Modal isOpen={modal}>
            <ModalHeader>{'New Interaction'}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label> Drug az </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={searchString || ((temp.drug || {}).name || {}).az || ''}
                            placeholder={dictionary.search[lang]}
                            onChange={e => {
                                setSearchString(e.target.value);
                                temp.drug.name.az = e.target.value;
                                setTemp({...temp});
                            }}
                        />
                        <ListGroup>
                            {suggest && drugResult ? drugResult.map(result => (
                                <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                    temp.drug = result;
                                    setTemp({...temp});
                                    setSearchString(null);
                                }} tag="a" action>{result.Caption}</ListGroupItem>
                            )) : ''}
                        </ListGroup>
                    </Col>
                    <Col>
                        <Label> Drug ru </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={((temp.drug || {}).name || {}).ru || ''}
                            onChange={e => {
                                temp.drug.name.ru = e.target.value;
                                setTemp({...temp});
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label> Interaction az </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                temp.interaction.az = data;
                                setTemp(temp);
                        }}
                        data={(temp.interaction || {}).az || ''} />
                    </Col>
                    <Col>
                        <Label> Interaction ru </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                temp.interaction.ru = data;
                                setTemp(temp);
                        }}
                        data={(temp.interaction || {}).ru || ''} />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={saveButtonHandler} > {dictionary.adminDrugs.saveButton[lang]} </Button>
                <Button color="danger" onClick={() => {setTemp({...tempInitialState}); setSearchString(''); setOpen(false)}}> {dictionary.adminDrugs.cancelButton[lang]} </Button>
            </ModalFooter>
        </Modal>
    )
}

const UndesirableEffectsElement = props => {
    const {text, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='12'>
                        <Table>
                            <thead>
                                <tr>
                                    <th> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsUndesirableEffect[lang]} </th>
                                    <th> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsBodySystem[lang]} </th>
                                    <th> {dictionary.adminDrugs.medicalProductEditPage.clinicalParticularsFrequency[lang]} </th>
                                </tr>
                            </thead>
                            <tbody>
                                {text.map(effect => <UndesirableEffectElement key={effect.id} lang={lang} effect={effect} />)}                
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}

const UndesirableEffectElement = props => {
    const {lang, effect} = props;
    return (
        <tr>
            <td> {((effect.undesirable_effect || {}).text || {})[lang]} </td>
            <td> {(((effect.undesirable_effect || {}).body_system || {}).text || {})[lang]} </td>
            <td> {effect.frequency} </td>
        </tr>
    )
}

const CodesElement = props => {
    const {text, setText, lang, dictionary, labelText, icd} = props;
    const [modal, setOpen] = useState(false);
    const [collapse, showCollapse] = useState(false);

    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]}
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                {text.map(code => <CodeElement key={code.code} icd={icd} setText={setText} code={code} lang={lang} dictionary={dictionary}/>)}
                <Button color='primary' onClick={() => setOpen(true)}> {dictionary.adminDrugs.medicalProductEditPage.addDrug[lang]} </Button>
            </Collapse>
            {icd ? <IcdCodeAddModalElement
                lang={lang} dictionary={dictionary}
                modal={modal} setOpen={setOpen} setText={setText} /> :
            <CpCodeAddModalElement
                lang={lang} dictionary={dictionary}
                modal={modal} setOpen={setOpen} setText={setText} />}
        </Col>
    )
}

const CodeElement = props => {
    const {lang, dictionary, code, setText, icd} = props;
    const [modal, setOpen] = useState(false);
    
    const deleteCodeHandler = code => {
        setText(old => old.filter(e => e.code !== code));
    }

    return (
        <>
            <Card className='mt-2 mb-2'>
                <CardHeader>{code.code}</CardHeader>
                <CardBody>
                    <CardText>
                        {(code.description || {})[lang] || (code.name || {})[lang]}
                    </CardText>
                </CardBody>
                <CardFooter>
                    <Button color='warning' onClick={() => setOpen(!modal)}> {dictionary.adminDrugs.medicalProductEditPage.editDrug[lang]} </Button>
                    <Button color='danger' onClick={deleteCodeHandler.bind(this, code.code)}> {dictionary.adminDrugs.medicalProductEditPage.deleteDrug[lang]} </Button>
                </CardFooter>
            </Card>
            {icd ? <IcdCodeEditModalElement 
                lang={lang} dictionary={dictionary}
                code={code} modal={modal} setOpen={setOpen} /> :
            <CpCodeEditModalElement 
                lang={lang} dictionary={dictionary}
                code={code} modal={modal} setOpen={setOpen} />}
        </>
    )
}

const IcdCodeEditModalElement = props => {
    const {lang, dictionary, code, modal, setOpen} = props;
    const [temp, setTemp] = useState({code: (code || {}).code});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    const dispatch = useDispatch();

    const {icdCodeResult, errorMessage} = useSelector(state => ({
        icdCodeResult: state.medicalProductsReducer.icdCodeResult,
        errorMessage: state.errorReducer
    }));

    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchICD(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const saveButtonHandler = () => {
        if (temp.id) {
            code.id = temp.id;
            code.description = temp.description;
            code.code = temp.code;
        }
        setOpen(false);
    }

    return (
        <Modal isOpen={modal}>
            <ModalHeader>{code.code}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label> {{az: 'Kod', ru: 'Код'}[lang]} </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={temp.code || searchString || ''}
                            placeholder={dictionary.search[lang]}
                            onChange={e => {
                                temp.code = e.target.value;
                                setSearchString(e.target.value);
                            }}
                        />
                        <ListGroup>
                            {suggest && icdCodeResult ? icdCodeResult.map(result => (
                                <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                    setTemp(result);
                                    setSearchString(null);
                                }} tag="a" action>{result.code} | {result.description[lang]}</ListGroupItem>
                            )) : ''}
                        </ListGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={saveButtonHandler} > {dictionary.adminDrugs.saveButton[lang]} </Button>
                <Button color="danger" onClick={() => {setTemp({}); setSearchString(''); setOpen(false)}}> {dictionary.adminDrugs.cancelButton[lang]} </Button>
            </ModalFooter>
        </Modal>
    )
}

const IcdCodeAddModalElement = props => {
    const {lang, dictionary, modal, setOpen, setText} = props;
    const [temp, setTemp] = useState({});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    const dispatch = useDispatch();

    const {icdCodeResult, errorMessage} = useSelector(state => ({
        icdCodeResult: state.medicalProductsReducer.icdCodeResult,
        errorMessage: state.errorReducer
    }));

    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchICD(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const saveButtonHandler = () => {
        if (temp.id) {
            setText(old => [...old, temp]);
        }
        setOpen(false);
        setTemp({});
        setSearchString('');
    }

    return (
        <Modal isOpen={modal}>
            <ModalHeader>{'New Interaction'}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label> Code </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={searchString || temp.code || ''}
                            placeholder={dictionary.search[lang]}
                            onChange={e => {
                                setSearchString(e.target.value);
                                temp['code'] = e.target.value;
                                temp['id'] = null;
                                setTemp({...temp});
                            }}
                        />
                        <ListGroup>
                            {suggest && icdCodeResult ? icdCodeResult.map(result => (
                                <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                    setTemp(result);
                                    setSearchString(null);
                                }} tag="a" action>{result.code} | {result.description[lang]}</ListGroupItem>
                            )) : ''}
                        </ListGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={saveButtonHandler} > {dictionary.adminDrugs.saveButton[lang]} </Button>
                <Button color="danger" onClick={() => {setTemp({}); setSearchString(''); setOpen(false)}}> {dictionary.adminDrugs.cancelButton[lang]} </Button>
            </ModalFooter>
        </Modal>
    )
}

const CpCodeEditModalElement = props => {
    const {lang, dictionary, code, modal, setOpen} = props;
    const [temp, setTemp] = useState({code: (code || {}).code});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    const dispatch = useDispatch();

    const {codeResult, errorMessage} = useSelector(state => ({
        codeResult: state.medicalProductsReducer.cpCodeResult,
        errorMessage: state.errorReducer
    }));

    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchCP(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const saveButtonHandler = () => {
        if (temp.id) {
            code.id = temp.id;
            code.name = temp.name;
            code.code = temp.code;
        }
        setOpen(false);
    }

    return (
        <Modal isOpen={modal}>
            <ModalHeader>{code.code}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label> Code </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={temp.code || searchString || ''}
                            placeholder={dictionary.search[lang]}
                            onChange={e => {
                                temp.code = e.target.value;
                                setSearchString(e.target.value)}
                            }
                        />
                        <ListGroup>
                            {suggest && codeResult ? codeResult.map(result => (
                                <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                    setTemp(result);
                                    setSearchString(null);
                                }} tag="a" action>{result.code} | {result.name[lang]}</ListGroupItem>
                            )) : ''}
                        </ListGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={saveButtonHandler} > {dictionary.adminDrugs.saveButton[lang]} </Button>
                <Button color="danger" onClick={() => {setTemp({}); setSearchString(''); setOpen(false)}}> {dictionary.adminDrugs.cancelButton[lang]} </Button>
            </ModalFooter>
        </Modal>
    )
}

const CpCodeAddModalElement = props => {
    const {lang, dictionary, modal, setOpen, setText} = props;
    const [temp, setTemp] = useState({});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    const dispatch = useDispatch();

    const {codeResult, errorMessage} = useSelector(state => ({
        codeResult: state.medicalProductsReducer.cpCodeResult,
        errorMessage: state.errorReducer
    }));

    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchCP(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const saveButtonHandler = () => {
        setText(old => [...old, temp]);
        setOpen(false);
        setTemp({});
        setSearchString('');
    }

    return (
        <Modal isOpen={modal}>
            <ModalHeader>{'New Interaction'}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label> Code </Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            value={searchString || temp.code || ''}
                            placeholder={dictionary.search[lang]}
                            onChange={e => {
                                setSearchString(e.target.value);
                                temp['code'] = e.target.value;
                                setTemp({...temp});
                            }}
                        />
                        <ListGroup>
                            {suggest && codeResult ? codeResult.map(result => (
                                <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                    setTemp(result);
                                    setSearchString(null);
                                }} tag="a" action>{result.code}</ListGroupItem>
                            )) : ''}
                        </ListGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={saveButtonHandler} > {dictionary.adminDrugs.saveButton[lang]} </Button>
                <Button color="danger" onClick={() => {setTemp({}); setSearchString(''); setOpen(false)}}> {dictionary.adminDrugs.cancelButton[lang]} </Button>
            </ModalFooter>
        </Modal>
    )
}

export default MPClinicalParticulars;