import React, {useState, useEffect} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button,
    Col, Row,
    Label, Input,
    Card, CardBody,
    Nav, NavItem, NavLink,
    TabContent, TabPane,
    Modal, ModalHeader, ModalBody, ModalFooter,
    ListGroup, ListGroupItem, 
    Form, FormGroup
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import {searchIngredient, addingNewIngredient, editPatientLeafletIngredient, isNumber, getIngredientsById} from '../../../../redux/actions/adminActions'

const PLIngredients = props => {
    const {lang, dictionary, patientLeaflet} = props;
    // переключатель языков для полей
    const [languageTab, setTab] = useState('az');
    // это отдельное поле, поэтому его храню тоже отдельно
    const [generalText, setGeneralText] = useState(
        {
            az: patientLeaflet.ingredientsGeneralText ? patientLeaflet.ingredientsGeneralText.az : '', 
            ru: patientLeaflet.ingredientsGeneralText ? patientLeaflet.ingredientsGeneralText.ru : ''
        });
    // открыть или закрыть модалку создания
    const [modal, setModal] = useState(false);
    // открыть модалку редактирования
    const [editModal, setEditModal] = useState(false);
    // эта хрень нужна во время редактирования, чтобы мы знали какой ингредиент именно мы пытаемся редактировать
    const [activeIndex, setActiveIndex] = useState();
    // показать и скрывать выпадашку
    const [suggest, setSuggest] = useState(false);
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    // здесь будут храниться поля нового ингредиента перед сохранением
    const [tmpIngredient, setTmpIngredient] = useState({
        az: null, ru: null,
        category: {az: null, ru: null},
        id: null,
        Caption: null, 
        ingredientText: {az: null, ru: null},
        quantity: '', unit: '', quantityInterval: ''
    });
    // здесь будут храниться поля отредактированных ингредиентов перед сохранением
    const [tmpIngredientArray] = useState(patientLeaflet.ingredients ? patientLeaflet.ingredients : []);

    let ingredientIds = [];

    // mapStateToProp
    const {ingredient, ingredientSearchResult} = useSelector(state => ({
        ingredient: state.adminReducer.ingredient,
        ingredientSearchResult: state.adminReducer.ingredientSearchResult
    }));

    const dispatch = useDispatch();

    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }

    // кнопка создания нового ингредиента
    // открытие модального окна
    const addIngredientButtonHandler = () => {
        setTmpIngredient({
            az: '', ru: '',
            category: {az: '', ru: ''},
            id: null, 
            Caption: '',
            quantity: '', unit: null, quantityInterval: ''}); // вычищаем хранилище
        setSearchString(null); // вычищаем поисковую строку
        setModal(true);
    }

    // кнопка редактирования существующего ингредиента
    // открытие модального окна
    const editIngredientButtonHandler = index => {
        setTmpIngredient({
            az: ingredient[index].name ? ingredient[index].name.az : null,
            ru: ingredient[index].name ? ingredient[index].name.ru : null,
            category: patientLeaflet.ingredients[index].category ? {
                az: patientLeaflet.ingredients[index].category.az,
                ru: patientLeaflet.ingredients[index].category.ru
            } : null,
            quantity: patientLeaflet.ingredients[index].quantity,
            quantityInterval: patientLeaflet.ingredients[index].quantityInterval,
            unit: patientLeaflet.ingredients[index].unit
        }); // присваиваем к хранилищу текущий ингредиент
        setSearchString(null); // вычищаем поисковую строку
        setEditModal(true);
        setActiveIndex(index);
    }

    // выбрать ингредиент из выпадашки
    // возможно эта хрень отредактируется
    const selectIngredient = ing => {
        setTmpIngredient({...tmpIngredient,
            Caption: ing.Caption,
            az: ing.name.az,
            ru: ing.name.ru,
            id: ing.id
        });
        setSuggest(false);
    }

    // создание массива ингредиентных id
    if (patientLeaflet && patientLeaflet.ingredients) {
        patientLeaflet.ingredients.map(ing => ing.ingredient ? ingredientIds.push(ing.ingredient.id) : '');
    }

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchIngredient(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    useEffect(() => {
        if (patientLeaflet) {
            dispatch(getIngredientsById(ingredientIds));
        }
    }, [patientLeaflet]);

    // добавление нового ингредиента
    const addNewIngredient = () => {
        if (!tmpIngredient.id) {
            setTmpIngredient({...tmpIngredient, az: searchString, Caption: searchString})
        }
        dispatch(addingNewIngredient(tmpIngredient, patientLeaflet));
        setModal(false);
        //props.toggleSave(true);
    }
    
    //изменение ингредиента
    const editIngredient = () => {
        // проверяем i-тый ингредиент мы заменили на другой ингредиент из базы или на совершенно новый или вообще не трогали
        // ингредиент, а меняли другие поля
        if (tmpIngredient.id || tmpIngredient.az === patientLeaflet.ingredients[activeIndex].ingredient.Caption) {
            dispatch(editPatientLeafletIngredient(tmpIngredient, patientLeaflet, activeIndex));
        } else {
            setTmpIngredient({...tmpIngredient, az: searchString, Caption: searchString})
            dispatch(editPatientLeafletIngredient(tmpIngredient, patientLeaflet, activeIndex));
        }
        setEditModal(false);
        //props.toggleSave(true);
        setActiveIndex(null);
    }

    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({
            ingredients: patientLeaflet.ingredients.filter((ing, i) => i !== index)
        });
        props.toggleSave(true);
    }

    // сохранение общих заметок
    const saveGeneralTextHandler = () => {
        props.changeLeaflet({
            ingredientsGeneralText: {
                az: generalText.az ? generalText.az : generalText.ru,
                ru: generalText.ru ? generalText.ru : generalText.az
            }
        });
        props.toggleSave(true);
    }

    return (
        <div>
            <Form style={{width: '100%'}}>
                <Row>
                    <Col xs='10' className='pt-0 pl-5 pb-5'>
                        <Row>
                            <Col xs='10'>
                                <TabContent className='pt-2' activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientsGeneralTextAz[lang]}</Label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setGeneralText({az: data, ru: generalText.ru});
                                            }}
                                            data={patientLeaflet.ingredientsGeneralText ? patientLeaflet.ingredientsGeneralText.az : ''} />
                                    </TabPane>
                                    <TabPane tabId='ru'>
                                        <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientsGeneralTextRu[lang]}</Label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setGeneralText({az: generalText.az, ru: data})
                                            }}
                                            data={patientLeaflet.ingredientsGeneralText ? patientLeaflet.ingredientsGeneralText.ru : ''} />
                                    </TabPane>
                                </TabContent>
                                {ingredient ? patientLeaflet.ingredients.map((ingredientI, key) => IngredietnElement({
                                    key, lang, dictionary, ingredient, ingredientI, 
                                    languageTab, deleteButtonHandler, editIngredientButtonHandler})) : ''}
                                <Button onClick={addIngredientButtonHandler} color="primary" size="lg" block><FontAwesomeIcon icon={faPlus} /> {dictionary.adminDrugs.patientLeafletEditPage.ingredients.addButton[lang]}</Button>
                            </Col>
                            <Col xs='2'>
                                <Nav tabs vertical pills className='lang-changer'>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'az' })}
                                            onClick={() => { toggleLang('az'); }}>
                                            Az
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'ru' })}
                                            onClick={() => { toggleLang('ru'); }}>
                                            Ru
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                        <Button onClick={saveGeneralTextHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
                    </Col>
                </Row>
            </Form>
            
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.newIngredient[lang]}</ModalHeader>
                <ModalBody>{IngredientEditElement({
                    lang, dictionary, ingredient, 
                    languageTab, ingredientSearchResult, selectIngredient,
                    searchString, setSearchString,
                    tmpIngredient, setTmpIngredient, suggest, setSuggest})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewIngredient}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpIngredient({}); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={editModal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.editIngredient[lang]}</ModalHeader>
                <ModalBody>{IngredientEditElement({
                    lang, dictionary, languageTab, ingredient,
                    tmpIngredient, setTmpIngredient, suggest, setSuggest,
                    searchString, setSearchString, ingredientSearchResult, selectIngredient
                })}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={editIngredient}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.editIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpIngredient({}); setEditModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const IngredietnElement = props => {
    const {
        languageTab, deleteButtonHandler, editIngredientButtonHandler,
        dictionary, lang, key, ingredientI,
        ingredient} = props;
    return (
        <Row className='mt-2' key={key}>
            <FormGroup className='admin' style={{width: '100%'}}>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Row className='pt-2'>
                                <Col xs={12}>
                                    <FontAwesomeIcon className='float-right pointer' icon={faTrash}
                                        onClick={deleteButtonHandler.bind(this, key)}/>
                                    <FontAwesomeIcon className='float-right mr-3 pointer' icon={faEdit}
                                        onClick={editIngredientButtonHandler.bind(this, key)} />
                                    <TabContent className='pt-2' activeTab={languageTab}>
                                        <TabPane tabId='az'>
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.categoryAz[lang]}</Label>
                                            <Input type='text' disabled id={`categoryAz_${key}`} disabled
                                                value={ingredientI.category ? ingredientI.category.az : ''}
                                             />
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientAz[lang]}</Label>
                                            <Input type='search' disabled id={`searchIngredientAz_${key}`}
                                                value={ingredient[key] ? ingredient[key].name.az : ''}/>
                                        </TabPane>
                                        <TabPane tabId='ru'>
                                            <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                            <Input type='text' disabled id={`categoryRu_${key}`}
                                                value={ingredientI.category ? ingredientI.category.ru : ''}/>
                                            <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                            <Input type='text' disabled id={`searchIngredientRu_${key}`}
                                                value={ingredient[key] ? ingredient[key].name.ru : ''}/>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                            <Row className='pt-5'>
                                <Col xs={6}>
                                    <Label for='quantity'>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.quantity[lang]}</Label>
                                    <Input type='text' disabled id={`quantity_${key}`}
                                        value={ingredientI.quantityInterval ? ingredientI.quantityInterval : ingredientI.quantity}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Label for='unit'>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.unitTitle[lang]}</Label>
                                    <Input type='text' disabled id={`unit_${key}`}
                                        value={ingredientI.unit ? dictionary.adminDrugs.patientLeafletEditPage.ingredients.unit.find(un => un.id === ingredientI.unit)[lang] : ''}
                                    />    
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </FormGroup>
        </Row>
    )
}

const IngredientEditElement = props => {
    const {
        lang, languageTab, dictionary,
        searchString, setSearchString, 
        tmpIngredient, setTmpIngredient,
        suggest, selectIngredient,
        ingredientSearchResult
    } = props;

    if (!tmpIngredient) setTmpIngredient({category: null, quantity: null, unit: null});

    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.categoryAz[lang]}</Label>
                                    <Input
                                        type='text'
                                        name='category'
                                        id={`categoryAz`}
                                        placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                        value={tmpIngredient.category ? tmpIngredient.category.az : ''}
                                        onChange={(e) => {
                                            setTmpIngredient({
                                                ...tmpIngredient, 
                                                category: {...tmpIngredient.category, az: e.target.value}})}}/>
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientAz[lang]}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        name='search'
                                        id={`searchIngredientAz`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            searchString !== null ? searchString :
                                            tmpIngredient ? tmpIngredient.az : ''
                                        }
                                        onChange={e => {
                                            setTmpIngredient({...tmpIngredient, az: e.target.value});
                                            setSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && ingredientSearchResult ? ingredientSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectIngredient(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                    <Input
                                        type='text'
                                        name='category'
                                        id={`categoryRu`}
                                        placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                        value={tmpIngredient.category ? tmpIngredient.category.ru : ''}
                                        onChange={(e) => {
                                            setTmpIngredient({...tmpIngredient, 
                                                category: {...tmpIngredient.category, ru: e.target.value}})}}
                                    />
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                    <Input
                                        type='search'
                                        name='search'
                                        id={`searchIngredientRu`}
                                        placeholder=''
                                        value={tmpIngredient ? tmpIngredient.ru : ''}
                                        onChange={(e) => {setTmpIngredient({...tmpIngredient, ru: e.target.value})}} />   
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col xs={6}>
                            <Label for='quantity'>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.quantity[lang]}</Label>
                            <Input 
                                type='text'
                                name='quantity'
                                id={`quantity`}
                                placeholder='miqdar'
                                value={tmpIngredient.quantity}
                                onChange={(e) => {
                                    setTmpIngredient({...tmpIngredient, quantity: e.target.value});}}
                            />
                        </Col>
                        <Col xs={6}>
                            <Label for='unit'>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.unitTitle[lang]}</Label>
                            <Input
                                type='select'
                                name='unit'
                                id={`unit`}
                                onChange={e => {
                                    setTmpIngredient({...tmpIngredient, unit: e.target.value});
                                }}>
                                <option defaultValue>{tmpIngredient.unit ? dictionary.adminDrugs.patientLeafletEditPage.ingredients.unit.find(un => un.id === tmpIngredient.unit)[lang] : 'choose...'}</option>
                                {dictionary.adminDrugs.patientLeafletEditPage.ingredients.unit.map(opt => 
                                    <option value={opt.id} key={opt.id}>{opt[lang]}</option>
                                )}
                            </Input>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default PLIngredients;