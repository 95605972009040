import { GET_USER_DATA, LOGIN, REGISTER_USER, AUTHORIZED } from './action-types';
import config from '../../config';

const _getUserData = userData => {
    return {
        type: GET_USER_DATA,
        payload: userData
    }
}

export const getUserData = token => dispatch => {
    return fetch(`${config.url}users/me`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(response => { dispatch(_getUserData(response)) });
}

export const authorized = auth => ({
    type: AUTHORIZED,
    payload: auth
})

export const _auth = token => ({
    type: LOGIN,
    payload: token
});

export const authorisation = (username, password) => dispatch => {
    return fetch(`${config.url}auth/local`, {
            method: 'POST',
            body: JSON.stringify({ identifier: username, password }),
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json'
            }
        })
        .then(res => res.json())
        .then(response => {
            if (response.error) { return alert(response.error); }
            console.log(response);
            dispatch(_auth(response.jwt));
            dispatch(authorized(true));
        })
}