import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import TextInput from "../form-components/TextInput";
import TextInputWithoutLabel from "../form-components/TextInputWithoutLabel";
import { withTranslation } from "react-i18next";

class ForgotCodeForm extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Field
                    name="code"
                    placeholder={t("Code")}
                    component={TextInputWithoutLabel}
                    style={{ width: "250px" }}
                />
            </div>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    if (!values.code || values.code.trim() === "") {
        errors.code = props.t("Field couldn't be empty");
    }
    return errors;
};

const form = reduxForm({
    form: "ForgotCodeForm",
    validate,
})(ForgotCodeForm);

export default connect(null)(withTranslation()(form));
