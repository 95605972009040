import React from "react";

import {
    Col, Container, Row
} from "reactstrap";

const AdminHeader = props => {
    return (
        <>
            <div className="header pt-5 d-flex align-items-center">
                <span className="mask bg-gradient-default opacity-7"/>
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col sm="6">Header</Col>
                    </Row>
                </Container>
            </div>
        </>
    );
    
}

export default AdminHeader;
