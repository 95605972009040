import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane
} from 'reactstrap';


const PLWhatYouNeedToKnow = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const {whatYouNeedToKnow} = patientLeaflet;
    const [toKnow, setToKnow] = useState(whatYouNeedToKnow ? {
        generalText: whatYouNeedToKnow.generalText ? {...whatYouNeedToKnow.generalText} : {az: '', ru: ''},
        useInChild: whatYouNeedToKnow.useInChild ? {...whatYouNeedToKnow.useInChild} : {az: '', ru: ''},
        doNotUse: whatYouNeedToKnow.doNotUse ? {...whatYouNeedToKnow.doNotUse} : {az: '', ru: ''},
        warningsAndPrecautions: whatYouNeedToKnow.warningsAndPrecautions ? {...whatYouNeedToKnow.warningsAndPrecautions} : {az: '', ru: ''},
        otherMedicines: whatYouNeedToKnow.otherMedicines ? {...whatYouNeedToKnow.otherMedicines} : {az: '', ru: ''},
        pregnancyAndBreastFeeding: whatYouNeedToKnow.pregnancyAndBreastFeeding ? {...whatYouNeedToKnow.pregnancyAndBreastFeeding} : {az: '', ru: ''},
        drivingAndUsingMachines: whatYouNeedToKnow.drivingAndUsingMachines ? {...whatYouNeedToKnow.drivingAndUsingMachines} : {az: '', ru: ''},
        contains: whatYouNeedToKnow.contains ? {...whatYouNeedToKnow.contains} : {az: '', ru: ''}
    } : {
        generalText: {az: '', ru: ''}, useInChild: {az: '', ru: ''}, doNotUse: {az: '', ru: ''},
        warningsAndPrecautions: {az: '', ru: ''}, otherMedicines: {az: '', ru: ''}, pregnancyAndBreastFeeding: {az: '', ru: ''},
        drivingAndUsingMachines: {az: '', ru: ''}, contains: {az: '', ru: ''}
    });
    const [languageTab, setTab] = useState('az');

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }
    
    const submitButtonHandler = () => {
        props.changeLeaflet({whatYouNeedToKnow: {
            generalText: {...toKnow.generalText, ru: toKnow.generalText.ru ? toKnow.generalText.ru : toKnow.generalText.az},
            useInChild: {...toKnow.useInChild, ru: toKnow.useInChild.ru ? toKnow.useInChild.ru : toKnow.useInChild.az},
            doNotUse: {...toKnow.doNotUse, ru: toKnow.doNotUse.ru ? toKnow.doNotUse.ru : toKnow.doNotUse.az},
            warningsAndPrecautions: {...toKnow.warningsAndPrecautions, ru: toKnow.warningsAndPrecautions.ru ? toKnow.warningsAndPrecautions.ru : toKnow.warningsAndPrecautions.az},
            otherMedicines: {...toKnow.otherMedicines, ru: toKnow.otherMedicines.ru ? toKnow.otherMedicines.ru : toKnow.otherMedicines.az},
            pregnancyAndBreastFeeding: {...toKnow.pregnancyAndBreastFeeding, ru: toKnow.pregnancyAndBreastFeeding.ru ? toKnow.pregnancyAndBreastFeeding.ru : toKnow.pregnancyAndBreastFeeding.az},
            drivingAndUsingMachines: {...toKnow.drivingAndUsingMachines, ru: toKnow.drivingAndUsingMachines.ru ? toKnow.drivingAndUsingMachines.ru : toKnow.drivingAndUsingMachines.az},
            contains: {...toKnow.contains, ru: toKnow.contains.ru ? toKnow.contains.ru : toKnow.contains.az},
        }});
        props.toggleSave(true);
    }
    
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.generalText[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            generalText: {
                                                ...toKnow.generalText,
                                                az: data
                                        }});
                                } }
                                data={toKnow.generalText ? toKnow.generalText.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.useInChild[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            useInChild: {
                                                ...toKnow.useInChild,
                                                az: data
                                        }});
                                } }
                                data={toKnow.useInChild ? toKnow.useInChild.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.doNotUse[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            doNotUse: {
                                                ...toKnow.doNotUse,
                                                az: data
                                        }});
                                } }
                                data={toKnow.doNotUse ? toKnow.doNotUse.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.warningsAndPrecautions[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            warningsAndPrecautions: {
                                                ...toKnow.warningsAndPrecautions,
                                                az: data
                                        }});
                                } }
                                data={toKnow.warningsAndPrecautions ? toKnow.warningsAndPrecautions.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.otherMedicines[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            otherMedicines: {
                                                ...toKnow.otherMedicines,
                                                az: data
                                        }});
                                } }
                                data={toKnow.otherMedicines ? toKnow.otherMedicines.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.pregnancyAndBreastFeeding[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            pregnancyAndBreastFeeding: {
                                                ...toKnow.pregnancyAndBreastFeeding,
                                                az: data
                                        }});
                                } }
                                data={toKnow.pregnancyAndBreastFeeding ? toKnow.pregnancyAndBreastFeeding.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.drivingAndUsingMachines[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            drivingAndUsingMachines: {
                                                ...toKnow.drivingAndUsingMachines,
                                                az: data
                                        }});
                                } }
                                data={toKnow.drivingAndUsingMachines ? toKnow.drivingAndUsingMachines.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.contains[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            contains: {
                                                ...toKnow.contains,
                                                az: data
                                        }});
                                } }
                                data={toKnow.contains ? toKnow.contains.az : ''} />
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.generalText[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            generalText: {
                                                ...toKnow.generalText,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.generalText ? toKnow.generalText.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.useInChild[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            useInChild: {
                                                ...toKnow.useInChild,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.useInChild ? toKnow.useInChild.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.doNotUse[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            doNotUse: {
                                                ...toKnow.doNotUse,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.doNotUse ? toKnow.doNotUse.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.warningsAndPrecautions[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            warningsAndPrecautions: {
                                                ...toKnow.warningsAndPrecautions,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.warningsAndPrecautions ? toKnow.warningsAndPrecautions.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.otherMedicines[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            otherMedicines: {
                                                ...toKnow.otherMedicines,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.otherMedicines ? toKnow.otherMedicines.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.pregnancyAndBreastFeeding[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            pregnancyAndBreastFeeding: {
                                                ...toKnow.pregnancyAndBreastFeeding,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.pregnancyAndBreastFeeding ? toKnow.pregnancyAndBreastFeeding.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.drivingAndUsingMachines[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            drivingAndUsingMachines: {
                                                ...toKnow.drivingAndUsingMachines,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.drivingAndUsingMachines ? toKnow.drivingAndUsingMachines.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.contains[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setToKnow({
                                            ...toKnow,
                                            contains: {
                                                ...toKnow.contains,
                                                ru: data
                                        }});
                                } }
                                data={toKnow.contains ? toKnow.contains.ru : ''} />
                            </TabPane>
                        </TabContent>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

export default PLWhatYouNeedToKnow;