import React from 'react';
import {Col, Nav, NavItem, NavLink} from 'reactstrap';

const LanguageToggler = props => {
    const {languageTab, setTab} = props;

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {if (languageTab !== tab) setTab(tab)}

    return (
        <Col xs='2'>
            <Nav tabs vertical pills className='lang-changer'>
                <NavItem>
                    <NavLink
                        className={languageTab === 'az' ? 'active' : ''}
                        onClick={() => { toggleLang('az'); }}>
                        Az
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={languageTab === 'ru' ? 'active' : '' }
                        onClick={() => { toggleLang('ru'); }}>
                        Ru
                    </NavLink>
                </NavItem>
            </Nav>
        </Col>
    )
}

export default LanguageToggler;
