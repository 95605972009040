import React, {useState, useEffect} from 'react';
import {
    Button,
    Col, Row,
    Label, Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const MPGeneralInfo = props => {
    const {lang, dictionary, medicalProduct} = props;
    const [name, setName] = useState(medicalProduct.name || []);
    const [internationalName, setInternationalName] = useState(
        medicalProduct.internationalNonPatentedName || (medicalProduct.patient_leaflet || {}).internationalNonPatentedName || '');

    const submitButtonHandler = () => {
        // убираем пустышки
        const cleanName = name.filter(n => n.az);
        props.changeProduct({
            name: cleanName,
            internationalNonPatentedName: internationalName
        });
        props.toggleSave(true);
    }
 
    const addName = () => {
        setName([...name, {az: '', ru: ''}]);
    }

    const deleteName = id => {
        if (id) return setName(n => n.filter(e => e.id !== id));
    }
 
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row className='mb-2'>
                    <Col>
                        <Label>{dictionary.adminDrugs.medicalProductEditPage.internationalNonPatentedName[lang]}</Label>
                        <Input
                            type='text'
                            name='international_name'
                            id={`international_name`}
                            defaultValue={internationalName}
                            onChange={e => {setInternationalName(e.target.value)}}
                        />
                    </Col>
                </Row>
                {name.map((n, i) => (
                    <NameArrayElement
                        key={n.id}
                        name={name} id={n.id} index={i} setName={setName} deleteName={deleteName}
                        dictionary={dictionary} lang={lang} />
                ))}
                <Button onClick={addName} color='primary'><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.medicalProductEditPage.addNameButton[lang]}</Button>
            </Col>
            <Col xs='2' className='pt-0 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

const NameArrayElement = props => {
    const {id, dictionary, lang, name, setName, index, deleteName} = props;
    return (
        <>
            <Row>
                <Col xs="5">
                    <Label>{index+1}. {dictionary.adminDrugs.medicalProductEditPage.name[lang]} {dictionary.adminDrugs.az[lang]}</Label>
                    <Input
                        type='text'
                        name='name'
                        id={`name_az_${index}`}
                        defaultValue={name[index] ? name[index].az : ''}
                        onChange={e => {
                            name[index].az = e.target.value;
                            setName(name);
                        }}
                    />
                </Col>
                <Col xs="5">
                    <Label>{dictionary.adminDrugs.medicalProductEditPage.name[lang]} {dictionary.adminDrugs.ru[lang]}</Label>
                    <Input
                        type='text'
                        name='name'
                        id={`name_ru_${index}`}
                        defaultValue={name[index] ? name[index].ru : ''}
                        onChange={e => {
                            name[index].ru = e.target.value;
                            setName(name);
                        }}
                    />
                </Col>
                <Col xs="2">
                    <Button onClick={deleteName.bind(this, id)} style={{position: 'absolute', bottom: 1}} color="danger"><FontAwesomeIcon icon={faTrash} /></Button>
                </Col>
            </Row>
            <hr/>
        </>
    )
}

export default MPGeneralInfo;