import React from "react";
import {Col, Row} from "reactstrap";

export const categoriesJsx = (letter) => {
    return (
        <Row>
            <Col>
                <div className="mt-3 mb-3">
                    <button className="alphabet_button text-left">{letter}</button>
                    <div className="btn-link d-block mt-2 mb-2">ACE inhibitors with calcium channel blocking agents</div>
                </div>
            </Col>
        </Row>
    );
};