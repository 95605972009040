import React from "react";
import "./TextInput.scss";

import NumberFormat from "react-number-format";

class TextInput extends React.Component {
    render() {
        const {
            placeholder,
            label,
            type,
            readOnly,
            disableInput,
            editable,
            multiline,
            _className,
            input: { onChange },
            meta: { touched, valid, error },
            name,
        } = this.props;

        let value = this.props.input.value;

        if (type === "datetime-local" && value.length == 19) {
            value = value.replace(" ", "T");
            value = value.substring(0, value.length - 3);
        }

        const errorClassName = !valid && touched ? "error" : "";

        return (
            <div
                className={
                    "text-input-component reg-input-styles" +
                    " " +
                    (_className || "")
                }
            >
                <p>{label ? label : placeholder}</p>
                {type === "label" && (
                    <label className={errorClassName}>
                        {value || placeholder}
                    </label>
                )}
                {(type === "decimal" || type === "integer") && (
                    <NumberFormat
                        className={errorClassName}
                        name={name}
                        onChange={onChange}
                        value={value}
                        readOnly={readOnly}
                        placeholder={placeholder}
                    />
                )}
                {type !== "decimal" &&
                    type !== "integer" &&
                    type !== "label" && (
                        <input
                            className={errorClassName}
                            name={name}
                            type={type}
                            onChange={onChange}
                            value={value}
                            readOnly={readOnly}
                            placeholder={placeholder}
                        />
                    )}
                {!valid && touched ? (
                    <p className="text-input-component__error">{error}</p>
                ) : null}
            </div>
        );
    }
}

export default TextInput;
