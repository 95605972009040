import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPatientLeaflets, countPatientLeaflets, deleteLeaflet, createLeaflet, resetNewLeaflet, searchPatientLeaflets} from '../../../redux/actions/adminActions'
import {
    Container,
    Table,
    Button,
    Col, Row,
    Breadcrumb, BreadcrumbItem,
    Modal, ModalHeader, ModalBody, ModalFooter, Input
} from 'reactstrap';
import AdminPagination from '../AdmiPagination/AdminPagination';
import moment from 'moment';
import {Link, Redirect} from 'react-router-dom';

import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const PatientLeaflets = props => {
    // для пагинации
    let page = props.location.search !== '' ? queryString.parse(props.location.search).page : '1';
    const start = (page - 1)*20;
    
    const dispatch = useDispatch();
    
    const {patientLeaflets, newLeaflet, leafletsCount, lang, dictionary, authorized} = useSelector(state => ({
        patientLeaflets: state.adminReducer.patientLeaflets,
        newLeaflet: state.adminReducer.newLeaflet,
        leafletsCount: state.adminReducer.leafletsCount,
        lang: state.languageReducer.language,
        dictionary: state.dictionaryReducer,
        authorized: state.userReducer.authorized
    }));
    const prevCount = useRef();
    // открыть или закрыть модалку создания
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [searchString, setSearchString] = useState('');
 
    useEffect(() => {
        if (searchString.length > 2) {
            dispatch(searchPatientLeaflets(searchString));
        } else {
            dispatch(countPatientLeaflets());
            dispatch(getPatientLeaflets(start));
        }
    }, [searchString, leafletsCount]);

    const deletePatientLeaflet = id => {
        dispatch(deleteLeaflet(id));
        setDeleteModal(false);
    }

    const createPatientLeaflet = () => {
        dispatch(createLeaflet());
    }

    page = parseInt(page);
    const count = parseInt(leafletsCount);
    const lastPage = (count - count%20)/20 + 1;

    if (!authorized) return (<Redirect to={`/login`} />)
    else if (newLeaflet) return (<Redirect to={`patient-leaflet/edit/${newLeaflet.id}`} />)
    else return (
        <Container>
            <Modal isOpen={deleteModal}>
                <ModalHeader>{dictionary.adminDrugs.deletePatientLeaflet.deleteLeaflet[lang]}</ModalHeader>
                <ModalBody>{dictionary.adminDrugs.deletePatientLeaflet.areYouSure[lang]}</ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={deletePatientLeaflet.bind(this, deleteId)}>{dictionary.adminDrugs.deleteButton[lang]}</Button>
                    <Button color='secondary' onClick={() => setDeleteModal(false)}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/admin'}>{dictionary.breadcrumbs.home[lang]}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{dictionary.adminDrugs.patientLeaflets[lang]}</BreadcrumbItem>
                </Breadcrumb>
                <Col xs={{size: 6, offset: 2}}>
                    <Button onClick={createPatientLeaflet} className='float-right mt-2' color='primary'><FontAwesomeIcon icon={faPlus} /> {dictionary.adminDrugs.createPatientLeaflet[lang]}</Button>
                </Col>
            </Row>
            <Row className='mt-2 mb-2'>
                <Col xs={{size: 6, offset: 3}}>
                    <Input type='search' placeholder={dictionary.search[lang]} value={searchString} onChange={e => setSearchString(e.target.value)} />
                </Col>
            </Row>
            <Table hover striped>
                <thead>
                    <tr>
                        <th>#id</th>
                        <th>{dictionary.adminDrugs.productsTable.name[lang]}</th>
                        <th>{dictionary.adminDrugs.productsTable.internationalNonPatentedName[lang]}</th>
                        <th>{dictionary.adminDrugs.productsTable.medicalProduct[lang]}</th>
                        <th>{dictionary.adminDrugs.productsTable.addedDate[lang]}</th>
                        <th>{dictionary.adminDrugs.leafletCount[lang]} <span className='color-blue'>{leafletsCount}</span></th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {patientLeaflets.length > 0 ? 
                        patientLeaflets.map(leaflet => 
                            <tr key={leaflet.id}>
                                <td>
                                    {leaflet.id}
                                </td>
                                <td>
                                    {leaflet.title ? leaflet.title[lang] : ''}
                                </td>
                                <td>
                                    {leaflet.internationalNonPatentedName}
                                </td>
                                <td>
                                    {leaflet.medical_product ? <Link to={`/admin/medical-product/edit/${(leaflet.medical_product || {}).id}`}>
                                        {(((leaflet.medical_product || {}).name || {})[0] || {})[lang]}
                                    </Link> : ''}
                                </td>
                                <td>
                                    {moment(leaflet.created_at).format('DD.MM.YYYY, hh:mm')}
                                </td>
                                <td>
                                    <Link to={`patient-leaflet/edit/${leaflet.id}`}>
                                        <Button color='success'><FontAwesomeIcon icon={faEdit}/></Button>
                                    </Link>
                                    <Button onClick={() => {setDeleteId(leaflet.id); setDeleteModal(true);}} style={{marginLeft: 3}} color='danger'><FontAwesomeIcon icon={faTrash}/></Button>
                                </td>
                            </tr>) 
                    : <tr></tr>}
                </tbody>
            </Table>
            <AdminPagination page={page} lastPage={lastPage} />
        </Container>
    )
}

export default PatientLeaflets;