import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Input,
    Dropdown,
    DropdownMenu, DropdownItem, DropdownToggle
} from "reactstrap";
import "./Register.scss";
import { connect } from "react-redux";
import { registerUser } from "../../../redux/actions/auth-actions";
import { Button, Form } from "react-bootstrap";
import { setFieldsValue } from "../../../redux/actions/simpleActions";
import Checkbox from "../Checkbox/Checkbox"

class Register extends React.Component {
    constructor(props) {
        super(props);
        let fieldsValue = this.props.fieldsValue;
        this.state = {
            professionalChosen: fieldsValue.professionalChosen,
            professional: fieldsValue.professional,
            sendEmailNotification: fieldsValue.sendEmailNotification,
            email: fieldsValue.email,
            fullName: fieldsValue.fullName,
            firstName: fieldsValue.firstName,
            birthdate: fieldsValue.birthdate,
            specialization: fieldsValue.specialization,
            medicalInstitution: fieldsValue.medicalInstitution,
            phone: fieldsValue.phone,
            id: "",
            type: fieldsValue.type,
            username: fieldsValue.username.toLowerCase(),
            password: "",
            confirmPassword: "",
            sex: fieldsValue.sex,
            validated: false,
            dropdown_open: false,
            datePlaceholder: true,
            iAgree: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(item, value) {
        if (item === 'username') value = value.toLowerCase();
        const newState = {
            ...this.state,
            [item]: value,
        };

        this.props.setFieldsValue(this.state);
        this.setState(newState);
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
        } else {
            event.preventDefault();
            this.props.registerUser(this.state);
        }

        this.setState({
            ...this.state,
            validated: true,
        });
    }

    hidePlaceholder = () => {
        this.setState({...this.state, datePlaceholder: false});
    }

    render() {
        if (this.props.authData) {
            this.props.history.push('/drugs/account')
        }

        let form = this.props.form;
        const {language, dictionary} = this.props;
        let fields = [];

        for (let field in form.generalInfo1) {
            let input = form.generalInfo1[field];

            if (input.type === "select") {
                const toggle = () => {
                    this.setState({
                        dropdown_open: !this.state.dropdown_open
                    });
                }

                fields.push(
                    <Row key={input.label[language]}>
                        <Col md="4" className="desktop tablet_large tablet_small">
                            <p>{input.label[language]}</p>
                        </Col>
                        <Col>
                            <Dropdown isOpen={this.state.dropdown_open} toggle={toggle}>
                                <DropdownToggle care color={this.state.sex ? '#111111' : '#707070'}>
                                    {this.state.sex ? this.props.dictionary.registerComponent.genders[this.state.sex][language] : input.label[language]}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {input.options.map((item) => (
                                        <DropdownItem value={item.value} key={item.value} onClick={(event) => {
                                            this.handleChange(field, event.target.value)
                                        }}>
                                            {item.label[language]}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row >
                );
            } else if (input.type === "date") {
                fields.push(
                    <Row key={input.label[language]}>
                        <Col md="4" className="desktop tablet_large tablet_small">
                            <p>{input.label[language]}</p>
                        </Col>
                        <Col>
                            <Form.Control
                                onChange={e => this.handleChange(field, e.target.value)}
                                onClick={this.hidePlaceholder}
                                type={input.type}
                                required
                                defaultValue={this.state[field]}
                                placeholder={input.label[language]}
                            />
                        </Col>
                        {this.state.datePlaceholder ? <div className="date-label mobile" > {input.label[language]} </div> : <></>}
                    </Row>
                );
            } else {
                fields.push(
                    <Row key={input.label[language]}>
                        <Col md="4" className="desktop tablet_large tablet_small">
                            <p>{input.label[language]}</p>
                        </Col>
                        <Col>
                            <Form.Control
                                onChange={(event) =>
                                    this.handleChange(field, event.target.value)
                                }
                                type={input.type}
                                required
                                defaultValue={this.state[field]}
                                placeholder={input.label[language]}
                            />
                        </Col>
                    </Row>
                );
            }
        }

        if (this.state.professional) {
            for (let field in form.medicalWorkerInfo) {
                let input = form.medicalWorkerInfo[field];

                if (input.type !== "select") {
                    fields.push(
                        <Row key={input.label[language]}>
                            <Col
                                md="4"
                                className="desktop tablet_large tablet_small"
                            >
                                <p>{input.label[language]}</p>
                            </Col>
                            <Col>
                                <Form.Control
                                    onChange={(event) =>
                                        this.handleChange(
                                            field,
                                            event.target.value
                                        )
                                    }
                                    type={input.type}
                                    required
                                    defaultValue={this.state[field]}
                                    placeholder={input.label[language]}
                                />
                            </Col>
                        </Row>
                    );
                }
            }
        }

        for (let field in form.generalInfo2) {
            let input = form.generalInfo2[field];

            fields.push(
                <Row key={input.label[language]}>
                    <Col md="4" className="desktop tablet_large tablet_small">
                        <p>{input.label[language]}</p>
                    </Col>
                    <Col>
                        <Form.Control
                            onChange={(event) =>
                                this.handleChange(field, event.target.value)
                            }
                            type={input.type}
                            pattern={
                                field === "confirmPassword"
                                    ? `^${this.state.password}$`
                                    : null
                            }
                            required
                            defaultValue={this.state[field]}
                            placeholder={input.label[language]}
                        />
                    </Col>
                </Row>
            );
        }

        return (
            <>
                <CardHeader className="bg-transparent border-0 register_header">
                    <h1 className="main-title-sign-up">
                        {this.props.auth.header[language]}
                    </h1>
                </CardHeader>
                {!this.state.professionalChosen ? (
                    <CardBody className="register_body" id="wrapper">
                        <Row>
                            <Col>
                                <p>{this.props.auth.chooseText[language]}</p>
                            </Col>
                        </Row>
                        <div className="register_choose_type">
                            <p
                                className="button register_as_user"
                                onClick={(event) => {
                                    this.setState({
                                        professionalChosen: true,
                                        professional: false,
                                    });
                                }}
                            >
                                {this.props.auth.asUser[language]}
                            </p>
                            <p
                                className="button register_as_medical_worker"
                                onClick={(event) => {
                                    this.setState({
                                        professionalChosen: true,
                                        professional: true,
                                    });
                                }}
                            >
                                {this.props.auth.asMedicalWorker[language]}
                            </p>
                        </div>
                    </CardBody>
                ) : (
                        <CardBody className="register_form" id="wrapper">
                            <Form
                                noValidate
                                validated={this.state.validated}
                                onSubmit={this.handleSubmit}
                            >
                                {fields}
                                <Row>
                                    <Col xs={'12'} className="checkbox_row">
                                        <Checkbox
                                            checked={this.state.sendEmailNotification}
                                            checkbox_handle={() => {
                                                this.setState({
                                                    sendEmailNotification: !this.state.sendEmailNotification,
                                                });
                                            }}
                                        ></Checkbox>
                                        <p className="send_notifications">
                                            {this.props.auth.form.sendNotifications[language]}
                                        </p>
                                    </Col>
                                    <Col xs={'12'} className="checkbox_row">
                                        <Checkbox
                                            checked={this.state.iAgree}
                                            checkbox_handle={() => {
                                                this.setState({iAgree: !this.state.iAgree});
                                            }}
                                        />
                                        <p className="send_notifications" dangerouslySetInnerHTML={{__html: dictionary.registerComponent.iAgree[language]}}/>
                                    </Col>
                                </Row>
                                <Button disabled={!this.state.iAgree} className="register_submit_button" type="submit">
                                    {this.props.auth.form.submitButton[language]}
                                </Button>
                            </Form>
                        </CardBody>
                    )}
            </>
        );
    }
}

const SelectInputElement = ({label, lang, openDropdown, toggle, defaultVal, defaultValLabel, options, handleChange}) => {
    return (
        <Row key={label}>
            <Col md="4" className="desktop tablet_large tablet_small">
                <p>{label}</p>
            </Col>
            <Col>
                <Dropdown isOpen={openDropdown} toggle={toggle}>
                    <DropdownToggle care color={defaultVal ? '#111111' : '#707070'}>
                        {defaultValLabel}
                    </DropdownToggle>
                    <DropdownMenu>
                        {options.map(item => (
                            <DropdownItem value={item.value} key={item.value} name={item.name} onClick={handleChange}>
                                {item.label[lang]}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row >
    )
}

const TextInputElement = ({label, type, defaultVal, placeholder, handleChange}) => {
    return (
        <Row key={label}>
            <Col md="4" className="desktop tablet_large tablet_small">
                <p>{label}</p>
            </Col>
            <Col>
                <Form.Control
                    onChange={handleChange}
                    type={type}
                    required
                    defaultValue={defaultVal}
                    placeholder={placeholder}
                />
            </Col>
        </Row>
    )
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    form: state.authReducer.form,
    fieldsValue: state.authReducer.fieldsValue,
    language: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
    authData: state.authReducer.authData,
});

const mapDispatchToProps = (dispatch) => {
    return {
        registerUser: (fieldsValue, auth) => {
            dispatch(registerUser(fieldsValue));
        },
        setFieldsValue: (fieldsValue) => {
            delete fieldsValue.id;
            delete fieldsValue.password;
            delete fieldsValue.confirmPassword;
            delete fieldsValue.validated;

            dispatch(setFieldsValue(fieldsValue));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
