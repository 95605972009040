import React, {useState, useEffect} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button,
    Col, Row,
    Label, Input,
    Card, CardBody,
    Nav, NavItem, NavLink,
    TabContent, TabPane,
    Modal, ModalHeader, ModalBody, ModalFooter,
    ListGroup, ListGroupItem, 
    Form, FormGroup, CardHeader
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { addUndesirableEffects, editPatientLeafletUndesirableEffect, getUndesirableEffectsByIds, searchBodySystem, searchUndesirableEffect} from '../../../../redux/actions/adminActions'
import { setErrorMessage } from '../../../../redux/actions/simpleActions';

const PLUndesirableEffect = props => {
    const {lang, dictionary, patientLeaflet} = props;
    // переключатель языков для полей
    const [languageTab, setTab] = useState('az');
    // открыть или закрыть модалку создания
    const [modal, setModal] = useState(false);
    // открыть модалку редактирования
    const [editModal, setEditModal] = useState(false);
    // эта хрень нужна во время редактирования, чтобы мы знали какой ингредиент именно мы пытаемся редактировать
    const [activeIndex, setActiveIndex] = useState();
    // показать и скрывать выпадашку
    const [suggest, setSuggest] = useState(false);
    // строка для поиска body systema
    const [searchString, setSearchString] = useState(null);
    // строка для поиска эффекта
    const [effectSearchString, setEffectSearchString] = useState(null);
    // здесь будут храниться поля нового ингредиента перед сохранением
    const [tmpUndesirableEffect, setTmpUndesirableEffect] = useState({
        id: null, Caption: '', az: '', ru: '',
        bodySystem: {az: '', ru: '', id: null, Caption: ''},
        oldBodySystem: {az: '', ru: '', id: null},
        oldEffect: {az: '', ru: '', id: null},
        frequency: ''
    });
    const [generalText, setGeneralText] = useState(patientLeaflet.possibleSideEffectsGeneralText ? {...patientLeaflet.possibleSideEffectsGeneralText} : {az: '', ru: ''});
    
    let effectsIds = [];

    // mapStateToProps
    const {undesirableEffectsById, bodySystemSearchResult, undesirableEffectSearchResult, errorMessage} = useSelector(state => ({
        undesirableEffectsById: state.adminReducer.undesirableEffectsById,
        bodySystemSearchResult: state.adminReducer.bodySystemSearchResult,
        undesirableEffectSearchResult: state.adminReducer.undesirableEffectSearchResult,
        errorMessage: state.errorReducer
    }));

    const dispatch = useDispatch();

    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }

    // кнопка создания нового ингредиента
    // открытие модального окна
    const addUndesirableEffectsButtonHandler = () => {
        setTmpUndesirableEffect({
            az: '', ru: '', id: '', Caption: '',
            bodySystem: {az: '', ru: '', id: null},
            frequency: null}); // вычищаем хранилище
        setSearchString(null); // вычищаем поисковую строку
        setModal(true);
    }

    // кнопка редактирования существующего ингредиента
    // открытие модального окна
    const editEffectButtonHandler = (index, bdId) => {
        setTmpUndesirableEffect({
            az: undesirableEffectsById[bdId].undesirableEffects[index].text ?
                undesirableEffectsById[bdId].undesirableEffects[index].text.az : '',
            ru: undesirableEffectsById[bdId].undesirableEffects[index].text ?
                undesirableEffectsById[bdId].undesirableEffects[index].text.ru : '',
            bodySystem: undesirableEffectsById[bdId].text ? {
                az: undesirableEffectsById[bdId].text.az,
                ru: undesirableEffectsById[bdId].text.ru,
            } : {az: '', ru: '', id: null},
            oldBodySystem: undesirableEffectsById[bdId].text ? {
                az: undesirableEffectsById[bdId].text.az,
                ru: undesirableEffectsById[bdId].text.ru,
                id: undesirableEffectsById[bdId].undesirableEffects[index].body_system.id
            } : {az: '', ru: '', id: null},
            oldEffect: {
                az: undesirableEffectsById[bdId].undesirableEffects[index].text ?
                    undesirableEffectsById[bdId].undesirableEffects[index].text.az : '',
                ru: undesirableEffectsById[bdId].undesirableEffects[index].text ?
                    undesirableEffectsById[bdId].undesirableEffects[index].text.ru : '',
                id: undesirableEffectsById[bdId].undesirableEffects[index].id
            },
            frequency: undesirableEffectsById[bdId].undesirableEffects[index].frequency
        }); // присваиваем к хранилищу текущий ингредиент
        setSearchString(null); // вычищаем поисковую строку
        setEffectSearchString(null);
        setEditModal(true);
        const firstIndex = patientLeaflet.possibleSideEffects.findIndex(effect => 
            effect.undesirable_effect.id === undesirableEffectsById[bdId].undesirableEffects[index].id);
        setActiveIndex(firstIndex);
    }

    // выбрать body system из выпадашки
    // возможно эта хрень отредактируется
    const selectBodySystem = bs => {
        setTmpUndesirableEffect({...tmpUndesirableEffect,
            bodySystem: {
                az: bs.text.az,
                ru: bs.text.ru,
                id: bs.id
            }
        });
        setSuggest(false);
    }

    // выбрать эффект из выпадашки
    const selectEffect = ue => {
        setTmpUndesirableEffect({...tmpUndesirableEffect,
            az: ue.text.az,
            ru: ue.text.ru,
            id: ue.id
        });
        setSuggest(false);
    }

    // создание массива ингредиентных id
    if (patientLeaflet && patientLeaflet.possibleSideEffects) {
        patientLeaflet.possibleSideEffects.map(pse => pse.undesirable_effect ? effectsIds.push({
            bodySystem: pse.undesirable_effect.body_system,
            effect: pse.undesirable_effect.id
        }) : '');
    }

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchBodySystem(searchString));
            setSuggest(true);
        } else setSuggest(false);
        
    }, [searchString]);

    useEffect(() => {
        if(effectSearchString && effectSearchString.length > 2) {
            dispatch(searchUndesirableEffect(effectSearchString));
            setSuggest(true);
        } else setSuggest(false)
    }, [effectSearchString]);

    useEffect(() => {
        if (patientLeaflet) {
            dispatch(getUndesirableEffectsByIds(effectsIds, patientLeaflet.possibleSideEffects));
        }
    }, [patientLeaflet])

    // добавление нового ингредиента
    const addNewUndesirableEffects = () => {
        let newEffects = [];
        const ueAz = tmpUndesirableEffect.az.split(',');
        const ueRu = tmpUndesirableEffect.ru.split(',');
        ueAz.map((uAz, i) => {
            newEffects.push({
                az: uAz.trim(), ru: ueRu[i] ? ueRu[i].trim() : uAz.trim(),
                frequency: tmpUndesirableEffect.frequency
            });
        });
        dispatch(addUndesirableEffects(newEffects, tmpUndesirableEffect.bodySystem, patientLeaflet));
        setModal(false);
        //props.toggleSave(true);
    }
    
    //сохранение изменений текущего эффекта
    const editIngredient = () => {
        if (tmpUndesirableEffect.az === '') dispatch(setErrorMessage(dictionary.error.emptyField[lang]));
        else {
            dispatch(editPatientLeafletUndesirableEffect(tmpUndesirableEffect, patientLeaflet, activeIndex));
            dispatch(setErrorMessage(''));
            setEditModal(false);
            setActiveIndex(null);
        }
    }

    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({
            possibleSideEffects: patientLeaflet.possibleSideEffects.filter((se, i) => {
                return se.undesirable_effect.id !== index
            })
        });
        props.toggleSave(true);
    }

    // сохранение общих заметок
    const saveGeneralTextHandler = () => {
        props.changeLeaflet({possibleSideEffectsGeneralText: {...generalText, ru: generalText.ru || generalText.az}});
        props.toggleSave(true);
    }

    return (
        <div>
            <Form style={{width: '100%'}}>
                <Row>
                    <Col xs='10' className='pt-0 pl-5 pb-5'>
                        <Row>
                            <Col xs='10'>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.generalText[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setGeneralText({...generalText, az: data});
                                            }}
                                            data={generalText && generalText.az ? generalText.az : ''} />
                                    </TabPane>
                                    <TabPane tabId='ru'>
                                        <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.generalText[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setGeneralText({...generalText, ru: data});
                                            }}
                                            data={generalText && generalText.ru ? generalText.ru : ''} />
                                    </TabPane>
                                </TabContent>
                                <Button className='mt-2' onClick={addUndesirableEffectsButtonHandler} color="primary" size="lg" block><FontAwesomeIcon icon={faPlus} /> {dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.addNewButton[lang]}</Button>
                                {undesirableEffectsById ? Object.keys(undesirableEffectsById).map(key => BodySystemElement({
                                    lang, languageTab, dictionary, bodySystem: undesirableEffectsById[key], bkey: key,
                                    editEffectButtonHandler, deleteButtonHandler
                                })) : ''}
                            </Col>
                            <Col xs='2'>
                                <Nav tabs vertical pills className='lang-changer'>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'az' })}
                                            onClick={() => { toggleLang('az'); }}>
                                            Az
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: languageTab === 'ru' })}
                                            onClick={() => { toggleLang('ru'); }}>
                                            Ru
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                        <Button onClick={saveGeneralTextHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
                    </Col>
                </Row>
            </Form>
            
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.newIngredient[lang]}</ModalHeader>
                <ModalBody>{AddEffectsElement({
                    lang, dictionary, undesirableEffectsById, 
                    languageTab, bodySystemSearchResult, selectBodySystem,
                    searchString, setSearchString,
                    tmpUndesirableEffect, setTmpUndesirableEffect, suggest, setSuggest, errorMessage})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewUndesirableEffects}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpUndesirableEffect({}); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={editModal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.editIngredient[lang]}</ModalHeader>
                <ModalBody>{EditCurrentEffectElement({
                    lang, dictionary, undesirableEffectsById, 
                    languageTab, bodySystemSearchResult, undesirableEffectSearchResult, selectBodySystem, selectEffect,
                    searchString, setSearchString, effectSearchString, setEffectSearchString,
                    tmpUndesirableEffect, setTmpUndesirableEffect, suggest, setSuggest, errorMessage
                })}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={editIngredient}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.editIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpUndesirableEffect({}); setEditModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const BodySystemElement = props => {
    const {lang, languageTab, dictionary, editEffectButtonHandler, deleteButtonHandler, bodySystem, bkey} = props;
    return (
        <Row className='mt-2' key={bkey}>
            <Col xs='12'>
                <Card>
                    <CardHeader><strong> {bkey !== 'empty' ? bodySystem.text[lang] : dictionary.undefinedField[lang]}: </strong></CardHeader>
                    <CardBody>
                        {bodySystem.undesirableEffects.map((effect, index) => PossibleSideEffectElement({
                            lang, languageTab, dictionary, index, key: effect.id, bkey, possibleSideEffect: effect, editEffectButtonHandler, deleteButtonHandler
                        }))}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

const PossibleSideEffectElement = props => {
    const {lang, dictionary, index, key, bkey, possibleSideEffect, editEffectButtonHandler, deleteButtonHandler} = props;
    const frequency = dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequency.find(fr => fr.id === possibleSideEffect.frequency);
    return (
        <Row className='mt-2' key={key}>
            <Col xs='6'>
                {possibleSideEffect.text[lang]}
            </Col>
            <Col xs='4'>
                {frequency ? frequency[lang] : dictionary.undefinedField[lang]}
            </Col>
            <Col xs='2'>
                <FontAwesomeIcon onClick={editEffectButtonHandler.bind(this, index, bkey)} className='pointer' icon={faEdit} />
                <FontAwesomeIcon onClick={deleteButtonHandler.bind(this, key)} className='pointer ml-2' icon={faTrash} />
            </Col>
        </Row>
    )
}

const AddEffectsElement = props => {
    const {
        lang, languageTab, dictionary,
        searchString, setSearchString,
        tmpUndesirableEffect, setTmpUndesirableEffect,
        suggest, bodySystemSearchResult, selectBodySystem, errorMessage
    } = props;
    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.bodySystem[lang]}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchBodySystem`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            searchString !== null ? searchString :
                                            tmpUndesirableEffect && tmpUndesirableEffect.bodySystem ? 
                                            tmpUndesirableEffect.bodySystem.az : ''
                                        }
                                        onChange={(e) => {
                                            setTmpUndesirableEffect({...tmpUndesirableEffect, 
                                                bodySystem: {...tmpUndesirableEffect.bodySystem, az: e.target.value}});
                                            setSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && bodySystemSearchResult ? bodySystemSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectBodySystem(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label className='mt-2'>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.enterUndesirableEffects[lang]}</Label>
                                    <Input
                                        type='textarea'
                                        name='undesirableEffectsAz'
                                        id={`uEffectsAz`}
                                        placeholder={dictionary.search[lang]}
                                        onChange={(e) => {
                                            setTmpUndesirableEffect({...tmpUndesirableEffect, az: e.target.value});
                                        }} />
                                    <Label style={{color: 'red', fontSize: '10pt'}}>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.attentionForEnter[lang]}</Label>
                                    <Label style={{color: 'red', fontSize: '10pt'}}>{errorMessage}</Label>
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                    <Input
                                        type='text'
                                        name='bodySystem'
                                        id={`bodySystemRu`}
                                        placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                        value={tmpUndesirableEffect && tmpUndesirableEffect.bodySystem ? 
                                            tmpUndesirableEffect.bodySystem.ru : ''}
                                        onChange={e => {
                                            setTmpUndesirableEffect({...tmpUndesirableEffect, 
                                                bodySystem: {...tmpUndesirableEffect.bodySystem, ru: e.target.value}});
                                        }}
                                    />
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                    <Input
                                        type='textarea'
                                        id={`uEffectsRu`}
                                        placeholder=''
                                        value={tmpUndesirableEffect ? tmpUndesirableEffect.ru : ''}
                                        onChange={(e) => {setTmpUndesirableEffect({...tmpUndesirableEffect, ru: e.target.value})}} />   
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col xs={3}>
                            <Label for='quantity'>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequencyText[lang]}</Label>
                        </Col>
                        <Col xs={9}>
                            <Input
                                type='select'
                                name='unit'
                                id={`frequency`}
                                onChange={e => {
                                    setTmpUndesirableEffect({...tmpUndesirableEffect, frequency: e.target.value});
                                }}>
                                <option> {dictionary.optionChoose[lang]} </option>
                                {dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequency.map(opt => 
                                    <option value={opt.id} key={opt.id}>{opt[lang]}</option>
                                )}
                            </Input>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

const EditCurrentEffectElement = props => {
    const {
        lang, languageTab, dictionary,
        searchString, setSearchString, effectSearchString,
        setEffectSearchString, tmpUndesirableEffect, setTmpUndesirableEffect,
        suggest, bodySystemSearchResult, undesirableEffectSearchResult, selectBodySystem, selectEffect, errorMessage
    } = props;
    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.bodySystem[lang]}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchBodySystem`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            searchString !== null ? searchString :
                                            tmpUndesirableEffect && tmpUndesirableEffect.bodySystem ? 
                                            tmpUndesirableEffect.bodySystem.az : ''
                                        }
                                        onChange={e => {
                                            setTmpUndesirableEffect({...tmpUndesirableEffect, 
                                                bodySystem: {...tmpUndesirableEffect.bodySystem, az: e.target.value}});
                                            setSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && bodySystemSearchResult && searchString ? bodySystemSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectBodySystem(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label className='mt-2'>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.enterUndesirableEffects[lang]}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchUEffect`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            effectSearchString !== null ? effectSearchString :
                                            tmpUndesirableEffect ? tmpUndesirableEffect.az : ''
                                        }
                                        onChange={(e) => {
                                            setTmpUndesirableEffect({...tmpUndesirableEffect, az: e.target.value});
                                            setEffectSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && undesirableEffectSearchResult && effectSearchString ? undesirableEffectSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectEffect(result);
                                                setEffectSearchString(null);
                                            }} tag="a" action>{`${result.body_system ? result.body_system.Caption + ': ' : ''}${result.Caption}`}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label style={{color: 'red', fontSize: '10pt'}}> {errorMessage} </Label>
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                    <Input
                                        type='text'
                                        name='bodySystem'
                                        id={`bodySystemRu`}
                                        placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                        value={tmpUndesirableEffect && tmpUndesirableEffect.bodySystem ? 
                                            tmpUndesirableEffect.bodySystem.ru : ''}
                                        onChange={e => {
                                            setTmpUndesirableEffect({...tmpUndesirableEffect, 
                                                bodySystem: {...tmpUndesirableEffect.bodySystem, ru: e.target.value}});
                                        }}
                                    />
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                    <Input
                                        type='text'
                                        id={`uEffectsRu`}
                                        placeholder=''
                                        value={tmpUndesirableEffect ? tmpUndesirableEffect.ru : ''}
                                        onChange={(e) => {setTmpUndesirableEffect({...tmpUndesirableEffect, ru: e.target.value})}} />   
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col xs={3}>
                            <Label for='quantity'>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequencyText[lang]}</Label>
                        </Col>
                        <Col xs={9}>
                            <Input
                                type='select'
                                name='unit'
                                id={`frequency`}
                                onChange={e => {
                                    setTmpUndesirableEffect({...tmpUndesirableEffect, frequency: e.target.value});
                                }}>
                                <option>
                                    {tmpUndesirableEffect.frequency ? 
                                    dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequency.find(opt => opt.id === tmpUndesirableEffect.frequency)[lang]
                                : dictionary.optionChoose[lang]}
                                </option>
                                {dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequency.map(opt => 
                                    <option value={opt.id} key={opt.id}>{opt[lang]}</option>
                                )}
                            </Input>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default PLUndesirableEffect;