import React from "react";
import { CardHeader, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import "./Pregnancy.scss";

import config from "../../../config";
import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import moment from "moment";
import { withTranslation } from "react-i18next";
import DrugsCategories from "../Elements/DrugsCategories";

class Pregnancy extends React.Component {
    constructor(props) {
        super(props);
        this.catchSearchLetter = this.catchSearchLetter.bind(this);
        this.state = {
            number_of_columns: window.innerWidth > 768 ? 3 : 2,
            news: [],
        };
    }

    componentDidMount = () => {
        fetch(`${config.url}rsses`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ news: data });
            });
    };

    catchSearchLetter(searchLetter) {
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    render() {
        const { t, dictionary, lang } = this.props;

        return (
            <div className="pregnancy_page_container">
                <div className="pregnancy_page">
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pregnancy.pregnancyTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.pregnancy.pregnancyBody[lang]}{" "}
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pregnancy.searchTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description search_card">
                        <SearchField searchTypes="pregnancy"></SearchField>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pregnancy.alphabetTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        <div className="alphabet-block-pregnancy alphabet_block">
                            {alphabeticalLoop(
                                lang,
                                letterJsx,
                                this.catchSearchLetter,
                                undefined,
                                this.props.lettersCount
                            )}
                            <button
                                onClick={() =>
                                    this.props.history.push(
                                        "/drugs/advanced-search"
                                    )
                                }
                                className="alphabet_button advanced-search-alphabet-button live-button"
                                value={dictionary.advancedSearch[lang]}
                            >
                                {dictionary.advancedSearch[lang]}
                            </button>
                        </div>
                    </div>
                    <div className="bg-white border-0">
                        <h1 className="mb-0 drugs-header">
                            {dictionary.pregnancy.infoTitle[lang]}
                        </h1>
                    </div>
                    <div className="drugs-description">
                        {dictionary.pregnancy.infoBody[lang]}
                    </div>
                    <DrugsCategories />
                    <div>
                        <div className="main_content">
                            <div className="bg-white border-0 display-block">
                                <h1 className="mb-0 drugs-header">
                                    {
                                        dictionary.pregnancy
                                            .popularRequestsTitle[lang]
                                    }
                                </h1>
                            </div>
                            <div className="pregnancy_body">
                                <PopularRequests
                                    type="undesirable"
                                    result="sideeffect"
                                />
                            </div>
                        </div>
                        <div className="pregnancy_news">
                            {this.state.news.map((el) => (
                                <div>
                                    <Link
                                        to={`/drugs/one_news/${el.id}`}
                                        className="news-title"
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: el.title[lang],
                                            }}
                                        ></div>
                                    </Link>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: el.miniText[lang],
                                        }}
                                    ></div>
                                    <p className="news-date">
                                        {el.Datetime && moment(el.Datetime)
                                            ? moment(el.Datetime).format(
                                                  "DD.MM.YYYY"
                                              )
                                            : t("Empty date")}{" "}
                                        <Link to={"/"}>Medical</Link>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="pregnancy_page__sda">
                    <AdsBlock></AdsBlock>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
});

export default withTranslation()(connect(mapStateToProps)(Pregnancy));
