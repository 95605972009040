import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';

//import createEditDeletePLActions from '../../../redux/actions/adminActions';

import {
    Container,
    Col,
    Row,
    Breadcrumb,
    BreadcrumbItem,
    Spinner,
    Nav, NavLink, NavItem
} from 'reactstrap';
import { editMedicalProduct, _createProduct } from '../../../redux/actions/adminMedicalProductActions';
import MPClinicalParticulars from './MPEditComponents/MPClinicalParticulars';
import MPGeneralInfo from './MPEditComponents/MPGeneralInfo';
import MPIngredients from './MPEditComponents/MPIngredients';
import MPPharmaceutical from './MPEditComponents/MPPharmaceutical';
import MPPharmaceuticalParticulars from './MPEditComponents/MPPharmaceuticalParticulars';
import MPPharmacological from './MPEditComponents/MPPharmacological';

const MedicalProductEdit = props => {
    const {id} = props.match.params;
    
    const [tab, selectTab] = useState('generalInfo');
    const [save, toggleSave] = useState(false);

    // эта хрень нужна, чтобы высылать только изменённые части, а то страпи не сохраняет большие объекты
    const [product, changeProduct] = useState({});

    const dispatch = useDispatch();
    
    const {medicalProduct, lang, dictionary, newProduct, authorized} = useSelector(state => ({
        medicalProduct: state.medicalProductsReducer.medicalProduct,
        newProduct: state.medicalProductsReducer.newProduct,
        lang: state.languageReducer.language,
        dictionary: state.dictionaryReducer,
        authorized: state.userReducer.authorized
    }));

    if (newProduct !== null) dispatch(_createProduct(null));

    useEffect(() => {
        if (medicalProduct === null || (medicalProduct.id && medicalProduct.id.toString() !== id)) {
            dispatch(editMedicalProduct(id, {}))
        };
    }, [product]);

    useEffect(() => {
        if (save === true) {
            dispatch(editMedicalProduct(id, product));    
            toggleSave(false);
        }
    }, [save]);

    const mpProps = {
        lang, dictionary,
        medicalProduct,
        toggleSave, changeProduct
    }

    if (!authorized || authorized === 'false') return (<Redirect to={`/login/`} />)
    else if (medicalProduct && medicalProduct.id && medicalProduct.id.toString() === id) return (
        <Container>
            <Row>
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/admin'}>{dictionary.breadcrumbs.home[lang]}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/admin/medical-products'}>{dictionary.adminDrugs.medicalProducts[lang]}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>
                        {medicalProduct.name ? medicalProduct.name[0][lang]
                            : medicalProduct.internationalNonPatentedName ? medicalProduct.internationalNonPatentedName
                            : medicalProduct.id
                        }
                    </BreadcrumbItem>
                </Breadcrumb>
            </Row>
            <Row>
                <Col xs='3'>
                    <Row>
                        <Nav className={'admin-tabs-nav'} vertical>
                            <NavItem className={tab === 'generalInfo' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('generalInfo')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.names[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'ingredients' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('ingredients')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.ingredients[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'pharmaceuticalForm' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('pharmaceuticalForm')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.pharmaceuticalForm[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'clinicalParticulars' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('clinicalParticulars')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.clinicalParticulars[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'pharmacologicalProperties' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('pharmacologicalProperties')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.pharmacologicalProperties[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'pharmaceuticalParticulars' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('pharmaceuticalParticulars')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.pharmaceuticalParticulars[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'marketingAuthorisation' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('marketingAuthorisation')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.marketingAuthorisation[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'marketingAuthorisationNumbers' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('marketingAuthorisationNumbers')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.marketingAuthorisationNumbers[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'authorisationDate' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('authorisationDate')}>
                                    {dictionary.adminDrugs.medicalProductEditPage.authorisationDate[lang]}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                </Col>
                <Col xs='9'>
                    {tab === 'generalInfo' ? 
                        <MPGeneralInfo {...mpProps} />
                    : tab === 'ingredients' ? 
                        <MPIngredients {...mpProps} />
                    : tab === 'pharmaceuticalForm' ?
                        <MPPharmaceutical {...mpProps} />
                    : tab === 'clinicalParticulars' ?
                        <MPClinicalParticulars {...mpProps} />
                    : tab === 'pharmacologicalProperties' ?
                        <MPPharmacological {...mpProps} />
                    : tab === 'pharmaceuticalParticulars' ?
                        <MPPharmaceuticalParticulars {...mpProps} />
                    : <Col></Col>}
                </Col>
            </Row>
        </Container>
    ); else return (<div><Col sm="12" md={{ size: 6, offset: 3 }}><Spinner color='primary' /></Col></div>)
}

export default MedicalProductEdit;