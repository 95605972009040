import React from "react";
import { Redirect, Route, Switch, Link, Router } from "react-router-dom";
import { Container, Row, Col, NavLink } from "reactstrap";

import DrugsFooter from "../components/Drugs/Footers/DrugsFooter.jsx";
import routes from "../routes";
import DrugsHeader from "../components/Drugs/DrugsHeader/DrugsHeader";
import AdminHeader from "../components/Admin/AdminHeader/AdminHeader";
import AdminNavbar from "../components/Admin/Navbar/AdminNavbar.jsx";

import { connect } from "react-redux";

import { getLettersCount } from "../redux/actions/actions";
import { restoreLogin } from "../redux/actions/vd-auth";

import "./style.scss";
import "../components/Admin/adminStyle.scss";
import "./ckeditor.scss";
import "./../components/Drugs/common_styles.scss";
import Alerts from "./Alerts.jsx";
import "./../i18n";
import history from "./../history";
import ScrollToTop from "./ScrollToTop";

class App extends React.Component {
    getRoutes = (routes) => {
        const dictionary = this.props.dictionary;
        const lang = this.props.lang;

        return routes.map((route, key) => (
            <Route
                exact
                path={route.layout + route.path}
                render={(props) => (
                    <div>
                        {route.layout === "/admin" ||
                        route.layout === "/login" ? (
                            <>
                                <Alerts />
                                <AdminNavbar brandText={"Admin home"} />
                                <AdminHeader {...props} />
                                <route.component {...props} />
                            </>
                        ) : (
                            <>
                                <DrugsHeader {...props} />
                                <Container className="mt--7 content_container">
                                    <Row>
                                        <Col>
                                            <div className="bg-secondary">
                                                <route.component {...props} />

                                                <div className="mobileAppBlock mobile">
                                                    <h1>
                                                        {
                                                            dictionary
                                                                .drugsMainComponent
                                                                .mobileAppTitle[
                                                                lang
                                                            ]
                                                        }
                                                    </h1>
                                                    <p className="mob-desc-p">
                                                        {
                                                            dictionary
                                                                .drugsMainComponent
                                                                .basicInformationDescription[
                                                                lang
                                                            ]
                                                        }
                                                    </p>
                                                    <div className="mobile_app_btn_block">
                                                        <NavLink
                                                            className="reg_button_register"
                                                            to="/drugs/mobile-app/"
                                                            tag={Link}
                                                        >
                                                            {
                                                                dictionary
                                                                    .drugsMainComponent
                                                                    .mobileAppButtonTitle[
                                                                    lang
                                                                ]
                                                            }
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <NewsMain />
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col>
                                            <div className="mobileAppBlock desktop-flex tablet_large tablet_small">
                                                <div className="mobileAppImg">
                                                    <img
                                                        alt="phones"
                                                        width={386.794213719}
                                                        height={300}
                                                        src={require("./img/mobile-app.png")}
                                                    />
                                                </div>
                                                <div className="mobile-app-desc">
                                                    <h1>
                                                        {
                                                            dictionary
                                                                .drugsMainComponent
                                                                .mobileAppTitle[
                                                                lang
                                                            ]
                                                        }
                                                    </h1>
                                                    <p className="mob-desc-p">
                                                        {
                                                            dictionary
                                                                .drugsMainComponent
                                                                .basicInformationDescription[
                                                                lang
                                                            ]
                                                        }
                                                    </p>
                                                    <div className="mobile_app_btn_block">
                                                        <NavLink
                                                            className="btn reg_button_register"
                                                            to="/drugs/mobile-app/"
                                                            tag={Link}
                                                        >
                                                            {
                                                                dictionary
                                                                    .drugsMainComponent
                                                                    .mobileAppButtonTitle[
                                                                    lang
                                                                ]
                                                            }
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )}
                    </div>
                )}
                key={route.layout + route.path}
            />
        ));
    };

    componentDidMount = () => {
        this.props.getLettersCount();
        if (JSON.parse(localStorage.getItem("az.ezgil.dermanlarim.token"))) {
            this.props.restoreLogin(
                JSON.parse(localStorage.getItem("az.ezgil.dermanlarim.token"))
            );
        }
    };

    render() {
        // console.log(`VERION: 0.0.8`);

        return (
            <>
                <Router history={history}>
                    <ScrollToTop />
                    <>
                        <div className="main-content" ref="mainContent">
                            <Switch>
                                {this.getRoutes(routes)}
                                <Redirect from="*" to="/" />
                            </Switch>
                        </div>

                        <DrugsFooter />
                    </>
                </Router>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getLettersCount: () => {
            dispatch(getLettersCount());
        },
        restoreLogin: (token) => {
            dispatch(restoreLogin(token));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
