import React, {useState} from 'react';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane,
    ListGroup, ListGroupItem
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import { useEffect } from 'react';
import { searchPLMedicalProducts, getPLMedicalProduct, updateMedicalProduct } from '../../../../redux/actions/adminActions';


const PLMedicalProduct = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [languageTab, setTab] = useState('az');
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    const [tmpMedicalProduct, setTmpMedicalProduct] = useState(patientLeaflet.medical_product ? {
        id: null,
        az: patientLeaflet.medical_product.name[0].az, ru: patientLeaflet.medical_product.name[0].ru} : {id: null, az: '', ru: ''});
    const [suggest, setSuggest] = useState(false);

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    };

    // mapStateToProps
    const {medicalProductPLSearchResult} = useSelector(state => ({
        medicalProductPLSearchResult: state.adminReducer.medicalProductPLSearchResult
    }));

    const dispatch = useDispatch();

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchPLMedicalProducts(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    const submitButtonHandler = () => {
        if (tmpMedicalProduct.az) dispatch(updateMedicalProduct(tmpMedicalProduct, patientLeaflet));
    };
    
    const selectMedicalProduct = mp => {
        setTmpMedicalProduct({id: mp.id, az: mp.name[0].az, ru: mp.name[0].ru});
    }

    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                                <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.medicalProduct.title[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <Input
                                    type='search'
                                    autoComplete='off'
                                    id={`medicalProductAz`}
                                    placeholder={dictionary.search[lang]}
                                    value={
                                        searchString !== null ? searchString :
                                        tmpMedicalProduct ? tmpMedicalProduct.az : ''
                                    }
                                    onChange={e => {
                                        setTmpMedicalProduct({...tmpMedicalProduct, az: e.target.value});
                                        setSearchString(e.target.value);
                                    }} />
                                <ListGroup>
                                    {suggest && medicalProductPLSearchResult ? medicalProductPLSearchResult.map(result => (
                                        <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                            selectMedicalProduct(result);
                                            setSearchString(null);
                                        }} tag="a" action>{`${result.id}: ${result.Caption}`}</ListGroupItem>
                                    )) : ''}
                                </ListGroup>
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.medicalProduct.title[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <Input
                                    type='text'
                                    id={`medicalProductRu`}
                                    placeholder={''}
                                    value={tmpMedicalProduct ? tmpMedicalProduct.ru : ''}
                                    onChange={e => {setTmpMedicalProduct({...tmpMedicalProduct, ru: e.target.value});}} />
                            </TabPane>
                        </TabContent>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}


export default PLMedicalProduct;