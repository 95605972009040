import React from "react";
import { CardHeader, Row, Col, CardBody } from "reactstrap";
import classnames from "classnames";

import { connect } from "react-redux";

import "./ConditionsAndDiseases.scss";

import { Link } from "react-router-dom";

import SearchField from "../SearchField/SearchField";

import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import AdsBlock from "../AdsBlock/AdsBlock";
import config from "../../../config";

class ConditionsAndDiseases extends React.Component {
    constructor(props) {
        super(props);
        this.catchSearchLetter = this.catchSearchLetter.bind(this);

        this.state = {
            number_of_columns: window.innerWidth > 768 ? 4 : 2,
            popularRequests: [],
        };

        window.addEventListener("resize", () => {
            this.setState({
                number_of_columns: window.innerWidth > 768 ? 4 : 2,
            });
        });
    }

    fetchPopularRequests = async () => {
        let request = await fetch(
            `${config.url}popular-search-results?_sort=requestNumber:desc&type=sicks&_limit=40`
        );
        let data = await request.json();
        // console.log(data);
        this.setState({
            popularRequests: data,
        });
    };

    componentDidMount = () => {
        this.fetchPopularRequests();
    };

    catchSearchLetter(searchLetter) {
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    getPopularRequests = (requests) => {
        let number_of_columns = this.state.number_of_columns;
        let number_of_rows = Math.ceil(requests.length / number_of_columns);
        let rows = [];
        for (let i = 0; i < number_of_rows; i++) {
            let columns = [];
            for (let f = 0; f < number_of_columns; f++) {
                let current_request = requests[i * number_of_columns + f];
                if (current_request) {
                    columns.push(
                        <Col key={f}>
                            <Link
                                to={`/drugs/search-results/${current_request.request}/types=disease`}
                            >
                                {current_request.request}
                            </Link>
                        </Col>
                    );
                } else {
                    break;
                }
            }
            rows.push(<Row key={i}>{columns}</Row>);
        }
        return rows;
    };

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <div className="conditions_and_diseases_page">
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 conditions_and_diseases_title">
                        {dictionary.conditionsDiseases.indexTitle[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="conditions_and_diseases_body">
                    {dictionary.conditionsDiseases.indexBody[lang]}
                    <SearchField
                        placeholder={
                            dictionary.conditionsDiseases.searchPlacegolder[
                                lang
                            ]
                        }
                        searchTypes="disease"
                    ></SearchField>
                </CardBody>
                <div className="main_content_sda_wrapper">
                    <div className="main_content">
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 conditions_and_diseases_title">
                                {dictionary.conditionsDiseases.alphabet[lang]}
                            </h1>
                        </CardHeader>
                        <CardBody className="conditions_and_diseases_body alphabet_card">
                            <div className="alphabet_block">
                                {alphabeticalLoop(
                                    lang,
                                    letterJsx,
                                    this.catchSearchLetter,
                                    undefined,
                                    this.props.lettersCount
                                )}
                                <button
                                    onClick={() =>
                                        this.props.history.push(
                                            "/drugs/advanced-search"
                                        )
                                    }
                                    className="alphabet_button advanced-search-alphabet-button live-button"
                                >
                                    {dictionary.advancedSearch[lang]}
                                </button>
                            </div>
                        </CardBody>
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 conditions_and_diseases_title">
                                {
                                    dictionary.conditionsDiseases
                                        .popularRequests[lang]
                                }
                            </h1>
                        </CardHeader>
                        <CardBody className="conditions_and_diseases_body">
                            {this.getPopularRequests(
                                this.state.popularRequests
                            )}
                        </CardBody>
                    </div>
                    <AdsBlock></AdsBlock>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
});

export default connect(mapStateToProps)(ConditionsAndDiseases);
