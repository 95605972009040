import {
    SEARCH_RESULT_ARRAY,
    GET_HEADER_DROPDOWN,
    GET_DRUG_DATA,
    GET_BODY_SYSTEMS_DATA,
    GET_ABOUT_US_DATA,
    SET_FOR_PROFESSIONALS,
    GET_NEWS_DATA,
    GET_FULL_MEDICAL_PRODUCT,
    GET_RENDERED_MEDICAL_PRODUCT,
    GET_RENDERED_PATIENT_LEAFLET,
    GET_PRIVACY_POLICY,
    GET_TERMS_OF_USE,
} from "../actions/action-types";

const initialState = {
    searchResultArray: [],
    searchPage: undefined,
    searchTotal: undefined,
    headerDropdown: [
        "allResults",
        "forConsumers",
        "forProfessionals",
        "sideEffects",
        "diseases",
        "drugs",
        "alerts",
        "trial",
        "news",
        "companies",
        "pregnancy",
    ],
    drugData: null,
    bodySystemsData: null,
    advancedSearchFilters1: ["allWords", "someWords", "entire"],
    advancedSearchFilters2: ["resultPerPage10", "resultPerPage20"],
    aboutUsData: null,
    forProfessionals:
        localStorage.getItem("az.ezgil.dermanlarim.professionals") === "true" ||
        null,
    newsData: null,
    fullMedicalProduct: null,
    renderedMedicalProduct: null,
    renderedPatientLeaflet: null,
    privacyPolicy: undefined,
    termsOfUse: undefined,
};

const drugReducer = (state = initialState, action) => {
    const { payload } = action;

    switch (action.type) {
        case SEARCH_RESULT_ARRAY:
            if (action.searchTotal === undefined) {
                return {
                    ...state,
                    searchResultArray: action.searchResultArray,
                    searchPage: action.searchPage,
                };
            }
            return {
                ...state,
                searchResultArray: action.searchResultArray,
                searchPage: action.searchPage,
                searchTotal: action.searchTotal,
            };
        case GET_HEADER_DROPDOWN:
            return { ...state, headerDropdown: payload };
        case GET_DRUG_DATA:
            return { ...state, drugData: payload };
        case GET_BODY_SYSTEMS_DATA:
            return { ...state, bodySystemsData: payload };
        case GET_ABOUT_US_DATA:
            return { ...state, aboutUsData: payload };
        case SET_FOR_PROFESSIONALS:
            localStorage.setItem("az.ezgil.dermanlarim.professionals", payload);
            return { ...state, forProfessionals: payload };
        case GET_NEWS_DATA:
            return { ...state, newsData: payload };
        case GET_FULL_MEDICAL_PRODUCT:
            return { ...state, fullMedicalProduct: payload };
        case GET_RENDERED_MEDICAL_PRODUCT:
            return { ...state, renderedMedicalProduct: payload };
        case GET_RENDERED_PATIENT_LEAFLET:
            return { ...state, renderedPatientLeaflet: payload };
        case GET_PRIVACY_POLICY:
            return { ...state, privacyPolicy: payload };
        case GET_TERMS_OF_USE:
            return { ...state, termsOfUse: payload };
        default:
            return state;
    }
};

export default drugReducer;
