import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Alert} from 'reactstrap';
import { closeAlert } from '../redux/actions/actions';

const Alerts = () => {
    const {alertMessage, lang, dictionary} = useSelector(state => ({
        alertMessage: state.adminReducer.alertMessage,
        lang: state.languageReducer.language,
        dictionary: state.dictionaryReducer
    }));

    const dispatch = useDispatch();

    const onDismiss = () => dispatch(closeAlert());

    if (alertMessage) return (
        <div style={alertStyle}>
            <Alert
                style={{minWidth: 300}}
                toggle={onDismiss}
                color={alertMessage.color}>
                { (dictionary.alerts[alertMessage.message] || {})[lang] || alertMessage.message}
            </Alert>
        </div>)
    else return <></>
}

const alertStyle = {
    position: 'absolute',
    top: 80,
    opacity: 0.7,
    width: '100%',
    zIndex: '1000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

export default Alerts;