import { compose } from 'redux';
import { arrayInsert } from 'redux-form';
import {
  CLEAN_SUBSCRIPTIONS_STORY,
  SELECT,
  SUBSCRIPTION,
  SubscriptionActionTypes,
  SUBSCRIPTIONS_STORY,
  VALID_SUBSCRIPTIONS,
} from '../actions/subscription-actions';

const initialState = {
  subscriptions: [],
  selectedId: undefined,
  selectedSubscription: undefined,
  subscriptionsStory: [],
  validSubscriptions: [],
  subscriptionsStoryEnd: false,
};


export function subscriptionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SUBSCRIPTION:
      return { ...state, ...{ subscriptions: action.subscriptions.sort((a, b) => a.price > b.price ? 1 : -1) } };
    case SELECT:
      return {
        ...state,
        ...{
          selectedSubscription: action.select,
          selectedId: action.select ? action.select.id : undefined,
        },
      };
    case SUBSCRIPTIONS_STORY:
      let subscriptionsStory = state.subscriptionsStory || [];
      subscriptionsStory = [...subscriptionsStory, ...action.subscriptionsStory];
      return { ...state, ...{ subscriptionsStory: subscriptionsStory, subscriptionsStoryEnd: action.subscriptionsStory.length < 10 } };
    case CLEAN_SUBSCRIPTIONS_STORY:
      return { ...state, ...{ subscriptionsStory: [] } };
    case VALID_SUBSCRIPTIONS:
      return { ...state, ...{ validSubscriptions: action.validSubscriptions } };
    default:
      return state;
  }
}