import React from "react";
import { CardBody, CardHeader } from "reactstrap";
import "./DeleteMyAccount.scss";
import { connect } from "react-redux";

class DeleteMyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <>
                <CardHeader className="bg-transparent border-0">
                    <h3 style={{ marginBottom: '-20px' }} className="first-level-title">Удаление аккаунта</h3>
                </CardHeader>
                <CardBody className="bottom-margin-del">

                    <p className='delete-acc-title'>Вы уверены что хотите удалить свой аккаунт?</p>
                    <div className='show-more-btn'
                        style={{ width: '250px', fontSize: '13px', margin: '30px auto', paddingTop: '7px' }}
                        onClick={() => { }}
                    >
                        <p className='show-more-btn-title'>Да, удалить мой аккаунт!</p>
                    </div>

                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMyAccount);
