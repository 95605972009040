import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationAz from './locale/az/translation.json';
import translationRu from './locale/ru/translation.json';
import LanguageDetector from "i18next-browser-languagedetector"


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            az: {
                translation: translationAz
            },
            ru: {
                translation: translationRu
            },
        },
        lang: 'az',
        fallbackLng: 'az',
        detection: {
            order: ['htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
            caches: ['localStorage'],
        },

        debug: true,
        nsSeparator: ':::',
        keySeparator: '::',
        interpolation: {
            escapeValue: false,
        },
    });