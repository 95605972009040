import { SET_LANGUAGE } from "../actions/action-types";
import i18n from 'i18next';

const INITIAL_STATE = {
    language: localStorage.getItem('az.ezgil.dermanlarim.language') || 'az'
}


const languageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            localStorage.setItem('az.ezgil.dermanlarim.language', action.payload);
            i18n.changeLanguage(action.payload);
            return {...state, language: action.payload };
        default:
            return state
    }
};

export default languageReducer;