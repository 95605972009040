import React from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    CardBody,
} from "reactstrap";
import classnames from "classnames";

import { connect } from "react-redux";
import { getSearchResult } from "../../../redux/actions/actions";

import "./AllNews.scss";
import { Link } from "react-router-dom";

import DrugBriefing from "../DrugBriefing/DrugBriefing";
import SearchField from "../SearchField/SearchField";
import NewsBlock from "../NewsBlock/NewsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import SearchList from "../SearchCommon/SearchList";
import AdsBlock from "../AdsBlock/AdsBlock";
import config from "../../../config";
import Ads from "../AdsBlock/Ads";

class AllNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "all",
            popularRequests: [],
        };
    }

    componentDidMount = () => {
        this.fetchPopularRequests();
    };

    fetchPopularRequests = async () => {
        let request = await fetch(
            `${config.url}popular-search-results?_sort=requestNumber:desc&type=drugs&_limit=10`
        );
        let data = await request.json();
        // console.log("news",data);
        this.setState({
            popularRequests: data,
        });
    };

    getPopularRequests = (requests) => {
        let rows = [];
        for (let f = 0; f < 10; f++) {
            let current_request = requests[f];
            if (current_request) {
                rows.push(
                    <Link
                        key={`${f}`}
                        to={`/drugs/search-results/${current_request.request}/types=professional`}
                    >
                        {current_request.request}
                    </Link>
                );
            } else {
                break;
            }
        }
        return rows;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.params !== prevState.activeTab) {
            return {
                activeTab: nextProps.match.params.params.substring(6),
            };
        }
        return null;
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        const { dictionary, lang } = this.props;

        let _activeTab = this.state.activeTab;
        let activeTab;
        //console.log('activeTab', _activeTab);
        // if (_activeTab === "news" || _activeTab === "alert") {
        //     activeTab = "all";
        // } else {
        activeTab = _activeTab;
        // }

        const params = (this.props.match || {}).params;
        const newParams = {};
        if (params.id === "_") {
            newParams.id = "";
        } else {
            newParams.id = params.id;
        }
        if (_activeTab === "all") {
            newParams.params = "types=news,alert,trial,newdrug";
        } else {
            newParams.params = "types=" + _activeTab;
        }
        return (
            <div className="all-news_container">
                <CardHeader className="bg-white border-0 mob">
                    <h1 className="mb-0 drugs-header">
                        {dictionary.allNews.title[lang]}
                    </h1>
                </CardHeader>
                <CardBody>
                    <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "all",
                                })}
                                onClick={() => {
                                    this.props.history.push(
                                        "/drugs/news/_/types=all"
                                    );
                                    this.toggle("all");
                                }}
                            >
                                {dictionary.newDrugs.allNews[lang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "news",
                                })}
                                onClick={() => {
                                    this.props.history.push(
                                        "/drugs/news/_/types=news"
                                    );
                                    this.toggle("news");
                                }}
                            >
                                {dictionary.newDrugs.medicalNews[lang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "alert",
                                })}
                                onClick={() => {
                                    this.props.history.push(
                                        "/drugs/news/_/types=alert"
                                    );
                                    this.toggle("alert");
                                }}
                            >
                                {dictionary.newDrugs.warnings[lang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "newdrug",
                                })}
                                onClick={() => {
                                    this.props.history.push(
                                        "/drugs/news/_/types=newdrug"
                                    );
                                    this.toggle("newdrug");
                                }}
                            >
                                {dictionary.newDrugs.newDrugs[lang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "trial",
                                })}
                                onClick={() => {
                                    this.props.history.push(
                                        "/drugs/news/_/types=trial"
                                    );
                                    this.toggle("trial");
                                }}
                            >
                                {dictionary.newDrugs.clinicalTrials[lang]}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <div className="all-news_main_container">
                        <div className="all-news_content">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="all">
                                    {/* <SearchField view={'news'} searchTypes={activeTab}></SearchField> */}
                                    <SearchList
                                        params={newParams}
                                        title={""}
                                        itemRender={(n) => (
                                            <NewsBlock
                                                key={n.id}
                                                title={{
                                                    az: n.source.titleAz,
                                                    ru: n.source.titleRu,
                                                }}
                                                miniText={{
                                                    az: n.source.miniTextAz,
                                                    ru: n.source.miniTextRu,
                                                }}
                                                id={n.source.entityId}
                                                Datetime={n.source.dateTime}
                                                EntityType={n.source.entityType}
                                                image={n.Image}
                                            ></NewsBlock>
                                        )}
                                    />
                                </TabPane>
                                <TabPane tabId="news">
                                    {/* <SearchField view={'news'} searchTypes={activeTab}></SearchField> */}
                                    <SearchList
                                        params={newParams}
                                        title={""}
                                        itemRender={(n) => (
                                            <NewsBlock
                                                key={n.id}
                                                title={{
                                                    az: n.source.titleAz,
                                                    ru: n.source.titleRu,
                                                }}
                                                miniText={{
                                                    az: n.source.miniTextAz,
                                                    ru: n.source.miniTextRu,
                                                }}
                                                id={n.source.entityId}
                                                Datetime={n.source.dateTime}
                                                style={{ cursor: "default" }}
                                                EntityType={n.source.entityType}
                                                image={n.Image}
                                            ></NewsBlock>
                                        )}
                                    />
                                </TabPane>
                                <TabPane tabId="alert">
                                    {/* <SearchField view={'news'} searchTypes={activeTab}></SearchField> */}
                                    <SearchList
                                        params={newParams}
                                        title={""}
                                        itemRender={(n) => (
                                            <NewsBlock
                                                key={n.id}
                                                title={{
                                                    az: n.source.titleAz,
                                                    ru: n.source.titleRu,
                                                }}
                                                miniText={{
                                                    az: n.source.miniTextAz,
                                                    ru: n.source.miniTextRu,
                                                }}
                                                id={n.source.entityId}
                                                Datetime={n.source.dateTime}
                                                style={{ cursor: "default" }}
                                                EntityType={n.source.entityType}
                                                image={n.Image}
                                            ></NewsBlock>
                                        )}
                                    />
                                </TabPane>
                                <TabPane tabId="newdrug">
                                    <div className="news__newdrug">
                                        <p>
                                            {
                                                dictionary.newDrugs.decription[
                                                    lang
                                                ]
                                            }
                                        </p>

                                        <SearchField
                                            view={"news"}
                                            searchTypes={activeTab}
                                        />
                                        <SearchList
                                            params={newParams}
                                            title={""}
                                            itemRender={(n, index) => (
                                                <>
                                                    <DrugBriefing
                                                        drugData={n}
                                                    />
                                                    {index === 0 && (
                                                        <Ads size="horizontal" />
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                </TabPane>

                                {/* <SearchField view={'news'} searchTypes={activeTab}></SearchField> */}

                                <TabPane tabId="trial">
                                    <SearchList
                                        params={newParams}
                                        title={""}
                                        itemRender={(n) => (
                                            <NewsBlock
                                                key={n.id}
                                                title={{
                                                    az: n.source.titleAz,
                                                    ru: n.source.titleRu,
                                                }}
                                                miniText={{
                                                    az: n.source.miniTextAz,
                                                    ru: n.source.miniTextRu,
                                                }}
                                                id={n.source.entityId}
                                                Datetime={n.source.dateTime}
                                                style={{ cursor: "default" }}
                                                EntityType={n.source.entityType}
                                                image={n.Image}
                                            ></NewsBlock>
                                        )}
                                    />
                                </TabPane>
                            </TabContent>
                            <div className="main_content">
                                <CardHeader className="bg-white border-0 display-block">
                                    <h1 className="mb-0 news_title">
                                        {
                                            dictionary.allNews.popularRequests[
                                                lang
                                            ]
                                        }
                                    </h1>
                                </CardHeader>
                                <CardBody className="popular_requests_container">
                                    {/* <div className="popular_requests_container"> */}
                                    <PopularRequests type="news" />
                                    {/* </div> */}
                                </CardBody>
                            </div>
                        </div>
                        <div className="last-news-main__sda">
                            <AdsBlock
                                component={
                                    <div style={{ marginTop: "1rem" }}>
                                        <h3>
                                            {
                                                dictionary.allNews
                                                    .popularRequestsAds[lang]
                                            }
                                        </h3>
                                        <div className="list">
                                            {this.getPopularRequests(
                                                this.state.popularRequests
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    searchResults: state.drugReducer.searchResultArray,
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getSearchResult: (searchNameDrug, params) => {
            dispatch(getSearchResult(searchNameDrug, params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllNews);
