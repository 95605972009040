import { LOADING_START, LOADING_END } from '../actions/loading-actions';

const initialState = {
    subjects: new Set()
}

export function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_START:
            return {...state, ... { subjects: state.subjects.add(action.subject) } };
        case LOADING_END:
            const subjects = state.subjects;
            subjects.delete(action.subject)
            return {...state, ... { subjects } };
        default:
            return state;
    }
}