import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { change, reset, submit } from "redux-form";
import {
    createPatientMedicine,
    deletePatientMedicine,
    getPatientMedicine,
    getPatientMedicineList,
    updatePatientMedicine,
    _patientMedicineItem,
} from "../../../redux/actions/patient-medicine-actions";
import UserMedicinesForm from "../../../forms/user-medicines-form";
import { LoadingSubject } from "../../../redux/models/loading-state.enum";
import { showConfirm } from "../../Dialogs";
import { CenterLoader } from "../../Drugs/Loader/Loader";
import "./MyMedicines.scss";

class MyMedicinesComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            updateItem: false,
            removeMode: null,

            fromDocItems: this.props.fromDocItems,
            forDocItem: this.props.fromDocItems,
        };
    }

    componentDidMount() {
        this.props.getPatientMedicineList();
    }

    onSubmit(data) {
        if (this.state.updateItem) {
            this.props.updatePatientMedicine(data, this.handleClose);
        } else {
            this.props.createPatientMedicine(data, this.handleClose);
        }
    }

    handleClose = () => {
        this.setState({ show: false });
        this.props._patientMedicineItem(null);
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    render() {
        const { fromDocItems, forDocItem } = this.state;
        const { dictionaryReducer, lang } = this.props;

        let itemsList = (this.props.patientMedicine || {}).list || [];
        if (fromDocItems) {
            itemsList = fromDocItems;
        }
        const removeItem =
            this.state.removeMode &&
            Object.values(this.state.removeMode).find((el) => el === true);

        const patientMedicineLoading =
            this.props.loading &&
            this.props.loading.subjects.has(LoadingSubject.PatientMedicine);

        return (
            <div className="medicines_wrapper">
                <Modal
                    centered
                    show={this.state.show}
                    onHide={this.handleClose}
                >
                    <Modal.Header className="my-meds-modal-header" closeButton>
                        <Modal.Title>
                            {this.state.updateItem
                                ? dictionaryReducer.myDrugs.edit[lang]
                                : dictionaryReducer.myDrugs.newDrug[lang]}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="my-medicines-modal">
                        {fromDocItems && this.state.forDocItem ? (
                            Object.entries(this.state.forDocItem).map((itm) => {
                                if (itm[0] !== "Files") {
                                    return (
                                        <div
                                            style={
                                                itm[1]
                                                    ? {}
                                                    : { display: "none" }
                                            }
                                        >
                                            <p
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "0.9rem",
                                                    marginBottom: "5px",
                                                    color: "#121C1E",
                                                }}
                                            >
                                                {itm[0]}
                                            </p>
                                            <p
                                                style={{
                                                    borderBottom:
                                                        "1px solid #ccc",
                                                    fontSize: "0.8rem",
                                                    paddingBottom: "2px",
                                                }}
                                            >
                                                {itm[1]}
                                            </p>
                                        </div>
                                    );
                                }
                            })
                        ) : (
                            <React.Fragment>
                                <UserMedicinesForm
                                    onSubmit={(data) => this.onSubmit(data)}
                                />

                                <button
                                    className="btn btn-primary drugs-button my-drugs-save"
                                    loading={patientMedicineLoading}
                                    onClick={() =>
                                        this.props.submit("UserMedicinesForm")
                                    }
                                >
                                    {" "}
                                    {
                                        dictionaryReducer.adminDrugs.saveButton[
                                            lang
                                        ]
                                    }
                                </button>
                            </React.Fragment>
                        )}
                    </Modal.Body>
                </Modal>

                <div className="medical_card_item_header">
                    <div className="md_item_title_image">
                        <img src={require("./img/tabletki.webp")}></img>
                        <h1>{dictionaryReducer.myDrugs.title[lang]}</h1>
                    </div>
                    {this.props.patientInfoBlock}

                    <div
                        className="button_blck"
                        style={fromDocItems ? { display: "none" } : {}}
                    >
                        <div
                            className="btn btn-primary drugs-button"
                            onClick={() => {
                                this.setState({ updateItem: false });
                                this.props.reset("UserMedicinesForm");
                                this.handleShow();
                            }}
                        >
                            {/* <p className='show-more-btn-title'> */}
                            {dictionaryReducer.myDrugs.add_drug[lang]}
                            {/* </p> */}
                        </div>
                    </div>
                </div>

                <div className="medical_card_item_desc">
                    <p>{dictionaryReducer.myDrugs.description[lang]}</p>
                </div>

                <div className="medical_card_item_data_block">
                    <p className="medical_card_item_title">
                        {dictionaryReducer.myDrugs.allData[lang]}
                    </p>

                    <div className="table_data">
                        <table
                            className={
                                this.props.auth &&
                                this.props.auth.userToken &&
                                this.props.auth.userToken.role === 3
                                    ? "pointer"
                                    : ""
                            }
                        >
                            <thead>
                                <th>{dictionaryReducer.myDrugs.name[lang]}</th>
                                <th>
                                    {dictionaryReducer.myDrugs.dosage[lang]}
                                </th>
                                <th>{dictionaryReducer.myDrugs.freq[lang]}</th>
                                <th>{dictionaryReducer.myDrugs.start[lang]}</th>
                                <th>{dictionaryReducer.myDrugs.end[lang]}</th>
                                <th>
                                    {dictionaryReducer.myDrugs.status[lang]}
                                </th>
                                <th>Fəaliyyətlər</th>
                            </thead>
                            <tbody>
                                {itemsList instanceof Array &&
                                    itemsList.map((item, index) => {
                                        return (
                                            <tr>
                                                {!forDocItem ? (
                                                    <React.Fragment>
                                                        <td>
                                                            {item.medical_product &&
                                                                item
                                                                    .medical_product
                                                                    .name &&
                                                                item.medical_product.name.map(
                                                                    (n) => (
                                                                        <span>
                                                                            {
                                                                                n[
                                                                                    lang
                                                                                ]
                                                                            }
                                                                        </span>
                                                                    )
                                                                )}
                                                        </td>
                                                        <td>{item.dose}</td>
                                                        <td>
                                                            {item.frequency && (
                                                                <span>
                                                                    {item
                                                                        .frequency[
                                                                        lang
                                                                    ] ||
                                                                        item
                                                                            .frequency[
                                                                            "az"
                                                                        ]}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.startDate}
                                                        </td>
                                                        <td>{item.endDate}</td>
                                                        <td>
                                                            {dictionaryReducer
                                                                .myDrugs[
                                                                item.status
                                                            ] &&
                                                                dictionaryReducer
                                                                    .myDrugs[
                                                                    item.status
                                                                ][lang]}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn icon-btn"
                                                                key={index}
                                                                onClick={async () => {
                                                                    if (
                                                                        this
                                                                            .state
                                                                            .removeMode ||
                                                                        forDocItem
                                                                    ) {
                                                                        return;
                                                                    }
                                                                    await this.props.getPatientMedicine(
                                                                        item.id
                                                                    );
                                                                    this.setState(
                                                                        {
                                                                            updateItem: true,
                                                                        }
                                                                    );
                                                                    this.handleShow();
                                                                }}
                                                            >
                                                                <svg
                                                                    className="icon-btn__icon"
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M0 15.25V19H3.75L14.81 7.94L11.06 4.19L0 15.25ZM17.71 5.04C18.1 4.65 18.1 4.02 17.71 3.63L15.37 1.29C14.98 0.899998 14.35 0.899998 13.96 1.29L12.13 3.12L15.88 6.87L17.71 5.04Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </button>

                                                            <button
                                                                onClick={async () => {
                                                                    if (
                                                                        await showConfirm(
                                                                            dictionaryReducer
                                                                                .myDrugs
                                                                                .confirmDelete[
                                                                                lang
                                                                            ]
                                                                        )
                                                                    ) {
                                                                        this.props.deletePatientMedicine(
                                                                            item.id
                                                                        );
                                                                    }
                                                                }}
                                                                className="btn icon-btn"
                                                            >
                                                                <svg
                                                                    className="icon-btn__icon"
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 18"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>{item.name}</td>
                                                        <td>{item.dose}</td>
                                                        <td>
                                                            {item.frequency
                                                                ? item
                                                                      .frequency[
                                                                      lang
                                                                  ] ||
                                                                  item
                                                                      .frequency[
                                                                      "az"
                                                                  ]
                                                                : ""}
                                                        </td>
                                                        <td>
                                                            {item.startDate}
                                                        </td>
                                                        <td>{item.endDate}</td>
                                                        <td>
                                                            {
                                                                dictionaryReducer
                                                                    .myDrugs[
                                                                    item.status
                                                                ][lang]
                                                            }
                                                        </td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {this.props.loading &&
                        this.props.loading.subjects.has(
                            LoadingSubject.PatientMedicineList
                        ) ? (
                            <div style={{ marginTop: "20px" }}>
                                {patientMedicineLoading && <CenterLoader />}
                            </div>
                        ) : (
                            !itemsList ||
                            (itemsList.length === 0 && (
                                <div className="no-items-no-results">
                                    {" "}
                                    <p>
                                        {dictionaryReducer.myDrugs.noData[lang]}
                                    </p>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    lang: state.languageReducer.language,
    dictionaryReducer: state.dictionaryReducer,
    patientMedicine: state.patientMedicine,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientMedicineList,
    deletePatientMedicine,
    change,

    createPatientMedicine,
    updatePatientMedicine,
    getPatientMedicine,
    _patientMedicineItem,
};
const MyMedicinesComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(MyMedicinesComponent));

export default MyMedicinesComponentContainer;
