import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane,
    Card, CardBody, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useLayoutEffect } from 'react';
import { editManufacturer, searchManufacturers, getManufacturersById, addingNewManufacturer } from '../../../../redux/actions/adminActions';


const PLManufacturers = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [languageTab, setTab] = useState('az');
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    const [tmpManufacturer, setTmpManufacturer] = useState({
        az: '', ru: '', id: null,
        country: { az: '', ru: '' },
        address: { az: '', ru: '' },
        number: '',
        otherInformation: { az: '', ru: '' },
        old: {id: null, az: '', ru: ''}
    });
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [suggest, setSuggest] = useState(false);
    let manufacturerIdsArray = [];
    const defaultTmp = {
        az: '', ru: '', id: null,
        country: { az: '', ru: '' },
        address: { az: '', ru: '' },
        number: '',
        otherInformation: { az: '', ru: '' },
        old: {id: null, az: '', ru: ''}
    };

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    };
    
    // mapStateToProps
    const {manufacturersById, manufacturersSearchResult} = useSelector(state => ({
        manufacturersById: state.adminReducer.manufacturersById,
        manufacturersSearchResult: state.adminReducer.manufacturersSearchResult
    }));

    const dispatch = useDispatch();

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchManufacturers(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    useEffect(() => {
        // создание массива ингредиентных id
        if (patientLeaflet && patientLeaflet.manufacturer.length > 0) {
            patientLeaflet.manufacturer.map(man => man.manufacturer ? manufacturerIdsArray.push(man.manufacturer.id) : '');
            dispatch(getManufacturersById(manufacturerIdsArray));
        }
    }, [patientLeaflet]);

    // кнопка создания нового ингредиента
    // открытие модального окна
    const addManufacturerButtonHandler = () => {
        setTmpManufacturer(defaultTmp); // вычищаем хранилище
        setSearchString(null); // вычищаем поисковую строку
        setModal(true);
    }

    const addNewManufacturer = () => {
        if (tmpManufacturer.az) {
            dispatch(addingNewManufacturer(tmpManufacturer, patientLeaflet));
            setModal(false);
            //props.toggleSave(true);
        }
    }

    // кнопка редактирования существующего ингредиента
    // открытие модального окна
    const editManufacturerButtonHandler = index => {
        setTmpManufacturer({
            az: manufacturersById[index].name.az,
            ru: manufacturersById[index].name.ru,
            id: null,
            country: {...manufacturersById[index].country},
            address: {...manufacturersById[index].address},
            number: manufacturersById.number,
            otherInformation: {...manufacturersById[index].otherInformation},
            old: {az: manufacturersById[index].name.az, ru: manufacturersById[index].name.ru, id: manufacturersById[index].id}
        }); // присваиваем к хранилищу текущий ингредиент
        setSearchString(null); // вычищаем поисковую строку
        setEditModal(true);
        setActiveIndex(index);
    }

    const editCurrentManufacturer = () => {
        dispatch(editManufacturer(activeIndex, tmpManufacturer, patientLeaflet));
        setEditModal(false);
        setActiveIndex(null);
    }

    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({manufacturer: patientLeaflet.manufacturer.filter((man, i) => i !== index)});
        props.toggleSave(true);
    };

    const selectManufacturer = manufacturer => {
        setTmpManufacturer({...tmpManufacturer, ...manufacturer.name, id: manufacturer.id, number: manufacturer.number})
    }

    return (
        <Row>
            <Col xs='12' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        {patientLeaflet.manufacturer ? 
                            patientLeaflet.manufacturer.map((manufacturerI, key) => ManufacturerElement({
                                lang, languageTab, dictionary, key, manufacturerI, manufacturerById: manufacturersById ? manufacturersById[key] : null,
                                editManufacturerButtonHandler, deleteButtonHandler
                            })) : ''}
                        <Button onClick={addManufacturerButtonHandler} className='mt-2' size='lg' block color='primary'><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.patientLeafletEditPage.manufacturer.addManufacturer[lang]} </Button>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}</ModalHeader>
                <ModalBody>{ManufacturerAddEditElement({
                    lang, languageTab, dictionary,
                    searchString, setSearchString, 
                    tmpManufacturer, setTmpManufacturer,
                    suggest, selectManufacturer,
                    manufacturersSearchResult})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewManufacturer}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpManufacturer(defaultTmp); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editModal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}</ModalHeader>
                <ModalBody>{ManufacturerAddEditElement({
                    lang, languageTab, dictionary,
                    searchString, setSearchString, 
                    tmpManufacturer, setTmpManufacturer,
                    suggest, selectManufacturer,
                    manufacturersSearchResult})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={editCurrentManufacturer}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpManufacturer(defaultTmp); setEditModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

const ManufacturerElement = props => {
    const {lang, languageTab, dictionary, key, manufacturerI, manufacturerById, deleteButtonHandler, editManufacturerButtonHandler} = props;

    return (
        <Row className='mt-2' key={key}>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <FontAwesomeIcon className='float-right pointer' icon={faTrash}
                                onClick={deleteButtonHandler.bind(this, key)}/>
                            <FontAwesomeIcon className='float-right mr-3 pointer' icon={faEdit}
                                onClick={editManufacturerButtonHandler.bind(this, key)} />
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerAz_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={manufacturerById && manufacturerById.name ? manufacturerById.name.az : ''}
                                    />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.country[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerCountryAz_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={manufacturerById && manufacturerById.country ? manufacturerById.country.az : ''}
                                    />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.address[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerAddressAz_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={manufacturerById && manufacturerById.address ? manufacturerById.address.az : ''}
                                    />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.otherInformation[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        disabled
                                        data={manufacturerById && manufacturerById.otherInformation && manufacturerById.otherInformation.az ? manufacturerById.otherInformation.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerRu_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={manufacturerById && manufacturerById.name ? manufacturerById.name.ru : ''}
                                    />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.country[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerCountryRu_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={manufacturerById && manufacturerById.country ? manufacturerById.country.ru : ''}
                                    />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.address[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerAddressRu_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={manufacturerById && manufacturerById.address ? manufacturerById.address.ru : ''}
                                    />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.otherInformation[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        disabled
                                        data={manufacturerById && manufacturerById.otherInformation && manufacturerById.otherInformation.ru ? manufacturerById.otherInformation.ru : ''} />  
                                </TabPane>
                            </TabContent>
                            <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.number[lang]}`}</Label>
                            <Input
                                type='text'
                                autoComplete='off'
                                id={`searchManufacturerNumber_${key}`}
                                placeholder={''}
                                disabled
                                value={manufacturerById && manufacturerById.number ? manufacturerById.number : ''}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

const ManufacturerAddEditElement = props => {
    const {
        lang, languageTab, dictionary,
        searchString, setSearchString, 
        tmpManufacturer, setTmpManufacturer,
        suggest, selectManufacturer,
        manufacturersSearchResult
    } = props;

    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerAz`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            searchString !== null ? searchString :
                                            tmpManufacturer.az ? tmpManufacturer.az : ''
                                        }
                                        onChange={e => {
                                            setTmpManufacturer({...tmpManufacturer, az: e.target.value});
                                            setSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && manufacturersSearchResult ? manufacturersSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectManufacturer(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.country[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerCountryAz`}
                                        placeholder={dictionary.search[lang]}
                                        value={tmpManufacturer.country ? tmpManufacturer.country.az : ''}
                                        onChange={e => {
                                            setTmpManufacturer({...tmpManufacturer, country: {
                                                ...tmpManufacturer.country, az: e.target.value}});
                                        }} />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.address[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerAddressAz`}
                                        placeholder={dictionary.search[lang]}
                                        value={tmpManufacturer.address ? tmpManufacturer.address.az : ''}
                                        onChange={e => {
                                            setTmpManufacturer({...tmpManufacturer, address: {
                                                ...tmpManufacturer.address, az: e.target.value}});
                                        }} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.otherInformation[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTmpManufacturer({
                                                ...tmpManufacturer, otherInformation: {
                                                    ...tmpManufacturer.otherInformation, az: data
                                                }});
                                        }}
                                        data={tmpManufacturer.otherInformation ? tmpManufacturer.otherInformation.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='text'
                                        id={`manufacturerRu`}
                                        placeholder=''
                                        value={tmpManufacturer ? tmpManufacturer.ru : ''}
                                        onChange={e => {
                                            setTmpManufacturer({...tmpManufacturer, ru: e.target.value})
                                        }} /> 
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.country[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerCountryRu`}
                                        placeholder={dictionary.search[lang]}
                                        value={tmpManufacturer.country ? tmpManufacturer.country.ru : ''}
                                        onChange={e => {
                                            setTmpManufacturer({...tmpManufacturer, country: {
                                                ...tmpManufacturer.country, ru: e.target.value}});
                                        }} />
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.address[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchManufacturerAddressRu`}
                                        placeholder={dictionary.search[lang]}
                                        value={tmpManufacturer.address ? tmpManufacturer.address.ru : ''}
                                        onChange={e => {
                                            setTmpManufacturer({...tmpManufacturer, address: {
                                                ...tmpManufacturer.address, ru: e.target.value}});
                                        }} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.otherInformation[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTmpManufacturer({
                                                ...tmpManufacturer, otherInformation: {
                                                    ...tmpManufacturer.otherInformation, ru: data
                                                }});
                                        }}
                                        data={tmpManufacturer.otherInformation ? tmpManufacturer.otherInformation.ru : ''} />  
                                </TabPane>
                            </TabContent>
                            <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.manufacturer.number[lang]}`}</Label>
                            <Input
                                type='text'
                                autoComplete='off'
                                id={`searchManufacturerNumber`}
                                placeholder={''}
                                value={tmpManufacturer ? tmpManufacturer.number : ''}
                                onChange={e => setTmpManufacturer({...tmpManufacturer, number: e.target.value})}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default PLManufacturers;