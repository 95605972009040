import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane
} from 'reactstrap';


const PLGeneralInfo = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [generalInfo, setGeneralInfo] = useState({
        title: patientLeaflet.title ? {
            az: patientLeaflet.title.az,
            ru: patientLeaflet.title.ru
        } : {az: '', ru: ''},
        internationalNonPatentedName: patientLeaflet.internationalNonPatentedName,
        whatIsIt: patientLeaflet.whatIsIt ? {
            az: patientLeaflet.whatIsIt.az,
            ru: patientLeaflet.whatIsIt.ru
        } : {az: '', ru: ''}
    });
    const [languageTab, setTab] = useState('az');

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }
    
    const submitButtonHandler = () => {
        props.changeLeaflet({
            title: {...generalInfo.title, ru: generalInfo.title.ru ? generalInfo.title.ru : generalInfo.title.az},
            whatIsIt: {...generalInfo.whatIsIt, ru: generalInfo.whatIsIt.ru ? generalInfo.whatIsIt.ru : generalInfo.whatIsIt.az},
            internationalNonPatentedName: generalInfo.internationalNonPatentedName
        });
        props.toggleSave(true);
    }
    
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                                <Label for='titleAz'>{`${dictionary.adminDrugs.patientLeafletEditPage.generalInfo.productTitle[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <Input type='text' id='titleAz'
                                    placeholder='Məhsulun adı'
                                    value={generalInfo.title.az}
                                    onChange={e => setGeneralInfo({...generalInfo, title: {...generalInfo.title, az: e.target.value}})} />
                                <Label className='mt-2' for='whatIsItAz'>{`${dictionary.adminDrugs.patientLeafletEditPage.generalInfo.whatIsIt[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralInfo({
                                            ...generalInfo,
                                            whatIsIt: {
                                                ...generalInfo.whatIsIt,
                                                az: data
                                        }});
                                } }
                                data={generalInfo.whatIsIt.az} />
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label for='titleRu'>{`${dictionary.adminDrugs.patientLeafletEditPage.generalInfo.productTitle[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <Input type='text' name='titleRu' id='titleRu'
                                    placeholder='Имя продукта'
                                    value={generalInfo.title.ru}
                                    onChange={e => setGeneralInfo({...generalInfo, title: {...generalInfo.title, ru: e.target.value}})} />
                                <Label className='mt-2' for='whatIsItRu'>{`${dictionary.adminDrugs.patientLeafletEditPage.generalInfo.whatIsIt[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralInfo({
                                            ...generalInfo,
                                            whatIsIt: {
                                                ...generalInfo.whatIsIt,
                                                ru: data
                                        }});
                                } }
                                data={generalInfo.whatIsIt.ru} />
                            </TabPane>
                        </TabContent>
                        <Label className='mt-2' for='internationalNonPatentedName'>{dictionary.adminDrugs.patientLeafletEditPage.generalInfo.internationalNonPatentedName[lang]}</Label>
                        <Input
                            type='text' name='internationalNonPatentedName' id='internationalNonPatentedName'
                            placeholder={dictionary.adminDrugs.patientLeafletEditPage.generalInfo.internationalNonPatentedName[lang]}
                            value={generalInfo.internationalNonPatentedName}
                            onChange={e => setGeneralInfo({...generalInfo, internationalNonPatentedName: e.target.value})} />
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

export default PLGeneralInfo;