import {
    SEARCH_RESULT_ARRAY,
    SET_LANGUAGE,
    GET_DRUG_DATA,
    GET_BODY_SYSTEMS_DATA,
    GET_ABOUT_US_DATA,
    SET_FOR_PROFESSIONALS,
    GET_NEWS_DATA,
    ERROR,
    GET_LETTERS_COUNT,
    GET_FULL_MEDICAL_PRODUCT,
    GET_RENDERED_MEDICAL_PRODUCT,
    GET_RENDERED_PATIENT_LEAFLET,
    GET_ALERT,
    CLOSE_ALERT,
    GET_PRIVACY_POLICY,
    GET_TERMS_OF_USE,
    GET_MEDICINE_DETAIL,
} from "./action-types";
import config from "../../config";
import { authorized } from "./userAction";
import { loadingEnd, loadingStart } from "./loading-actions";

export const PAGE_SIZE = 10;

export const getRenderedMedicalProduct = (id) => async (dispatch) => {
    try {
        const res = await fetch(`${config.url}medical-product-text/${id}`);
        const response = await res.json();
        if (response.error) {
            console.error("ошибка", response.error);
            return dispatch(errorAction(response));
        } else {
            return dispatch({
                type: GET_RENDERED_MEDICAL_PRODUCT,
                payload: response,
            });
        }
    } catch (err) {
        console.error(err.message);
    }
};

export const getRenderedPatientLeaflet = (id) => async (dispatch) => {
    try {
        const res = await fetch(`${config.url}patient-leaflet-text/${id}`);
        const response = await res.json();
        if (response.error) {
            console.error("ошибка", response.error);
            dispatch(loadingEnd(GET_MEDICINE_DETAIL));
            return dispatch(errorAction(response));
        } else {
            dispatch(loadingEnd(GET_MEDICINE_DETAIL));
            return dispatch({
                type: GET_RENDERED_PATIENT_LEAFLET,
                payload: response,
            });
        }
    } catch (err) {
        dispatch(loadingEnd(GET_MEDICINE_DETAIL));
        console.error(err.message);
    }
};

const getSearchResultAction = (searchResultArray, searchPage, searchTotal) => {
    return {
        type: SEARCH_RESULT_ARRAY,
        searchResultArray,
        searchPage,
        searchTotal,
    };
};

export const getSearchResult =
    (drugSearchName, params, page, autoC) => async (dispatch, getState) => {
        try {
            dispatch(loadingStart(SEARCH_RESULT_ARRAY));

            let res = await fetch(
                `${config.csharp}api/public/Search/textSearch?search=${drugSearchName}&${params}&${autoC}&limit=${PAGE_SIZE}&offset=${
                    page * PAGE_SIZE
                }`,
                {
                    method: "POST",
                    body: "{}",
                    //body: JSON.stringify({['content' + {'az': 'Az', 'ru': 'Ru', 'en': 'En'}[getState().languageReducer.language] ] : drugSearchName }),
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            const data = await res.json();

            if (page === 0) {
                res = await fetch(
                    `${config.csharp}api/public/Search/count?search=${drugSearchName}&${params}&${autoC}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json",
                            Accept: "application/json",
                        },
                    }
                );
                let total;

                if (drugSearchName === " ") {
                    total = parseInt(1000);
                } else {
                    total = parseInt(await res.text());
                }

                dispatch(getSearchResultAction(data, page, total));
                return;
            }

            dispatch(getSearchResultAction(data, page));
        } finally {
            dispatch(loadingEnd(SEARCH_RESULT_ARRAY));
        }
    };

const getDrugDataAction = (drugData) => {
    return {
        type: GET_DRUG_DATA,
        payload: drugData,
    };
};

export const getDrugData = (drugId) => (dispatch) => {
    return fetch(config.url + `medical-products/${drugId}`)
        .then((res) => res.json())
        .then((response) => {
            dispatch(getDrugDataAction(response));
        });
};

const getBodySystemsDataAction = (bodySystemsData) => {
    return {
        type: GET_BODY_SYSTEMS_DATA,
        payload: bodySystemsData,
    };
};

export const getBodySystemsData = () => (dispatch) => {
    return fetch(config.url + `body-systems/`)
        .then((res) => res.json())
        .then((response) => {
            dispatch(getBodySystemsDataAction(response));
        });
};

const getAboutUsDataAction = (aboutUsData) => {
    return {
        type: GET_ABOUT_US_DATA,
        payload: aboutUsData,
    };
};

export const getAboutUsData = (aboutId) => (dispatch) => {
    return fetch(config.url + `abouts/${aboutId}`)
        .then((res) => res.json())
        .then((response) => {
            dispatch(getAboutUsDataAction(response));
        });
};

export const getPrivacyPolicy = () => (dispatch) => {
    return fetch(`${config.url}privacy`)
        .then((res) => res.json())
        .then((response) =>
            dispatch({
                type: GET_PRIVACY_POLICY,
                payload: response,
            })
        );
};

export const getTermsOfUse = () => (dispatch) => {
    return fetch(`${config.url}terms`)
        .then((res) => res.json())
        .then((response) =>
            dispatch({
                type: GET_TERMS_OF_USE,
                payload: response,
            })
        );
};

export const setForProfessionals = (forProfessionals) => {
    return {
        type: SET_FOR_PROFESSIONALS,
        payload: forProfessionals,
    };
};

const getNewsDataAction = (newsData) => {
    return {
        type: GET_NEWS_DATA,
        payload: newsData,
    };
};

export const getNewsData = (articleId, Types) => (dispatch) => {
    articleId = articleId ? articleId : "";
    return fetch(config.url + `rss-text/${articleId}?types=${Types}`)
        .then((res) => res.json())
        .then((response) => {
            dispatch(getNewsDataAction(response));
        });
};

const _errorAction = (error) => {
    return {
        type: ERROR,
        payload: error,
    };
};

export const errorAction = (error) => (dispatch) => {
    console.log("Ошибка!", error.message);
    alert(error.message);
    if (error.statusCode === 401) return dispatch(authorized(false));
    else return dispatch(_errorAction(error));
};

const getLettersCountAction = (lettersCount) => {
    return {
        type: GET_LETTERS_COUNT,
        payload: lettersCount,
    };
};

export const getLettersCount = () => (dispatch) => {
    fetch(`${config.url}alphabets?_limit=10000`)
        .then((res) => res.json())
        .then((response) => {
            if (response.error) {
                console.log("ошибка", response);
                return dispatch(errorAction(response));
            } else {
                return dispatch(getLettersCountAction(response));
            }
        })
        .catch((err) => console.log("ошибка:", err.message));
};

export const getFullMedicalProduct = (medicalProductId) => async (dispatch) => {
    try {
        const res = await fetch(
            config.url + `full-medical-product/${medicalProductId}`
        );
        const response = await res.json();
        if (response.error) {
            console.error(response.error);
            return dispatch(errorAction(response));
        }
        return dispatch({
            type: GET_FULL_MEDICAL_PRODUCT,
            payload: response,
        });
    } catch (err) {
        console.log(err.message);
    }
};

export const getAlert = (message, color) => (dispatch) => {
    dispatch({
        type: GET_ALERT,
        payload: { message, color },
    });

    setTimeout(
        () =>
            dispatch({
                type: CLOSE_ALERT,
            }),
        5000
    );
};

export const closeAlert = () => (dispatch) => {
    dispatch({
        type: CLOSE_ALERT,
    });
};
