import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import history from "./../../../history";
import "./Letters";

const searchParams = "nameOnly=1&types=consumer,professional";

class Letters extends React.Component {
    constructor(props) {
        super(props);
        this.toDrugsByLetter = this.toDrugsByLetter.bind(this);

        this.state = {
            old_id: props.match ? props.match.params.id : undefined,
        };
    }

    toDrugsByLetter(searchLetter) {
        history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    componentDidMount = () => {
        if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id
        ) {
            this.props.getSearchResult(
                this.props.match.params.id,
                searchParams,
                0
            );
        }
    };

    componentDidUpdate = () => {
        if (
            this.props.match &&
            this.props.match.params &&
            this.state.old_id !== this.props.match.params.id
        ) {
            this.props.getSearchResult(
                this.props.match.params.id,
                searchParams,
                0
            );
            this.setState({
                old_id: this.props.match.params.id,
            });
        }
    };

    render() {
        const { dictionary, lang, searchLetter } = this.props;

        let langForAlphabet = lang;

        if (/[а-я]/i.test(searchLetter)) {
            langForAlphabet = "ru";
        } else if (/[a-z]/i.test(searchLetter)) {
            langForAlphabet = "az";
        }

        return (
            <>
                <div className="alphabet_block_by_letter alphabet_block drugs-description">
                    {alphabeticalLoop(
                        langForAlphabet,
                        letterJsx,
                        this.toDrugsByLetter,
                        searchLetter,
                        this.props.lettersCount
                    )}
                    <Link
                        to="/drugs/advanced-search"
                        className="alphabet_button live-button"
                    >
                        {dictionary.advancedSearch[lang]}
                    </Link>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lettersCount: state.searchByLetterReducer.lettersCount,
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
    loading: state.loadingReducer,
});

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(Letters);
