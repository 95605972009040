export const LOADING_START = 'LOADING_START';

export const loadingStart = subject => {
    return {
        type: LOADING_START,
        subject
    };
};

export const LOADING_END = 'LOADING_END';

export const loadingEnd = subject => {
    return {
        type: LOADING_END,
        subject
    };
};