import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { showAlert } from "../../Dialogs/index";
import { subscription, subscriptionPay, getValidSubscriptions } from "../../../redux/actions/subscription-actions";
import "./subscription.scss";

class Subscription extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		this.fetch_data();
	};

	fetch_data = () => {
		const { auth } = this.props;

		this.props.subscription();
		if (auth.self) {
			this.props.getValidSubscriptions();
		}
	};

	componentDidUpdate = (prevProps) => {
		const { auth } = this.props;

		if (!prevProps.auth.self && auth.self) {
			this.fetch_data();
		}
	};
	async makePayment(already_subbed, sub) {
		const { validSubscriptions } = this.props;
		const freesubscription = this.props.validSubscriptions.find(item => item.id === 5)

		if (!validSubscriptions || !validSubscriptions.length || !freesubscription) {
			this.props.subscriptionPay(sub);
		} else {
			showAlert('You have an active subscription.');
		}
	}

	render = () => {
		console.log(this.props.validSubscriptions, 'validSubscriptions')
		console.log(this.props.subscriptions, 'subscriptions')
		const { t, subscriptions, validSubscriptions } = this.props;

		return (
			<div className="subscription-container">
				<div>
					<h1 className="drugs-header">{t("Subscribe")}</h1>
					<p className="article-text">{t("Opportunities to subscribe to Dermanlarim.az")}</p>
					<p className="article-text">
						{t("Opportunities 1")}
					</p>
					<p className="article-text">
						{t("Opportunities 2")}
					</p>
					<p className="article-text">
						{t("Opportunities 3")}
					</p>
				</div>

				<div className="container_card">
					<div className="card-wrapper">
						{subscriptions &&
							subscriptions.map((sub, index) => {
								let already_subbed = !!(validSubscriptions && validSubscriptions.find(vs => vs.subscriptionId === sub.id));

								return (
									<div className="card-wrapper_el" key={index}>
										<Card>
											<CardContent>
												<p className="title">{sub.description[this.props.lang]}</p>
											</CardContent>
											<hr />
											<p className="small-title">{sub.price} AZN</p>
											<div className="block-button">
												<button
													className={"btn btn-primary drugs-button" + (already_subbed ? " active" : "")}
													onClick={() => {
														if (already_subbed) {
															showAlert('You have an active subscription.')
														} else {
															this.makePayment(already_subbed, sub)
														}
													}}
												>
													{already_subbed ? t("Abunə mövcuddur") : t("Subscribe")}
												</button>
											</div>
										</Card>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		subscriptions: state.subscriptionReducer.subscriptions,
		validSubscriptions: state.subscriptionReducer.validSubscriptions,
		auth: state.vdAuthReducer,
		lang: state.languageReducer.language
	};
}

const mapDispatchToProps = {
	subscription,
	getValidSubscriptions,
	subscriptionPay,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Subscription));
