const INITIAL_STATE = {};

const mainPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default mainPageReducer;
