import React from "react";
import { withTranslation } from "react-i18next";
import {
    GoogleMap,
    Marker,
    GoogleMapProps,
    withGoogleMap,
} from "react-google-maps";
import "./contacts.scss";
import MapImg from "../../../assets/img/map.webp";

class Contacts extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        return (
            <div className="contacts">
                <div className="contacts__info">
                    <h1 className="contacts__header">{t("Contacts")}</h1>

                    <div className="contacts__content">
                        <svg
                            className="contacts__icon"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                                fill="#FB6C47"
                            />
                        </svg>
                        (+994) 51-206-38-13
                    </div>
                    <div className="contacts__content">
                        <svg
                            className="contacts__icon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.05 2.91C15.18 1.03 12.69 0 10.04 0C4.58 0 0.130003 4.45 0.130003 9.91C0.130003 11.66 0.590003 13.36 1.45 14.86L0.0500031 20L5.3 18.62C6.75 19.41 8.38 19.83 10.04 19.83C15.5 19.83 19.95 15.38 19.95 9.92C19.95 7.27 18.92 4.78 17.05 2.91ZM10.04 18.15C8.56 18.15 7.11 17.75 5.84 17L5.54 16.82L2.42 17.64L3.25 14.6L3.05 14.29C2.23 12.98 1.79 11.46 1.79 9.91C1.79 5.37 5.49 1.67 10.03 1.67C12.23 1.67 14.3 2.53 15.85 4.09C17.41 5.65 18.26 7.72 18.26 9.92C18.28 14.46 14.58 18.15 10.04 18.15ZM14.56 11.99C14.31 11.87 13.09 11.27 12.87 11.18C12.64 11.1 12.48 11.06 12.31 11.3C12.14 11.55 11.67 12.11 11.53 12.27C11.39 12.44 11.24 12.46 10.99 12.33C10.74 12.21 9.94 11.94 9 11.1C8.26 10.44 7.77 9.63 7.62 9.38C7.48 9.13 7.6 9 7.73 8.87C7.84 8.76 7.98 8.58 8.1 8.44C8.22 8.3 8.27 8.19 8.35 8.03C8.43 7.86 8.39 7.72 8.33 7.6C8.27 7.48 7.77 6.26 7.57 5.76C7.37 5.28 7.16 5.34 7.01 5.33C6.86 5.33 6.7 5.33 6.53 5.33C6.36 5.33 6.1 5.39 5.87 5.64C5.65 5.89 5.01 6.49 5.01 7.71C5.01 8.93 5.9 10.11 6.02 10.27C6.14 10.44 7.77 12.94 10.25 14.01C10.84 14.27 11.3 14.42 11.66 14.53C12.25 14.72 12.79 14.69 13.22 14.63C13.7 14.56 14.69 14.03 14.89 13.45C15.1 12.87 15.1 12.38 15.03 12.27C14.96 12.16 14.81 12.11 14.56 11.99Z"
                                fill="#FB6C47"
                            />
                        </svg>
                        (+994) 51-206-38-13
                    </div>
                    <div className="contacts__content">
                        <svg
                            className="contacts__icon"
                            width="20"
                            height="16"
                            viewBox="0 0 20 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
                                fill="#FB6C47"
                            />
                        </svg>
                        <a href="mailto:Ezgilmedical@gmail.com">
                            Ezgilmedical@gmail.com
                        </a>
                        <span>,&nbsp;</span>
                        <a href="mailto:Info@ezgilmedical.az">
                            Info@ezgilmedical.az
                        </a>
                    </div>

                    <div className="contacts__content">
                        <svg
                            className="contacts__icon"
                            width="14"
                            height="20"
                            viewBox="0 0 14 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
                                fill="#FB6C47"
                            />
                        </svg>
                        {t("our_address")}
                    </div>
                </div>
            </div>
        );
    }
}

const GoogleMaps = withGoogleMap((props) => (
    <GoogleMap>
        <Marker position={{ lat: 40.5, lng: 47.5 }} />
    </GoogleMap>
));

export default withTranslation()(Contacts);
