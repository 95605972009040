import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    NavLink,
    Row,
} from "reactstrap";

import "./Reset.scss";
import { connect } from "react-redux";
import { resetPass as resetPassword } from '../../../redux/actions/auth-actions';
import { Link, Redirect } from "react-router-dom";
import queryString from 'query-string';

class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirmation: ''
        }
    }

    resetChange = e => {
        this.setState({...this.state, [e.target.name]: e.target.value});
    }

    onSubmit = e => {
        e.preventDefault();
        const {code} = queryString.parse(this.props.location.search);
        const {password, passwordConfirmation} = this.state;
        if (password !== passwordConfirmation) return alert('passwords are not the same!');
        this.props.resetPassword(code, password, passwordConfirmation);
    }

    render() {
        const {lang, dictionary, resetPass} = this.props;
        const {code} = queryString.parse(this.props.location.search);

        if (!code) return <Redirect to={`/drugs/login`} />
        return (
            <>
                <CardHeader className="bg-transparent border-0 bottom-margin-del">
                    <h3 className="main-title-forgot">
                        {dictionary.auth.title[lang]}
                    </h3>
                    <p className="info-block-forgot">
                        {dictionary.auth.subTitle[lang]}
                    </p>
                </CardHeader>
                {!resetPass || resetPass === 'sended' ? 
                    <CardBody className="bottom-margin-del">
                        <form onSubmit={this.onSubmit}>
                            <p className="second-level-title">{dictionary.auth.enterNewPass[lang]}</p>
                            <p>
                                <input className="inpusts-sign-in" type="password" name="password"
                                    onChange={this.resetChange}
                                />
                            </p>
                            <p className="second-level-title">{dictionary.auth.repeatNewPass[lang]}</p>
                            <p>
                                <input className="inpusts-sign-in" type="password" name="passwordConfirmation"
                                    onChange={this.resetChange}
                                />
                            </p>
                            <div className="reg_button_wrapper">
                                <button className="reg_button">
                                    {dictionary.forgotComponent.submit[lang]}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                    :
                    <CardBody>
                        <h3 className="main-title-forgot">
                            {dictionary.auth.successfullUpdated[lang]}
                        </h3>
                        <p className="info-block-forgot">
                            {<Link to={`/drugs/login`} >Login</Link>}
                        </p>
                    </CardBody>
                }
                
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    resetPass: state.authReducer.resetPass,
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = {
    resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);