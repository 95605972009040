import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import TextInputWithoutLabel from "../form-components/TextInputWithoutLabel";
import "./Forgot-form.scss";

class ForgotForm extends React.Component {
    render() {
        return (
            <div>
                <Field
                    name="login"
                    placeholder={this.props.t("Input login, email or phone")}
                    component={TextInputWithoutLabel}
                />
            </div>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    if (!values.login || values.login.trim() === "") {
        errors.login = props.t("Field couldn't be empty");
    }
    return errors;
};

const form = reduxForm({
    form: "ForgotForm",
    validate,
})(ForgotForm);

export default connect(null)(withTranslation()(form));
