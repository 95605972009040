import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import TextInput from "../form-components/TextInput";
import TextInputMask from "../form-components/TextInputMask";
import { withTranslation } from "react-i18next";

class RegistrationFormUser extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Field
                    name="login"
                    placeholder={t("Login")}
                    component={TextInput}
                />
                <Field
                    name="phone"
                    component={TextInputMask}
                    label={t("Phone number")}
                />
                <Field
                    name="email"
                    placeholder={t("Email if you have")}
                    component={TextInput}
                />
                <Field
                    name="password"
                    placeholder={t("Password")}
                    type="password"
                    component={TextInput}
                />
                <Field
                    name="password_repeat"
                    placeholder={t("Repeat password")}
                    type="password"
                    component={TextInput}
                />
            </div>
        );
    }
}

const loginValidator = /^\w+$/g;

const validate = (values, props) => {
    const errors = {};
    const { t } = props;

    if (!values.login || values.login.trim() === "") {
        errors.login = t("Field couldn't be empty");
    } else if (!values.login.match(loginValidator)) {
        errors.login = t("Field couldn't be empty");
    }
    if (!values.phone || values.phone.trim() === "") {
        errors.phone = t("Field couldn't be empty");
    }
    if (!values.password || values.password.trim() === "") {
        errors.password = t("Field couldn't be empty");
    }
    if (!values.password_repeat || values.password_repeat.trim() === "") {
        errors.password_repeat = t("Field couldn't be empty");
    }
    if (values.password !== values.password_repeat) {
        errors.password_repeat = t("Passwords do not match");
    }

    return errors;
};

const form = reduxForm({
    form: "RegistrationFormUser",
    validate,
})(RegistrationFormUser);

export default connect(null)(withTranslation()(form));
