import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button,
    Col, Row,
    Nav, NavItem, NavLink, TabContent, TabPane, Label
} from 'reactstrap';

const PLGeneralFields = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [generalFields, setGeneralFields] = useState(patientLeaflet.generalFields ? {
        readCarefully: {...patientLeaflet.generalFields.readCarefully},
        whatIsInLaflet: {...patientLeaflet.generalFields.whatIsInLaflet}
    } : {readCarefully: {az: '', ru: ''}, whatIsInLaflet: {az: '', ru: ''}});
    const [languageTab, setTab] = useState('az');

    const toggleLang = tab => {if (languageTab !== tab) setTab(tab)}

    const submitButtonHandler = () => {
        props.changeLeaflet({generalFields: {
            readCarefully: {...generalFields.readCarefully, ru: generalFields.readCarefully.ru ? generalFields.readCarefully.ru : generalFields.readCarefully.az},
            whatIsInLaflet: {...generalFields.whatIsInLaflet, ru: generalFields.whatIsInLaflet.ru ? generalFields.whatIsInLaflet.ru : generalFields.whatIsInLaflet.az}
        }});
        props.toggleSave(true);
    }
    
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                                <Label>{dictionary.adminDrugs.patientLeafletEditPage.generalFields.readCarefullyAz[lang]}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralFields({
                                            ...generalFields,
                                            readCarefully: {...generalFields.readCarefully, az: data}});
                                        }}
                                    data={generalFields.readCarefully && generalFields.readCarefully.az ? generalFields.readCarefully.az : ''} />
                                <Label>{dictionary.adminDrugs.patientLeafletEditPage.generalFields.contentAz[lang]}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralFields({
                                            ...generalFields,
                                            whatIsInLaflet: {...generalFields.whatIsInLaflet, az: data}});
                                        }}
                                    data={generalFields.whatIsInLaflet && generalFields.whatIsInLaflet.az ? generalFields.whatIsInLaflet.az : ''} />
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label>{dictionary.adminDrugs.patientLeafletEditPage.generalFields.readCarefullyRu[lang]}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralFields({
                                            ...generalFields,
                                            readCarefully: {...generalFields.readCarefully, ru: data}});
                                        }}
                                    data={generalFields.readCarefully && generalFields.readCarefully.ru ? generalFields.readCarefully.ru : ''} />
                                <Label>{dictionary.adminDrugs.patientLeafletEditPage.generalFields.contentRu[lang]}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralFields({
                                            ...generalFields,
                                            whatIsInLaflet: {...generalFields.whatIsInLaflet, ru: data}});
                                        }}
                                    data={generalFields.whatIsInLaflet && generalFields.whatIsInLaflet.ru ? generalFields.whatIsInLaflet.ru : ''} />
                            </TabPane>
                        </TabContent>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

export default PLGeneralFields;