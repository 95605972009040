import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Ads from "./Ads";
import "./AdsBlock.scss";
import { setForProfessionals } from "../../../redux/actions/actions";
import Modal from "react-bootstrap/Modal";
import { getAdds } from "./../../../redux/actions/ads-actions";

class AdsBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profConfirm: false,
        };
    }

    handleClose = (confirm) => {
        this.setState({ profConfirm: false });
        if (confirm) {
            this.props.setForProfessionals(true);
            this.props.getAdds(true);
        }
    };

    renderProfConfirmation = () => {
        const { lang, dictionary } = this.props;
        const { profConfirm } = this.state;
        return (
            <Modal centered show={profConfirm} onHide={this.handleClose}>
                <Modal.Header className="my-meds-modal-header" closeButton>
                    <Modal.Title className="prof-confirm-title">
                        {dictionary.confirmAction[lang]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <div>{dictionary.confirmProfessional[lang]}</div>
                        <div class="prof-button modal-button-block">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleClose(false)}
                            >
                                {dictionary.back[lang]}
                            </button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleClose(true)}
                            >
                                {dictionary.continue[lang]}
                            </button>
                        </div>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        );
    };
    render() {
        const { dictionary, lang, component, forProfessionals } = this.props;

        return (
            <div className={`sda_block ${this.props.className}`}>
                <Ads size="vertical" />
                <div className="sda_block__geneal">
                    <h3>{dictionary.drugsMainComponent.drugsReview[lang]}</h3>
                    <Link to="/drugs/drugs_and_medications">
                        {dictionary.drugsMainComponent.drugsFromAtoZ[lang]}
                    </Link>

                    {/* <span>{dictionary.drugsMainComponent.medicalEmployees[lang]}</span> */}
                    <Link to="/drugs/side_effects">
                        {dictionary.drugsMainComponent.sideEffectsChecker[lang]}
                    </Link>
                    <Link to="/drugs/news/_/types=news">
                        {dictionary.drugsMainComponent.medicalNews[lang]}
                    </Link>
                    <Link to="/drugs/dosage_recomendations">
                        {
                            dictionary.drugsMainComponent.dosageRecomendations[
                                lang
                            ]
                        }
                    </Link>
                    <Link to="/drugs/news/_/types=alert">
                        {dictionary.drugsMainComponent.FDAWarnings[lang]}
                    </Link>
                    <Link to="/drugs/my_drugs">
                        {dictionary.drugsMainComponent.manageYourDrugs[lang]}
                    </Link>
                    <Link to="/drugs/news/_/types=newdrug">
                        {dictionary.drugsMainComponent.newDrugs[lang]}
                    </Link>
                    <Link to="/drugs/pregnancy_and_lactation">
                        {dictionary.drugsMainComponent.pregnancySections[lang]}
                    </Link>
                </div>
                {/* <Ads size="vertical" /> */}
                {/* {component ? <>{component}<Ads size="vertical" /></> : <></>}
                {this.renderProfConfirmation()} */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    forProfessionals: state.drugReducer.forProfessionals,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setForProfessionals: (forProfessionals) => {
            dispatch(setForProfessionals(forProfessionals));
        },
        getAdds: (forProfessionals) => {
            dispatch(getAdds(forProfessionals));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsBlock);
