import React from "react";
import { connect } from "react-redux";

import "./MyDrugBlock.scss";


class MyDrugBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { dictionary, lang } = this.props;

        return (
            <div className="my_drug_block">
                <div className="text_block">
                    <p className="date">{this.props.date[lang]}</p>
                    <h3 className="title">{this.props.title[lang]}</h3>
                    <p className="content">{this.props.content[lang]}</p>
                </div>
                <div className="buttons_block">
                    <img src={require("./img/eye.png")} className="eye_icon" width={28} height={28} />
                    <img src={require("./img/pen.png")} className="pen_icon" width={32} height={32} />
                    <img src={require("./img/cross.png")} className="cross_icon" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDrugBlock);
