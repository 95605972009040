import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import MiniDropDownSelect from "./form-components/MiniDropDownSelect";
import MiniTextInput from "./form-components/MiniTextInput";
import DrugSelect from "./form-components/DrugSelect";
import "./user-medicines-form.scss";

const renderAutosuggest = (inputProps) => {
    return <DrugSelect inputProps={inputProps} />;
};

class UserMedicinesForm extends React.Component {
    componentWillMount() {
        this.props.patientMedicine && this.props.patientMedicine.selected
            ? this.props.initialize(this.props.patientMedicine.selected)
            : this.props.initialize({ status: 0 });
    }

    render() {
        const { dictionaryReducer, lang, frequencies } = this.props;

        const medicineStatusItems = [
            { value: "continue", label: dictionaryReducer.myDrugs.going[lang] },
            {
                value: "finished",
                label: dictionaryReducer.myDrugs.finished[lang],
            },
        ];

        return (
            <div className="auth-form auth-form-flex">
                <div className="auth-form__row">
                    <div className="auth-form__col">
                        <Field
                            name="medical_product"
                            label={dictionaryReducer.myDrugs.name[lang]}
                            placeholder={
                                dictionaryReducer.myDrugs.typeDrug[lang]
                            }
                            component={renderAutosuggest}
                        />
                    </div>
                    <div className="auth-form__col">
                        <Field
                            name="startDate"
                            type="date"
                            placeholder={dictionaryReducer.myDrugs.start[lang]}
                            component={MiniTextInput}
                        />
                    </div>
                </div>
                <div className="auth-form__row">
                    <div className="auth-form__col">
                        <Field
                            name="dose"
                            placeholder={dictionaryReducer.myDrugs.dosage[lang]}
                            component={MiniTextInput}
                        />
                    </div>
                    <div className="auth-form__col">
                        <Field
                            name="endDate"
                            type="date"
                            placeholder={dictionaryReducer.myDrugs.end[lang]}
                            component={MiniTextInput}
                        />
                    </div>
                </div>
                <div className="auth-form__row">
                    <div className="auth-form__col">
                        <Field
                            className=" new-drug-modal-drop"
                            name="frequency"
                            label={dictionaryReducer.myDrugs.freq[lang]}
                            component={MiniDropDownSelect}
                            valuesForDropdown={frequencies.map((d) => ({
                                value: d.id,
                                label: d[lang] || d.az,
                            }))}
                        />
                    </div>
                    <div className="auth-form__col">
                        <Field
                            className=" new-drug-modal-drop"
                            name="status"
                            label={dictionaryReducer.myDrugs.status[lang]}
                            component={MiniDropDownSelect}
                            valuesForDropdown={medicineStatusItems}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (values, props) => {
    const { dictionaryReducer, lang } = props;
    const errors = {};
    const { t } = props;
    if (!values.medical_product) {
        errors.medical_product = dictionaryReducer.myDrugs.noEmpty[lang];
    }
    if (!values.startDate) {
        errors.startDate = dictionaryReducer.myDrugs.noEmpty[lang];
    }
    if (!values.dose ) {
        errors.dose = dictionaryReducer.myDrugs.noEmpty[lang];
    }
    if (!values.endDate) {
        errors.endDate = dictionaryReducer.myDrugs.noEmpty[lang];
    }
    if (!values.frequency && values.frequency !== 0) {
        errors.frequency = dictionaryReducer.myDrugs.noEmpty[lang];
    }
    if (!values.status) {
        errors.status = dictionaryReducer.myDrugs.noEmpty[lang];
    }
    if (values.endDate && values.endDate < values.startDate) {
        errors.endDate = "Bitmə tarixi, başlama tarixinən tez ola bilməz";
    }

    return errors;
};

const form = reduxForm({
    form: "UserMedicinesForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(UserMedicinesForm);

const selector = formValueSelector("UserMedicinesForm");

const mapStateToProps = (state) => {
    const { type, status, frequency, startDate, endDate, medical_product } =
        selector(
            state,
            "type",
            "status",
            "frequency",
            "startDate",
            "endDate",
            "medical_product"
        ) || {};
    // console.log('form', state.form);
    return {
        patientMedicine: state.patientMedicine,
        status,
        frequency,
        type,
        startDate,
        endDate,
        medical_product,
        dictionaryReducer: state.dictionaryReducer,
        lang: state.languageReducer.language,
        frequencies: state.authReducer.frequencies,
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(form));
