import React from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    CardBody,
} from "reactstrap";
import classnames from "classnames";

import { connect } from "react-redux";

import "./Warnings.scss";
import { Link } from "react-router-dom";

import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";

class Warnings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
        };
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <div className="warnings_page">
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 main-title">
                        {dictionary.warnings.title[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="warnings_body">
                    <p>{dictionary.newDrugs.decription[lang]}</p>
                    {/* <p>
                        <span>{dictionary.newDrugs.viewAlso[lang]} </span>
                        <Link to="/drugs/aem_approval">
                            {dictionary.newDrugs.FDAApproval[lang]}
                        </Link>
                    </p>
                    <p>
                        {dictionary.newDrugs.receiveNews[lang]}{" "}
                        <Link to="/drugs/receive_news_email">
                            {dictionary.newDrugs.email[lang]}
                        </Link>{" "}
                        {dictionary.newDrugs.orSubscribe[lang]}{" "}
                        <Link to="/drugs/receive_news_email">
                            {dictionary.newDrugs.newsFeed[lang]}
                        </Link>
                        .
                    </p> */}
                    <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "1",
                                })}
                                onClick={() => {
                                    this.toggle("1");
                                }}
                            >
                                {dictionary.newDrugs.allNews[lang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "2",
                                })}
                                onClick={() => {
                                    this.toggle("2");
                                }}
                            >
                                {dictionary.newDrugs.newDrugs[lang]}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "3",
                                })}
                                onClick={() => {
                                    this.props.history.push("/drugs/trial");
                                    this.toggle("3");
                                }}
                            >
                                {dictionary.newDrugs.clinicalTrials[lang]}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Row>
                        <Col sm="12">
                            <SearchField searchTypes="alert"></SearchField>
                        </Col>
                    </Row>
                    <div className="main_content_sda_wrapper">
                        <div className="main_content">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    
                                </TabPane>
                            </TabContent>
                        </div>
                        <AdsBlock/>
                    </div>
                </CardBody>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default connect(mapStateToProps)(Warnings);
