import React from "react";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    NavLink,
    Row,
} from "reactstrap";

import "./AdvancedSearchStyles.scss";
import { letterJsx } from "./advanced-search-container";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { getSuggestions } from "./../../../services/services";

import debounce from "lodash.debounce";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

class AdvancedSearch extends React.Component {
    constructor(props) {
        super(props);
        this.toDrugsByLetter = this.toDrugsByLetter.bind(this);
        this.state = {
            searchType: "allWords",
            entityType: "all",
            suggestions: [],
            value: "",
        };
        this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 400);
    }

    getSearchType() {
        let searchType =
            "";

        if (this.state.activeDropdown === "forConsumers") {
            searchType = "consumer";
        } else if (this.state.activeDropdown === "forProfessionals") {
            searchType = "professional";
        } else if (this.state.activeDropdown === "sideEffects") {
            searchType = "sideeffect";
        } else if (this.state.activeDropdown === "diseases") {
            searchType = "disease";
        } else if (this.state.activeDropdown === "drugs") {
            searchType = "drug";
        } else if (this.state.activeDropdown === "alerts") {
            searchType = "alert";
        } else if (this.state.activeDropdown === "trial") {
            searchType = "trial";
        } else if (this.state.activeDropdown === "news") {
            searchType = "news";
        }

        return `types=${searchType}`;
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        let suggestions = getSuggestions(value, this.props.lang);
        suggestions.then((sugs) => {
            this.setState({
                suggestions: sugs,
            });
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getParams() {
        let params = "";
        if (this.state.searchType === "entire") {
            params += "whole=true";
        } else if (this.state.searchType === "someWords") {
            params += "partial=true";
        }

        if (params !== "") {
            params = "/" + params;
        }

        return params;
    }

    toDrugsByLetter(searchLetter) {
        this.setState({ searchLetter: searchLetter });
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });
    };
    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            this.props.history.push(
                `/drugs/search-results/${this.state.value}/${this.getSearchType()}`)
        }
    }

    render() {
        // console.log(this.props.lettersCount);

        const { searchLetter, suggestions, value } = this.state;
        const {
            dictionary,
            lang,
            advancedSearchFilters1,
            advancedSearchFilters2,
        } = this.props;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: dictionary.search[lang],
            value,
            onChange: this.onChange,
            onKeyDown: this.onKeyDown
        };

        return (
            <>
                <CardHeader className="bg-transparent border-0">
                    <h3 className="main-title-advanced-search">
                        {dictionary.advancedSearch[lang]}
                    </h3>
                </CardHeader>
                <CardBody id="wrapper">
                    <div className="inputs_block">
                        <Autosuggest
                            className="search_input"
                            id="check_field"
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}

                        // onKeyPress={(event) => {
                        //     event.preventDefault();
                        //     if (event.key === "Enter" &&
                        //         this.state.value) {
                        //         alert('123')

                        //         // this.props.history.push(
                        //         //     `/drugs/search-results/${value}/${this.getSearchType()}`
                        //         // );
                        //     }
                        // }}
                        />
                        <NavLink
                            className="search_button"
                            onClick={() => {
                                if (value.trim().length !== 0) {
                                    // console.log(
                                    //     `/drugs/search-results/${value}${this.getParams()}`
                                    // );
                                    this.props.history.push(
                                        `/drugs/search-results/${value}${this.getParams()}`
                                    );
                                }
                            }}
                        >
                            <img
                                src={require("./img/search_icon.webp")}
                                alt="search icon"
                                className="fas"
                            />
                        </NavLink>
                    </div>
                    <div className="selectors_block">
                        <Input
                            type="select"
                            className="main_selector"
                            onChange={(e) => {
                                // console.log(e.target.value);
                                this.setState({
                                    searchType: e.target.value,
                                });
                            }}
                        >
                            {advancedSearchFilters1.map((str) => (
                                <option value={str} key={str}>
                                    {
                                        dictionary.advancedSearchComponent[str][
                                        lang
                                        ]
                                    }
                                </option>
                            ))}
                        </Input>
                        {/*                         <Input type="select" className="results_selector">
                            {advancedSearchFilters2.map((str) => (
                                <option>
                                    {
                                        dictionary.advancedSearchComponent[str][
                                            lang
                                        ]
                                    }
                                </option>
                            ))}
                        </Input> */}
                    </div>

                    <p className="search_by_alphabet_title">
                        {
                            dictionary.advancedSearchComponent.searchByAlphabet[
                            lang
                            ]
                        }
                    </p>
                    <div className="alphabet_button_block alphabet_block">
                        {alphabeticalLoop(
                            lang,
                            letterJsx,
                            this.toDrugsByLetter,
                            searchLetter,
                            this.props.lettersCount
                        )}
                    </div>
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
    advancedSearchFilters1: state.drugReducer.advancedSearchFilters1,
    advancedSearchFilters2: state.drugReducer.advancedSearchFilters2,
});

export default connect(mapStateToProps, null)(AdvancedSearch);
