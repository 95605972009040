import { GET_FREQUENCIES, GET_MY_PREPARATIONS, RESET, RESETED, SET_FIELDS_VALUE } from "../actions/action-types";
const REGISTER = "REGISTER";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const tokenString = localStorage.getItem('az.drugs.auth');

const INITIAL_STATE = {
    header: {
        az: "Qeydiyyat",
        ru: "Регистрация",
    },
    form: {
        professional: {
            label: {
                az: "Tibb isçisiyəm",
                ru: "Я медицинский работник",
            },
        },
        medicalWorkerInfo: {
            specialization: {
                type: "text",
                label: {
                    az: "İxtisasınız",
                    ru: "Ваша специализация",
                },
                defaultValue: {
                    az: "İxtisasınızı seçin",
                    ru: "Выберите специализацию"
                },
                options: [{
                    label: {
                        az: "Azərbaycan Tibb Universiteti",
                        ru: "Азербайджанский Медицинский Университет"
                    },
                    value: "amu"
                }]
            },
            medicalInstitution: {
                type: "text",
                label: {
                    az: "Tibb müəssisəsi",
                    ru: "Медучреждение",
                },
            },
        },
        generalInfo1: {
            firstName: {
                type: "text",
                label: {
                    az: "Adınızı daxil edin",
                    ru: "Введите Имя",
                },
            },
            lastName: {
                type: "text",
                label: {
                    az: "Soyadınızı daxil edin",
                    ru: "Введите Фамилию",
                },
            },
            sex: {
                type: "select",
                label: {
                    az: "Cinsinizi daxil edin",
                    ru: "Введите Ваш пол",
                },
                defaultValue: {
                    az: "Cinsinizi seçin",
                    ru: "Выберите пол",
                },
                options: [{
                        label: {
                            az: "Kişi",
                            ru: "Мужской",
                        },
                        value: "male",
                    },
                    {
                        label: {
                            az: "Qadın",
                            ru: "Женский",
                        },
                        value: "female",
                    },
                ],
            },
            birthdate: {
                type: "date",
                label: {
                    az: "Doğum tarixi",
                    ru: "Дата рождения",
                }
            },
            email: {
                type: "email",
                label: {
                    az: "E-mail ünvanınız",
                    ru: "Ваш e-mail",
                },
            },
        },
        generalInfo2: {
            username: {
                type: "text",
                label: {
                    az: "İstifadəçi adı",
                    ru: "Введите логин",
                },
            },
            password: {
                type: "password",
                label: {
                    az: "Şifrənizi daxil edin",
                    ru: "Введите пароль",
                },
            },
            confirmPassword: {
                type: "password",
                label: {
                    az: "Şifrəni təkrarlayın",
                    ru: "Введите повторный пароль",
                },
            },
        },
        sendNotifications: {
            az: "E-mail vasitəsilə bildiriş göndərmək",
            ru: "Отправлять уведомления по e-mail",
        },
        submitButton: {
            az: "Yadda saxla",
            ru: "Сохранить",
        },
    },
    chooseText: {
        ru: "Выберите как кто вы хотите зарегестрироваться",
        az: "Kim kimi qeydiyyatdan keçmək istədiyinizi seçin",
    },
    asUser: {
        ru: "Как гражданин",
        az: "Vətəndaş kimi",
    },
    asMedicalWorker: {
        ru: "Как медицинский работник",
        az: "Tibb işçisi kimi",
    },
    fieldsValue: {
        professionalChosen: false,
        professional: false,
        sendEmailNotification: false,
        email: "",
        fullName: "",
        firstName: "",
        birthdate: "",
        specialization: "",
        medicalInstitution: "",
        phone: "",
        type: "",
        username: "",
    },
    authData: tokenString && JSON.parse(tokenString),
    myPreparations: undefined,
    frequencies: [],
    resetPass: undefined
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FIELDS_VALUE:
            return {
                ...state,
                ...action.payload,
            };
        case REGISTER:
            localStorage.setItem('az.drugs.auth', JSON.stringify(action.authData));
            return {
                ...state,
                ... { authData: action.authData }
            };
        case SIGN_IN:
            if (action.authData) {
                localStorage.setItem('az.drugs.auth', JSON.stringify(action.authData));
            } else {
                localStorage.removeItem('az.drugs.auth');
            }
            return {
                ...state,
                ... { authData: action.authData }
            };
        case GET_MY_PREPARATIONS:
            return {
                ...state,
                ... { myPreparations: action.payload }
            }
        case GET_FREQUENCIES:
            return {
                ...state,
                ... { frequencies: action.payload }
            }
        case SIGN_OUT:
            localStorage.removeItem('az.drugs.auth');
            return {
                ...state,
                ... { authData: undefined, myPreparations: undefined }
            };
        case RESET:
            return {
                ...state,
                resetPass: action.payload
            }
        case RESETED:
            return {
                ...state,
                resetPass: action.payload
            }
        default:
            return state;
    }
};

export default authReducer;