import React from "react";
import { CardHeader, Row, Col, CardBody } from "reactstrap";
import classnames from "classnames";

import { connect } from "react-redux";

import "./InteractionsChecker.scss";

import { Link } from "react-router-dom";

import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";

class InteractionsChecker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <div className="interactions_checker_page">
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 interactions_checker_title">
                        {
                            dictionary.interactionsChecker
                                .interactionsCheckerTitle[lang]
                        }
                    </h1>
                </CardHeader>
                <CardBody className="interactions_checker_body">
                    {
                        dictionary.interactionsChecker.interactionsCheckerBody[
                        lang
                        ]
                    }
                    <SearchField
                        placeholder={
                            dictionary.interactionsChecker.searchPlaceholder[
                            lang
                            ]
                        }
                        searchTypes=""
                    ></SearchField>
                    {dictionary.interactionsChecker.searchInfo[lang]}
                </CardBody>
                <div className="main_content_sda_wrapper">
                    <div className="main_content">
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 interactions_checker_title">
                                {
                                    dictionary.interactionsChecker
                                        .whatAreInteractionsTitle[lang]
                                }
                            </h1>
                        </CardHeader>
                        <CardBody className="interactions_checker_body">
                            {
                                dictionary.interactionsChecker
                                    .whatAreInteractionsBodyStart[lang]
                            }{" "}
                            <Link to="/drugs/TODO">
                                {" "}
                                {
                                    dictionary.interactionsChecker
                                        .whatAreInteractionsBodyLink[lang]
                                }
                            </Link>
                            {". "}
                            {
                                dictionary.interactionsChecker
                                    .whatAreInteractionsBodyEnd[lang]
                            }
                        </CardBody>
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 interactions_checker_title">
                                {dictionary.interactionsChecker.whatToDoTitle[lang]}
                            </h1>
                        </CardHeader>
                        <CardBody className="interactions_checker_body">
                            {dictionary.interactionsChecker.whatToDoBody[lang]}
                        </CardBody>
                    </div>
                    <AdsBlock></AdsBlock>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default connect(mapStateToProps)(InteractionsChecker);
