import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import "./layouts.scss"


class VDDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isItemActive: new Array((this.props.items || []).length).fill(false),
        }
    }

    render() {
        const { title, text, items } = this.props;
        return(
            <div className='vd-dropdown'> 
                {/* <div className='vd-dropdown__title' onClick={() => this.setState({isActive: !this.state.isActive})}>
                    <p>{title}</p>
                    <p className='vd-dropdown__drop'><FontAwesomeIcon size={'lg'} icon={faCaretDown} /></p>
                </div> */}
                {/* {this.state.isActive && ( */}
                    <div className='vd-dropdown__item'>
                        {this.props.items instanceof Array && this.props.items.map((item, i) => (
                            <Fragment key={i}>
                                <p
                                    onClick={() => {
                                        let tmpArr = this.state.isItemActive;
                                        tmpArr[i] = !tmpArr[i];
                                        this.setState({isItemActive: tmpArr});
                                    }} 
                                    className={this.state.isItemActive[i] ? 'vd-dropdown__item_title item-selected' : 'vd-dropdown__item_title'}>
                                    <FontAwesomeIcon color='#ff6e41' icon={farCheckCircle} /> {item.title}
                                </p> 
                                {this.state.isItemActive[i] && 
                                    <div className='vd-dropdown__item_text' dangerouslySetInnerHTML={{__html: item.text || ''}}/>
                                }
                            </Fragment> 
                        ))}
                    </div>
                {/* )} */}
            </div>
        )
    }

}

export default VDDropdown;