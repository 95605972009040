import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, TabContent, TabPane,
    Label, Input, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';


const PLTermsOfSale = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [terms, setTerms] = useState(patientLeaflet.termsOfSale);
    const [generalText, setGeneralText] = useState(patientLeaflet.termsOfSaleText ? {...patientLeaflet.termsOfSaleText} : {az: '', ru: ''});
    // переключатель языков для полей
    const [languageTab, setTab] = useState('az');
    
    const toggleLang = tab => {if (languageTab !== tab) setTab(tab);}

    const submitButtonHandler = () => {
        props.changeLeaflet({termsOfSale: terms, termsOfSaleText: {...generalText, ru: generalText.ru || generalText.az}});
        props.toggleSave(true);
    }
    
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.generalText[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralText({...generalText, az: data});
                                    }}
                                    data={generalText && generalText.az ? generalText.az : ''} />
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.generalText[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setGeneralText({...generalText, ru: data});
                                    }}
                                    data={generalText && generalText.ru ? generalText.ru : ''} />
                            </TabPane>
                        </TabContent>
                        <Label>{dictionary.adminDrugs.patientLeafletEditPage.termsOfSale.title[lang]}</Label>
                        <Input
                            type='select'
                            id={`terms`}
                            onChange={e => {
                                setTerms(e.target.value);
                            }}>
                            <option defaultValue>{patientLeaflet.termsOfSale ? dictionary.adminDrugs.patientLeafletEditPage.termsOfSale.terms.find(term => term.id === patientLeaflet.termsOfSale)[lang] : 'choose...'}</option>
                            {dictionary.adminDrugs.patientLeafletEditPage.termsOfSale.terms.map(opt => 
                                <option value={opt.id} key={opt.id}>{opt[lang]}</option>
                            )}
                        </Input>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: languageTab === 'az' })}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: languageTab === 'ru' })}
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

export default PLTermsOfSale;