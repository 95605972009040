import React from "react";

import "./SearchResults.scss";
import { connect } from "react-redux";
import SearchList from "../SearchCommon/SearchList";

class SearchResults extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lang, dictionary } = this.props;

        return (
            <SearchList
                params={(this.props.match || {}).params}
                title={dictionary.searchResults[lang]}
                itemRender={(drug) => {
                    return (
                        <div
                            key={drug.source.entityId}
                            className="drug-name-others"
                        >
                            <h2>
                                <a
                                    className="link"
                                    onClick={() => {
                                        if (
                                            drug.source.entityType ===
                                                "alert" ||
                                            drug.source.entityType ===
                                                "trial" ||
                                            drug.source.entityType ===
                                                "newdrug" ||
                                            drug.source.entityType === "news"
                                        ) {
                                            this.props.history.push(
                                                `/drugs/one_news/${drug.source.entityId}/${drug.source.entityType}`
                                            );
                                        } else {
                                            this.props.history.push(
                                                `/drugs/drug-details/${
                                                    drug.source.entityId
                                                }/${
                                                    (
                                                        (this.props.match || {})
                                                            .params || {}
                                                    ).params
                                                }`
                                            );
                                        }
                                    }}
                                >
                                    {
                                        drug.source[
                                            "title" +
                                                {
                                                    az: "Az",
                                                    ru: "Ru",
                                                    en: "En",
                                                }[lang]
                                        ]
                                    }
                                </a>
                            </h2>
                            {this.props.match.params.autoC ? (
                                <p className="text shortened-text">
                                    {
                                        drug.source[
                                            "content" +
                                                {
                                                    az: "Az",
                                                    ru: "Ru",
                                                    en: "En",
                                                }[lang]
                                        ]
                                    }
                                </p>
                            ) : (
                                <p
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                        __html: drug.highlight[
                                            "Content" +
                                                {
                                                    az: "Az",
                                                    ru: "Ru",
                                                    en: "En",
                                                }[lang]
                                        ],
                                    }}
                                ></p>
                            )}
                        </div>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
