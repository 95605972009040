import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { categoriesJsx } from "./categories-container";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { connect } from "react-redux";

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { dictionary, lang } = this.props;

        return (
            <>
                <CardHeader className="bg-white border-0">
                    <h3 className="mb-0">
                        {dictionary.categoriesComponent.mainTitle[lang]}
                    </h3>
                </CardHeader>
                <CardBody>
                    <p>{dictionary.categoriesComponent.description[lang]}</p>
                    {alphabeticalLoop(
                        lang,
                        categoriesJsx,
                        undefined,
                        undefined,
                        this.props.lettersCount
                    )}
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
});

export default connect(mapStateToProps, null)(Categories);
