import config from '../../config';
import {
    GET_MEDICAL_PRODUCTS,
    GET_PATIENT_LEAFLETS,
    EDIT_PATIENT_LEAFLET,
    COUNT_PATIENT_LEAFLETS,
    COUNT_MEDICAL_PRODUCTS,
    GET_INGREDIENTS_BY_ID,
    SEARCH_INGREDIENTS,
    CREATE_PATIENT_LEAFLET,
    GET_EXCIPIENTS_BY_ID,
    SEARCH_EXCIPIENTS,
    GET_COATINGS_BY_ID,
    SEARCH_COATINGS,
    GET_UNDESIRABLE_EFFECTS_BY_IDS,
    SEARCH_BODY_STSTEMS,
    SEARCH_UNDESIRABLE_EFFECTS,
    GET_KIND_OF_SICKS_BY_ID,
    SEARCH_SICKS,
    SEARCH_PL_MEDICAL_PRODUCTS,
    GET_PL_MEDICAL_PRODUCT,
    GET_MANUFACTURERS_BY_ID,
    SEARCH_MANUFACTURER,
    SEARCH_MARKETING_AUTHORISATION,
    GET_MARKETING_AUTHORISATIONS_BY_ID,
    SEARCH_PATIENT_LEAFLETS,
    SEARCH_ATC
} from './action-types';
import { errorAction } from './actions';

const queryHeaders = {
    'Content-type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('usertoken')}` || null
};

// checkNumber
export const isNumber = n => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

// PATIENT LEAFLETS
// create leaflet
const _searchPatientLeaflets = patientLeaflets => ({
    type: SEARCH_PATIENT_LEAFLETS,
    payload: patientLeaflets
});

export const searchPatientLeaflets = string => dispatch => {
    fetch(`${config.url}search-patient-leaflet?_q=${encodeURIComponent(string)}`, {
            method: 'GET',
            headers: queryHeaders
        })
        .then(res => res.json())
        .then(response => {
            if (response.error) {
                console.log('ошибка', response)
                return dispatch(errorAction(response));
            } else {
                dispatch(_countPatientLeaflets(response.length));
                dispatch(_getPatientLeaflets(response));
            }
        })
        .catch(err => console.log('ошибка:', err.message))
}

export const _createLeaflet = leaflet => ({
    type: CREATE_PATIENT_LEAFLET,
    payload: leaflet
});

export const createLeaflet = () => dispatch => (
    fetch(`${config.url}patient-leaflets`, {
        method: 'POST',
        headers: queryHeaders
    })
    .then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response)
            return dispatch(errorAction(response));
        } else return dispatch(_createLeaflet(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//delete leaflet
export const deleteLeaflet = id => dispatch => (
    fetch(`${config.url}patient-leaflets/${id}`, {
        method: 'DELETE',
        headers: queryHeaders
    })
    .then(() => { dispatch(countPatientLeaflets()) })
    .catch(err => console.log('ошибка:', err.message))
);

const _getPatientLeaflets = patientLeaflets => ({
    type: GET_PATIENT_LEAFLETS,
    payload: patientLeaflets
});

export const getPatientLeaflets = start => dispatch => (
    fetch(`${config.url}patient-leaflets?_limit=20&_start=${start}&_sort=created_at:desc`, {
        method: 'GET',
        headers: queryHeaders
    })
    .then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response)
            return dispatch(errorAction(response));
        } else return dispatch(_getPatientLeaflets(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

// edit leaflet
const _editPatientLeaflet = patientLeaflet => ({
    type: EDIT_PATIENT_LEAFLET,
    payload: patientLeaflet
});

export const editPatientLeaflet = (id, patientLeaflet) => dispatch => {
    return fetch(`${config.url}patient-leaflets/${id}`, {
            method: 'PUT',
            headers: queryHeaders,
            body: patientLeaflet ? JSON.stringify(patientLeaflet) : JSON.stringify({})
        })
        .then(res => res.json())
        .then(response => {
            if (response.error) {
                console.log('ошибка', response)
                return dispatch(errorAction(response));
            } else return dispatch(_editPatientLeaflet(response))
        })
        .catch(err => console.log('ошибка:', err.message))
};

// добавить новый ингредиент не существующий в базе
export const addingNewIngredient = (ingredient, patientLeaflet) => dispatch => {
    if (!ingredient.category.ru) ingredient.category.ru = ingredient.category.az;
    if (ingredient.id) return dispatch(editPatientLeaflet(patientLeaflet.id, {
        ingredients: [...patientLeaflet.ingredients, {
            category: ingredient.category ? { az: ingredient.category.az, ru: ingredient.category.ru } : null,
            ingredient: { Caption: ingredient.Caption, id: ingredient.id },
            quantity: isNumber(ingredient.quantity) ? ingredient.quantity : null,
            quantityInterval: !isNumber(ingredient.quantity) ? ingredient.quantity : null,
            unit: ingredient.unit,
        }]
    }));
    else return fetch(`${config.url}ingredients`, {
            method: 'POST',
            headers: queryHeaders,
            body: ingredient ? JSON.stringify({
                name: { az: ingredient.az, ru: ingredient.ru ? ingredient.ru : ingredient.az },
                Caption: ingredient.az
            }) : JSON.stringify({})
        })
        .then(res => res.json())
        .then(response => {
            if (response.error) {
                console.log('ошибка', response)
                return dispatch(errorAction(response));
            } else return fetch(`${config.url}patient-leaflets/${patientLeaflet.id}`, {
                    method: 'PUT',
                    headers: queryHeaders,
                    body: patientLeaflet ?
                        JSON.stringify({
                            ingredients: [...patientLeaflet.ingredients, {
                                category: ingredient.category ? { az: ingredient.category.az, ru: ingredient.category.ru } : null,
                                ingredient: { id: response.id, Caption: response.Caption },
                                quantity: isNumber(ingredient.quantity) ? ingredient.quantity : '',
                                quantityInterval: !isNumber(ingredient.quantity) ? ingredient.quantity : '',
                                unit: ingredient.unit
                            }]
                        }) : JSON.stringify({})
                })
                .then(res2 => res2.json())
                .then(response2 => {
                    if (response2.error) {
                        console.log('ошибка', response2)
                        return dispatch(errorAction(response2));
                    } else return dispatch(_editPatientLeaflet(response2))
                })
                .catch(err => console.log('ошибка:', err.message))
        })
        .catch(err => console.log('ошибка:', err.message))
}

// изменить ингредиент у лефлета на несуществующий в базе
export const editPatientLeafletIngredient = (ingredient, patientLeaflet, ingredientIndex) => dispatch => {
    if (!ingredient.category.ru) ingredient.category.ru = ingredient.category.az;
    if (ingredient.id || ingredient.az === patientLeaflet.ingredients[ingredientIndex].ingredient.Caption) {
        console.log(ingredient);
        let ingArray = patientLeaflet.ingredients;
        ingArray[ingredientIndex] = {
            id: ingArray[ingredientIndex].id,
            category: ingredient.category ? { az: ingredient.category.az, ru: ingredient.category.ru } : null,
            ingredient: { Caption: ingredient.Caption, id: ingredient.id ? ingredient.id : patientLeaflet.ingredients[ingredientIndex].ingredient.id },
            quantity: isNumber(ingredient.quantity) ? ingredient.quantity : null,
            quantityInterval: !isNumber(ingredient.quantity) ? ingredient.quantity : null,
            unit: ingredient.unit,
        };
        console.log(ingArray);
        dispatch(editPatientLeaflet(patientLeaflet.id, {
            ingredients: ingArray
        }));
    } else return fetch(`${config.url}ingredients`, {
            method: 'POST',
            headers: queryHeaders,
            body: ingredient ? JSON.stringify({
                name: { az: ingredient.az, ru: ingredient.ru ? ingredient.ru : ingredient.az },
                Caption: ingredient.az
            }) : JSON.stringify({})
        })
        .then(ingRes => ingRes.json())
        .then(ingResponse => {
            if (ingResponse.error) {
                console.log('ошибка', ingResponse)
                return dispatch(errorAction(ingResponse));
            } else {
                let ingArray = patientLeaflet.ingredients;
                ingArray[ingredientIndex] = {
                    id: ingArray[ingredientIndex].id,
                    category: ingredient.category ? { az: ingredient.category.az, ru: ingredient.category.ru } : null,
                    ingredient: { Caption: ingResponse.Caption, id: ingResponse.id },
                    quantity: isNumber(ingredient.quantity) ? ingredient.quantity : null,
                    quantityInterval: !isNumber(ingredient.quantity) ? ingredient.quantity : null,
                    unit: ingredient.unit,
                };
                return dispatch(editPatientLeaflet(patientLeaflet.id, {
                    ingredients: ingArray
                }));
            }
        })
        .catch(err => console.log('ошибка:', err.message))
}

// count leaflets
const _countPatientLeaflets = leafletsCount => ({
    type: COUNT_PATIENT_LEAFLETS,
    payload: leafletsCount
});

export const countPatientLeaflets = () => dispatch => (
    fetch(`${config.url}patient-leaflets/count`, {
        method: 'GET',
        headers: queryHeaders
    })
    .then(res => res.json())
    .then(response => {
        if (response.error) {
            dispatch(errorAction(response));
            console.log('ошибка', response)
        } else dispatch(_countPatientLeaflets(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

// INGREDIENTS
// get by id
const _getIngredientsById = ingredients => ({
    type: GET_INGREDIENTS_BY_ID,
    payload: ingredients
});

export const getIngredientsById = ids => dispatch => (
    Promise.all(ids.map(id => fetch(`${config.url}ingredients/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })))
    .then(ress => Promise.all(ress.map(res => res.json())))
    .then(responses => {
        if (responses.error) {
            console.log('ошибка', responses)
            return dispatch(errorAction(responses));
        } else return dispatch(_getIngredientsById(responses))
    })
    .catch(err => console.log('ошибка:', err.message))
);

const _searchIngredient = ingredient => ({
    type: SEARCH_INGREDIENTS,
    payload: ingredient
});

export const searchIngredient = string => dispatch => (
    fetch(`${config.url}ingredients?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response)
            return dispatch(errorAction(response));
        } else return dispatch(_searchIngredient(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

// EXCIPIENTS
// get by id
const _getExcipientsById = excipients => ({
    type: GET_EXCIPIENTS_BY_ID,
    payload: excipients
});

export const getExcipientsById = ids => dispatch => (
    Promise.all(ids.map(id => fetch(`${config.url}excipients/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })))
    .then(ress => Promise.all(ress.map(res => res.json())))
    .then(responses => {
        if (responses.error) {
            console.log('ошибка', responses)
            return dispatch(errorAction(responses));
        } else return dispatch(_getExcipientsById(responses))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//search
const _searchExcipients = excipients => ({
    type: SEARCH_EXCIPIENTS,
    payload: excipients
});

export const searchExcipients = string => dispatch => (
    fetch(`${config.url}excipients?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json()).then(response => {
        if (response.error) {
            console.log('ошибка', response)
            return dispatch(errorAction(response));
        } else return dispatch(_searchExcipients(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const addExcipients = (excipients, patientLeaflet) => dispatch => {
    let excArray = patientLeaflet.excipients || [];
    return Promise.all(excipients.map(exc => fetch(`${config.url}excipients?Caption=${exc.excipient.az}`, {
            method: 'GET',
            headers: queryHeaders
        })))
        .then(ress => Promise.all(ress.map(res => res.json())))
        .then(responses => {
            if (responses.error) {
                console.log('ошибка', responses)
                return dispatch(errorAction(responses));
            } else Promise.all(excipients.map(async(exc, index) => {
                    if (responses[index].length > 0) {
                        excArray.push({
                            category: exc.category ? {
                                az: exc.category.az,
                                ru: exc.category.ru
                            } : null,
                            excipient: {
                                id: responses[index][0].id,
                                Caption: responses[index][0].Caption
                            }
                        })
                    } else {
                        await fetch(`${config.url}excipients`, {
                                method: 'POST',
                                headers: queryHeaders,
                                body: JSON.stringify({
                                    Caption: exc.excipient ? exc.excipient.az : null,
                                    name: exc.excipient ? {
                                        az: exc.excipient.az,
                                        ru: exc.excipient.ru
                                    } : null
                                })
                            })
                            .then(eRes => eRes.json())
                            .then(eResult => {
                                if (eResult.error) {
                                    console.log('ошибка', eResult)
                                    return dispatch(errorAction(eResult));
                                } else excArray.push({
                                    category: exc.category ? {
                                        az: exc.category.az,
                                        ru: exc.category.ru
                                    } : null,
                                    excipient: {
                                        id: eResult.id,
                                        Caption: eResult.Caption
                                    }
                                })
                            })
                    }
                }))
                .then(() => dispatch(editPatientLeaflet(patientLeaflet.id, { excipients: [...excArray] })))
                .catch(err => console.log('ошибка:', err.message))
        })
        .catch(err => console.log('ошибка:', err.message))
};

// COATINGS
// get by id
const _getCoatingsById = coatings => ({
    type: GET_COATINGS_BY_ID,
    payload: coatings
});

export const getCoatingsById = ids => dispatch => (
    Promise.all(ids.map(id => fetch(`${config.url}coatings/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })))
    .then(ress => Promise.all(ress.map(res => res.json())))
    .then(responses => {
        if (responses.error) {
            console.log('ошибка', responses)
            return dispatch(errorAction(responses));
        } else return dispatch(_getCoatingsById(responses))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//search
const _searchCoatings = coatings => ({
    type: SEARCH_COATINGS,
    payload: coatings
});

export const searchCoatings = string => dispatch => (
    fetch(`${config.url}coatings?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response)
            return dispatch(errorAction(response));
        } else return dispatch(_searchCoatings(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const addCoatings = (coatings, patientLeaflet) => dispatch => {
    let coatArray = patientLeaflet.coatings || [];
    return Promise.all(coatings.map(exc => fetch(`${config.url}coatings?Caption=${exc.coating.az}`, {
            method: 'GET',
            headers: queryHeaders
        })))
        .then(ress => Promise.all(ress.map(res => res.json())))
        .then(responses => {
            if (responses.error) {
                console.log('ошибка', responses)
                return dispatch(errorAction(responses));
            } else Promise.all(coatings.map(async(exc, index) => {
                    if (responses[index].length > 0) {
                        coatArray.push({
                            category: exc.category ? {
                                az: exc.category.az,
                                ru: exc.category.ru
                            } : null,
                            coating: {
                                id: responses[index][0].id,
                                Caption: responses[index][0].Caption
                            }
                        })
                    } else {
                        await fetch(`${config.url}coatings`, {
                                method: 'POST',
                                headers: queryHeaders,
                                body: JSON.stringify({
                                    Caption: exc.coating ? exc.coating.az : null,
                                    name: exc.coating ? {
                                        az: exc.coating.az,
                                        ru: exc.coating.ru
                                    } : null
                                })
                            })
                            .then(eRes => eRes.json())
                            .then(eResult => {
                                coatArray.push({
                                    category: exc.category ? {
                                        az: exc.category.az,
                                        ru: exc.category.ru
                                    } : null,
                                    coating: {
                                        id: eResult.id,
                                        Caption: eResult.Caption
                                    }
                                })
                            })
                    }
                }))
                .then(() => dispatch(editPatientLeaflet(patientLeaflet.id, { coatings: [...coatArray] })))
                .catch(err => console.log('ошибка:', err.message))
        })
        .catch(err => console.log('ошибка:', err.message))
};

//UNDESIRABLE EFFECTS
const _getUndesirableEffectsByIds = undesirableEffects => ({
    type: GET_UNDESIRABLE_EFFECTS_BY_IDS,
    payload: undesirableEffects
});

export const getUndesirableEffectsByIds = (ids, sideEffects) => dispatch => {
    let resObj = {};
    return Promise.all(ids.map((id, key) => fetch(`${config.url}undesirable-effects/${id.effect}`, {
                method: 'GET',
                headers: queryHeaders
            })
            .then(res1 => res1.json())
            .then(uEffect => {
                if (uEffect.error) {
                    console.log('ошибка', uEffect)
                    return dispatch(errorAction(uEffect));
                } else {
                    // группировка по body system
                    if (!uEffect.body_system) {
                        if (!resObj['empty']) resObj['empty'] = { undesirableEffects: [{...uEffect, frequency: sideEffects[key].frequency }] };
                        else resObj['empty'] = { undesirableEffects: [...resObj['empty'].undesirableEffects, {...uEffect, frequency: sideEffects[key].frequency }] };
                    } else {
                        if (!resObj[uEffect.body_system.id]) resObj[uEffect.body_system.id] = {
                            text: { az: uEffect.body_system.text.az, ru: uEffect.body_system.text.ru },
                            undesirableEffects: [{...uEffect, frequency: sideEffects[key].frequency }]
                        };
                        else {
                            resObj[uEffect.body_system.id] = {
                                text: { az: uEffect.body_system.text.az, ru: uEffect.body_system.text.ru },
                                undesirableEffects: [...resObj[uEffect.body_system.id].undesirableEffects, {...uEffect, frequency: sideEffects[key].frequency }]
                            }
                        }
                    }
                }
            })
            .catch(err => console.log('ошибка:', err.message))
        ))
        .then(() => { dispatch(_getUndesirableEffectsByIds(resObj)) })
        .catch(err => console.log('ошибка:', err.message))
}

//search
const _searchBodySystem = bodySystems => ({
    type: SEARCH_BODY_STSTEMS,
    payload: bodySystems
});

export const searchBodySystem = string => dispatch => (
    fetch(`${config.url}body-systems?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_searchBodySystem(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

const _searchUndesirableEffect = undesirableEffects => ({
    type: SEARCH_UNDESIRABLE_EFFECTS,
    payload: undesirableEffects
})

export const searchUndesirableEffect = string => dispatch => (
    fetch(`${config.url}undesirable-effects?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response)
            return dispatch(errorAction(response));
        } else return dispatch(_searchUndesirableEffect(response))
    })
    .catch(err => console.log('ошибка:', err.message))
)

// add undesirable effects
export const addUndesirableEffects = (undesirableEffects, bodySystem, patientLeaflet) => async dispatch => {
    let bSystemId;
    if (bodySystem.az === '' && bodySystem.id === null) bodySystem = null;
    if (bodySystem && !bodySystem.id && bodySystem.az !== '') {
        await fetch(`${config.url}body-systems`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    Caption: bodySystem.az,
                    text: { az: bodySystem.az, ru: bodySystem.ru || bodySystem.az }
                })
            }).then(resBs => resBs.json())
            .then(responseBs => {
                if (responseBs.error) {
                    console.log('ошибка', responseBs)
                    return dispatch(errorAction(responseBs));
                } else bSystemId = responseBs.id;
            })
            .catch(err => console.log('ошибка:', err.message))
    } else if (bodySystem && bodySystem.id) bSystemId = bodySystem.id;
    else if (!bodySystem || !bodySystem.id) bSystemId = null;

    return Promise.all(undesirableEffects.map(uEffect => fetch(`${config.url}undesirable-effects?Caption=${uEffect.az}&body_system=${bSystemId}`, {
            method: 'GET',
            headers: queryHeaders
        })))
        .then(ress => Promise.all(ress.map(res => res.json())))
        .then(responses => {
            let ueArray = patientLeaflet.possibleSideEffects || [];
            Promise.all(undesirableEffects.map((uEffect, index) => {
                    if (responses[index].length > 0) {
                        ueArray.push({
                            undesirable_effect: {
                                id: responses[index][0].id,
                                Caption: uEffect.az,
                                body_system: responses[index][0].body_system ? responses[index][0].body_system.id : null
                            },
                            frequency: uEffect.frequency
                        });
                    } else {
                        return fetch(`${config.url}undesirable-effects`, {
                                method: 'POST',
                                headers: queryHeaders,
                                body: JSON.stringify({
                                    Caption: uEffect.az,
                                    text: {
                                        az: uEffect.az,
                                        ru: uEffect.ru
                                    },
                                    body_system: bSystemId
                                })
                            })
                            .then(uRes => uRes.json())
                            .then(uResult => {
                                ueArray.push({
                                    undesirable_effect: {
                                        id: uResult.id,
                                        Caption: uEffect.az,
                                        body_system: bSystemId
                                    },
                                    frequency: uEffect.frequency
                                });
                            })
                    }
                }))
                .then(() => {
                    return fetch(`${config.url}patient-leaflets/${patientLeaflet.id}`, {
                            method: 'PUT',
                            headers: queryHeaders,
                            body: JSON.stringify({
                                possibleSideEffects: [...ueArray]
                            })
                        })
                        .then(r => r.json())
                        .then(rjson => dispatch(_editPatientLeaflet(rjson)))
                        .catch(err => console.log('ошибка:', err.message))
                })
                .catch(err => console.log('ошибка:', err.message))
        })
        .catch(err => console.log('ошибка:', err.message))
};

// изменить сторонний эффект у лефлета
export const editPatientLeafletUndesirableEffect = (undesirableEffect, patientLeaflet, effectIndex) => async dispatch => {
    let bSystemId;
    let { bodySystem } = undesirableEffect;
    let uEffectId;
    const { oldBodySystem } = undesirableEffect;
    // на случай, если не тронули бади систем
    if (bodySystem.az === oldBodySystem.az) bodySystem.id = oldBodySystem.id;

    if (bodySystem.az === '') bSystemId = null;
    else if (!bodySystem.id) {
        await fetch(`${config.url}body-systems`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    Caption: bodySystem.az,
                    text: { az: bodySystem.az, ru: bodySystem.ru || bodySystem.az }
                })
            })
            .then(resBs => resBs.json())
            .then(responseBs => {
                if (responseBs.error) {
                    console.log('ошибка', responseBs);
                    return dispatch(errorAction(responseBs));
                } else bSystemId = responseBs.id;
            })
            .catch(err => console.log('ошибка:', err.message))
    } else bSystemId = bodySystem.id;

    if (undesirableEffect.az === undesirableEffect.oldEffect.az) uEffectId = undesirableEffect.oldEffect.id;

    if (uEffectId) {
        let effectArray = patientLeaflet.possibleSideEffects;
        effectArray[effectIndex] = {
            id: effectArray[effectIndex].id,
            undesirable_effect: {
                Caption: undesirableEffect.az,
                id: uEffectId,
                body_system: bSystemId,
            },
            frequency: undesirableEffect.frequency
        };
        fetch(`${config.url}undesirable-effects/${uEffectId}`, {
                method: 'PUT',
                headers: queryHeaders,
                body: JSON.stringify({ body_system: bSystemId })
            }).then(dispatch(editPatientLeaflet(patientLeaflet.id, { possibleSideEffects: [...effectArray] })))
            .catch(err => console.log('ошибка:', err.message))
    } else {
        fetch(`${config.url}undesirable-effects`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    text: { az: undesirableEffect.az, ru: undesirableEffect.ru || undesirableEffect.az },
                    Caption: undesirableEffect.az,
                    body_system: bSystemId
                })
            }).then(res => res.json()).then(response => {
                if (response.error) {
                    console.log('ошибка', response)
                    return dispatch(errorAction(response));
                } else {
                    let effectArray = patientLeaflet.possibleSideEffects;
                    effectArray[effectIndex] = {
                        id: effectArray[effectIndex].id,
                        undesirable_effect: { Caption: undesirableEffect.az, id: response.id, body_system: bSystemId },
                        frequency: undesirableEffect.frequency
                    };
                    dispatch(editPatientLeaflet(patientLeaflet.id, { possibleSideEffects: [...effectArray] }));
                }
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}

// HOW TO USE
// get by id
const _getSicksById = sicks => ({
    type: GET_KIND_OF_SICKS_BY_ID,
    payload: sicks
});

export const getSicksById = ids => dispatch => (
    Promise.all(ids.map(id => fetch(`${config.url}kind-of-sicks/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })))
    .then(ress => Promise.all(ress.map(res => res.json())))
    .then(responses => {
        if (responses.error) {
            console.log('ошибка', responses);
            return dispatch(errorAction(responses));
        } else return dispatch(_getSicksById(responses))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//search
const _searchSicks = sicks => ({
    type: SEARCH_SICKS,
    payload: sicks
});

export const searchSicks = string => dispatch => (
    fetch(`${config.url}kind-of-sicks?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_searchSicks(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const addingNewSick = (sick, patientLeaflet) => dispatch => {
    if (!sick.howToUse.ru) sick.howToUse.ru = sick.howToUse.az;
    if (sick.kindOfSick.id) return dispatch(editPatientLeaflet(patientLeaflet.id, {
        howToUse: {...patientLeaflet.howToUse,
            kindOfSick: patientLeaflet.howToUse ? [...patientLeaflet.howToUse.kindOfSick, {
                howToUse: sick.howToUse,
                kind_of_sick: { id: sick.kindOfSick.id, Caption: sick.kindOfSick.az }
            }] : [{ howToUse: sick.howToUse, kind_of_sick: { id: sick.kindOfSick.id, Caption: sick.kindOfSick.az } }]
        }
    }));
    else {
        fetch(`${config.url}kind-of-sicks`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({ Caption: sick.kindOfSick.az, name: { az: sick.kindOfSick.az, ru: sick.kindOfSick.ru ? sick.kindOfSick.ru : sick.kindOfSick.az } })
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    console.log('ошибка', response);
                    return dispatch(errorAction(response));
                } else return dispatch(editPatientLeaflet(patientLeaflet.id, {
                    howToUse: {...patientLeaflet.howToUse,
                        kindOfSick: patientLeaflet.howToUse ? [...patientLeaflet.howToUse.kindOfSick, {
                            howToUse: sick.howToUse,
                            kind_of_sick: { id: response.id, Caption: response.Caption }
                        }] : [{
                            howToUse: sick.howToUse,
                            kind_of_sick: { id: response.id, Caption: response.Caption }
                        }]
                    }
                }))
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}

//edit
export const editSick = (index, sick, patientLeaflet) => dispatch => {
    if (sick.kindOfSick.az === sick.oldSick.az) sick.kindOfSick.id = sick.oldSick.id;
    if (!sick.howToUse.ru) sick.howToUse.ru = sick.howToUse.az;

    let kindOfSickArray = patientLeaflet.howToUse.kindOfSick;
    if (sick.kindOfSick.id) {
        kindOfSickArray[index] = {
            howToUse: sick.howToUse,
            kind_of_sick: { id: sick.kindOfSick.id, Caption: sick.kindOfSick.az }
        }
        dispatch(editPatientLeaflet(patientLeaflet.id, {
            howToUse: {...patientLeaflet.howToUse,
                kindOfSick: [...kindOfSickArray]
            }
        }));
    } else {
        fetch(`${config.url}kind-of-sicks`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({ Caption: sick.kindOfSick.az, name: { az: sick.kindOfSick.az, ru: sick.kindOfSick.ru ? sick.kindOfSick.ru : sick.kindOfSick.az } })
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    console.log('ошибка', response);
                    return dispatch(errorAction(response));
                } else {
                    kindOfSickArray[index] = {
                        howToUse: sick.howToUse,
                        kind_of_sick: { id: response.id, Caption: response.Caption }
                    }
                    dispatch(editPatientLeaflet(patientLeaflet.id, {
                        howToUse: {...patientLeaflet.howToUse,
                            kindOfSick: [...kindOfSickArray]
                        }
                    }))
                }
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}

//MEDICAL PRODUCT
//get
const _getPLMedicalProduct = medicalProduct => ({
    type: GET_PL_MEDICAL_PRODUCT,
    payload: medicalProduct
});

export const getPLMedicalProduct = id => dispatch => (
    fetch(`${config.url}medical-products/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })
    .then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_getPLMedicalProduct(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//search
const _searchPLMedicalProducts = medicalProducts => ({
    type: SEARCH_PL_MEDICAL_PRODUCTS,
    payload: medicalProducts
});

export const searchPLMedicalProducts = string => dispatch => (
    fetch(`${config.url}medical-products?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    })
    .then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_searchPLMedicalProducts(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const updateMedicalProduct = (medicalProduct, patientLeaflet) => dispatch => {
    if (medicalProduct.id) fetch(`${config.url}medical-products/${medicalProduct.id}`, {
            method: 'PUT',
            headers: queryHeaders,
            body: JSON.stringify({ patient_leaflet: patientLeaflet.id })
        })
        .then(res => res.json())
        .then(response => {
            if (response.error) {
                console.log('ошибка', response);
                return dispatch(errorAction(response));
            } else return dispatch(editPatientLeaflet(patientLeaflet.id, { medical_product: response.id }))
        })
        .catch(err => console.log('ошибка:', err.message))
    else fetch(`${config.url}medical-products`, {
            method: 'POST',
            headers: queryHeaders,
            body: JSON.stringify({ Caption: medicalProduct.az, name: [{ az: medicalProduct.az, ru: medicalProduct.ru || medicalProduct.az }], patient_leaflet: patientLeaflet.id })
        })
        .then(res => res.json())
        .then(response => {
            if (response.error) {
                console.log('ошибка', response);
                return dispatch(errorAction(response));
            } else return dispatch(editPatientLeaflet(patientLeaflet.id, { medical_product: response.id }))
        })
        .catch(err => console.log('ошибка:', err.message))
}

// MANUFACTURER
// get by id
const _getManufacturersById = manufacturers => ({
    type: GET_MANUFACTURERS_BY_ID,
    payload: manufacturers
});

export const getManufacturersById = ids => dispatch => (
    Promise.all(ids.map(id => fetch(`${config.url}manufacturers/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })))
    .then(ress => Promise.all(ress.map(res => res.json())))
    .then(responses => {
        if (responses.error) {
            console.log('ошибка', responses);
            return dispatch(errorAction(responses));
        } else return dispatch(_getManufacturersById(responses))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//search
const _searchManufacturers = manufacturers => ({
    type: SEARCH_MANUFACTURER,
    payload: manufacturers
});

export const searchManufacturers = string => dispatch => (
    fetch(`${config.url}manufacturers?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    }).then(res => res.json()).then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_searchManufacturers(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const addingNewManufacturer = (manufacturer, patientLeaflet) => dispatch => {
    if (manufacturer.id) return dispatch(editPatientLeaflet(patientLeaflet.id, {
        manufacturer: [...patientLeaflet.manufacturer, {
            manufacturer: { id: manufacturer.id, Caption: manufacturer.az, number: manufacturer.number }
        }]
    }));
    else {
        fetch(`${config.url}manufacturers`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    Caption: manufacturer.az,
                    name: { az: manufacturer.az, ru: manufacturer.ru || manufacturer.az },
                    country: {...manufacturer.country, ru: manufacturer.country.ru || manufacturer.country.az },
                    address: {...manufacturer.address, ru: manufacturer.address.ru || manufacturer.address.az },
                    number: manufacturer.number,
                    otherInformation: {...manufacturer.otherInformation, ru: manufacturer.otherInformation.ru || manufacturer.otherInformation.az }
                })
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    console.log('ошибка', response);
                    return dispatch(errorAction(response));
                } else return dispatch(editPatientLeaflet(patientLeaflet.id, {
                    manufacturer: [...patientLeaflet.manufacturer, {
                        manufacturer: { id: response.id, Caption: response.Caption, number: response.number }
                    }]
                }))
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}

//edit
export const editManufacturer = (index, manufacturer, patientLeaflet) => dispatch => {
    if (manufacturer.az === manufacturer.old.az) manufacturer.id = manufacturer.old.id;

    let manufacturerArray = patientLeaflet.manufacturer;
    if (manufacturer.id) {
        manufacturerArray[index] = {
            ...manufacturerArray[index],
            manufacturer: { id: manufacturer.id, Caption: manufacturer.az, number: manufacturer.number }
        }
        dispatch(editPatientLeaflet(patientLeaflet.id, {
            manufacturer: [...manufacturerArray]
        }));
    } else {
        fetch(`${config.url}manufacturers`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    Caption: manufacturer.az,
                    name: { az: manufacturer.az, ru: manufacturer.ru || manufacturer.az },
                    country: {...manufacturer.country, ru: manufacturer.country.ru || manufacturer.country.az },
                    address: {...manufacturer.address, ru: manufacturer.address.ru || manufacturer.address.az },
                    number: manufacturer.number,
                    otherInformation: {...manufacturer.otherInformation, ru: manufacturer.otherInformation.ru || manufacturer.otherInformation.az }
                })
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    console.log('ошибка', response);
                    return dispatch(errorAction(response));
                } else {
                    manufacturerArray[index] = {
                        ...manufacturerArray[index],
                        manufacturer: { id: response.id, Caption: response.Caption, number: response.number }
                    }
                    dispatch(editPatientLeaflet(patientLeaflet.id, { manufacturer: [...manufacturerArray] }))
                }
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}


// MARKETING_AUTHORISATION
// get by id
const _getMarketingAuthorisationsById = marketingAuthorisations => ({
    type: GET_MARKETING_AUTHORISATIONS_BY_ID,
    payload: marketingAuthorisations
});

export const getMarketingAuthorisationsById = ids => dispatch => (
    Promise.all(ids.map(id => fetch(`${config.url}marketing-authorisations/${id}`, {
        method: 'GET',
        headers: queryHeaders
    })))
    .then(ress => Promise.all(ress.map(res => res.json())))
    .then(responses => {
        if (responses.error) {
            console.log('ошибка', responses);
            return dispatch(errorAction(responses));
        } else return dispatch(_getMarketingAuthorisationsById(responses))
    })
    .catch(err => console.log('ошибка:', err.message))
);

//search
const _searchMarketingAuthorisations = marketingAuthorisations => ({
    type: SEARCH_MARKETING_AUTHORISATION,
    payload: marketingAuthorisations
});

export const searchMarketingAuthorisations = string => dispatch => (
    fetch(`${config.url}marketing-authorisations?Caption_contains=${encodeURIComponent(string)}`, {
        method: 'GET',
        headers: queryHeaders
    })
    .then(res => res.json())
    .then(response => {
        if (response.error) {
            console.log('ошибка', response);
            return dispatch(errorAction(response));
        } else return dispatch(_searchMarketingAuthorisations(response))
    })
    .catch(err => console.log('ошибка:', err.message))
);

export const addingNewMarketingAuthorisation = (marketingAuthorisation, patientLeaflet) => dispatch => {
    if (marketingAuthorisation.id) return dispatch(editPatientLeaflet(patientLeaflet.id, {
        marketingAuthorisation: [...patientLeaflet.marketingAuthorisation, {
            marketing_authorisation: {
                id: marketingAuthorisation.id,
                Caption: marketingAuthorisation.az,
                authorisationNumber: marketingAuthorisation.authorisationNumber
            }
        }]
    }));
    else {
        fetch(`${config.url}marketing-authorisations`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    Caption: marketingAuthorisation.az,
                    name: { az: marketingAuthorisation.az, ru: marketingAuthorisation.ru || marketingAuthorisation.az },
                    holder: {...marketingAuthorisation.holder, ru: marketingAuthorisation.holder.ru || marketingAuthorisation.holder.az },
                    authorisationNumber: marketingAuthorisation.authorisationNumber
                })
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    console.log('ошибка', response);
                    return dispatch(errorAction(response));
                } else return dispatch(editPatientLeaflet(patientLeaflet.id, {
                    marketingAuthorisation: [...patientLeaflet.marketingAuthorisation, {
                        marketing_authorisation: {
                            id: response.id,
                            Caption: response.Caption,
                            authorisationNumber: response.authorisationNumber
                        }
                    }]
                }))
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}

//edit
export const editMarketingAuthorisation = (index, marketingAuthorisation, patientLeaflet) => dispatch => {
    if (marketingAuthorisation.az === marketingAuthorisation.old.az) marketingAuthorisation.id = marketingAuthorisation.old.id;

    let marketingAuthorisationArray = patientLeaflet.marketingAuthorisation;
    if (marketingAuthorisation.id) {
        marketingAuthorisationArray[index] = {
            ...marketingAuthorisationArray[index],
            marketing_authorisation: {
                id: marketingAuthorisation.id,
                Caption: marketingAuthorisation.az,
                authorisationNumber: marketingAuthorisation.authorisationNumber
            }
        }
        dispatch(editPatientLeaflet(patientLeaflet.id, {
            marketingAuthorisation: [...marketingAuthorisationArray]
        }));
    } else {
        fetch(`${config.url}marketing-authorisations`, {
                method: 'POST',
                headers: queryHeaders,
                body: JSON.stringify({
                    Caption: marketingAuthorisation.az,
                    name: { az: marketingAuthorisation.az, ru: marketingAuthorisation.ru || marketingAuthorisation.az },
                    holder: {...marketingAuthorisation.holder, ru: marketingAuthorisation.holder.ru || marketingAuthorisation.holder.az },
                    authorisationNumber: marketingAuthorisation.authorisationNumber
                })
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    console.log('ошибка', response);
                    return dispatch(errorAction(response));
                } else {
                    marketingAuthorisationArray[index] = {
                        ...marketingAuthorisationArray[index],
                        marketing_authorisation: {
                            id: response.id,
                            Caption: response.Caption,
                            authorisationNumber: response.authorisationNumber
                        }
                    }
                    dispatch(editPatientLeaflet(patientLeaflet.id, {
                        marketingAuthorisation: [...marketingAuthorisationArray]
                    }))
                }
            })
            .catch(err => console.log('ошибка:', err.message))
    }
}

//ATC code search
export const searchATC = string => async dispatch => {
    try {
        const res = await fetch(`${config.url}atc-codes/search?_q=${string}`, {
            method: 'GET',
            headers: queryHeaders
        });
        const response = await res.json();
        console.log('at', response);
        if (response.error) {
            console.error('ошибка', response.error);
            return dispatch(errorAction(response));
        }
        dispatch({
            type: SEARCH_ATC,
            payload: response
        });
    } catch (err) {
        return dispatch(errorAction(err.message));
    }
}