import React from "react";

export const letterJsx = (
    letter,
    method,
    selectedLetter,
    lang,
    lettersCount
) => {
    let currentLetter = "";
    if (selectedLetter) {
        if (selectedLetter !== "0-9") {
            currentLetter = selectedLetter.charAt(0) + letter.toLowerCase();
            //if the second letter is 0-9, return empty
            if (letter === "0-9") {
                return <></>;
            }
        } else {
            currentLetter = letter;
        }
    } else {
        currentLetter = letter;
    }

    let highlightButton =
        selectedLetter &&
        selectedLetter.length > 1 &&
        currentLetter === selectedLetter;

    for (let obj of lettersCount) {
        if (obj.language === lang) {
            if (obj.letter === currentLetter.toLocaleUpperCase()) {
                if (obj.quantity && parseInt(obj.quantity) > 0) {
                    return (
                        <input
                            className={
                                highlightButton
                                    ? "alphabet_button alphabet_button_selected"
                                    : "alphabet_button"
                            }
                            type="button"
                            value={currentLetter}
                            key={letter}
                            onClick={() => {
                                {
                                    method(currentLetter);
                                }
                            }}
                        />
                    );
                }
            }
        }
    }

    return (
        <span
            className={
                highlightButton ? "alphabet_button_inactive" : "alphabet_button"
            }
            key={letter}
        >
            {currentLetter}
        </span>
    );
};
