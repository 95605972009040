import React, {useState, useEffect} from 'react';
import {Button, Row, Col, 
    Card, CardHeader, CardBody, 
    Label, Collapse, TabContent, TabPane,
    Input, ListGroup, ListGroupItem,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Nav, NavItem, NavLink, Table} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import LanguageToggler from '../../AdminUtils/LanguageToggler';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {useDispatch, useSelector} from 'react-redux';
import { searchBodySystem, searchUndesirableEffect} from '../../../../redux/actions/adminActions'

const MPIngredients = props => {
    const {lang, dictionary, medicalProduct} = props;
    const [ingredients, setIngredients] = useState((medicalProduct.patient_leaflet || {}).ingredients || []);
    const [details, showDetails] = useState(new Array((medicalProduct.patient_leaflet || []).length).fill(false) || []);
    const [effects, showEffects] = useState(new Array((medicalProduct.patient_leaflet || []).length).fill(false) || []);
    const [languageTab, setTab] = useState('az');
    
    const submitButtonHandler = () => {
        props.changeProduct({
            ingredients: ingredients,
            patientLeafletId: medicalProduct.patient_leaflet.id
        });
        props.toggleSave(true);
    }

    const showIngredientDetails = index => {
        details[index] = !details[index];
        showDetails([...details]);
    }

    const showIngredientEffects = index => {
        effects[index] = !effects[index];
        showEffects([...effects]);
    }

    const addEffectsHandler = () => {
        setIngredients([...ingredients]);
    }

    const deleteHandler = () => {
        setIngredients([...ingredients]);
    }

    return (
        <Row>
            <Col xs='8' className='pt-0 pb-5'>
                {ingredients.map((ingredient, i) => (
                    <IngredientElement
                        lang={lang}
                        key={i}
                        index={i}
                        dictionary={dictionary}
                        ingredient={ingredient}
                        addEffectsHandler={addEffectsHandler}
                        deleteHandler={deleteHandler}
                        details={details}
                        effects={effects}
                        languageTab={languageTab}
                        showIngredientEffects={showIngredientEffects}
                        showIngredientDetails={showIngredientDetails} />
                ))}
            </Col>
            <LanguageToggler languageTab={languageTab} setTab={setTab} />
            <Col xs='2' className='pt-0 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

const IngredientElement = props => {
    const {lang, dictionary, ingredient,
        index, details, effects, showIngredientDetails, 
        showIngredientEffects, languageTab, addEffectsHandler, deleteHandler} = props;

    const [addModal, setAddModal] = useState(false);
    const [bodySystem, setBodySystem] = useState({text: {az: '', ru: ''}});
    const [undesirableEffects, setUndesirableEffects] = useState({az: '', ru: '', frequency: null});
    
    const deleteFunction = effectId => {
        ingredient.undesirableEffects = ingredient.undesirableEffects.filter(e => e.id !== effectId);
        deleteHandler();
    }

    const addFunction = (bs, ue) => {
        const ueAz = ue.az.split(',');
        const ueRu = ue.ru.split(',');
        ueAz.map((e, i) => {
            ingredient.undesirableEffects.push({
                frequency: ue.frequency,
                undesirable_effect: {
                    body_system: bs,
                    text: {
                        az: e.trim(),
                        ru: ((ueRu || [])[i] || '').trim() || e.trim()
                    }
                }
            })
        });
        setAddModal(false);
        addEffectsHandler();
    }

    return (
        <Row className="mt-2">
            <Col>
                <Card>
                    <CardHeader> {ingredient.ingredient.name[lang]} </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <Label> {dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.category[lang]}: {ingredient.category ? ingredient.category[lang] : ''} </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label> {dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.quantity[lang]}: {ingredient.quantity || ingredient.quantityInterval} {ingredient.unit} </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={showIngredientDetails.bind(this, index)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                                    {dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.pharmacologicalProperties[lang]} 
                                    <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
                                </Button>
                                <Collapse isOpen={details[index]}>
                                    <TabContent activeTab={languageTab}>
                                        <TabPane tabId='az'>
                                            <Row>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.pharmacodynamicProperties[lang]} ${dictionary.adminDrugs.az[lang]}`}
                                                    </Label>
                                                    <CKEditor
                                                        editor={InlineEditor}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            ingredient.pharmacologicalProperties = {
                                                                ...ingredient.pharmacologicalProperties, pharmacodynamicProperties:
                                                                ingredient.pharmacologicalProperties ? {
                                                                    ...ingredient.pharmacologicalProperties.pharmacodynamicProperties,
                                                                    az: data
                                                                } : {
                                                                    az: data
                                                                }
                                                            }
                                                        }}
                                                        data={
                                                            ingredient.pharmacologicalProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacodynamicProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacodynamicProperties.az ? 
                                                            ingredient.pharmacologicalProperties.pharmacodynamicProperties.az : ''} />
                                                </Col>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.pharmacokineticProperties[lang]} ${dictionary.adminDrugs.az[lang]}`}
                                                    </Label>
                                                    <CKEditor
                                                        editor={InlineEditor}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            ingredient.pharmacologicalProperties = {
                                                                ...ingredient.pharmacologicalProperties, pharmacokineticProperties:
                                                                ingredient.pharmacologicalProperties ? {
                                                                    ...ingredient.pharmacologicalProperties.pharmacokineticProperties,
                                                                    az: data
                                                                } : {
                                                                    az: data
                                                                }
                                                            }
                                                        }}
                                                        data={
                                                            ingredient.pharmacologicalProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacokineticProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacokineticProperties.az ? 
                                                            ingredient.pharmacologicalProperties.pharmacokineticProperties.az : ''} />
                                                </Col>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.preclinicalSafetyData[lang]} ${dictionary.adminDrugs.az[lang]}`}
                                                    </Label>
                                                    <CKEditor
                                                        editor={InlineEditor}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            ingredient.pharmacologicalProperties = {
                                                                ...ingredient.pharmacologicalProperties, preclinicalSafetyData:
                                                                ingredient.pharmacologicalProperties ? {
                                                                    ...ingredient.pharmacologicalProperties.preclinicalSafetyData,
                                                                    az: data
                                                                } : {
                                                                    az: data
                                                                }
                                                            }
                                                        }}
                                                        data={
                                                            ingredient.pharmacologicalProperties &&
                                                            ingredient.pharmacologicalProperties.preclinicalSafetyData &&
                                                            ingredient.pharmacologicalProperties.preclinicalSafetyData.az ? 
                                                            ingredient.pharmacologicalProperties.preclinicalSafetyData.az : ''} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.undesirableEffects[lang]} ${dictionary.adminDrugs.az[lang]}`}
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId='ru'>
                                            <Row>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.pharmacodynamicProperties[lang]} ${dictionary.adminDrugs.ru[lang]}`}
                                                    </Label>
                                                    <CKEditor
                                                        editor={InlineEditor}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            ingredient.pharmacologicalProperties = {
                                                                ...ingredient.pharmacologicalProperties, pharmacodynamicProperties:
                                                                ingredient.pharmacologicalProperties ? {
                                                                    ...ingredient.pharmacologicalProperties.pharmacodynamicProperties,
                                                                    ru: data
                                                                } : {
                                                                    ru: data
                                                                }
                                                            }
                                                        }}
                                                        data={
                                                            ingredient.pharmacologicalProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacodynamicProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacodynamicProperties.ru ? 
                                                            ingredient.pharmacologicalProperties.pharmacodynamicProperties.ru : ''} />
                                                </Col>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.pharmacokineticProperties[lang]} ${dictionary.adminDrugs.ru[lang]}`}
                                                    </Label>
                                                    <CKEditor
                                                        editor={InlineEditor}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            ingredient.pharmacologicalProperties = {
                                                                ...ingredient.pharmacologicalProperties, pharmacokineticProperties:
                                                                ingredient.pharmacologicalProperties ? {
                                                                    ...ingredient.pharmacologicalProperties.pharmacokineticProperties,
                                                                    ru: data
                                                                } : {
                                                                    ru: data
                                                                }
                                                            }
                                                        }}
                                                        data={
                                                            ingredient.pharmacologicalProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacokineticProperties &&
                                                            ingredient.pharmacologicalProperties.pharmacokineticProperties.ru ? 
                                                            ingredient.pharmacologicalProperties.pharmacokineticProperties.ru : ''} />
                                                </Col>
                                                <Col xs='12'>
                                                    <Label className='mt-2'>
                                                        {`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.preclinicalSafetyData[lang]} ${dictionary.adminDrugs.ru[lang]}`}
                                                    </Label>
                                                    <CKEditor
                                                        editor={InlineEditor}
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            ingredient.pharmacologicalProperties = {
                                                                ...ingredient.pharmacologicalProperties, preclinicalSafetyData:
                                                                ingredient.pharmacologicalProperties ? {
                                                                    ...ingredient.pharmacologicalProperties.preclinicalSafetyData,
                                                                    ru: data
                                                                } : {
                                                                    ru: data
                                                                }
                                                            }
                                                        }}
                                                        data={
                                                            ingredient.pharmacologicalProperties &&
                                                            ingredient.pharmacologicalProperties.preclinicalSafetyData &&
                                                            ingredient.pharmacologicalProperties.preclinicalSafetyData.ru ? 
                                                            ingredient.pharmacologicalProperties.preclinicalSafetyData.ru : ''} />
                                                </Col>
                                            </Row>
                                            <Label className='mt-2'>{`${dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.undesirableEffects[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                        </TabPane>
                                    </TabContent>
                                </Collapse>
                                
                                <Button onClick={showIngredientEffects.bind(this, index)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                                    {dictionary.adminDrugs.medicalProductEditPage.ingredientsPage.undesirableEffects[lang]} 
                                    <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
                                </Button>
                                <Collapse isOpen={effects[index]}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th> {dictionary.adminDrugs.medicalProductEditPage.undesirableEffect.effect[lang]} </th>
                                                <th> {dictionary.adminDrugs.medicalProductEditPage.undesirableEffect.bodySystem[lang]} </th>
                                                <th> {dictionary.adminDrugs.medicalProductEditPage.undesirableEffect.frequency[lang]} </th>
                                                <th> # </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(ingredient.undesirableEffects || []).map(
                                                (undesirableEffect, i) => 
                                                <UndesirableEffectElement
                                                    key={i}
                                                    index={i}
                                                    lang={lang}
                                                    dictionary={dictionary}
                                                    deleteHandler={deleteFunction}
                                                    undesirableEffect={undesirableEffect} />)
                                            }
                                        </tbody>
                                    </Table>
                                    <Button color='primary' onClick={() => setAddModal(true)}> {dictionary.adminDrugs.medicalProductEditPage.undesirableEffect.addNew[lang]} </Button>
                                </Collapse>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Modal isOpen={addModal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.newIngredient[lang]}</ModalHeader>
                <ModalBody>{AddEffectsElement({
                    lang, dictionary, languageTab, bodySystem, setBodySystem,
                    undesirableEffects, setUndesirableEffects})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={() => {addFunction(bodySystem, undesirableEffects)}}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setAddModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

const UndesirableEffectElement = props => {
    const {undesirableEffect, deleteHandler, editHandler, lang} = props;
    
    return (
        <tr>
            <td> {((undesirableEffect.undesirable_effect || {}).text || {})[lang]} </td>
            <td> {(((undesirableEffect.undesirable_effect || {}).body_system || {}).text || {})[lang]} </td>
            <td> {undesirableEffect.frequency} </td>
            <td>
                {/* <FontAwesomeIcon  className='pointer' icon={faEdit} /> */}
                <FontAwesomeIcon onClick={deleteHandler.bind(this, undesirableEffect.id)} className='pointer ml-2' icon={faTrash} /> 
            </td>
        </tr>        
    )
}

const AddEffectsElement = props => {
    const {lang, languageTab, dictionary,
        bodySystem, setBodySystem, undesirableEffects, setUndesirableEffects} = props;
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);
    
    // mapStateToProps
    const {bodySystemSearchResult, errorMessage} = useSelector(state => ({
        bodySystemSearchResult: state.adminReducer.bodySystemSearchResult,
        errorMessage: state.errorReducer
    }));

    const dispatch = useDispatch();

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchBodySystem(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.bodySystem[lang]}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchBodySystem`}
                                        placeholder={dictionary.search[lang]}
                                        value={searchString || ((bodySystem || {}).text || {}).az || ''}
                                        onChange={e => {
                                            setSearchString(e.target.value);
                                            bodySystem.text.az = e.target.value;
                                            setBodySystem(bodySystem);
                                        }} />
                                    <ListGroup>
                                        {suggest && bodySystemSearchResult ? bodySystemSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                setBodySystem(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label className='mt-2'>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.enterUndesirableEffects[lang]}</Label>
                                    <Input
                                        type='textarea'
                                        name='undesirableEffectsAz'
                                        id={`uEffectsAz`}
                                        placeholder={dictionary.search[lang]}
                                        onChange={e => {
                                            undesirableEffects.az = e.target.value;
                                            setUndesirableEffects({...undesirableEffects});
                                        }} />
                                    <Label style={{color: 'red', fontSize: '10pt'}}>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.attentionForEnter[lang]}</Label>
                                    <Label style={{color: 'red', fontSize: '10pt'}}>{errorMessage}</Label>
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label> {dictionary.adminDrugs.patientLeafletEditPage.categoryRu[lang]} </Label>
                                    <Input
                                        type='text'
                                        name='bodySystem'
                                        id={`bodySystemRu`}
                                        placeholder={dictionary.adminDrugs.patientLeafletEditPage.categoryPlaceholder[lang]}
                                        value={bodySystem.text.ru || ''}
                                        onChange={e => {
                                            bodySystem.text.ru = e.target.value;
                                            setBodySystem({...bodySystem});
                                        }}
                                    />
                                    <Label>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.ingredientRu[lang]}</Label>
                                    <Input
                                        type='textarea'
                                        id={`uEffectsRu`}
                                        placeholder=''
                                        onChange={e => {
                                            undesirableEffects.ru = e.target.value;
                                            setUndesirableEffects({...undesirableEffects});
                                        }} />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col xs={3}>
                            <Label for='quantity'>{dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequencyText[lang]}</Label>
                        </Col>
                        <Col xs={9}>
                            <Input
                                type='select'
                                name='unit'
                                id={`frequency`}
                                onChange={e => {
                                    undesirableEffects.frequency = e.target.value;
                                    setUndesirableEffects({...undesirableEffects});
                                }}>
                                <option> {dictionary.optionChoose[lang]} </option>
                                {dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.frequency.map(opt => 
                                    <option value={opt.id} key={opt.id}>{opt[lang]}</option>
                                )}
                            </Input>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default MPIngredients;