import React from "react";
import { CardHeader, CardBody, Nav, NavLink, NavItem } from "reactstrap";
import { connect } from "react-redux";
import "./Classes.scss";
import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";
import { withTranslation } from "react-i18next";
import DrugsCategories from "../Elements/DrugsCategories";
import {
    getATC,
    getICD,
    getCP,
    getATCById,
    getICDById,
    getCPById,
} from "../../../redux/actions/classifications-actions";
import { getSearchResult, PAGE_SIZE } from "../../../redux/actions/actions";
import ClassesTree from "./ClassesTree";
import config from "../../../config";

const searchParams = "nameOnly=1&types=consumer,professional";

class Classes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treeBranch: {},
            foundDrugs: {},
            activeTab: "atc",
            searchString: "",
            activeObject: {},
        };
    }

    componentDidMount() {
        this.props.getATC();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate");
        const { activeObject } = this.state;
        const { lang, getSearchResult, searchResults } = this.props;
        console.log(`~ prevState.activeObject`, prevState.activeObject);
        console.log(`~ activeObject`, activeObject);
        if (
            prevState.activeObject !== activeObject &&
            activeObject.description
        ) {
            getSearchResult(activeObject.description[lang], searchParams);
        }
        if (searchResults && prevProps.searchResults !== searchResults) {
            this.setState({
                foundDrugs: {
                    ...this.state.foundDrugs,
                    [activeObject.code]: (searchResults[0] || {}).EntityId,
                },
            });
        }
    }

    toggle = (type) => {
        if (this.state.activeTab !== type) {
            //this.props.history.push(`/drugs/classes/_/types=${type}`);
            this.setState({
                activeTab: type,
            });
            this.getCodes(type);
        }
    };

    getCodes = (type) => {
        switch (type) {
            case "atc":
                this.props.getATC();
                break;
            case "icd":
                this.props.getICD();
                break;
            case "cp":
                this.props.getCP();
        }
    };

    renderNavItem = (type, label) => (
        <NavItem>
            <NavLink
                className={this.state.activeTab === type && "active"}
                onClick={() => this.toggle(type)}
            >
                {label}
            </NavLink>
        </NavItem>
    );

    render() {
        const {
            lang,
            t,
            classifications: { atcCodes, icdCodes, cpCodes, children },
            searchResults,
        } = this.props;
        const { treeBranch, foundDrugs } = this.state;

        const openHandler = (parentId) => {
            this.setState({ treeBranch: { ...treeBranch, [parentId]: true } });
        };

        const closeHandler = (parentId) => {
            delete treeBranch[parentId];
            this.setState({ treeBranch: treeBranch });
        };

        const searchF = (object) => {
            console.log(`~ searchFObject`, object);
            this.setState((prevS) => {
                console.log(`~ prevS`, prevS);
                return { ...prevS, activeObject: object };
            });
            // return searchResults
        };

        // console.log('searchResults', searchResults)

        // console.log('this.state.activeObject', this.state.activeObject)

        return (
            <div className="classes_page">
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 drugs-header">
                        {t("Pharmacotherapeutic groups")}
                    </h1>
                </CardHeader>
                <CardBody>
                    <Nav tabs className="nav-tabs-custom">
                        {this.renderNavItem("atc", t("ATC"))}
                        {this.renderNavItem("icd", t("Nosology"))}
                        {this.renderNavItem("cp", t("CP"))}
                    </Nav>
                    {this.state.activeTab === "atc" &&
                        atcCodes &&
                        ClassesTree(
                            atcCodes,
                            treeBranch,
                            openHandler,
                            closeHandler,
                            lang,
                            searchF,
                            foundDrugs,
                            searchResults,
                            "atc_codes",
                            this.props.getATCById,
                            children["ATC"],
                            "atcCode"
                        )}
                    {this.state.activeTab === "icd" &&
                        icdCodes &&
                        ClassesTree(
                            icdCodes,
                            treeBranch,
                            openHandler,
                            closeHandler,
                            lang,
                            searchF,
                            foundDrugs,
                            searchResults,
                            "children",
                            this.props.getICDById,
                            children["ICD"],
                            "icd10id"
                        )}
                    {this.state.activeTab === "cp" &&
                        cpCodes &&
                        ClassesTree(
                            cpCodes,
                            treeBranch,
                            openHandler,
                            closeHandler,
                            lang,
                            searchF,
                            foundDrugs,
                            searchResults,
                            "children",
                            this.props.getCPById,
                            children["CP"],
                            "cpclassid"
                        )}
                </CardBody>
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 drugs-header">
                        {t(
                            "Search the Pharmacotherapeutic Groups' Recomendations Database"
                        )}
                    </h1>
                </CardHeader>
                <CardBody className="classes_body search_card">
                    <SearchField searchTypes="class"></SearchField>
                </CardBody>
                {}
                {/* <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 classes_title">
                        {dictionary.classes.infoTitle[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="classes_body">
                    {dictionary.classes.infoBody[lang]}
                </CardBody> */}
                <CardBody>
                    <DrugsCategories />
                </CardBody>
                <div className="main_content_sda_wrapper">
                    <div className="main_content">
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 drugs-header">
                                {t(
                                    "Popular searches by pharmacotherapeutic group"
                                )}
                            </h1>
                        </CardHeader>
                        <CardBody className="classes_body">
                            <PopularRequests type="class" />
                        </CardBody>
                    </div>
                    <AdsBlock></AdsBlock>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    classifications: state.classifications,
    searchResults: state.drugReducer.searchResultArray,
    searchPage: state.drugReducer.searchPage,
    searchTotal: state.drugReducer.searchTotal,
});

const mapDispatchToProps = (dispatch) => ({
    getATC: () => dispatch(getATC()),
    getICD: () => dispatch(getICD()),
    getCP: () => dispatch(getCP()),
    getATCById: (id) => dispatch(getATCById(id)),
    getCPById: (id) => dispatch(getCPById(id)),
    getICDById: (id) => dispatch(getICDById(id)),
    getSearchResult: (searchNameDrug, params, page) =>
        dispatch(getSearchResult(searchNameDrug, params, page)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Classes));
