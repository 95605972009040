import { ERROR } from "../actions/action-types";

const initialState = '';

const errorReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ERROR:
            return payload;
        default:
            return state;
    }
}

export default errorReducer;