import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import config from "../../../config";
import AdsBlock from "../AdsBlock/AdsBlock";
import fdaTemp from "./img/fda.webp";
import { withTranslation } from "react-i18next";
import "./NewsMain.scss";

class NewsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsFirst: [],
            newsSecond: [],
            newsThird: [],
            newsFourth: [],
        };
    }

    componentDidMount = () => {
        this.fetch_events();
    };

    async fetch_events() {
        let request1 = await fetch(
            `${
                config.url
            }search-summary-plains/extended-find?string=&${"types=news"}&limit=${1}&offset=${0}`
        );
        let data1 = await request1.json();
        this.setState({
            newsFirst: data1 || [],
        });

        let request2 = await fetch(
            `${
                config.url
            }search-summary-plains/extended-find?string=&${"types=alert"}&limit=${1}&offset=${0}`
        );
        let data2 = await request2.json();
        this.setState({
            newsSecond: data2 || [],
        });

        let request3 = await fetch(
            `${
                config.url
            }search-summary-plains/extended-find?string=&${"types=trial"}&limit=${1}&offset=${0}`
        );
        let data3 = await request3.json();
        this.setState({
            newsThird: data3 || [],
        });

        let request4 = await fetch(
            `${
                config.url
            }search-summary-plains/extended-find?string=&${"types=newdrug"}&limit=${1}&offset=${0}`
        );
        let data4 = await request4.json();
        this.setState({
            newsFourth: data4 || [],
        });
    }

    render() {
        const { t, lang } = this.props;

        // console.log('this.props.news',this.props.news)

        const newParams = {};
        newParams.params = "types=news,alert,newdrug,trial";
        newParams.id = "";

        return (
            <>
                <div className="news-main">
                    <div className="card-deck ">
                        {this.state.newsFirst.map((n) => (
                            <div className="card mr-4">
                                <img
                                    className="card-img-top"
                                    src={
                                        n.Image
                                            ? `${config.url}${n.Image.url}`
                                            : fdaTemp
                                    }
                                />
                                <div className="card-body ">
                                    <p className="card-text">
                                        <Link
                                            className="news-main__content__title"
                                            to={"/drugs/news/_/types=news"}
                                        >
                                            {t("Medical news")}
                                        </Link>
                                    </p>
                                    <Link
                                        to={`/drugs/one_news/${n.EntityId}`}
                                        className="card-title news-title"
                                    >
                                        {n.TitleAz}
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {this.state.newsSecond.map((n) => (
                            <div className="card mr-4">
                                <img
                                    className="card-img-top"
                                    src={
                                        n.Image
                                            ? `${config.url}${n.Image.url}`
                                            : fdaTemp
                                    }
                                />

                                <div className="card-body news-main__content">
                                    <p className="card-text news-main__content__title">
                                        <Link
                                            className="news-main__content__title"
                                            to={"/drugs/news/_/types=alert"}
                                        >
                                            {t("Alerts AEM")}
                                        </Link>
                                    </p>
                                    <Link
                                        to={`/drugs/one_news/${n.EntityId}`}
                                        className="card-title news-title"
                                    >
                                        {n.TitleAz}
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {/* {this.state.newsFourth.map((n) => (
                        <div className='news-main__block'>
                            <div className='news-main__image'>
                                <img src={n.Image ? `${config.url}${n.Image.url}` : fdaTemp} />
                            </div>
                            <div className='news-main__content'>
                                <p className='news-main__content__title'>
                                    <Link to={'/drugs/news/_/types=newdrug'}>{t('New drugs')}</Link>
                                </p>
                                <Link to={`/drugs/one_news/${n.EntityId}`} className='news-title'>
                                    {n.TitleAz}
                                </Link>
                            </div>
                        </div>
                    ))
                    } */}
                        {this.state.newsThird.map((n) => (
                            <div className="card">
                                <img
                                    className="card-img-top"
                                    src={
                                        n.Image
                                            ? `${config.url}${n.Image.url}`
                                            : fdaTemp
                                    }
                                />

                                <div className="card-body news-main__content">
                                    <p className="card-text news-main__content__title">
                                        <Link
                                            className="news-main__content__title"
                                            to={"/drugs/news/_/types=trial"}
                                        >
                                            {t("Trials")}
                                        </Link>
                                    </p>
                                    <Link
                                        to={`/drugs/one_news/${n.EntityId}`}
                                        className="card-title news-title"
                                    >
                                        {n.TitleAz}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="last-news-main">
                    <div className="last-news-main__news">
                        {this.props.news.map((el) => (
                            <div className="last-news-main__news-block">
                                <div className="last-news-main__news-block__image">
                                    <img
                                        src={
                                            el.image
                                                ? `${config.url}${el.image.url}`
                                                : fdaTemp
                                        }
                                        alt={"fda"}
                                    />
                                </div>
                                <div className="last-news-main__news-block__content">
                                    <Link
                                        to={`/drugs/one_news/${el.id}`}
                                        className="news-title"
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: el.title[lang],
                                            }}
                                        ></div>
                                    </Link>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: el.miniText[lang],
                                        }}
                                    ></div>
                                    <p className="news-date">
                                        {el.Datetime && moment(el.Datetime)
                                            ? moment(el.Datetime).format(
                                                  "DD.MM.YYYY"
                                              )
                                            : t("Empty date")}{" "}
                                        <Link
                                            to={
                                                "/drugs/news/_/types=" +
                                                (el.Type
                                                    ? el.Type.toLowerCase()
                                                    : "")
                                            }
                                            className="link"
                                        >
                                            {t(el.Type)}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <AdsBlock />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
});

export default withTranslation()(connect(mapStateToProps)(NewsMain));
