import React, {useState} from 'react';
import {Col, Row, Button, 
    Collapse, Label, Input} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const MPPharmacological = props => {
    const {lang, dictionary, medicalProduct} = props;
    const [general, setGeneral] = useState(
        (medicalProduct.pharmacologicalProperties || {}).PharmacologicalPropertiesGeneral || {az: '', ru: ''});
    const [group, setGroup] = useState({
        pharmacotherapeuticGroup: (medicalProduct.pharmacologicalProperties || {}).pharmacotherapeuticGroup || {az: '', ru: ''},
        ATCCode: (medicalProduct.pharmacologicalProperties || {}).ATCCode || ''
    });
    const [dynamic, setDynamic] = useState(
        (medicalProduct.pharmacologicalProperties || {}).PharmacodynamicProperties || {az: '', ru: ''}
    );
    const [kinetic, setKinetic] = useState(
        (medicalProduct.pharmacologicalProperties || {}).PharmacokineticProperties || {az: '', ru: ''}
    );
    const [preclinic, setPreclinic] = useState(
        (medicalProduct.pharmacologicalProperties || {}).PreclinicalSafetyData || {az: '', ru: ''}
    );

    const submitButtonHandler = () => {
        props.changeProduct({
            pharmacologicalProperties: {...medicalProduct.pharmacologicalProperties,
                PharmacologicalPropertiesGeneral: general,
                pharmacotherapeuticGroup: group.pharmacotherapeuticGroup,
                ATCCode: group.ATCCode,
                PharmacodynamicProperties: dynamic,
                PharmacokineticProperties: kinetic,
                PreclinicalSafetyData: preclinic
            },
            patientLeafletId: (medicalProduct.patient_leaflet || {}).id
        });
        props.toggleSave(true);
    }

    return (
        <Row>
            <Col xs='12'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
            <RichTextElement
                text={general} setText={setGeneral}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.pharmacologicalPropertiesGeneral} />
            <PharmacoTherapeuticGroupElement
                text={group} setText={setGroup}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.prarmacologicalPropertiesGroup} />
            <RichTextElement
                text={dynamic} setText={setDynamic}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.pharmacodynamicProperties} />
            <RichTextElement
                text={kinetic} setText={setKinetic}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.pharmacokineticProperties} />
            <RichTextElement
                text={preclinic} setText={setPreclinic}
                lang={lang} dictionary={dictionary}
                labelText={dictionary.adminDrugs.medicalProductEditPage.preclinicalSafetyData} />
        </Row>
    )
}

const RichTextElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.az = data;
                                setText({...text});
                            }}
                            data={text.az || ''} />
                    </Col>
                    <Col xs='6'>
                        <Label> {labelText[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <CKEditor
                            editor={InlineEditor}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                text.ru = data;
                                setText({...text});
                            }}
                            data={text.ru || ''} />
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}

const PharmacoTherapeuticGroupElement = props => {
    const {text, setText, lang, dictionary, labelText} = props;
    const [collapse, showCollapse] = useState(false);
    return (
        <Col xs='12'>
            <Button onClick={() => showCollapse(!collapse)} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                {labelText[lang]} 
                <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
            </Button>
            <Collapse isOpen={collapse}>
                <Row className='mt-2 mb-2'>
                    <Col xs='12'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.pharmacologicalPropertiesATC[lang]} </Label>
                        <Input
                            defaultValue={text.ATCCode || ''}
                            onChange={e => {
                                text.ATCCode = e.target.value;
                                setText(text);
                            }} />
                    </Col>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.prarmacologicalPropertiesGroup[lang]} {dictionary.adminDrugs.az[lang]} </Label>
                        <Input
                            defaultValue={(text.pharmacotherapeuticGroup || {}).az || ''}
                            onChange={e => {
                                text.pharmacotherapeuticGroup.az = e.target.value;
                                setText(text);
                            }} />
                    </Col>
                    <Col xs='6'>
                        <Label> {dictionary.adminDrugs.medicalProductEditPage.prarmacologicalPropertiesGroup[lang]} {dictionary.adminDrugs.ru[lang]} </Label>
                        <Input
                            defaultValue={(text.pharmacotherapeuticGroup || {}).ru || ''}
                            onChange={e => {
                                text.pharmacotherapeuticGroup.ru = e.target.value;
                                setText(text);
                            }} />
                    </Col>
                </Row>
            </Collapse>
        </Col>
    )
}

export default MPPharmacological;