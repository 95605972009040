import { LOAD_ADDS } from "./action-types";
import config from '../../config';
import { errorAction, getAlert } from './actions';

const loadAds = ads => ({
    type: LOAD_ADDS,
    payload: ads
});

export const getAdds = (professional) => async dispatch => {
    try {
        const profiFilter = professional ? '?professional=true' : '?professional=false';
        const res = await fetch(`${config.url}ads` + profiFilter);
        const response = await res.json();
        if (response.error) {
            return dispatch(errorAction(response));
        } else {
            // console.log('response', response);
            return dispatch(loadAds(response));
        }
    } catch (err) {
        console.error(err.message);
    }
}
