import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import {
    Button, Col, Row, Nav, NavItem, NavLink,
    Label, Input, TabContent, TabPane, Collapse,
    Card, CardBody, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { searchSicks, getSicksById, addingNewSick, editSick } from '../../../../redux/actions/adminActions';


const PLHowToUse = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [howToUse, setHowToUse] = useState(patientLeaflet.howToUse ? {
        generalText: patientLeaflet.howToUse.generalText ? {...patientLeaflet.howToUse.generalText} : {az: '', ru: ''},
        ifYouAreGivenMore: patientLeaflet.howToUse.ifYouAreGivenMore ? {...patientLeaflet.howToUse.ifYouAreGivenMore} : {az: '', ru: ''},
        useInChildren: patientLeaflet.howToUse.useInChildren ? {...patientLeaflet.howToUse.useInChildren} : {az: '', ru: ''},
        adults: patientLeaflet.howToUse.adults ? {...patientLeaflet.howToUse.adults} : {az: '', ru: ''},
        elderly: patientLeaflet.howToUse.elderly ? {...patientLeaflet.howToUse.elderly} : {az: '', ru: ''},
        using: patientLeaflet.howToUse.using ? {...patientLeaflet.howToUse.using} : {az: '', ru: ''},
        kindOfSick: patientLeaflet.howToUse.kindOfSick ? patientLeaflet.howToUse.kindOfSick : [],
    } : {
        generalText: {az: '', ru: ''}, ifYouAreGivenMore: {az: '', ru: ''}, useInChildren: {az: '', ru: ''},
        adults: {az: '', ru: ''}, elderly: {az: '', ru: ''}, using: {az: '', ru: ''}, kindOfSick: []
    });
    const [languageTab, setTab] = useState('az');
    const [ageGroupOpen, setageGroup] = useState(false);
    // строка для поиска
    const [searchString, setSearchString] = useState(null);
    const [tmpSick, setTmpSick] = useState({
        howToUse: {az: '', ru: ''},
        kindOfSick: {id: null, az: '', ru: ''},
        oldSick: {id: null, az: '', ru: ''}
    });
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [suggest, setSuggest] = useState(false);
    let sickIdsArray = [];
    const defaultTmp = {
        howToUse: {az: '', ru: ''},
        kindOfSick: {id: null, az: '', ru: ''},
        oldSick: {id: null, az: '', ru: ''}
    };

    const usingMultilangText = {
        az: `"${patientLeaflet.title ? patientLeaflet.title.az : patientLeaflet.id}"${dictionary.adminDrugs.patientLeafletEditPage.howToUse.using.az}`,
        ru: `${dictionary.adminDrugs.patientLeafletEditPage.howToUse.using.ru}"${patientLeaflet.title ? patientLeaflet.title.ru : patientLeaflet.id}"`
    }

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {if (languageTab !== tab) setTab(tab)}
    
    const showAgeGroup = () => setageGroup(!ageGroupOpen);

    // mapStateToProps
    const {sicksById, sicksSearchResult} = useSelector(state => ({
        sicksById: state.adminReducer.sicksById,
        sicksSearchResult: state.adminReducer.sicksSearchResult
    }));

    const dispatch = useDispatch();

    // создание массива ингредиентных id
    if (patientLeaflet && patientLeaflet.howToUse && patientLeaflet.howToUse.kindOfSick) {
        patientLeaflet.howToUse.kindOfSick.map(sick => sick.kind_of_sick ? sickIdsArray.push(sick.kind_of_sick.id) : '');
    }

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 2) {
            dispatch(searchSicks(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    useEffect(() => {
        if (patientLeaflet) {
            dispatch(getSicksById(sickIdsArray));
        }
    }, [patientLeaflet]);

    const submitButtonHandler = () => {
        props.changeLeaflet({howToUse: {
            generalText: {...howToUse.generalText, ru: howToUse.generalText.ru ? howToUse.generalText.ru : howToUse.generalText.az},
            ifYouAreGivenMore: {...howToUse.ifYouAreGivenMore, ru: howToUse.ifYouAreGivenMore.ru ? howToUse.ifYouAreGivenMore.ru : howToUse.ifYouAreGivenMore.az},
            useInChildren: {...howToUse.useInChildren, ru: howToUse.useInChildren.ru ? howToUse.useInChildren.ru : howToUse.useInChildren.az},
            adults: {...howToUse.adults, ru: howToUse.adults.ru ? howToUse.adults.ru : howToUse.adults.az},
            elderly: {...howToUse.elderly, ru: howToUse.elderly.ru ? howToUse.elderly.ru : howToUse.elderly.az},
            using: {...howToUse.using, ru: howToUse.using.ru ? howToUse.using.ru : howToUse.using.az},
            kindOfSick: [...howToUse.kindOfSick]
        }});
        props.toggleSave(true);
    };
    
    // кнопка создания нового ингредиента
    // открытие модального окна
    const addSickButtonHandler = () => {
        setTmpSick({
            howToUse: {az: '', ru: ''},
            kindOfSick: {id: null, az: '', ru: ''}
        }); // вычищаем хранилище
        setSearchString(null); // вычищаем поисковую строку
        setModal(true);
    }

    const addNewSick = () => {
        if (tmpSick.kindOfSick.az) {
            dispatch(addingNewSick(tmpSick, patientLeaflet));
            setModal(false);
            //props.toggleSave(true);
        }
    }

    // кнопка редактирования существующего ингредиента
    // открытие модального окна
    const editSickButtonHandler = index => {
        setTmpSick({
            howToUse: {...patientLeaflet.howToUse.kindOfSick[index].howToUse},
            kindOfSick: {...sicksById[index].name, id: null},
            oldSick: {...sicksById[index].name, id: sicksById[index].id}
        }); // присваиваем к хранилищу текущий ингредиент
        setSearchString(null); // вычищаем поисковую строку
        setEditModal(true);
        setActiveIndex(index);
    }

    const editCurrentSick = () => {
        dispatch(editSick(activeIndex, tmpSick, patientLeaflet));
        setEditModal(false);
        setActiveIndex(null);
    }

    // кнопка удаления
    const deleteButtonHandler = index => {
        props.changeLeaflet({
            howToUse: {...patientLeaflet.howToUse, 
                kindOfSick: patientLeaflet.howToUse.kindOfSick.filter((ing, i) => i !== index)}
        });
        props.toggleSave(true);
    };

    const selectSick = sick => {
        setTmpSick({...tmpSick, kindOfSick: {...sick.name, id: sick.id}})
    }

    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                <Row>
                    <Col xs='10'>
                        <TabContent activeTab={languageTab}>
                            <TabPane tabId='az'>
                            <Label className='mt-2'>{`${usingMultilangText[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setHowToUse({
                                            ...howToUse,
                                            using: {
                                                ...howToUse.using,
                                                az: data
                                        }});
                                    }}
                                    data={howToUse.using && howToUse.using.az ? howToUse.using.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.generalText[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setHowToUse({
                                            ...howToUse,
                                            generalText: {
                                                ...howToUse.generalText,
                                                az: data
                                        }});
                                    }}
                                    data={howToUse.generalText && howToUse.generalText.az ? howToUse.generalText.az : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.ifYouAreGivenMore[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setHowToUse({
                                            ...howToUse,
                                            ifYouAreGivenMore: {
                                                ...howToUse.ifYouAreGivenMore,
                                                az: data
                                        }});
                                } }
                                data={howToUse.ifYouAreGivenMore ? howToUse.ifYouAreGivenMore.az : ''} />
                            </TabPane>
                            <TabPane tabId='ru'>
                                <Label className='mt-2'>{`${usingMultilangText[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                <CKEditor
                                    editor={InlineEditor}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setHowToUse({
                                            ...howToUse,
                                            using: {
                                                ...howToUse.using,
                                                ru: data
                                        }});
                                    }}
                                    data={howToUse.using && howToUse.using.ru ? howToUse.using.ru : ''} />
                                <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.generalText[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                generalText: {
                                                    ...howToUse.generalText,
                                                    ru: data
                                            }});
                                        }}
                                        data={howToUse.generalText ? howToUse.generalText.ru : ''} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.ifYouAreGivenMore[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                ifYouAreGivenMore: {
                                                    ...howToUse.ifYouAreGivenMore,
                                                    ru: data
                                            }});
                                    } }
                                    data={howToUse.ifYouAreGivenMore ? howToUse.ifYouAreGivenMore.ru : ''} />
                            </TabPane>
                        </TabContent>
                        <Button onClick={showAgeGroup} className='mt-2' size='lg' block style={{backgroundColor: '#e2e2e2'}}>
                            {dictionary.adminDrugs.patientLeafletEditPage.howToUse.ageGroups[lang]} 
                            <FontAwesomeIcon className='ml-2' icon={faChevronDown} />
                        </Button>
                        <Collapse isOpen={ageGroupOpen}>
                            <TabContent activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.useInChildren[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                useInChildren: {
                                                    ...howToUse.useInChildren,
                                                    az: data
                                            }});
                                        }}
                                        data={howToUse.useInChildren ? howToUse.useInChildren.az : ''} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.adults[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                adults: {
                                                    ...howToUse.adults,
                                                    az: data
                                            }});
                                        }}
                                        data={howToUse.adults ? howToUse.adults.az : ''} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.elderly[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                elderly: {
                                                    ...howToUse.elderly,
                                                    az: data
                                            }});
                                        }}
                                        data={howToUse.elderly ? howToUse.elderly.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.useInChildren[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                useInChildren: {
                                                    ...howToUse.useInChildren,
                                                    ru: data
                                            }});
                                        }}
                                        data={howToUse.useInChildren ? howToUse.useInChildren.ru : ''} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.adults[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                adults: {
                                                    ...howToUse.adults,
                                                    ru: data
                                            }});
                                        }}
                                        data={howToUse.adults ? howToUse.adults.ru : ''} />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.elderly[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setHowToUse({
                                                ...howToUse,
                                                elderly: {
                                                    ...howToUse.elderly,
                                                    ru: data
                                            }});
                                        }}
                                        data={howToUse.elderly ? howToUse.elderly.ru : ''} />
                                </TabPane>
                            </TabContent>
                        </Collapse>
                        {patientLeaflet.howToUse && patientLeaflet.howToUse.kindOfSick ? 
                            patientLeaflet.howToUse.kindOfSick.map((sickI, key) => SickElement({
                                lang, languageTab, dictionary, key, sickI, sickById: sicksById ? sicksById[key] : null,
                                editSickButtonHandler, deleteButtonHandler
                            })) : ''}
                        <Button onClick={addSickButtonHandler} className='mt-2' size='lg' block color='primary'><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.patientLeafletEditPage.howToUse.kindOfSick[lang]} </Button>
                    </Col>
                    <Col xs='2'>
                        <Nav tabs vertical pills className='lang-changer'>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'az' ? 'active' : ''}
                                    onClick={() => { toggleLang('az'); }}>
                                    Az
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={languageTab === 'ru' ? 'active' : '' }
                                    onClick={() => { toggleLang('ru'); }}>
                                    Ru
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>    
            </Col>
            <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
            <Modal isOpen={modal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.howToUse.createSick[lang]}</ModalHeader>
                <ModalBody>{SickAddEditElement({
                    lang, languageTab, dictionary,
                    searchString, setSearchString, 
                    tmpSick, setTmpSick,
                    suggest, selectSick,
                    sicksSearchResult})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={addNewSick}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpSick(defaultTmp); setModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editModal}>
                <ModalHeader>{dictionary.adminDrugs.patientLeafletEditPage.howToUse.createSick[lang]}</ModalHeader>
                <ModalBody>{SickAddEditElement({
                    lang, languageTab, dictionary,
                    searchString, setSearchString, 
                    tmpSick, setTmpSick,
                    suggest, selectSick,
                    sicksSearchResult})}</ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={editCurrentSick}>{dictionary.adminDrugs.patientLeafletEditPage.ingredients.addIngredientButton[lang]}</Button>
                    <Button color='danger' onClick={() => {setTmpSick(defaultTmp); setEditModal(false)}}>{dictionary.adminDrugs.cancelButton[lang]}</Button>
                </ModalFooter>
            </Modal>
        </Row>
    )
}

const SickElement = props => {
    const {lang, languageTab, dictionary, key, sickI, sickById, deleteButtonHandler, editSickButtonHandler} = props;

    return (
        <Row className='mt-2' key={key}>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <FontAwesomeIcon className='float-right pointer' icon={faTrash}
                                onClick={deleteButtonHandler.bind(this, key)}/>
                            <FontAwesomeIcon className='float-right mr-3 pointer' icon={faEdit}
                                onClick={editSickButtonHandler.bind(this, key)} />
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.sick[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchSickAz_${key}`}
                                        placeholder={''}
                                        disabled
                                        value={sickById ? sickById.name.az : ''}
                                    />
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.howToUse[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        disabled
                                        data={sickI ? sickI.howToUse.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.sick[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='text'
                                        id={`sickRu_${key}`}
                                        placeholder=''
                                        disabled
                                        value={sickById ? sickById.name.ru : ''}
                                    /> 
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.howToUse[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        disabled
                                        data={sickI ? sickI.howToUse.ru : ''} />  
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

const SickAddEditElement = props => {
    const {
        lang, languageTab, dictionary,
        searchString, setSearchString, 
        tmpSick, setTmpSick,
        suggest, selectSick,
        sicksSearchResult
    } = props;

    return (
        <Row className='mt-2'>
            <Card className='admin' style={{width: '100%'}}>
                <CardBody>
                    <Row className='pt-2'>
                        <Col xs={12}>
                            <TabContent className='pt-2' activeTab={languageTab}>
                                <TabPane tabId='az'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.sick[lang]}  ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <Input
                                        type='search'
                                        autoComplete='off'
                                        id={`searchSickAz`}
                                        placeholder={dictionary.search[lang]}
                                        value={
                                            searchString !== null ? searchString :
                                            tmpSick.kindOfSick ? tmpSick.kindOfSick.az : ''
                                        }
                                        onChange={e => {
                                            setTmpSick({...tmpSick, kindOfSick: {...tmpSick.kindOfSick, az: e.target.value}});
                                            setSearchString(e.target.value);
                                        }} />
                                    <ListGroup>
                                        {suggest && sicksSearchResult ? sicksSearchResult.map(result => (
                                            <ListGroupItem style={{cursor: 'pointer'}} key={result.id} onClick={() => {
                                                selectSick(result);
                                                setSearchString(null);
                                            }} tag="a" action>{result.Caption}</ListGroupItem>
                                        )) : ''}
                                    </ListGroup>
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.howToUse[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTmpSick({
                                                ...tmpSick,
                                                howToUse: {
                                                    ...tmpSick.howToUse,
                                                    az: data
                                            }});
                                        }}
                                        data={tmpSick.howToUse ? tmpSick.howToUse.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <Label>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.sick[lang]}  ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <Input
                                        type='text'
                                        id={`sickRu`}
                                        placeholder=''
                                        value={tmpSick ? tmpSick.kindOfSick.ru : ''}
                                        onChange={e => {
                                            setTmpSick({...tmpSick, kindOfSick: {...tmpSick.kindOfSick, ru: e.target.value}})
                                        }} /> 
                                    <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.howToUse.howToUse[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                    <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setTmpSick({
                                                ...tmpSick,
                                                howToUse: {
                                                    ...tmpSick.howToUse,
                                                    ru: data
                                            }});
                                        }}
                                        data={tmpSick.howToUse ? tmpSick.howToUse.ru : ''} />  
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default PLHowToUse;