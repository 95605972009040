import config from "../../config";
import {
    GET_ATC,
    GET_ICD,
    GET_CP,
    GET_ATC_ID,
    GET_ICD_ID,
    GET_CP_ID,
} from "./action-types";

const _getATC = (codes) => ({
    type: GET_ATC,
    payload: codes,
});

export const getATC = () => async (dispatch) => {
    return fetch(
        `${config.url}atc-codes?_sort=code:ASC&_limit=10000&parent_null=true`
    )
        .then((res) => res.json())
        .then((result) => dispatch(_getATC(result)));
};

const _getATCById = (codes) => ({
    type: GET_ATC_ID,
    payload: codes,
});

export const getATCById = (id) => async (dispatch) => {
    return fetch(
        `${config.url}atc-codes?_sort=code:ASC&_limit=10000&parent=${id}`
    )
        .then((res) => res.json())
        .then((result) => dispatch(_getATCById({ id, children: result })));
};

const _getICD = (codes) => ({
    type: GET_ICD,
    payload: codes,
});

export const getICD = () => async (dispatch) => {
    return fetch(
        `${config.url}icd-10-codes?_sort=code:ASC&_limit=10000&parent_null=true`
    )
        .then((res) => res.json())
        .then((result) => dispatch(_getICD(result)));
};

const _getICDById = (codes) => ({
    type: GET_ICD_ID,
    payload: codes,
});

export const getICDById = (id) => async (dispatch) => {
    return fetch(
        `${config.url}icd-10-codes?_sort=code:ASC&_limit=10000&parent=${id}`
    )
        .then((res) => res.json())
        .then((result) => dispatch(_getICDById({ id, children: result })));
};

const _getCP = (codes) => ({
    type: GET_CP,
    payload: codes,
});

export const getCP = () => async (dispatch) => {
    return fetch(
        `${config.url}cp-classes?_sort=code:ASC&_limit=10000&parent_null=true`
    )
        .then((res) => res.json())
        .then((result) => dispatch(_getCP(result)));
};

const _getCPById = (codes) => ({
    type: GET_CP_ID,
    payload: codes,
});

export const getCPById = (id) => async (dispatch) => {
    return fetch(
        `${config.url}cp-classes?_sort=code:ASC&_limit=10000&parent=${id}`
    )
        .then((res) => res.json())
        .then((result) => dispatch(_getCPById({ id, children: result })));
};

///icd-10-codes
