import AdminLogin from "./components/Admin/Login/AdminLogin.jsx";
import AdminMain from "./components/Admin/Main/AdminMain";
import MedicalProductEdit from "./components/Admin/MedicalProducts/MedicalProductEdit.jsx";
import MedicalProducts from "./components/Admin/MedicalProducts/MedicalProducts";
import PatientLeafletEdit from "./components/Admin/PatientLeaflets/PatientLeafletEdit";
import PatientLeaflets from "./components/Admin/PatientLeaflets/PatientLeaflets";
import AboutUs from "./components/Drugs/AboutUs/AboutUs";
import Account from "./components/Drugs/Account/Account";
import AdvancedSearch from "./components/Drugs/AdvancedSearch/AdvancedSearch";
import AllNews from "./components/Drugs/AllNews/AllNews.jsx";
import Categories from "./components/Drugs/Categories/Categories";
import Change from "./components/Drugs/Change/Change";
import Classes from "./components/Drugs/Classes/Classes.jsx";
import ConditionsAndDiseases from "./components/Drugs/ConditionsAndDiseases/ConditionsAndDiseases.jsx";
import Contacts from "./components/Drugs/Contacts/Contacts";
import DeleteMyAccount from "./components/Drugs/DeleteMyAccount/DeleteMyAccount";
import Dosage from "./components/Drugs/Dosage/Dosage.jsx";
import DrugDetails from "./components/Drugs/DrugDetails/DrugDetails.jsx";
import DrugsAZ from "./components/Drugs/DrugsAZ/DrugsAZ.jsx";
import DrugsByLetter from "./components/Drugs/DrugsByLetter/DrugsByLetter";
import FAQ from "./components/Drugs/FAQ/FAQ";
import Forgot from "./components/Drugs/Forgot/Forgot";
import InteractionsChecker from "./components/Drugs/InteractionsChecker/InteractionsChecker.jsx";
import InternationalDatabase from "./components/Drugs/InternationalDatabase/InternationalDatabase.jsx";
import Login from "./components/Drugs/Login/Login";
import DrugsMain from "./components/Drugs/Main/DrugsMain.jsx";
import MobileApp from "./components/Drugs/MobileApp/MobileApp";
import MyDrugs from "./components/Drugs/MyDrugs/MyDrugs.jsx";
import MyMedecinesList from "./components/Drugs/MyMedecinesList/MyMedecinesList";
import News from "./components/Drugs/News/News";
import PharmaCompanies from "./components/Drugs/PharmaCompanies/PharmaCompanies.jsx";
import Pregnancy from "./components/Drugs/Pregnancy/Pregnancy.jsx";
import Breastfeeding from "./components/Drugs/Breastfeeding/Breastfeeding.jsx";
import PrivacyPolicy from "./components/Drugs/PrivacyPolicy/PrivacyPolicy";
import Register from "./components/Drugs/Register/Register";
import Reset from "./components/Drugs/Reset/Reset.jsx";
import SearchResults from "./components/Drugs/SearchResults/SearchResults";
import SideEffects from "./components/Drugs/SideEffects/SideEffects.jsx";
import TermsOfUse from "./components/Drugs/TermsOfUse/TermsOfUse";
import TreatmentOptions from "./components/Drugs/TreatmentOptions/TreatmentOptions.jsx";
import Warnings from "./components/Drugs/Warnings/Warnings.jsx";
import WithoutConditions from "./components/Drugs/WithoutConditions/WithoutConditions.jsx";
import Subscription from "./components/Drugs/Subscription/Subscription";
import CategoriesSearchResult from "./components/Drugs/CategoriesSearchResult/CategoriesSearchResult.jsx";

const routes = [
    {
        path: "/",
        name: "Drugs",
        icon: "ni ni-single-02 text-yellow",
        component: DrugsMain,
        layout: "",
    },
    {
        path: "/verify",
        name: "Verification",
        icon: "",
        component: DrugsMain,
        layout: "",
    },
    {
        path: "/terms-of-use",
        name: "Terms of use",
        icon: "",
        component: MyMedecinesList,
        layout: "",
    },
    {
        path: "/delete-my-account",
        name: "Drugs",
        icon: "ni ni-single-02 text-yellow",
        component: DeleteMyAccount,
        layout: "/drugs",
    },
    {
        path: "/my-medicines-list",
        name: "MyMedecinesList",
        icon: "ni ni-single-02 text-yellow",
        component: MyMedecinesList,
        layout: "/drugs",
    },
    {
        path: "/subscribe",
        name: "Subscribe",
        icon: "ni ni-single-02 text-yellow",
        component: Subscription,
        layout: "/drugs",
    },
    {
        path: "/news/:id/:params?",
        name: "News",
        icon: "ni ni-single-02 text-yellow",
        component: AllNews,
        layout: "/drugs",
    },
    {
        path: "/all_news",
        name: "All News",
        icon: "ni ni-single-02 text-yellow",
        component: AllNews,
        layout: "/drugs",
    },
    {
        path: "/warnings",
        name: "Warnings",
        icon: "ni ni-single-02 text-yellow",
        component: Warnings,
        layout: "/drugs",
    },
    {
        path: "/drugs_and_medications",
        name: "Drugs and Medications",
        icon: "ni ni-single-02 text-yellow",
        component: DrugsAZ,
        layout: "/drugs",
    },
    {
        path: "/interactions_checker",
        name: "Interactions Checker",
        icon: "ni ni-single-02 text-yellow",
        component: InteractionsChecker,
        layout: "/drugs",
    },
    {
        path: "/side_effects",
        name: "Side Effects",
        icon: "ni ni-single-02 text-yellow",
        component: SideEffects,
        layout: "/drugs",
    },
    {
        path: "/classes",
        name: "Drug Classes",
        icon: "ni ni-single-02 text-yellow",
        component: Classes,
        layout: "/drugs",
    },
    {
        path: "/without_conditions",
        name: "Without Conditions",
        icon: "ni ni-single-02 text-yellow",
        component: WithoutConditions,
        layout: "/drugs",
    },
    {
        path: "/treatment_options",
        name: "Treatment Options",
        icon: "ni ni-single-02 text-yellow",
        component: TreatmentOptions,
        layout: "/drugs",
    },
    {
        path: "/pharma_companies",
        name: "PharmaCompanies",
        icon: "ni ni-single-02 text-yellow",
        component: PharmaCompanies,
        layout: "/drugs",
    },
    {
        path: "/international_database",
        name: "International Database",
        icon: "ni ni-single-02 text-yellow",
        component: InternationalDatabase,
        layout: "/drugs",
    },
    {
        path: "/pregnancy_and_lactation",
        name: "Pregnancy",
        icon: "ni ni-single-02 text-yellow",
        component: Pregnancy,
        layout: "/drugs",
    },
    {
        path: "/lactation",
        name: "Breastfeeding",
        icon: "ni ni-single-02 text-yellow",
        component: Breastfeeding,
        layout: "/drugs",
    },
    {
        path: "/dosage_recomendations",
        name: "Dosage Recomendations",
        icon: "ni ni-single-02 text-yellow",
        component: Dosage,
        layout: "/drugs",
    },
    {
        path: "/conditions_and_diseases",
        name: "Conditions and Diseases",
        icon: "ni ni-single-02 text-yellow",
        component: ConditionsAndDiseases,
        layout: "/drugs",
    },
    {
        path: "/my_drugs",
        name: "My Drugs",
        icon: "ni ni-single-02 text-yellow",
        component: MyDrugs,
        layout: "/drugs",
    },
    {
        path: "/advanced-search",
        name: "Advanced Search",
        icon: "",
        component: AdvancedSearch,
        layout: "/drugs",
    },

    {
        path: "/categories",
        name: "Categories",
        icon: "ni ni-single-02 text-yellow",
        component: Categories,
        layout: "/drugs",
    },
    {
        path: "/drug-details/:id/:type?",
        name: "Drug Details",
        icon: "",
        component: DrugDetails,
        layout: "/drugs",
    },
    {
        path: "/search-results/:id/:params?/:autoC?",
        name: "Search Results",
        icon: "",
        component: SearchResults,
        layout: "/drugs",
    },
    {
        path: "/categories-search-result/:id",
        name: "Categories Search Result",
        icon: "",
        component: CategoriesSearchResult,
        layout: "",
    },
    {
        path: "/drugs-by-letter/:id",
        name: "Drugs by letter",
        icon: "",
        component: DrugsByLetter,
        layout: "/drugs",
    },
    {
        path: "/register",
        name: "Qeydiyyat",
        icon: "",
        component: Register,
        layout: "/drugs",
    },
    {
        path: "/login",
        name: "Giriş",
        icon: "",
        component: Login,
        layout: "/drugs",
    },
    {
        path: "/forgot",
        name: "Parolu / İstifadəçinin adını unutdunuz?",
        icon: "",
        component: Forgot,
        layout: "/drugs",
    },
    {
        path: "/reset-pass",
        name: "Yeni şifrə",
        icon: "",
        component: Reset,
        layout: "/drugs",
    },
    {
        path: "/account",
        name: "Sizin səhifəniz",
        icon: "",
        component: Account,
        layout: "/drugs",
    },
    {
        path: "/change",
        name: "Change your password",
        icon: "",
        component: Change,
        layout: "/drugs",
    },
    {
        path: "/about-us",
        name: "About us",
        icon: "",
        component: AboutUs,
        layout: "/drugs",
    },
    {
        path: "/faq",
        name: "FAQ",
        icon: "",
        component: FAQ,
        layout: "/drugs",
    },
    {
        path: "/contacts",
        name: "Contacts",
        icon: "",
        component: Contacts,
        layout: "/drugs",
    },
    {
        path: "/terms-of-use",
        name: "Terms of use",
        icon: "",
        component: TermsOfUse,
        layout: "/drugs",
    },
    {
        path: "/privacy-policy",
        name: "Privacy policy",
        icon: "",
        component: PrivacyPolicy,
        layout: "/drugs",
    },
    {
        path: "/one_news/:id/:type?",
        name: "News",
        icon: "",
        component: News,
        layout: "/drugs",
    },
    {
        path: "/mobile-app",
        name: "Mobile application",
        icon: "",
        component: MobileApp,
        layout: "/drugs",
    },
    {
        path: "/",
        name: "Login page",
        icon: "",
        component: AdminLogin,
        layout: "/login",
    },
    {
        path: "/",
        name: "Admin panel",
        icon: "",
        component: AdminMain,
        layout: "/admin",
    },
    {
        path: "/medical-products",
        name: "Admin panel - Medical products",
        icon: "",
        component: MedicalProducts,
        layout: "/admin",
    },
    {
        path: "/patient-leaflets",
        name: "Admin panel - Patient leaflets",
        icon: "",
        component: PatientLeaflets,
        layout: "/admin",
    },
    {
        path: "/patient-leaflet/edit/:id",
        name: "Admin panel - Patient leaflet",
        icon: "",
        component: PatientLeafletEdit,
        layout: "/admin",
    },
    {
        path: "/medical-product/edit/:id",
        name: "Admin panel - Medical product",
        icon: "",
        component: MedicalProductEdit,
        layout: "/admin",
    },
];

export default routes;
