import React from "react";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    NavLink,
    Row,
} from "reactstrap";
import "./Change.scss";
import { connect } from "react-redux";
import { changePassword } from "../../../services/services";

class Change extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codeToken: null,
            newPassword: null,
            newPasswordConfirm: null,
        };
    }

    componentWillMount() {
        this.setState({
            codeToken: this.props.location.search.split("?code=")[1],
        });
        this.props.history.push("/drugs/change");
    }

    render() {
        const { codeToken, newPassword, newPasswordConfirm } = this.state;
        const { dictionary, lang } = this.props;

        return (
            <>
                <CardHeader className="bg-transparent border-0 bottom-margin-del">
                    <h3 className="main-title-forgot">
                        {dictionary.changeComponent.mainTitle[lang]}
                    </h3>
                    <p className="info-block-forgot">
                        {dictionary.changeComponent.description[lang]}
                    </p>
                </CardHeader>
                <CardBody className="bottom-margin-del">
                    <p>
                        <input
                            className="inpusts-sign-in"
                            placeholder={
                                dictionary.changeComponent.newPassword[lang]
                            }
                        />
                    </p>
                    <p>
                        <input
                            className="inpusts-sign-in"
                            placeholder={
                                dictionary.changeComponent.newPasswordConfirm[
                                lang
                                ]
                            }
                        />
                    </p>
                    <div className="reg_button_wrapper">

                        <NavLink
                            className="reg_button"
                            onClick={() => {
                                if (
                                    codeToken &&
                                    newPassword &&
                                    newPasswordConfirm &&
                                    newPassword === newPasswordConfirm
                                ) {
                                    changePassword(
                                        codeToken,
                                        newPassword,
                                        newPasswordConfirm
                                    );
                                } else {
                                    alert("Check fields!");
                                }
                            }}
                        >
                            <p>{dictionary.changeComponent.submit[lang]}</p>
                        </NavLink>
                    </ div>
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Change);
