import {
    GET_PATIENT_LEAFLETS,
    EDIT_PATIENT_LEAFLET,
    COUNT_PATIENT_LEAFLETS,
    GET_INGREDIENTS_BY_ID,
    SEARCH_INGREDIENTS,
    CREATE_PATIENT_LEAFLET,
    RESET_NEW_LEAFLET,
    GET_EXCIPIENTS_BY_ID,
    SEARCH_EXCIPIENTS,
    GET_COATINGS_BY_ID,
    SEARCH_COATINGS,
    GET_UNDESIRABLE_EFFECTS_BY_IDS,
    SEARCH_BODY_STSTEMS,
    SEARCH_UNDESIRABLE_EFFECTS,
    GET_KIND_OF_SICKS_BY_ID,
    SEARCH_SICKS,
    GET_PL_MEDICAL_PRODUCT,
    SEARCH_PL_MEDICAL_PRODUCTS,
    SEARCH_MANUFACTURER,
    GET_MANUFACTURERS_BY_ID,
    SEARCH_MARKETING_AUTHORISATION,
    GET_MARKETING_AUTHORISATIONS_BY_ID,
    ERROR,
    SEARCH_ATC,
    GET_ALERT,
    CLOSE_ALERT
} from '../actions/action-types';

const initialState = {
    patientLeaflets: [],
    patientLeaflet: null,
    newLeaflet: null,
    leafletsCount: 0,
    ingredient: null,
    ingredientSearchResult: null,
    excipientsById: null,
    excipientSearchResult: null,
    coatingsById: null,
    coatingSearchResult: null,
    undesirableEffectsById: null,
    bodySystemSearchResult: null,
    undesirableEffectSearchResult: null,
    sicksById: null,
    sicksSearchResult: null,
    medicalProductPL: null,
    medicalProductPLSearchResult: null,
    manufacturersById: null,
    manufacturersSearchResult: null,
    marketingAuthorisationsById: null,
    marketingAuthorisationsSearchResult: null,
    atcSearchResult: null,
    alertMessage: null,
    error: null
}

const adminReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PATIENT_LEAFLETS:
            return {...state, patientLeaflets: payload }
        case EDIT_PATIENT_LEAFLET:
            return {...state, patientLeaflet: payload }
        case COUNT_PATIENT_LEAFLETS:
            return {...state, leafletsCount: payload }
        case GET_INGREDIENTS_BY_ID:
            return {...state, ingredient: payload }
        case SEARCH_INGREDIENTS:
            return {...state, ingredientSearchResult: payload }
        case CREATE_PATIENT_LEAFLET:
            return {...state, newLeaflet: payload }
        case RESET_NEW_LEAFLET:
            return {...state, newLeaflet: null }
        case GET_EXCIPIENTS_BY_ID:
            return {...state, excipientsById: payload }
        case SEARCH_EXCIPIENTS:
            return {...state, excipientSearchResult: payload }
        case GET_COATINGS_BY_ID:
            return {...state, coatingsById: payload }
        case SEARCH_COATINGS:
            return {...state, coatingSearchResult: payload }
        case GET_UNDESIRABLE_EFFECTS_BY_IDS:
            return {...state, undesirableEffectsById: payload }
        case SEARCH_BODY_STSTEMS:
            return {...state, bodySystemSearchResult: payload }
        case SEARCH_UNDESIRABLE_EFFECTS:
            return {...state, undesirableEffectSearchResult: payload }
        case GET_KIND_OF_SICKS_BY_ID:
            return {...state, sicksById: payload }
        case SEARCH_SICKS:
            return {...state, sicksSearchResult: payload }
        case GET_PL_MEDICAL_PRODUCT:
            return {...state, medicalProductPL: payload }
        case SEARCH_PL_MEDICAL_PRODUCTS:
            return {...state, medicalProductPLSearchResult: payload }
        case SEARCH_MANUFACTURER:
            return {...state, manufacturersSearchResult: payload }
        case GET_MANUFACTURERS_BY_ID:
            return {...state, manufacturersById: payload }
        case SEARCH_MARKETING_AUTHORISATION:
            return {...state, marketingAuthorisationsSearchResult: payload }
        case GET_MARKETING_AUTHORISATIONS_BY_ID:
            return {...state, marketingAuthorisationsById: payload }
        case SEARCH_ATC:
            return {...state, atcSearchResult: payload }
        case GET_ALERT:
            return {...state, alertMessage: payload }
        case CLOSE_ALERT:
            return {...state, alertMessage: null }
        case ERROR:
            return {...state, error: payload }
        default:
            return state;
    }
}

export default adminReducer;