import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LoadingSubject } from "../../../redux/models/loading-state.enum";
import { subscriptionsStoryList } from "../../../redux/actions/subscription-actions";
import CenterLoader from "../Loader/Loader";
import "./SubscriptionHistory.scss";
import moment from "moment";

class SubscriptionHistory extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		this.props.subscriptionsStoryList(0);
	};

	render = () => {
		const { t, subscriptionsStory, lang, loading } = this.props;

		if(loading.subjects.has(LoadingSubject.Subscriptions)) {
            return <CenterLoader className="loading-search-results"></CenterLoader>
        }
		return (
			<div className="subscription-history-container">
				<table>
					<thead>
						<tr>
							<td>{t("Naming")}</td>
							<td>{t("Date")}</td>
							<td>{t("Price")}</td>
							<td>{t("Status")}</td>
							<td>{t("Validity")}</td>
						</tr>
					</thead>
					<tbody>
						{subscriptionsStory && subscriptionsStory.map((sub, index) => {
							let status = "Active";

							if (sub.canceled) {
								status = "Canceled";
							} else {
								if (sub.paidDate) {
									status = "Active";
								} else {
									status = "Pending";
								}
							}

							return (
								<tr key={index}>
									<td className="name">{sub.description[lang]}</td>
									<td>{sub.paidDate && moment(sub.paidDate).format('DD.MM.YYYY')}</td>
									<td>{sub.paidAmount}</td>
									<td className={status}>{t(status)}</td>
									<td>{sub.validBefore && moment(sub.validBefore).format('DD.MM.YYYY')}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{subscriptionsStory.length === 0 && <div class="drugs-table-empty">{t('Subscription history is empty')}</div>}
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		subscriptionsStory: state.subscriptionReducer.subscriptionsStory,
		auth: state.vdAuthReducer,
		lang: state.languageReducer.language,
		loading: state.loadingReducer
	};
}

const mapDispatchToProps = {
	subscriptionsStoryList
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionHistory));
