import Axios from "axios";
import config from "../config";

const loginUserUrl = `${config.url}auth/local`;
const changePasswordUrl = "/api/auth/reset-password";
const resetUserPasswordUrl = "/api/auth/forgot-password";

// Teach Autosuggest how to calculate suggestions for any given input value.
export async function getSuggestions(value, lang) {
    const inputValue = value.trim().toLowerCase();

    let request = await fetch(
        // `${config.url}search-summary-plains/autocomplete?string=${inputValue}`
        `${config.csharp}api/public/Search/autoComplete?search=${inputValue}`,
        {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
        }
    );
    let data = await request.json();
    let results = [];

    for (let v of data) {
        let title = "";
        if (v.source.titleAz && v.source.titleRu) {
            title = v.source["title" + { az: "Az", ru: "Ru", en: "En" }[lang]];
        } else if (v.source.titleAz) {
            title = v.source.titleAz;
        } else {
            title = v.source.titleRu;
        }

        results.push(title);
    }

    return results;
}

function changePassword(code, newPass, newPassConfirm) {
    Axios.post(changePasswordUrl, {
        code: code,
        password: newPass,
        passwordConfirmation: newPassConfirm,
    })
        .then((response) => {
            alert("Success! Your password has been changed!");
        })
        .catch((error) => {
            alert("Error!");
        });
}

function resetUserPassword(email) {
    Axios.post(resetUserPasswordUrl, {
        email: email,
    })
        .then((response) => {
            alert("Succsess! Check your email");
        })
        .catch((error) => {
            alert("Error!");
        });
}

export { changePassword, resetUserPassword };
