import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    NavLink,
    Row,
} from "reactstrap";

import "./Forgot.scss";
import { connect } from "react-redux";
import { forgotPass } from '../../../redux/actions/auth-actions';

class Forgot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
        };
        this.changeEmailField = this.changeEmailField.bind(this);
    }

    changeEmailField(event) {
        this.setState({
            email: event.target.value,
        });
    }

    render() {
        const { dictionary, lang, resetPass } = this.props;
        const { email } = this.state;

        return (
            <>
                <CardHeader className="bg-transparent border-0 bottom-margin-del">
                    <h3 className="main-title-forgot">
                        {dictionary.forgotComponent.forgotPassOrNickname[lang]}
                    </h3>
                    <p className="info-block-forgot">
                        {dictionary.forgotComponent.instructionForPassReset[lang]}
                    </p>
                </CardHeader>
                {!resetPass || resetPass === 'reseted' ? 
                    <CardBody className="bottom-margin-del">
                        <p>
                            <input
                                className="inpusts-sign-in"
                                placeholder={dictionary.forgotComponent.emailAdress[lang]}
                                onChange={this.changeEmailField}
                            />
                        </p>
                        <div className="reg_button_wrapper">
                            <NavLink
                                className="reg_button"
                                onClick={() => {if (email) this.props.forgotPass(email)}}
                            >
                                <p>{dictionary.forgotComponent.submit[lang]}</p>
                            </NavLink>
                        </div>
                    </CardBody>
                    :
                    <CardBody>
                        sended
                    </CardBody>
                }
                
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    resetPass: state.authReducer.resetPass,
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = {
    forgotPass
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
