import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';

import {editPatientLeaflet, _createLeaflet} from '../../../redux/actions/adminActions'
//import createEditDeletePLActions from '../../../redux/actions/adminActions';

import {
    Container,
    Col,
    Row,
    Breadcrumb,
    BreadcrumbItem,
    Spinner,
    Nav, NavLink, NavItem
} from 'reactstrap';

import PLGeneralInfo from './PLEditComponents/PLGeneralInfo';
import PLGeneralFields from './PLEditComponents/PLGeneralFields';
import PLIngredients from './PLEditComponents/PLIngredients';
import PLExcipients from './PLEditComponents/PLExcipients';
import PLCoatings from './PLEditComponents/PLCoatings';
import PLPharmacologicalProperties from './PLEditComponents/PLPharmacologicalProperties';
import PLUndesirableEffect from './PLEditComponents/PLUndesirableEffects';
import PLContentsOfThePack from './PLEditComponents/PLContentsOfThePack';
import PLWhatYouNeedToKnow from './PLEditComponents/PLWhatYouNeedToKnow';
import PLHowToUse from './PLEditComponents/PLHowToUse';
import PLHowToStoreIt from './PLEditComponents/PLHowToStoreIt';
import PLMedicalProduct from './PLEditComponents/PLMedicalProduct';
import PLManufacturers from './PLEditComponents/PLManufacturers';
import PLMarketingAuthorisations from './PLEditComponents/PLMarketingAuthorisations';
import PLTermsOfSale from './PLEditComponents/PLTermsOfSale';

const PatientLeafletEdit = props => {
    const {id} = props.match.params;
    
    const [tab, selectTab] = useState('generalInfo');
    const [save, toggleSave] = useState(false);

    // эта хрень нужна, чтобы высылать только изменённые части, а то страпи не сохраняет большие объекты
    const [leaflet, changeLeaflet] = useState({});

    const dispatch = useDispatch();
    
    const {patientLeaflet, lang, dictionary, newLeaflet, authorized} = useSelector(state => ({
        patientLeaflet: state.adminReducer.patientLeaflet,
        newLeaflet: state.adminReducer.newLeaflet,
        lang: state.languageReducer.language,
        dictionary: state.dictionaryReducer,
        authorized: state.userReducer.authorized
    }));

    if (newLeaflet !== null) dispatch(_createLeaflet(null));

    useEffect(() => {
        if (patientLeaflet === null || (patientLeaflet.id && patientLeaflet.id.toString() !== id)) {
            dispatch(editPatientLeaflet(id, {}));
        }
    });

    useEffect(() => {
        if (save === true) {
            dispatch(editPatientLeaflet(id, leaflet));
            toggleSave(false);
        }
    }, [save])

    if (!authorized || authorized === 'false') return (<Redirect to={`/login/`} />)
    else if (patientLeaflet && patientLeaflet.id && patientLeaflet.id.toString() === id) return (
        <Container>
            <Row>
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/admin'}>{dictionary.breadcrumbs.home[lang]}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/admin/patient-leaflets'}>{dictionary.adminDrugs.patientLeaflets[lang]}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>
                        {patientLeaflet.title ? patientLeaflet.title[lang]
                            : patientLeaflet.internationalNonPatentedName ? patientLeaflet.internationalNonPatentedName
                            : patientLeaflet.id
                        }
                    </BreadcrumbItem>
                </Breadcrumb>
            </Row>
            <Row>
                <Col xs='3'>
                    <Row>
                        <Nav className={'admin-tabs-nav'} vertical>
                            <NavItem className={tab === 'generalInfo' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('generalInfo')}>
                                    {`${dictionary.adminDrugs.patientLeafletEditPage.generalInfo.title[lang]} (${dictionary.adminDrugs.patientLeafletEditPage.generalInfo.whatIsIt[lang]})`}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'generalFields' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('generalFields')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.generalFields.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'whatYouNeedToKnow' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('whatYouNeedToKnow')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.whatYouNeedToKnow.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'howToUse' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('howToUse')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.howToUse.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'ingredients' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('ingredients')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.ingredients.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'excipients' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('excipients')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.excipients.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'coatings' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('coatings')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.coatings.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'pharmacologicalProperties' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('pharmacologicalProperties')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'possibleSideEffects' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('possibleSideEffects')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.possibleSideEffects.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'howToStoreIt' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('howToStoreIt')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.howToStoreIt.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'contentsOfThePack' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('contentsOfThePack')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.contentsOfThePack.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'medicalProduct' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('medicalProduct')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.medicalProduct.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'manufacturer' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('manufacturer')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.manufacturer.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'marketingAuthorisation' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('marketingAuthorisation')}>
                                    {dictionary.adminDrugs.patientLeafletEditPage.marketingAuthorisation.title[lang]}
                                </NavLink>
                            </NavItem>
                            <NavItem className={tab === 'termsOfSale' ? 'active' : ''}>
                                <NavLink onClick={() => selectTab('termsOfSale')}>{dictionary.adminDrugs.patientLeafletEditPage.termsOfSale.title[lang]}</NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                </Col>
                <Col xs='9'>
                    {tab === 'generalInfo' ? 
                        <PLGeneralInfo lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'generalFields' ?
                        <PLGeneralFields lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'whatYouNeedToKnow' ?
                        <PLWhatYouNeedToKnow lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'howToUse' ?
                        <PLHowToUse lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'ingredients' ?
                        <PLIngredients lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'excipients' ?
                        <PLExcipients lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'coatings' ?
                        <PLCoatings lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'pharmacologicalProperties' ?
                        <PLPharmacologicalProperties lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'possibleSideEffects' ?
                        <PLUndesirableEffect lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'contentsOfThePack' ?
                        <PLContentsOfThePack lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'howToStoreIt' ?
                        <PLHowToStoreIt lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'medicalProduct' ?
                        <PLMedicalProduct lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'manufacturer' ?
                        <PLManufacturers lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'marketingAuthorisation' ?
                        <PLMarketingAuthorisations lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : tab === 'termsOfSale' ?
                        <PLTermsOfSale lang={lang} dictionary={dictionary} patientLeaflet={patientLeaflet}
                            toggleSave={toggleSave} changeLeaflet={changeLeaflet} />
                    : <Col></Col>
                    }
                </Col>
            </Row>
        </Container>
    ); else return (<div><Col sm="12" md={{ size: 6, offset: 3 }}><Spinner color='primary' /></Col></div>)
}

export default PatientLeafletEdit;