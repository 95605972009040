import { PATIENT_MEDICINE_ITEM, PATIENT_MEDICINE_LIST } from "../actions/patient-medicine-actions";

const initialState = {
    medicines: []
}

export function patientMedicineReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case PATIENT_MEDICINE_LIST:
            return { ...state, ...{ list: action.medicines } };
        case PATIENT_MEDICINE_ITEM:
            return { ...state, ...{ selected: action.medicine } };
        default:
            return state;
    }
}
