import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import { showAlert } from "../../Dialogs";
import SubscriptionHistory from "../SubscriptionHistory/SubscriptionHistory";
import history from "./../../../history";
// import UserPersonalCabinet from '../../../forms/user-personal-cabinet'

const accountTab = (tab, activeTab, toggle, title) => {
    return (
        <NavItem>
            <NavLink
                className={classnames(
                    { active: activeTab === tab },
                    { "p-2 mr-1 pointer inactive-tab-style": true },
                    { "active-tab-style": activeTab === tab }
                )}
                onClick={() => toggle(tab)}
            >
                {title}
            </NavLink>
        </NavItem>
    );
};
const formatDate = (date) => {
    let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

const alertDateError = (e) => {
    e.preventDefault();
    console.log(
        "🚀 ~ file: account-container.js ~ line 42 ~ alertDateError ~ preventDefault"
    );
    showAlert("Doğum tarixini düzgün seçin");
};

const addAccountData = (
    tab,
    toggle,
    props,
    drugModal,
    setDrugModal,
    onProfileFormSubmit,
    profileInputChange,
    onDrugModalSubmit,
    drugInputChange,
    suggestions,
    value,
    onChangeSuggestion,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested
) => {
    const { lang, dictionary, authData, auth, t } = props;
    const nowDate = formatDate(new Date());
    if (tab === "overview") {
        return (
            <>
                <div className="user-info-block">
                    <div>
                        <p>
                            {t("First Name")}:{" "}
                            <span>
                                {(props.auth.profile || {}).firstName || ""}
                            </span>
                        </p>
                        <p>
                            {t("Last Name")}:{" "}
                            <span>
                                {(props.auth.profile || {}).lastName || ""}
                            </span>
                        </p>
                    </div>
                    <p>
                        {t("Participant level")}:{" "}
                        <span>{t("Standart participant")}</span>
                    </p>
                </div>
                <div className="overview-block">
                    <div className="overview-block-item">
                        <Link to="/drugs/my-medicines-list">
                            <h1 className="overview-block-title">
                                {t("My medicines list")}
                            </h1>
                            <p className="overview-block-desc">
                                {t("My medicines list management")}
                            </p>
                        </Link>
                    </div>
                    <div
                        className="overview-block-item"
                        onClick={() => history.push("/drugs/subscribe")}
                    >
                        <h1 className="overview-block-title">
                            {t("Refresh subscriptions")}
                        </h1>
                        <p className="overview-block-desc">
                            {t("Subscriptions management")}
                        </p>
                    </div>
                </div>
                <div style={{ clear: "both" }}></div>

                <div className="help-report-errors-block">
                    <p>
                        {t("Tech support")}
                        <a
                            className="send-again-span"
                            href="mailto:Ezgilmedical@gmail.com"
                        >
                            Ezgilmedical@gmail.com
                        </a>
                    </p>
                </div>
            </>
        );
    } else if (tab === "editDetails") {
        if (!auth) {
            return <div></div>;
        }
        const help = (e) => {
            e.target[2].value > nowDate || e.target[2].value < "1900-01-01"
                ? alertDateError(e)
                : onProfileFormSubmit(e);
        };
        return (
            <div className="tab-edit-personal">
                <p>{dictionary.account.editDetails.p_1[lang]}</p>
                {/* <UserPersonalCabinet onSubmit={(data) => this.onProfileFormSubmit(data)} profileInputChange={profileInputChange} lang={lang} dictionary={dictionary} auth={auth} /> */}
                <form
                    onSubmit={(e) => {
                        // e.preventDefault();
                        help(e);
                    }}
                >
                    <p className="second-level-title">
                        {dictionary.account.editDetails.firstName[lang]}
                    </p>
                    <p>
                        <input
                            name="firstName"
                            defaultValue={(auth.profile || {}).firstName || ""}
                            className="input-field"
                            type="text"
                            placeholder={
                                dictionary.account.editDetails.firstName[lang]
                            }
                            onChange={profileInputChange}
                        />
                    </p>
                    <p className="second-level-title">
                        {dictionary.account.editDetails.lastName[lang]}
                    </p>
                    <p>
                        <input
                            name="lastName"
                            defaultValue={(auth.profile || {}).lastName || ""}
                            className="input-field "
                            type="text"
                            placeholder={
                                dictionary.account.editDetails.lastName[lang]
                            }
                            onChange={profileInputChange}
                        />
                    </p>
                    <p className="second-level-title">
                        {dictionary.account.editDetails.birthdate[lang]}
                    </p>
                    <p>
                        <input
                            name="birthdate"
                            defaultValue={(auth.profile || {}).birthdate || ""}
                            className="input-field point"
                            type="date"
                            onChange={profileInputChange}
                        />
                    </p>
                    <p className="second-level-title">
                        {dictionary.account.editDetails.sex[lang]}
                    </p>
                    <p>
                        <select
                            name="sex"
                            defaultValue={(auth.profile || {}).sex}
                            className="input-field point"
                            onChange={profileInputChange}
                        >
                            <option value={"male"}>
                                {
                                    dictionary.account.editDetails.sexChoose
                                        .male[lang]
                                }
                            </option>
                            <option value={"female"}>
                                {
                                    dictionary.account.editDetails.sexChoose
                                        .female[lang]
                                }
                            </option>
                        </select>
                    </p>
                    {(auth.profile || {}).professional && (
                        <>
                            <p className="second-level-title">
                                {
                                    dictionary.account.editDetails
                                        .specialization[lang]
                                }
                            </p>
                            <p>
                                <textarea
                                    name="specialization"
                                    defaultValue={
                                        (authData.user || {}).specialization
                                    }
                                    className="input-field"
                                    onChange={profileInputChange}
                                />
                            </p>
                            <p className="second-level-title">
                                {
                                    dictionary.account.editDetails
                                        .medicalInstitution[lang]
                                }
                            </p>
                            <p>
                                <textarea
                                    name="medicalInstitution"
                                    defaultValue={
                                        (authData.user || {}).medicalInstitution
                                    }
                                    className="input-field"
                                    onChange={profileInputChange}
                                />
                            </p>
                        </>
                    )}

                    <p className="second-level-title">
                        {dictionary.account.editDetails.changePassword[lang]}
                    </p>
                    <p>
                        <input
                            name="oldPassword"
                            className="input-field"
                            type="password"
                            onChange={profileInputChange}
                            placeholder={
                                dictionary.account.editDetails.tapOldPassword[
                                    lang
                                ]
                            }
                        />
                    </p>
                    <p>
                        <input
                            name="password"
                            className="input-field"
                            type="password"
                            onChange={profileInputChange}
                            placeholder={
                                dictionary.account.editDetails.tapNewPassword[
                                    lang
                                ]
                            }
                        />
                    </p>
                    <p>
                        <input
                            name="password2"
                            className="input-field"
                            type="password"
                            onChange={profileInputChange}
                            placeholder={
                                dictionary.account.editDetails
                                    .repeatNewPassword[lang]
                            }
                        />
                    </p>
                    <button
                        // onClick={() => {
                        //     this.props.submit("UserPersonalCabinetForm");
                        // }}
                        className="show-more-btn submit-btn"
                        style={{
                            width: 200,
                            fontSize: "1rem",
                            margin: "30px 0",
                            paddingTop: 7,
                            fontWeight: 600,
                        }}
                        type="submit"
                    >
                        {dictionary.account.buttons.editProfileInfo[lang]}
                    </button>
                </form>
                {/* <button onClick={() => {
                        this.props.submit("UserPersonalCabinetForm");
                    }}
                        className='show-more-btn submit-btn'
                        style={{ width: 200, fontSize: '1rem', margin: '30px 0', paddingTop: 7, fontWeight: 600 }}
                        type="submit">
                        {dictionary.account.buttons.editProfileInfo[lang]}
                    </button> */}
            </div>
        );
    } else if (tab === "follows") {
        return (
            <div className="abune-block">
                <p>{t("Subscription conditions")}</p>
                {/* <p>
                    {t("Subscription conditions description")}
                    Aşağıdakı abunə imkanlarından yararlanaraq, Siz ən vacib klinik təlimatlar, alqoritmlər və davamlı yenilənən klinik
                    məlumata çıxış əldə etmiş olursunuz.
                </p>
                <p>
                    * {t("Subscription auto refresh")}
                    * {t("Subscription cancel opportunity")}
                    * Abunəliyin avtomatik yenilənməsi <br />
                    * Siz istənilən vaxt abunəlikdən imtina edə bilərsiniz.
                </p> */}

                <SubscriptionHistory />
            </div>
        );
    }
};

export { accountTab, addAccountData };
