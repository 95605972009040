import React from "react";
import {
    CardBody,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import "./DrugDetails.scss";
import classnames from "classnames";

import { connect } from "react-redux";
import {
    getFullMedicalProduct,
    getBodySystemsData,
    getRenderedMedicalProduct,
    getRenderedPatientLeaflet,
} from "../../../redux/actions/actions";
import config from "./../../../config";
import {
    loadingStart,
    loadingEnd,
} from "./../../../redux/actions/loading-actions";
import { GET_MEDICINE_DETAIL } from "../../../redux/actions/action-types";
import { CenterLoader } from "../Loader/Loader";
import AdsBlock from "../AdsBlock/AdsBlock";
import { Link } from "react-router-dom";
import { PopularRequests } from "../PopularRequest/PopularRequests";

class DrugDetails extends React.Component {
    constructor(props) {
        super(props);

        let activeTab = "1";
        if (this.props.match.params.type === "types=professional") {
            activeTab = "1";
        } else if (this.props.match.params.type === "types=sideeffect") {
            activeTab = "2";
        } else if (this.props.match.params.type === "types=dosage") {
            activeTab = "3";
        } else if (this.props.match.params.type === "types=pregnancy") {
            activeTab = "4";
        }

        this.state = {
            activeTab: activeTab,
            popularRequests: [],
        };
        this.htmlTagsDel = this.htmlTagsDel.bind(this);
    }

    htmlTagsDel(txt) {
        if (!txt) return <p></p>;
        return <p dangerouslySetInnerHTML={{ __html: txt }} />;
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    async componentDidMount() {
        const drugId = this.props.match.params.id;
        this.props.loadingStart(GET_MEDICINE_DETAIL);
        await this.props.getRenderedPatientLeaflet(drugId);
        await this.props.getRenderedMedicalProduct(drugId);
        await this.props.getBodySystemsData();
        await this.fetchPopularRequests();
    }

    fetchPopularRequests = async () => {
        let request = await fetch(
            `${config.url}popular-search-results?_sort=requestNumber:desc&type=news&_limit=40`
        );
        let data = await request.json();
        this.setState({
            popularRequests: data,
        });
    };
    getPopularRequests = (requests) => {
        let rows = [];
        for (let f = 0; f < 10; f++) {
            let current_request = requests[f];
            if (current_request) {
                rows.push(
                    <Link
                        key={`${f}`}
                        to={`/drugs/search-results/${current_request.request}/types=news`}
                    >
                        {current_request.request}
                    </Link>
                );
            } else {
                break;
            }
        }
        return rows;
    };

    render() {
        console.log("this.props.match.params", this.props.match.params);

        const {
            renderedMedicalProduct,
            renderedPatientLeaflet,
            lang,
            authData,
            loading,
        } = this.props;
        const { professional, role } = (authData || {}).user || {};

        let { activeTab } = this.state;

        if (activeTab == "5" && !professional) {
            activeTab = "1";
        }

        if (loading.subjects.has(GET_MEDICINE_DETAIL)) {
            return (
                <CenterLoader className="loading-search-results"></CenterLoader>
            );
        }

        return (
            <div className="drug-details_container">
                <div className="drug-details_top">
                    <div className="drug-details_body">
                        <CardHeader className="bg-transparent border-0">
                            <h1 className="drug_name">
                                {
                                    ((renderedPatientLeaflet || {}).title ||
                                        {})[lang]
                                }
                            </h1>
                        </CardHeader>

                        <div className="header_block">
                            <div className="drug-details drug-details_text-block">
                                <p className="drug_code">
                                    {this.props.dictionary.drug.ATCCode[lang] +
                                        ": "}
                                    <a>
                                        {(renderedPatientLeaflet || {}).ATCCode}
                                    </a>
                                </p>
                                <p className="tags">
                                    {this.props.dictionary.activeSubstance[
                                        lang
                                    ] + ": "}
                                    {(
                                        (renderedPatientLeaflet || {})
                                            .ingredients || []
                                    ).map((ingredient) => (
                                        <a key={ingredient.az}>
                                            {(ingredient || {})[lang]};
                                        </a>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="drug-details drug-image">
                        {((renderedPatientLeaflet || {}).image || {}).url ? (
                            <img
                                alt={(renderedPatientLeaflet || {}).alt}
                                src={
                                    config.url +
                                    ((renderedPatientLeaflet || {}).image || {})
                                        .url
                                }
                            />
                        ) : (
                            <div>{this.props.dictionary.noPhoto[lang]}</div>
                        )}
                    </div>
                </div>
                <CardBody>
                    <Nav tabs className="drug-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                className={classnames(
                                    { active: activeTab === "1" },
                                    { "active-tab": activeTab === "1" }
                                )}
                                onClick={() => this.toggle("1")}
                            >
                                {
                                    this.props.dictionary.drugDetailsTabs
                                        .tabCommonInformation[lang]
                                }
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames(
                                    { active: activeTab === "2" },
                                    {
                                        "active-tab common-for-nav":
                                            activeTab === "2",
                                    }
                                )}
                                onClick={() => this.toggle("2")}
                            >
                                {
                                    this.props.dictionary.drugDetailsTabs
                                        .tabUndesirableEffects[lang]
                                }
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames(
                                    { active: activeTab === "3" },
                                    {
                                        "active-tab common-for-nav":
                                            activeTab === "3",
                                    }
                                )}
                                onClick={() => this.toggle("3")}
                            >
                                {
                                    this.props.dictionary.drugDetailsTabs
                                        .tabMethodOfApplicationAndDosage[lang]
                                }
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames(
                                    { active: activeTab === "4" },
                                    {
                                        "active-tab common-for-nav":
                                            activeTab === "4",
                                    }
                                )}
                                onClick={() => this.toggle("4")}
                            >
                                {
                                    this.props.dictionary.drugDetailsTabs
                                        .tabPregnancy[lang]
                                }
                            </NavLink>
                        </NavItem>
                        {professional ? (
                            <NavItem>
                                <NavLink
                                    className={classnames(
                                        { active: activeTab === "5" },
                                        {
                                            "active-tab common-for-nav":
                                                activeTab === "5",
                                        }
                                    )}
                                    onClick={() => this.toggle("5")}
                                >
                                    {
                                        this.props.dictionary.drugDetailsTabs
                                            .tabForProfessionals[lang]
                                    }
                                </NavLink>
                            </NavItem>
                        ) : (
                            ""
                        )}
                    </Nav>

                    <div className="drug-details_main_container">
                        <div className="drug-details_content">
                            <TabContent
                                className="content-block"
                                activeTab={activeTab}
                            >
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            {this.htmlTagsDel(
                                                ((renderedPatientLeaflet || {})
                                                    .htmlRenderPL || {})[lang]
                                            )}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            {this.htmlTagsDel(
                                                ((renderedPatientLeaflet || {})
                                                    .htmlRenderUE || {})[lang]
                                            )}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            {this.htmlTagsDel(
                                                ((renderedPatientLeaflet || {})
                                                    .htmlRenderUsing || {})[
                                                    lang
                                                ]
                                            )}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>
                                        <Col sm="12">
                                            {this.htmlTagsDel(
                                                ((renderedPatientLeaflet || {})
                                                    .htmlRenderPregnancy || {})[
                                                    lang
                                                ]
                                            )}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Row>
                                        <Col sm="12">
                                            {this.htmlTagsDel(
                                                ((renderedMedicalProduct || {})
                                                    .htmlRenderMP || {})[lang]
                                            )}
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                            <div className="main_content">
                                <h1 className="mb-0 news_title">
                                    {
                                        this.props.dictionary.allNews
                                            .popularRequests[lang]
                                    }
                                </h1>
                                <CardBody className="news_body">
                                    <div className="popular_requests_container">
                                        <PopularRequests type="drugs" />
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                        <div className="last-news-main__sda">
                            <AdsBlock
                                component={
                                    <div>
                                        <h3>
                                            {
                                                this.props.dictionary.allNews
                                                    .popularRequests[lang]
                                            }
                                        </h3>
                                        <div className="list">
                                            {this.getPopularRequests(
                                                this.state.popularRequests
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    renderedMedicalProduct: state.drugReducer.renderedMedicalProduct,
    renderedPatientLeaflet: state.drugReducer.renderedPatientLeaflet,
    bodySystemsData: state.drugReducer.bodySystemsData,
    authData: state.authReducer.authData,
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    forProfessionals: state.drugReducer.forProfessionals,
    loading: state.loadingReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        loadingStart: (subject) => {
            dispatch(loadingStart(subject));
        },
        loadingEnd: (subject) => {
            dispatch(loadingEnd(subject));
        },
        getRenderedMedicalProduct: (drugId) => {
            dispatch(getRenderedMedicalProduct(drugId));
        },
        getRenderedPatientLeaflet: (drugId) => {
            dispatch(getRenderedPatientLeaflet(drugId));
        },
        getBodySystemsData: () => {
            dispatch(getBodySystemsData());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugDetails);
