import React, { Component } from "react";
import { connect } from "react-redux";
import { submit, FormAction } from "redux-form";
import "./Auth.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LoginForm from "../../../forms/auth/LoginForm";
import VerifyAccountForm from "../../../forms/auth/VerifyAccountForm";
import RegistrationFormUser from "../../../forms/auth/RegistrationFormUser";
import {
    signIn,
    signUp,
    signOut,
    authErrorText,
    verifyPhone,
    verifyEmail,
    processStageChange,
    _preSignIn,
    restorePassword,
    verifyOperation,
    restoreChangePassword,
    resendVerification,
    toggleAuth,
    editProfile,
} from "../../../redux/actions/vd-auth";
import { Link } from "react-router-dom";
import history from "../../../history";
import ForgotForm from "../../../forms/forgot/Forgot-form";
import ForgotCodeForm from "../../../forms/forgot/Forgot-code-form";
import ForgotRepeatForm from "../../../forms/forgot/Forgot-repeat-form";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { language } from "../../../redux/actions/settings-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as LogoutIcon } from "../../../assets/img/logout.svg";
import { LoadingSubject } from "../../../redux/models/loading-state.enum";
import Spinner from "react-spinner-material";
import { LoginStage } from "../../../redux/models/auth-state.enum";
import RegistrationProfileForm from "../../../forms/auth/RegistrationProfileForm";

class AuthComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openLogin: false,
            openRegistration: false,
            asDoctor: false,
            doctorFinal: false,
            doctorStep: 1,
            medWorker: false,
        };

        let language = "az"
        //     ((window.navigator || {}).userLanguage || "").substring(0, 2) ||
        //     ((window.navigator || {}).language || "").substring(0, 2);
        // if (language !== "az" && language !== "ru") {
            // language = "az";
        // }
        const currentLang =
            localStorage.getItem("az.ezgil.dermanlarim.language") || language;
        this.setLanguage(currentLang);
    }

    setLanguage(lang) {
        i18n.changeLanguage(lang);
        this.props.language(lang);
    }

    render() {
        const authToken = ((this.props.auth || {}).userToken || {}).auth;
        const { t } = this.props;
        const handleShow = () => this.props.toggleAuth(true);
        const handleClose = () => {
            this.props.authErrorText("");
            this.props.processStageChange(0);
            this.setState({
                openLogin: false,
                openRegistration: false,
                asDoctor: false,
                doctorFinal: false,
            });
            this.props.toggleAuth(false);
        };

        const onSuccess = () => {};

        const onSubmit = (user) => {
            const { openLogin, openRegistration } = this.state;
            const { processStage, operation } = this.props.auth;

            this.props.authErrorText("");

            if (processStage === 0) {
                if (!openRegistration) {
                    this.props.signIn(user, onSuccess);
                } else {
                    this.props.signUp(user, onSuccess);
                }
            } else if (processStage === LoginStage.PhoneVerification) {
                this.props.verifyPhone(
                    (this.props.auth.user || {}).login,
                    user.code
                );
            } else if (processStage === LoginStage.EmailVerification) {
                this.props.verifyEmail(
                    (this.props.auth.user || {}).login,
                    user.code
                );
            } else if (
                processStage === LoginStage.VerifyPhoneSuccess ||
                processStage === LoginStage.VerifyEmailSuccess
            ) {
                user.role = this.state.medWorker ? "doctor" : "patient";
                this.props.editProfile(user);
            } else if (processStage === 5) {
                this.props.restorePassword(user.login);
            } else if (processStage === 6) {
                this.props.verifyOperation(operation || "", user.code);
            } else {
                user.login = (this.props.auth.user || {}).login;
                user.email = (this.props.auth.user || {}).email;
                this.props.restoreChangePassword(operation || "", user);
            }
        };

        const renderError = () => {
            const errorText = this.props.auth.authErrorText;
            if (errorText) {
                return (
                    <div className="auth-error-wrapper">
                        <img
                            src={require("../../../assets/img/error-img.webp")}
                        />
                        <p>{errorText}</p>
                    </div>
                );
            }
        };

        const renderRegistration = () => {
            const patientLoading = this.props.loading.subjects.has(
                LoadingSubject.SignUp
            );

            return (
                <Modal.Body className="register">
                    <div className="modal-close">
                        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                    </div>

                    <Modal.Title> {t("Register")} </Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        {this.props.auth.authErrorText && (
                            <div
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "-15px",
                                }}
                            >
                                {renderError()}
                            </div>
                        )}
                        <RegistrationFormUser
                            onSubmit={(data) => onSubmit(data)}
                        />
                        <div className="modal-custom-footer">
                            <div className="modal-button-block">
                                <button
                                    className="btn btn-primary auth-button"
                                    onClick={() => {
                                        this.props._preSignIn(null);
                                        this.setState({ asDoctor: false });
                                        this.props.submit(
                                            "RegistrationFormUser"
                                        );
                                    }}
                                    disabled={patientLoading}
                                >
                                    <div style={{ display: "flex" }}>
                                        {patientLoading && (
                                            <div style={{ marginRight: "8px" }}>
                                                {
                                                    <Spinner
                                                        radius={18}
                                                        color={"#ffffff"}
                                                        stroke={3}
                                                        visible={true}
                                                    />
                                                }
                                            </div>
                                        )}
                                        {t("Register")}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            );
        };

        const renderVerify = (stage) => {
            const verifyLoading = this.props.loading.subjects.has(
                LoadingSubject.VerifyPhone
            );

            const { operation } = this.props.auth;
            return (
                <Modal.Body>
                    <Modal.Title
                        style={{ textAlign: "left", marginBottom: "20px" }}
                    >
                        {t(
                            LoginStage.PhoneVerification
                                ? "Phone confirmation"
                                : "Email confirmation"
                        )}
                    </Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        {renderError()}
                        <VerifyAccountForm
                            onSubmit={(data) => onSubmit(data)}
                        />
                        <Link
                            to={""}
                            className="modal-link"
                            onClick={() =>
                                this.props.resendVerification(
                                    (this.props.auth.user || {}).login,
                                    stage === LoginStage.PhoneVerification
                                        ? "Phone"
                                        : "Email",
                                    operation
                                )
                            }
                        >
                            {t("Send code again")}
                        </Link>
                        <div className="modal-button-block">
                            <button
                                className="btn btn-primary auth-button"
                                onClick={() => {
                                    this.props.submit("VerifyAccountForm");
                                }}
                                disabled={verifyLoading}
                            >
                                <div style={{ display: "flex" }}>
                                    {verifyLoading && (
                                        <div style={{ marginRight: "8px" }}>
                                            {
                                                <Spinner
                                                    radius={18}
                                                    color={"#ffffff"}
                                                    stroke={3}
                                                    visible={true}
                                                />
                                            }
                                        </div>
                                    )}
                                    {t("Confirm")}
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            );
        };

        const renderProfile = () => {
            const NewDoctor1Loading = this.props.loading.subjects.has(
                LoadingSubject.NewDoctor1
            );

            return (
                <Modal.Body className="step1">
                    <Modal.Title>{t("Profile")}</Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        {renderError()}
                        <RegistrationProfileForm
                            medWorker={this.state.medWorker}
                            onSubmit={(data) => onSubmit(data)}
                        />
                        <div className="modal-custom-footer">
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {t("Register as medical worker")}
                                <input
                                    style={{ marginLeft: 10 }}
                                    type="checkbox"
                                    checked={this.state.medWorker}
                                    onClick={() => {
                                        this.setState({
                                            medWorker: !this.state.medWorker,
                                        });
                                    }}
                                />
                            </p>
                            <div className="modal-button-block">
                                <button
                                    className="btn btn-primary auth-button"
                                    onClick={() => {
                                        this.props.submit(
                                            "RegistrationProfileForm"
                                        );
                                    }}
                                    disabled={NewDoctor1Loading}
                                >
                                    <div style={{ display: "flex" }}>
                                        {NewDoctor1Loading && (
                                            <div style={{ marginRight: "8px" }}>
                                                {
                                                    <Spinner
                                                        radius={18}
                                                        color={"#ffffff"}
                                                        stroke={3}
                                                        visible={true}
                                                    />
                                                }
                                            </div>
                                        )}
                                        {t("Next")}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            );
        };

        const renderLogin = () => {
            const loginLoading = this.props.loading.subjects.has(
                LoadingSubject.SignIn
            );

            return (
                <Modal.Body
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            this.props.submit("LoginForm");
                        }
                    }}
                    className="login"
                >
                    <div className="modal-close">
                        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                    </div>
                    <Modal.Title>{t("Authorization")}</Modal.Title>
                    {renderError()}
                    <LoginForm onSubmit={(data) => onSubmit(data)} />
                    <Link
                        className="modal-link"
                        to={""}
                        onClick={() => this.props.processStageChange(5)}
                    >
                        {t("Forgot your password?")}
                    </Link>
                    <div className="modal-button-block">
                        <button
                            className="btn btn-primary auth-button"
                            onClick={() => {
                                this.props._preSignIn(null);
                                this.props.authErrorText("");
                                this.setState({ openRegistration: true });
                            }}
                        >
                            {t("Register")}
                        </button>
                        <button
                            className="btn btn-primary dark auth-button"
                            onClick={() => {
                                this.props.submit("LoginForm");
                            }}
                            disabled={loginLoading}
                        >
                            <div style={{ display: "flex" }}>
                                {loginLoading && (
                                    <div style={{ marginRight: "8px" }}>
                                        {
                                            <Spinner
                                                radius={18}
                                                color={"#ffffff"}
                                                stroke={3}
                                                visible={true}
                                            />
                                        }
                                    </div>
                                )}
                                {t("Sign in")}
                            </div>
                        </button>
                    </div>
                </Modal.Body>
            );
        };

        const renderForgotPswd = () => {
            const restoreLoading = this.props.loading.subjects.has(
                LoadingSubject.RestorePswd
            );

            return (
                <Modal.Body>
                    <div className="modal-close">
                        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                    </div>
                    <Modal.Title
                        style={{ textAlign: "left", marginBottom: "20px" }}
                    >
                        {t("Forgot your password?")}
                    </Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        <p>{t("Login or Email or Phone")}</p>
                        {/* {renderError()} */}
                        <ForgotForm onSubmit={(data) => onSubmit(data)} />
                        <Button
                            size="sm"
                            className="btn-modal-window"
                            style={{
                                display: "table",
                                margin: "20px auto 0 auto",
                            }}
                            variant="primary"
                            onClick={() => {
                                this.props.submit("ForgotForm");
                            }}
                            disabled={restoreLoading}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {restoreLoading && (
                                    <div style={{ marginRight: "8px" }}>
                                        {
                                            <Spinner
                                                radius={18}
                                                color={"#ffffff"}
                                                stroke={3}
                                                visible={true}
                                            />
                                        }
                                    </div>
                                )}
                                {t("Restore")}
                            </div>
                        </Button>
                    </div>
                </Modal.Body>
            );
        };

        const renderVerifyEmail = () => {
            const { operation, hasEmail } = this.props.auth;
            const verificationLoading = this.props.loading.subjects.has(
                LoadingSubject.VerifyEmail
            );

            return (
                <Modal.Body style={{ textAlign: "center" }}>
                    <div className="modal-close">
                        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
                    </div>
                    <Modal.Title
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        {t("Forgot your password?")}
                    </Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        <p style={{ fontSize: "16px" }}>
                            {hasEmail
                                ? t("restore_by_mail")
                                : t("restore_by_phone")}
                        </p>
                        {renderError()}
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <ForgotCodeForm
                                onSubmit={(data) => onSubmit(data)}
                            />
                            <Button
                                size="sm"
                                className="btn-modal-window"
                                style={{
                                    display: "table",
                                    margin: "20px auto 0 auto",
                                }}
                                variant="primary"
                                onClick={() => {
                                    this.props.submit("ForgotCodeForm");
                                }}
                                disabled={verificationLoading}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {verificationLoading && (
                                        <div style={{ marginRight: "8px" }}>
                                            {
                                                <Spinner
                                                    radius={18}
                                                    color={"#ffffff"}
                                                    stroke={3}
                                                    visible={true}
                                                />
                                            }
                                        </div>
                                    )}
                                    {t("Verification")}
                                </div>
                            </Button>
                            <Link
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "10px",
                                    fontSize: "14px",
                                }}
                                to={""}
                                onClick={() =>
                                    this.props.resendVerification(
                                        (this.props.auth.user || {}).login,
                                        "OperationByEmail",
                                        operation
                                    )
                                }
                            >
                                {t("Send code again")}
                            </Link>
                        </div>
                    </div>
                </Modal.Body>
            );
        };

        const renderChangePswd = () => {
            const changePswdLoading = this.props.loading.subjects.has(
                LoadingSubject.ChangePswd
            );

            return (
                <Modal.Body>
                    <Modal.Title
                        style={{ textAlign: "left", marginBottom: "20px" }}
                    >
                        {t("Forgot your password?")}
                    </Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        <p>{t("Login or email")}</p>
                        {renderError()}
                        <ForgotRepeatForm onSubmit={(data) => onSubmit(data)} />
                        <Button
                            size="sm"
                            className="btn-modal-window"
                            style={{
                                display: "table",
                                margin: "20px auto 0 auto",
                            }}
                            variant="primary"
                            onClick={() => {
                                this.props.submit("ForgotRepeatForm");
                            }}
                            disabled={changePswdLoading}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {changePswdLoading && (
                                    <div style={{ marginRight: "8px" }}>
                                        {
                                            <Spinner
                                                radius={18}
                                                color={"#ffffff"}
                                                stroke={3}
                                                visible={true}
                                            />
                                        }
                                    </div>
                                )}
                                {t("Change")}
                            </div>
                        </Button>
                    </div>
                </Modal.Body>
            );
        };

        const renderPswdChanged = () => {
            return (
                <Modal.Body>
                    <Modal.Title
                        style={{ textAlign: "left", marginBottom: "20px" }}
                    >
                        {this.props.t("Forgot your password?")}
                    </Modal.Title>
                    <div className="doctor-or-patient-buttons-block">
                        <p>
                            {t(
                                "Password successfully changed. Please, login with new password"
                            )}
                        </p>
                        <Button
                            size="sm"
                            className="btn-modal-window"
                            style={{
                                display: "table",
                                margin: "20px auto 0 auto",
                            }}
                            variant="primary"
                            onClick={() => {
                                this.props.processStageChange(0);
                            }}
                        >
                            {" "}
                            {t("Close")}
                        </Button>
                    </div>
                </Modal.Body>
            );
        };

        const whichFormToRender = () => {
            const { openRegistration } = this.state;
            const { processStage } = this.props.auth || {};

            let doctorFinal = this.state.doctorFinal;
            if (((this.props.auth || {}).userToken || {}).status === 3) {
                doctorFinal = true;
            }

            if (processStage === 0) {
                if (openRegistration) {
                    return renderRegistration();
                } else {
                    return renderLogin();
                }
            } else if (
                processStage === LoginStage.PhoneVerification ||
                processStage === LoginStage.EmailVerification
            ) {
                return renderVerify(processStage);
            } else if (
                processStage === LoginStage.VerifyPhoneSuccess ||
                processStage === LoginStage.VerifyEmailSuccess
            ) {
                return renderProfile();
            } else if (processStage === 5) {
                return renderForgotPswd();
            } else if (processStage === 6) {
                return renderVerifyEmail();
            } else if (processStage === 7) {
                return renderChangePswd();
            } else if (processStage === 8) {
                return renderPswdChanged();
            } else {
                return null;
            }
        };

        return (
            <div className="auth-wrapper">
                {!authToken && (
                    <Button
                        className="btn btn-secondary mobile-btn"
                        onClick={() => !authToken && handleShow()}
                    >
                        {t("Profile")}
                    </Button>
                )}
                {authToken && (
                    <>
                        <Button
                            className="btn btn-secondary"
                            onClick={() => history.push("/drugs/account")}
                        >
                            {(this.props.auth.self || {}).name || t("Profile")}
                        </Button>
                        <Button
                            className="btn btn-secondary"
                            onClick={() => {
                                this.props.signOut(
                                    this.props.auth.userToken.auth
                                );
                            }}
                        >
                            {!this.props.loading.subjects.has(
                                LoadingSubject.SignOut
                            ) && (
                                <SvgIcon
                                    htmlColor="#0279bd"
                                    fontSize="small"
                                    component={LogoutIcon}
                                />
                            )}
                            {this.props.loading.subjects.has(
                                LoadingSubject.SignOut
                            ) && (
                                <Spinner
                                    radius={20}
                                    color={"#0279BD"}
                                    stroke={2}
                                    visible={true}
                                />
                            )}
                        </Button>
                    </>
                )}
                <Modal
                    dialogClassName="modal-main"
                    centered
                    show={(this.props.auth || {}).showAuth}
                    onHide={handleClose}
                >
                    {whichFormToRender()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.vdAuthReducer,
    loading: state.loadingReducer,
});

const mapDispatchToProps = {
    submit,
    signIn,
    signOut,
    signUp,
    authErrorText,
    verifyPhone,
    verifyEmail,
    processStageChange,
    editProfile,
    _preSignIn,
    restorePassword,
    verifyOperation,
    restoreChangePassword,
    resendVerification,
    language,
    toggleAuth,
};

let AuthComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(AuthComponent));

export default AuthComponentContainer;
