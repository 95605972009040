export const SEARCH_RESULT_ARRAY = "SEARCH_RESULT_ARRAY";
export const GET_MEDICINE_DETAIL = "GET_MEDICINE_DETAIL";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_FIELDS_VALUE = "SET_FIELDS_VALUE";
export const GET_HEADER_DROPDOWN = "GET_HEADER_DROPDOWN";
export const GET_DRUG_DATA = "GET_DRUG_DATA";
export const GET_BODY_SYSTEMS_DATA = "GET_BODY_SYSTEMS_DATA";
export const GET_ABOUT_US_DATA = "GET_ABOUT_US_DATA";
export const SET_FOR_PROFESSIONALS = "SET_FOR_PROFESSIONALS";
export const GET_NEWS_DATA = "GET_NEWS_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const REGISTER_USER = "REGISTER_USER";
export const AUTHORIZED = "AUTHORIZED";
export const LOGIN = "LOGIN";
export const GET_MEDICAL_PRODUCTS = "GET_MEDICAL_PRODUCTS";
export const GET_PATIENT_LEAFLETS = "GET_PATIENT_LEAFLETS";
export const EDIT_MEDICAL_PRODUCT = "EDIT_MEDICAL_PRODUCT";
export const EDIT_PATIENT_LEAFLET = "EDIT_PATIENT_LEAFLET";
export const CREATE_MEDICAL_PRODUCT = "CREATE_MEDICAL_PRODUCT";
export const CREATE_PATIENT_LEAFLET = "CREATE_PATIENT_LEAFLET";
export const RESET_NEW_LEAFLET = "RESET_NEW_LEAFLET";
export const DELETE_MEDICAL_PRODUCT = "DELETE_MEDICAL_PRODUCT";
export const DELETE_PATIENT_LEAFLET = "DELETE_PATIENT_LEAFLET";
export const COUNT_MEDICAL_PRODUCTS = "COUNT_MEDICAL_PRODUCTS";
export const COUNT_PATIENT_LEAFLETS = "COUNT_PATIENT_LEAFLETS";
export const GET_INGREDIENTS = "GET_INGREDIENTS";
export const GET_INGREDIENTS_BY_ID = "GET_INGREDIENTS_BY_ID";
export const GET_EXCIPIENTS = "GET_EXCIPIENTS";
export const GET_EXCIPIENTS_BY_ID = "GET_EXCIPIENTS_BY_ID";
export const SEARCH_INGREDIENTS = "SEARCH_INGREDIENTS";
export const SEARCH_EXCIPIENTS = "SEARCH_EXCIPIENTS";
export const GET_COATINGS_BY_ID = "GET_COATINGS_BY_ID";
export const SEARCH_COATINGS = "SEARCH_COATINGS";
export const GET_UNDESIRABLE_EFFECTS_BY_IDS = "GET_UNDESIRABLE_EFFECTS_BY_IDS";
export const GET_BODY_SYSTEMS_BY_IDS = "GET_BODY_SYSTEMS_BY_IDS";
export const SEARCH_BODY_STSTEMS = "SEARCH_BODY_STSTEMS";
export const SEARCH_UNDESIRABLE_EFFECTS = "SEARCH_UNDESIRABLE_EFFECTS";
export const GET_KIND_OF_SICKS_BY_ID = "GET_KIND_OF_SICKS_BY_ID";
export const SEARCH_SICKS = "SEARCH_SICKS";
export const SEARCH_PL_MEDICAL_PRODUCTS = "SEARCH_PL_MEDICAL_PRODUCTS";
export const GET_PL_MEDICAL_PRODUCT = "GET_PL_MEDICAL_PRODUCT";
export const GET_MANUFACTURERS_BY_ID = "GET_MANUFACTURERS_BY_ID";
export const SEARCH_MANUFACTURER = "SEARCH_MANUFACTURER";
export const GET_MARKETING_AUTHORISATIONS_BY_ID =
    "GET_MARKETING_AUTHORISATIONS_BY_ID";
export const SEARCH_MARKETING_AUTHORISATION = "SEARCH_MARKETING_AUTHORISATION";
export const SEARCH_PATIENT_LEAFLETS = "SEARCH_PATIENT_LEAFLETS";
export const ALERT = "ALERT";
export const ERROR = "ERROR";
export const GET_LETTERS_COUNT = "GET_LETTERS_COUNT";
export const SEARCH_DRUGS = "SEARCH_DRUGS";
export const GET_FULL_PATIENT_LEAFLET = "GET_FULL_PATIENT_LEAFLET";
export const GET_FULL_MEDICAL_PRODUCT = "GET_FULL_MEDICAL_PRODUCT";
export const GET_RENDERED_MEDICAL_PRODUCT = "GET_RENDERED_MEDICAL_PRODUCT";
export const GET_RENDERED_PATIENT_LEAFLET = "GET_RENDERED_PATIENT_LEAFLET";
export const SEARCH_ATC = "SEARCH_ATC";
export const GET_MY_PREPARATIONS = "GET_MY_PREPARATIONS";
export const DELETE_PREPARATION = "DELETE_PREPARATION";
export const GET_FREQUENCIES = "GET_FREQUENCIES";
export const RESET = "RESET";
export const RESETED = "RESETED";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_TERMS_OF_USE = "GET_TERMS_OF_USE";
export const GET_ALERT = "GET_ALERT";
export const GET_ICD = "GET_ICD";
export const GET_CP = "GET_CP";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const LOAD_ADDS = "LOAD_ADDS";
export const GET_ATC = "GET_ATC";
export const GET_ATC_ID = "GET_ATC_ID";
export const GET_CP_ID = "GET_CP_ID";
export const GET_ICD_ID = "GET_ICD_ID";
