import React from "react";
import {
    CardBody,
    CardHeader,
} from "reactstrap";
import "./MyMedecinesList.scss";
import { connect } from "react-redux";
import MyMedicines from "./../MyMedicines/MyMedicines";

class MyMedecinesList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <>
                <CardHeader className="bg-transparent border-0">
                    <h3 style={{ marginBottom: '-20px' }} className="drugs-header">{dictionary.myDrugs.title[lang]}</h3>
                </CardHeader>
                <CardBody>
                    <p className='my-medecines-text'>
                        {dictionary.myDrugs.body_1[lang]}
                    </p>
                    <div className='my-md-wrapper'>
                       <MyMedicines />
                        <div className='line-break'></div>
                    </div>

                    <p className='my-medecines-text'>
                        {dictionary.myDrugs.body_2[lang]}
                    </p>

                    <p className='my-medecines-text'>
                        {dictionary.myDrugs.body_3[lang]}
                    </p>

                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMedecinesList);
