import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import "./AboutUs.scss";
import { getAboutUsData } from "../../../redux/actions/actions";

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getAboutUsData("1");
    }

    render() {
        const { lang, aboutUsData } = this.props;

        return (
            <div className="aboutus-container">
                <h1 className="mainTitle">
                    {aboutUsData &&
                        aboutUsData.aboutUs &&
                        aboutUsData.aboutUs.title
                        ? aboutUsData.aboutUs.title[lang]
                        : ""}
                </h1>
                <p
                    dangerouslySetInnerHTML={{
                        __html:
                            aboutUsData &&
                                aboutUsData.aboutUs &&
                                aboutUsData.aboutUs.text
                                ? aboutUsData.aboutUs.text[
                                lang
                                ]
                                : "",
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    aboutUsData: state.drugReducer.aboutUsData,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getAboutUsData: (aboutId) => {
            dispatch(getAboutUsData(aboutId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
