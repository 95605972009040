import { createConfirmation } from 'react-confirm';
import AlertDialog from './AlertDialog';
import ConfirmationDialog from './ConfirmationDialog';

const defaultConfirmation = createConfirmation(ConfirmationDialog);

export function showConfirm(confirmation, options = {}) {
  return defaultConfirmation({ confirmation, ...options });
}

const defaultAlert = createConfirmation(AlertDialog);

export function showAlert(alert, options = {}) {
  return defaultAlert({ alert, ...options });
}

