import React from 'react';
import {Row, Col, TabContent, TabPane, Container, Card, CardTitle, Nav, NavLink, NavItem, Button, Label, Input} from 'reactstrap';
import { useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const PLContentsOfThePack = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [languageTab, setTab] = useState('az');
    const [contentsProp, setContentsProp] = useState(patientLeaflet.contentsOfThePack ? {
        whatContains: patientLeaflet.contentsOfThePack.whatContains ? {
            az: patientLeaflet.contentsOfThePack.whatContains.az,
            ru: patientLeaflet.contentsOfThePack.whatContains.ru
        } : {az: '', ru: ''},
        whatLooksLike: patientLeaflet.contentsOfThePack.whatLooksLike ? {
            az: patientLeaflet.contentsOfThePack.whatLooksLike.az,
            ru: patientLeaflet.contentsOfThePack.whatLooksLike.ru
        } : {az: '', ru: ''},
    } : {
        whatContains: {az: '', ru: ''},
        whatLooksLike: {az: '', ru: ''}
    });
    
    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }

    const submitButtonHandler = () => {
        props.changeLeaflet({
            contentsOfThePack: {...patientLeaflet.contentsOfThePack,
                whatContains: {...contentsProp.whatContains, ru: contentsProp.whatContains.ru || contentsProp.whatContains.az},
                whatLooksLike: {...contentsProp.whatLooksLike, ru: contentsProp.whatLooksLike.ru || contentsProp.whatLooksLike.az}
            }
        });
        props.toggleSave(true);
    }

    return (
        <Container>
            <Row>
                <Col xs='10'>
                    <Row>
                        <Col xs='10'>
                            <Card body>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.contentsOfThePack.whatLooksLike[lang]} ${dictionary.adminDrugs.az[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setContentsProp({
                                                    ...contentsProp,
                                                    whatLooksLike: {
                                                        ...contentsProp.whatLooksLike,
                                                        az: data
                                                }});
                                        } }
                                        data={contentsProp.whatLooksLike.az} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.contentsOfThePack.whatLooksLike[lang]} ${dictionary.adminDrugs.ru[lang]}`}</CardTitle>
                                        <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setContentsProp({...contentsProp,
                                                whatLooksLike: {
                                                    ...contentsProp.whatLooksLike,
                                                    ru: data
                                                }});
                                        } }
                                        data={contentsProp.whatLooksLike.ru} />
                                </TabPane>
                                </TabContent>
                            </Card>
    
                            <Card body>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.contentsOfThePack.whatContains[lang]} ${dictionary.adminDrugs.az[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setContentsProp({
                                                    ...contentsProp,
                                                    whatContains: {
                                                        ...contentsProp.whatContains,
                                                        az: data
                                                }});
                                        } }
                                        data={contentsProp.whatContains.az} />
                                    </TabPane>
                                    <TabPane tabId='ru'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.contentsOfThePack.whatContains[lang]} ${dictionary.adminDrugs.ru[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setContentsProp({...contentsProp,
                                                    whatContains: {
                                                        ...contentsProp.whatContains,
                                                    ru: data
                                                }});
                                        } }
                                        data={contentsProp.whatContains.ru} />
                                </TabPane>
                                </TabContent>
                            </Card>
                        </Col>

                        <Col xs='2'>
                            <Nav tabs vertical pills className='lang-changer'>
                                <NavItem>
                                    <NavLink
                                        className={languageTab === 'az' ? 'active' : ''}
                                        onClick={() => { toggleLang('az'); }}>
                                        Az
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={languageTab === 'ru' ? 'active' : '' }
                                        onClick={() => { toggleLang('ru'); }}>
                                        Ru
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Col>
                <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                    <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default PLContentsOfThePack;