import React from "react";
import { Link } from "react-router-dom";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import moment from 'moment';
import config from "../../../config";
import { withTranslation } from "react-i18next";

import "./Dosage.scss";

import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";

class Dosage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news:[]
        };
    }

    componentDidMount = () => {
        fetch(`${config.url}rsses`).then((response) => {
            return response.json();
        }).then((data) => {this.setState({news: data})});
    }

    render() {
        const { t, dictionary, lang, newsData } = this.props;

        return (
            <div className="dosages_page_container">
                <div className="bg-white border-0">
                    <h1 className="mb-0 drugs-header">
                        {dictionary.dosage.dosageTitle[lang]}
                    </h1>
                </div>
                <div className="drugs-description">
                    {dictionary.dosage.dosageBody[lang]}
                </div>
                <div className="bg-white border-0">
                    <h1 className="mb-0 drugs-header">
                        {dictionary.dosage.searchTitle[lang]}
                    </h1>
                </div>
                <div className="drugs-description search_card">
                    <SearchField searchTypes="dosage"></SearchField>
                </div>
                <div className="bg-white border-0">
                    <h1 className="mb-0 drugs-header">
                        {dictionary.dosage.infoTitle[lang]}
                    </h1>
                </div>
                <div className="drugs-description">
                    {dictionary.dosage.infoBody[lang]}
                </div>
                <div className="dosage_container_main_content">
                    <div className="main_content">
                        <div className="main_content_wrapper">
                            <div className="bg-white border-0">
                                <h1 className="mb-0 drugs-header">
                                    {dictionary.dosage.popularRequestsTitle[lang]}
                                </h1>
                            </div>
                            <div className="dosages_body drugs-description">
                                <PopularRequests type="dosage" />
                            </div>
                        </div>
                        <div>
                            {this.state.news.map((el) => (
                                <div className="dosage_news">
                                    <Link to={`/drugs/one_news/${el.id}`} className='news-title'>
                                        <div dangerouslySetInnerHTML={{ __html: el.title[lang] }}></div>
                                    </Link>
                                    <div dangerouslySetInnerHTML={{ __html: el.miniText[lang] }}></div>
                                    <p className='news-date'>{el.Datetime && moment(el.Datetime) ? moment(el.Datetime).format('DD.MM.YYYY') : t("Empty date")} <Link to={'/'}>Medical</Link></p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <AdsBlock />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default withTranslation()(connect(mapStateToProps)(Dosage));
