import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Col, Container, Row } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import config from "../../../config";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import NewsBlock from "../NewsBlock/NewsBlock";
import { verfyFromParams } from "./../../../redux/actions/vd-auth";
import "./DrugsMain.scss";
import NewsMain from "./NewsMain";
import { setForProfessionals } from "../../../redux/actions/actions";
import Modal from "react-bootstrap/Modal";
import { getAdds } from "../../../redux/actions/ads-actions";

class DrugsMain extends React.Component {
    constructor(props) {
        super(props);
        this.catchSearchLetter = this.catchSearchLetter.bind(this);
        this.state = { news: [], profConfirm: false, toggleTitile: "" };
    }

    componentDidMount = () => {
        fetch(`${config.url}rsses`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ news: data });
            });

        if (this.props.location.pathname !== "/verify") {
            return;
        }
        const params = new URLSearchParams(this.props.location.search);
        const code = params.get("code");
        const userdata = params.get("userdata");
        const operation = params.get("operation");
        if (code && userdata) {
            this.props.verfyFromParams(userdata, code, operation);
        }
    };

    catchSearchLetter(searchLetter) {
        this.props.history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    render_news = () => {
        let news_blocks = [];
        for (let i = 0; i < this.state.news.length; i++) {
            let n = this.state.news[i];
            if (n.Type === "news") {
                news_blocks.push(
                    <NewsBlock
                        key={i}
                        title={n.title}
                        id={n.id}
                        Datetime={n.Datetime}
                        miniText={n.miniText}
                        EntityType={n.Type}
                        image={n.image}
                    ></NewsBlock>
                );
            }
        }
        return <div className="container_of_news_blocks">{news_blocks}</div>;
    };

    handleClose = (confirm) => {
        this.setState({ profConfirm: false });
        if (confirm) {
            this.props.setForProfessionals(true);
            this.props.getAdds(true);
        }
    };

    renderProfConfirmation = () => {
        const { lang, dictionary } = this.props;
        const { profConfirm } = this.state;
        return (
            <Modal centered show={profConfirm} onHide={this.handleClose}>
                <Modal.Header className="my-meds-modal-header" closeButton>
                    <Modal.Title className="prof-confirm-title">
                        {dictionary.confirmAction[lang]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <div>{dictionary.confirmProfessional[lang]}</div>
                        <div class="prof-button modal-button-block">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleClose(false)}
                            >
                                {dictionary.back[lang]}
                            </button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleClose(true)}
                            >
                                {dictionary.continue[lang]}
                            </button>
                        </div>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        );
    };

    render() {
        const { dictionary, lang, forProfessionals } = this.props;

        let settings = {
            touchMove: false,
            speed: 190,
            slidesToScroll: 1,
            slidesToShow: 4,

            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    },
                },
            ],
        };

        return (
            <>
                <div className="slider-box">
                    <Slider {...settings}>
                        <Link
                            className="slider-item"
                            to="/drugs/drugs_and_medications"
                        >
                            <img
                                src={require("./img/drugsAndTreatment.webp")}
                                alt={"drugs and treatment icon"}
                            />
                            <h3>
                                {
                                    dictionary.drugsMainComponent.slider
                                        .drugsAndMedications[lang]
                                }
                            </h3>
                        </Link>
                        <Link to="/drugs/side_effects" className="slider-item">
                            <img src={require("./img/sideEffects.webp")} />
                            <h3>
                                {
                                    dictionary.drugsMainComponent.slider
                                        .sideEffects[lang]
                                }
                            </h3>
                        </Link>
                        <Link to="/drugs/my_drugs" className="slider-item">
                            <img src={require("./img/my_drugs.webp")} />
                            <h3>
                                {
                                    dictionary.drugsMainComponent.slider
                                        .myMedList[lang]
                                }
                            </h3>
                        </Link>
                        <div className="slider-item">
                            <img
                                src={require("./img/cropped_interactions.webp")}
                            />
                            <h3 className="slider_item_header_inactive">
                                {
                                    dictionary.drugsMainComponent.slider
                                        .interactionsChecker[lang]
                                }
                            </h3>
                        </div>
                        <div
                            to="/drugs/conditions_and_diseases"
                            className="slider-item"
                        >
                            <img
                                style={{ opacity: 0.1 }}
                                src={require("./img/conditionsAndDiseases.webp")}
                            />
                            <h3 className="slider_item_header_inactive">
                                {
                                    dictionary.drugsMainComponent.slider
                                        .conditionsDiseases[lang]
                                }
                            </h3>
                        </div>
                    </Slider>
                </div>

                <div className="main-page-content-block">
                    <Container>
                        <Row>
                            <Col className="search-box">
                                <h2 className="browse-drugs">
                                    {
                                        dictionary.drugsMainComponent
                                            .drugsReview[lang]
                                    }
                                </h2>
                                <div className="alphabet-block-main-page alphabet_block">
                                    {alphabeticalLoop(
                                        lang,
                                        letterJsx,
                                        this.catchSearchLetter,
                                        undefined,
                                        this.props.lettersCount
                                    )}
                                    <button
                                        onClick={() =>
                                            this.props.history.push(
                                                "/drugs/advanced-search"
                                            )
                                        }
                                        className="alphabet_button advanced-search-alphabet-button live-button"
                                        value={dictionary.advancedSearch[lang]}
                                    >
                                        {dictionary.advancedSearch[lang]}
                                    </button>
                                </div>

                                <div>
                                    <span className="search-links">
                                        A-dan Z-yə baxış:
                                        <Link
                                            to="/drugs/drugs_and_medications"
                                            className="pl-1 search-links__link"
                                        >
                                            dərman
                                        </Link>
                                        <span className="pr-1">,</span>
                                        <Link
                                            className="search-links__link"
                                            to="/drugs/side_effects"
                                        >
                                            təsirlər
                                        </Link>
                                        <span className="pr-1"> və</span>
                                        <Link
                                            to="/drugs/pregnancy_and_lactation"
                                            className="search-links__link"
                                        >
                                            hamiləlik
                                        </Link>
                                    </span>
                                </div>
                            </Col>
                            <Col className="browseSectionsBlock">
                                <h2 className="browseSectionsHeader">
                                    {
                                        dictionary.drugsMainComponent
                                            .browseSections[lang]
                                    }
                                </h2>
                                <Container className="browseSectionsContent">
                                    <Row>
                                        <Col xs="7">
                                            <Link to="/drugs/drugs_and_medications">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .drugsFromAtoZ[lang]
                                                }
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link
                                                onClick={() => {
                                                    if (!forProfessionals) {
                                                        this.setState({
                                                            profConfirm: true,
                                                            toggleTitile:
                                                                "Peşəkar versiyası",
                                                        });
                                                    } else {
                                                        this.props.setForProfessionals(
                                                            !forProfessionals
                                                        );
                                                        this.props.getAdds(
                                                            !forProfessionals
                                                        );
                                                        this.setState({
                                                            toggleTitile:
                                                                "İstifadəçi versiyası",
                                                        });
                                                    }
                                                }}
                                            >
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .medicalEmployees[lang]
                                                }
                                            </Link>
                                            {/* <span>{dictionary.drugsMainComponent.medicalEmployees[lang]}</span> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="7">
                                            <Link to="/drugs/side_effects">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .sideEffectsChecker[
                                                        lang
                                                    ]
                                                }
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link to="/drugs/news/_/types=all">
                                                Xəbərlər
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="7">
                                            <Link to="/drugs/dosage_recomendations">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .dosageRecomendations[
                                                        lang
                                                    ]
                                                }
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link to="/drugs/news/_/types=alert">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .FDAWarnings[lang]
                                                }
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="7">
                                            <Link to="/drugs/my_drugs">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .manageYourDrugs[lang]
                                                }
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link to="/drugs/news/_/types=newdrug">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .newDrugs[lang]
                                                }
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="7">
                                            <Link to="/drugs/mobile-app/">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .mobileApps[lang]
                                                }
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link to="/drugs/pregnancy_and_lactation">
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .pregnancySections[lang]
                                                }
                                            </Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                    {this.renderProfConfirmation()}
                </div>

                <NewsMain news={this.state.news} />
                {/* <div className="news_sda_block">
                    <AdsBlock className="desktop tablet_large tablet_small"></AdsBlock>
                    {this.render_news()}
                    <AdsBlock className="mobile"></AdsBlock>
                </div> */}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    lettersCount: state.searchByLetterReducer.lettersCount,
    newsData: state.drugReducer.newsData,
    forProfessionals: state.drugReducer.forProfessionals,
});

const mapDispatchToProps = (dispatch) => {
    return {
        verfyFromParams,
        setForProfessionals: (forProfessionals) => {
            dispatch(setForProfessionals(forProfessionals));
        },
        getAdds: (professional) => {
            dispatch(getAdds(professional));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugsMain);
