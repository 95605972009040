import React from "react";

import "./DrugBriefing.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';

class DrugBriefing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { lang, dictionary, drugData } = this.props;

        const date = moment(drugData.Confirmed);
        return (
            <div className="drug_briefing">
                <h3>
                    <Link to={drugData.drugPage}>
                        {{az: drugData.TitleAz, ru: drugData.TitleRu}[lang]}
                        <span className="drug_briefing__subtitle">{{az: drugData.SubtitleAz, ru: drugData.SubtitleRu}[lang]}</span>
                    </Link>
                </h3>
                <p>
                    <span>{dictionary.drugBriefing.company[lang]}</span>
                    {{az: drugData.source.companyAz, ru: drugData.source.companyRu}[lang]}
                </p>
                {date.isValid() && <p>
                    <span>{dictionary.drugBriefing.date[lang]}</span>
                    {date.format('DD.MM.YYYY')}
                </p>}
                <p className="drug_briefing__treatment">
                    <span>{dictionary.drugBriefing.curing[lang]}</span>
                    <div>{{az: drugData.source.treatmentAz, ru: drugData.source.treatmentRu}[lang]}</div>
                </p>
                <p dangerouslySetInnerHTML={{__html: {az: drugData.source.miniTextAz, ru: drugData.source.miniTextRu}[lang]}}></p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
});

export default connect(mapStateToProps)(DrugBriefing);
