import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "../DrugsByLetter/DrugsByLetter.scss";
import { alphabeticalLoop } from "../../../utils/alphabetical-loop";
import { letterJsx } from "../AdvancedSearch/advanced-search-container";
import { connect } from "react-redux";
import { getSearchResult, PAGE_SIZE } from "../../../redux/actions/actions";
import { SEARCH_RESULT_ARRAY } from "../../../redux/actions/action-types";
import { CenterLoader } from "../Loader/Loader";
import history from "./../../../history";

const searchParams = "nameOnly=1&types=consumer,professional";

class DrugsByLetter extends React.Component {
    constructor(props) {
        super(props);
        this.toDrugsByLetter = this.toDrugsByLetter.bind(this);

        this.state = {
            old_id: props.match ? props.match.params.id : undefined,
        };
    }

    toDrugsByLetter(searchLetter) {
        history.push("/drugs/drugs-by-letter/" + searchLetter);
    }

    componentDidMount = () => {
        if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id
        ) {
            this.props.getSearchResult(
                this.props.match.params.id,
                searchParams,
                0
            );
        }
    };

    componentDidUpdate = () => {
        if (
            this.props.match &&
            this.props.match.params &&
            this.state.old_id !== this.props.match.params.id
        ) {
            this.props.getSearchResult(
                this.props.match.params.id,
                searchParams,
                0
            );
            this.setState({ old_id: this.props.match.params.id });
        }
    };

    render() {
        const searchLetter = this.props.match ? this.props.match.params.id : 0;
        const {
            dictionary,
            lang,
            searchResults,
            searchTotal,
            loading,
            searchPage,
        } = this.props;
        let pages = [];

        if (searchTotal > 0) {
            const totalPages = Math.ceil(searchTotal / PAGE_SIZE);
            pages = [...Array(totalPages).keys()].map((p) => {
                if (p < 4 || p > totalPages - 5) {
                    return p;
                }
                if (p - searchPage < 4 && p - searchPage > -4) {
                    return p;
                }
                return "...";
            });

            pages = pages.filter((p, i) => i === 0 || pages[i - 1] !== "...");
        }

        let langForAlphabet = lang;

        if (/[а-я]/i.test(searchLetter)) {
            langForAlphabet = "ru";
        } else if (/[a-z]/i.test(searchLetter)) {
            langForAlphabet = "az";
        }

        return (
            <>
                <div className="drugs-by-letter">
                    <h3 className="main-title-drugs-by-letter">
                        {dictionary.drugs[lang]}: {searchLetter}
                    </h3>
                    <div className="alphabet_block_by_letter alphabet_block">
                        {alphabeticalLoop(
                            langForAlphabet,
                            letterJsx,
                            this.toDrugsByLetter,
                            searchLetter,
                            this.props.lettersCount
                        )}
                        <button
                            className="alphabet_button live-button"
                            onClick={() =>
                                this.props.history.push(
                                    "/drugs/advanced-search"
                                )
                            }
                        >
                            {dictionary.advancedSearch[lang]}
                        </button>
                    </div>
                    <div className="description-title">
                        <p className="resultsTitle">
                            {dictionary.searchResults[lang] +
                                " " +
                                (searchTotal &&
                                !loading.subjects.has(SEARCH_RESULT_ARRAY)
                                    ? searchTotal
                                    : "...")}
                        </p>
                        <div>
                            {loading.subjects.has(SEARCH_RESULT_ARRAY) && (
                                <CenterLoader className="loading-search-results"></CenterLoader>
                            )}
                            {!loading.subjects.has(SEARCH_RESULT_ARRAY) &&
                                searchResults.map((drug) => {
                                    const title = drug.source.titleRu
                                        ? drug.source.titleAz
                                            ? drug.source[
                                                  "title" +
                                                      {
                                                          az: "Az",
                                                          ru: "Ru",
                                                          en: "En",
                                                      }[lang]
                                              ]
                                            : drug.source.titleRu
                                        : drug.source.titleAz;
                                    let body = drug.source.contentRu
                                        ? drug.source.contentAz
                                            ? drug.source[
                                                  "content" +
                                                      {
                                                          az: "Az",
                                                          ru: "Ru",
                                                          en: "En",
                                                      }[lang]
                                              ]
                                            : drug.source.contentRu
                                        : drug.source.contentAz;
                                    if (body.length > 400) {
                                        body = body.substring(0, 400);
                                        const space = body.lastIndexOf(" ");
                                        if (space > 0) {
                                            body =
                                                body.substring(0, space) +
                                                "...";
                                        } else {
                                            body += "...";
                                        }
                                    }
                                    return (
                                        <div
                                            key={drug.source.id}
                                            className="drug-name-others"
                                        >
                                            <h2>
                                                <Link
                                                    className="search_result_link"
                                                    to={`/drugs/drug-details/${drug.source.entityId}`}
                                                >
                                                    {title}
                                                </Link>
                                            </h2>
                                            <p className="text">{body}</p>
                                        </div>
                                    );
                                }, this)}
                            {searchTotal &&
                                searchTotal > 1 &&
                                !loading.subjects.has(SEARCH_RESULT_ARRAY) && (
                                    <div className="page-block">
                                        {pages.map((page) => {
                                            if (page === "...") {
                                                return (
                                                    <div
                                                        className="page-skip"
                                                        key="..."
                                                    >
                                                        ...
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div
                                                    className={
                                                        "page-button" +
                                                        (page === searchPage
                                                            ? " page-current"
                                                            : "")
                                                    }
                                                    key={page}
                                                    onClick={() =>
                                                        this.props.getSearchResult(
                                                            this.props.match
                                                                .params.id,
                                                            searchParams,
                                                            page
                                                        )
                                                    }
                                                >
                                                    {page + 1}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lettersCount: state.searchByLetterReducer.lettersCount,
    searchResults: state.drugReducer.searchResultArray,
    searchPage: state.drugReducer.searchPage,
    searchTotal: state.drugReducer.searchTotal,
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
    loading: state.loadingReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getSearchResult: (searchNameDrug, params, page) => {
            dispatch(getSearchResult(searchNameDrug, params, page));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugsByLetter);
