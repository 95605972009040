import React from "react";

import "./SearchField.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { getSuggestions } from "./../../../services/services";

import {
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import { withRouter } from "react-router-dom";

import debounce from "lodash.debounce";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

class SearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            suggestions: [],
        };

        this.onSuggestionsFetchRequested = debounce(
            this.onSuggestionsFetchRequested.bind(this),
            400
        );
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        let suggestions = getSuggestions(value, this.props.lang);
        suggestions.then((sugs) => {
            this.setState({
                suggestions: sugs,
            });
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });
    };

    render() {
        const { lang, dictionary, view } = this.props;
        const { suggestions, value } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: this.props.placeholder
                ? this.props.placeholder
                : dictionary.search[lang],
            value,
            onChange: this.onChange,
        };

        return (
            <>
                <Form className="navbar-search navbar-search-light form-inline search_field">
                    <FormGroup className="mb-0" style={{ margin: "auto" }}>
                        <InputGroup className="input-group-alternative">
                            <div className="search_input_wrapper">
                                <Autosuggest
                                    className="search_input"
                                    id="check_field"
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                        this.onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                        this.onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    onKeyPress={(event) => {
                                        // console.log('this.state.value', this.state.value);
                                        // console.log('this.state.search', this.state.searchTypes);
                                        event.preventDefault();
                                        if (
                                            event.key === "Enter" &&
                                            (this.state.value ||
                                                view === "news")
                                        ) {
                                            this.props.history.push(
                                                `/drugs/${
                                                    view || "search-results"
                                                }/${
                                                    this.state.value || "_"
                                                }/type=${
                                                    this.props.searchTypes
                                                }`
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div
                                className="search_button"
                                addonType="prepend"
                                onClick={(event) => {
                                    if (this.state.value || view === "news") {
                                        this.props.history.push(
                                            // there was | {view='news'} and ${this.props.searchTypes} at the end
                                            `/drugs/${"search-results"}/${
                                                this.state.value || "_"
                                            }/types=${this.props.searchTypes}`
                                        );
                                    }
                                }}
                            >
                                <InputGroupText>
                                    <div
                                        style={{
                                            color: "inherit",
                                        }}
                                    >
                                        <img
                                            src={require("./img/search_icon.webp")}
                                            alt="search icon"
                                            className="fas"
                                        />
                                    </div>
                                </InputGroupText>
                            </div>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
});

export default withRouter(connect(mapStateToProps)(SearchField));
