import React from "react";
import { CardHeader, CardBody } from "reactstrap";

import { connect } from "react-redux";

import "./InternationalDatabase.scss";

import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";

class InternationalDatabase extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <div className="international_database_page">
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 international_database_title">
                        {dictionary.internationalDatabase.internationalDatabaseTitle[lang]}
                    </h1>
                </CardHeader>
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 international_databases_title">
                        {dictionary.internationalDatabase.searchTitle[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="international_database_body search_card">
                    <SearchField searchTypes="international_database"></SearchField>
                </CardBody>
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 international_database_title">
                        {dictionary.internationalDatabase.infoTitle[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="international_database_body">
                    {dictionary.internationalDatabase.infoBody[lang]}
                </CardBody>
                <div className="main_content_ads_wrapper">
                    <div className="main_content">
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 international_database_title">
                                {dictionary.internationalDatabase.popularRequestsTitle[lang]}
                            </h1>
                        </CardHeader>
                        <CardBody className="international_database_body">
                            <PopularRequests type="international_database"/>
                        </CardBody>
                    </div>
                    <AdsBlock></AdsBlock>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default connect(mapStateToProps)(InternationalDatabase);
