import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import "./PrivacyPolicy.scss";
import { getPrivacyPolicy } from "../../../redux/actions/actions";
import moment from 'moment';

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getPrivacyPolicy();
    }

    render() {
        const { lang, dictionary, privacyPolicy } = this.props;
        moment.locale(lang);

        return (
            <>
            <Container>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <CardHeader className="bg-white border-0">
                            <h1 className="mainTitle center-title" dangerouslySetInnerHTML={{__html: ((privacyPolicy || {}).title || {})[lang] || ''}} />
                            <p style={{textAlign: 'center'}}><i> {`${dictionary.footer.terms.lastEdited[lang]}: ${moment((privacyPolicy || {}).updated_at).format('DD.MM.YYYY')}`} </i></p>
                        </CardHeader>
                        <CardBody>
                            <p dangerouslySetInnerHTML={{__html: ((privacyPolicy || {}).text || {})[lang] || ''}} />
                        </CardBody>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionary: state.dictionaryReducer,
    privacyPolicy: state.drugReducer.privacyPolicy,
});

const mapDispatchToProps = {
    getPrivacyPolicy
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
