import {
    LOGIN,
    GET_USER_DATA,
    AUTHORIZED
} from '../actions/action-types';

const initialState = {
    token: localStorage.getItem('usertoken') || null,
    authorized: localStorage.getItem('authorized') === 'true',
    userData: null
}

const userReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOGIN:
            localStorage.setItem('usertoken', payload);
            return {...state, token: payload };
        case AUTHORIZED:
            localStorage.setItem('authorized', payload.toString());
            return {...state, authorized: payload }
        case GET_USER_DATA:
            return {...state, userData: payload }
        default:
            return state;
    }
}

export default userReducer;