import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../form-components/TextInput';
import { withTranslation } from 'react-i18next';

class RegistrationProfileForm extends React.Component {
    render() {
        const { medWorker } = this.props;
        return (
            <div>
                <Field name="firstName" placeholder={this.props.t('First Name')} component={TextInput} />
                <Field name="lastName" placeholder={this.props.t('Last Name')} component={TextInput} />
                {medWorker && <Field name="specialization" placeholder={this.props.t('Specialization')} component={TextInput} />}
                {medWorker && <Field name="institution" placeholder={this.props.t('Medical Institution')} component={TextInput} />}
            </div>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    const { t } = props;
    if (!values.login || values.login.trim() === '') {
        errors.login = t('Field couldn\'t be empty');
    }
    if (!values.password || values.password.trim() === '') {
        errors.password = t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm({
    form: 'RegistrationProfileForm',
    validate
})(RegistrationProfileForm);

export default connect(null)(withTranslation()(form));