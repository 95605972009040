import React from "react";
import { connect } from "react-redux";
import DrugsCategories from "../Elements/DrugsCategories";
import DrugsPopular from "../DrugsPopular/DrugsPopular";
import "./DrugsAZ.scss";
import { Link } from "react-router-dom";
import AdsBlock from "../AdsBlock/AdsBlock";
import SearchField from "../SearchField/SearchField";
import config from "../../../config";
import Letters from "../Letters/Letters";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

class DrugsAZ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popularRequests: [],
        };
    }

    componentDidMount = () => {
        this.fetchPopularRequests();
    };

    fetchPopularRequests = async () => {
        let request = await fetch(
            `${config.url}popular-search-results?_sort=requestNumber:desc&type=drugs&_limit=10`
        );
        let data = await request.json();
        this.setState({
            popularRequests: data,
        });
    };

    getPopularRequests = (requests) => {
        let rows = [];
        for (let f = 0; f < 10; f++) {
            let current_request = requests[f];
            if (current_request) {
                rows.push(
                    <Link
                        key={`${f}`}
                        to={`/drugs/search-results/${current_request.request}/types=professional`}
                    >
                        {current_request.request}
                    </Link>
                );
            } else {
                break;
            }
        }
        return rows;
    };

    render() {
        const { dictionary, lang, t } = this.props;

        return (
            <div className="drugs-az_container">
                <div className="drugs-az">
                    <div className="drugs-az__header">
                        <h1 className="drugs-header">
                            {dictionary.drugzAZ.title[lang]}
                        </h1>
                        <p className="drugs-description">
                            {dictionary.drugzAZ.description[lang]}
                        </p>
                        <h2 className="drugs-header">
                            {dictionary.drugzAZ.searchEffects[lang]}
                        </h2>
                        <SearchField
                            placeholder={
                                dictionary.drugzAZ.searchPlaceholder[lang]
                            }
                            searchTypes="professional"
                        ></SearchField>
                    </div>
                    <h3 className="drugs-header">{t("View by alphabet")}</h3>
                    <Letters />
                    <DrugsCategories />
                    <DrugsPopular />
                </div>
                <div>
                    <AdsBlock
                        component={
                            <div style={{ marginTop: "1rem" }}>
                                {/* <h3>
                            {dictionary.allNews.popularRequestsAds[lang]}
                        </h3> */}
                                {/* <div className="list">
                            {this.getPopularRequests(this.state.popularRequests)}
                        </div> */}
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default connect(mapStateToProps)(withTranslation()(DrugsAZ));
