import React, {useState, useEffect} from 'react';
import {
    Container,
    NavLink,
    Collapse,
    Navbar,
    Nav,
    NavItem,
    Jumbotron,
    Button} from 'reactstrap';
import { useSelector } from 'react-redux';
import {Redirect} from 'react-router-dom';

const AdminMain = () => {
    const {lang, dictionary, authorized} = useSelector(state => ({
        dictionary: state.dictionaryReducer,
        lang: state.languageReducer.language,
        authorized: state.userReducer.authorized,
        token: state.userReducer.token
    }));

    const [collapseOpen] = useState(true);
    if (!authorized) return (<Redirect to={`/login`} />)
    else return (
        <div>
            <NavPanel isOpen={collapseOpen} lang={lang} dictionary={dictionary} />
            <Container>
                <Jumbotron>
                    <h1 className="display-3">Hello, world!</h1>
                    <p className="lead">This is a simple hero unit, a simple Jumbotron-style component for calling extra attention to featured content or information.</p>
                    <hr className="my-2" />
                    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
                    <p className="lead">
                    <Button color="primary">Learn More</Button>
                    </p>
                </Jumbotron>
            </Container>
        </div>
    )
}

const NavPanel = (props) => {
    const {lang, dictionary} = props;
    return (
        <Navbar color="light" light expand="md">
            <Collapse isOpen={props.isOpen}>
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <NavLink href="/admin/medical-products">{dictionary.adminDrugs.medicalProducts[lang]}</NavLink>
                    </NavItem>
                    <NavItem className="ml-4">
                        <NavLink href="/admin/patient-leaflets">{dictionary.adminDrugs.patientLeaflets[lang]}</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    )
}

export default AdminMain;