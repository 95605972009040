import debounce from "lodash.debounce";
import React from "react";
import Autosuggest from "react-autosuggest";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Navbar,
    NavLink,
    Row,
    UncontrolledDropdown,
    Button,
} from "reactstrap";
import config from "../../../config";
import { setForProfessionals } from "../../../redux/actions/actions";
import { setLanguage } from "../../../redux/actions/simpleActions";
import AuthComponentContainer from "../Auth/Auth";
import { getSuggestions } from "./../../../services/services";
import "./DrugsHeader.scss";
import { getAdds } from "./../../../redux/actions/ads-actions";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

class DrugsHeader extends React.Component {
    constructor(props) {
        super(props);

        let value = "";
        let activeDropdown = "allResults";

        if (window.location.pathname.indexOf("search-results") !== -1) {
            value = `${this.props.match.params.id}`;
            //search type
            if (
                window.location.pathname.endsWith("types=") ||
                window.location.pathname.indexOf("types=&") !== -1
            ) {
                activeDropdown = "allResults";
            } else if (
                window.location.pathname.indexOf("types=consumer") !== -1
            ) {
                activeDropdown = "forConsumers";
            } else if (
                window.location.pathname.indexOf("types=professional") !== -1
            ) {
                activeDropdown = "forProfessionals";
            } else if (
                window.location.pathname.indexOf("types=sideeffect") !== -1
            ) {
                activeDropdown = "sideEffects";
            } else if (
                window.location.pathname.indexOf("types=disease") !== -1
            ) {
                activeDropdown = "diseases";
            } else if (
                window.location.pathname.indexOf("types=newdrug") !== -1
            ) {
                activeDropdown = "drugs";
            } else if (window.location.pathname.indexOf("types=alert") !== -1) {
                activeDropdown = "alerts";
            } else if (window.location.pathname.indexOf("types=trial") !== -1) {
                activeDropdown = "trial";
            } else if (window.location.pathname.indexOf("types=news") !== -1) {
                activeDropdown = "news";
            } else if (
                window.location.pathname.indexOf("types=pharma_companies") !==
                -1
            ) {
                activeDropdown = "companies";
            } else if (
                window.location.pathname.indexOf("types=pregnancy") !== -1
            ) {
                activeDropdown = "pregnancy";
            } else {
                activeDropdown = "allResults";
            }
        } else if (window.location.pathname.indexOf("new_drugs") !== -1) {
            activeDropdown = "drugs";
        } else if (window.location.pathname.indexOf("warnings") !== -1) {
            activeDropdown = "alerts";
        } else if (
            window.location.pathname.indexOf("drugs_and_medications") !== -1
        ) {
            activeDropdown = "drugs";
        } else if (window.location.pathname.indexOf("side_effects") !== -1) {
            activeDropdown = "sideEffects";
        } else if (
            window.location.pathname.indexOf("conditions_and_diseases") !== -1
        ) {
            activeDropdown = "diseases";
        }

        this.state = {
            activeDropdown: activeDropdown,
            searchNameDrug: null,
            value: value,
            suggestions: [],
            mobileNav: false,
            profConfirm: false,
            isToggleForProf: false,
            autoC: "",
        };

        this.onSuggestionsFetchRequested = debounce(
            this.onSuggestionsFetchRequested.bind(this),
            400
        );
    }

    getSuggestionValue = (suggestion) => {
        console.log(`~ getSuggestionValueSuggestion`, suggestion);
        if (suggestion) {
            this.setState({ autoC: suggestion });
        } else {
            this.setState({ autoC: "" });
        }
        return suggestion;
    };

    componentDidMount() {
        const { forProfessionals } = this.props;
        this.props.getAdds(forProfessionals);
    }

    getSearchType() {
        let searchType = "";

        if (this.state.activeDropdown === "forConsumers") {
            searchType = "consumer";
        } else if (this.state.activeDropdown === "forProfessionals") {
            searchType = "professional";
        } else if (this.state.activeDropdown === "sideEffects") {
            searchType = "sideeffect";
        } else if (this.state.activeDropdown === "diseases") {
            searchType = "disease";
        } else if (this.state.activeDropdown === "drugs") {
            searchType = "newdrug";
        } else if (this.state.activeDropdown === "alerts") {
            searchType = "alert";
        } else if (this.state.activeDropdown === "trial") {
            searchType = "trial";
        } else if (this.state.activeDropdown === "news") {
            searchType = "news";
        } else if (this.state.activeDropdown === "companies") {
            searchType = "pharma_companies";
        } else if (this.state.activeDropdown === "pregnancy") {
            searchType = "pregnancy";
        }

        return `types=${searchType}`;
    }

    onChange = (event, { newValue }) => {
        this.setState({ value: newValue });
    };

    toggleMobileNav = () => {
        this.setState({ ...this.state, mobileNav: !this.state.mobileNav });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        let suggestions = getSuggestions(value, this.props.lang);
        suggestions.then((sugs) => {
            this.setState({
                suggestions: sugs,
            });
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    //   onSuggestionsClearRequested = () => {
    //     this.setState({
    //       suggestions: [],
    //     })
    //   }

    handleClose = (confirm) => {
        this.setState({ profConfirm: false });
        if (confirm) {
            this.props.setForProfessionals(true);
            this.props.getAdds(true);
        }
    };

    renderProfConfirmation = () => {
        const { lang, dictionary } = this.props;
        const { profConfirm } = this.state;
        return (
            <Modal centered show={profConfirm} onHide={this.handleClose}>
                <Modal.Header className="my-meds-modal-header" closeButton>
                    <Modal.Title className="prof-confirm-title">
                        {dictionary.confirmAction[lang]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <div>{dictionary.confirmProfessional[lang]}</div>
                        <div className="prof-button modal-button-block">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleClose(false)}
                            >
                                {dictionary.back[lang]}
                            </button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleClose(true)}
                            >
                                {dictionary.continue[lang]}
                            </button>
                        </div>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        );
    };

    render() {
        console.log("this.state.autoC", this.state.autoC);
        console.log("this.state.suggestions", this.state.suggestions);
        const { activeDropdown, searchNameDrug } = this.state;
        const { lang, dictionary, headerDropdown, forProfessionals } =
            this.props;
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: dictionary.search[lang],
            value,
            onChange: this.onChange,
        };

        let search_component = (
            <Row className="justify-content-center">
                <Col className="search_column">
                    {this.props.location.pathname === "/" && (
                        <h2 className="search_header everywhere-search__text">
                            {dictionary.headerSearchHeader[lang]}
                        </h2>
                    )}
                    <Form className="everywhere-search navbar-search navbar-search-light form-inline d-md-flex">
                        <FormGroup
                            className="mb-0 header_input"
                            style={{ margin: "auto" }}
                        >
                            <InputGroup
                                className="input-group-alternative"
                                onKeyPress={(event) => {
                                    if (
                                        event.key === "Enter" &&
                                        this.state.value.replace(/^\s/g, "")
                                            .length !== 0
                                    ) {
                                        event.preventDefault();
                                        if (
                                            this.state.value.replace(/^\s/g, "")
                                                .length !== 0
                                        ) {
                                            this.props.history.push(
                                                `/drugs/search-results/${
                                                    this.state.value
                                                }/${this.getSearchType()}${
                                                    this.state.suggestions.includes(
                                                        this.state.value
                                                    )
                                                        ? "/nameonly=1"
                                                        : ""
                                                }`
                                            );
                                        }
                                        this.setState({ suggestions: [] });
                                    }
                                }}
                            >
                                <InputGroupAddon
                                    addonType="append"
                                    className="desktop tablet_large"
                                >
                                    <InputGroupText>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                style={{
                                                    paddingLeft: "0.5rem",
                                                }}
                                                className="search-filter"
                                                caret
                                            >
                                                <span className="text-greyish dropdown-title">
                                                    {
                                                        dictionary
                                                            .headerDropDown[
                                                            activeDropdown
                                                        ][lang]
                                                    }
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-content">
                                                {headerDropdown.map((str) => {
                                                    return (
                                                        <DropdownItem
                                                            key={str}
                                                            className="dropdownItemPointer"
                                                            active={
                                                                activeDropdown ===
                                                                str
                                                                // &&
                                                                // activeDropdown !==
                                                                // "diseases"
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    this.state
                                                                        .activeDropdown !==
                                                                    str
                                                                    // &&
                                                                    // str !==
                                                                    // "diseases"
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            activeDropdown:
                                                                                str,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                dictionary
                                                                    .headerDropDown[
                                                                    str
                                                                ][lang]
                                                            }
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                        this.onSuggestionsFetchRequested
                                    }
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    onSuggestionSelected={
                                        this.onSuggestionSelected
                                    }
                                    onSuggestionHighlighted={
                                        this.onSuggestionHighlighted
                                    }
                                />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <div
                                            onClick={() => {
                                                if (
                                                    this.state.value.replace(
                                                        /^\s/g,
                                                        ""
                                                    ).length !== 0
                                                ) {
                                                    this.props.history.push(
                                                        `/drugs/search-results/${
                                                            this.state.value
                                                        }/${this.getSearchType()}${
                                                            this.state.suggestions.includes(
                                                                this.state.value
                                                            )
                                                                ? "/nameonly=1"
                                                                : ""
                                                        }`
                                                    );
                                                }
                                                this.setState({
                                                    suggestions: [],
                                                });
                                            }}
                                            style={{
                                                color: "inherit",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                src={require("./img/search.webp")}
                                                width="30px"
                                                alt="search icon"
                                                className="fas"
                                            />
                                        </div>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        );

        let links_component = (
            <div className="middle_links_togglers">
                <div className="drugs_navbar_middle_links">
                    <Link
                        className="pointer header-link"
                        to="/drugs/news/_/types=newdrug"
                    >
                        {dictionary.navBarComponent.new_drugs[lang]}
                    </Link>

                    <Link
                        className="pointer header-link"
                        to="/drugs/news/_/types=alert"
                    >
                        {dictionary.navBarComponent.warnings[lang]}
                    </Link>
                </div>
                {/* <div className="header-languages">
                <NavLink
                    className={"padding-for-mobile pointer header-language" + (lang === 'az' ? ' selected-language' : '')}
                    onClick={() => this.props.setLanguage("az")}>
                    Az
                </NavLink>
                <NavLink
                    className={"padding-for-mobile pointer header-language" + (lang === 'ru' ? ' selected-language' : '')}
                    onClick={() => this.props.setLanguage("ru")}>
                    Ru
                </NavLink>

            </div> */}
                <div>
                    <AuthComponentContainer view="button" />
                </div>
            </div>
        );

        return (
            <>
                <div
                    className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                    style={{
                        minHeight: "300px",
                        backgroundImage:
                            "url(" +
                            require("assets/img/theme/pills-green.webp") +
                            ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                >
                    <Navbar
                        className="navbar-top navbar-dark"
                        expand="md"
                        id="navbar-main"
                    >
                        <Container className="navbar_container">
                            <Row className="main_row">
                                <Col>
                                    <div className="logo">
                                        <NavLink
                                            className="brand-name-padding-mobile h1 mb-0 text-uppercase d-lg-inline-block"
                                            to="/"
                                            tag={Link}
                                        >
                                            <img
                                                src={require("./img/logo.webp")}
                                                alt={"Dermanlar logo"}
                                                height="100rem"
                                            />
                                        </NavLink>
                                        <button
                                            className={
                                                "btn padding-for-mobile mt-3 tab-for-pro"
                                            }
                                            onClick={() => {
                                                if (!forProfessionals) {
                                                    this.setState({
                                                        profConfirm: true,
                                                    });
                                                } else {
                                                    this.props.setForProfessionals(
                                                        !forProfessionals
                                                    );
                                                    this.props.getAdds(
                                                        !forProfessionals
                                                    );
                                                }
                                                this.setState((state) => ({
                                                    isToggleForProf:
                                                        !state.isToggleForProf,
                                                }));
                                            }}
                                        >
                                            {!!forProfessionals
                                                ? "Peşəkərlar versiyası"
                                                : "İstifadəçi versiyası"}
                                        </button>
                                    </div>

                                    <div className="menu-tablet desktop tablet_large tablet_small">
                                        {links_component}
                                        {/* {
                                        this.props.location.pathname === "/"
                                            ? (links_component) : (<div>
                                                {links_component}
                                                {<Container className="header_search_container other">{search_component}</Container>}
                                            </div>)
                                    } */}
                                    </div>
                                    <button
                                        onClick={() => this.toggleMobileNav()}
                                        className="navbar-toggler"
                                        id="navbar-collapse-main"
                                    >
                                        <img src={require("./img/menu.webp")} />
                                    </button>

                                    <div className="mobile">
                                        <Collapse
                                            isOpen={this.state.mobileNav}
                                            className="collapse-btn"
                                            navbar
                                            toggler="#navbar-collapse-main"
                                        >
                                            {/* <div className="header-languages">
                                                <NavLink className={"padding-for-mobile pointer header-language" + (lang === 'az' ? ' selected-language' : '')}
                                                    onClick={() => { this.props.setLanguage("az"); this.toggleMobileNav() }}>
                                                    Az
                                                </NavLink>
                                                <NavLink className={"padding-for-mobile pointer header-language" + (lang === 'ru' ? ' selected-language' : '')}
                                                    onClick={() => { this.props.setLanguage("ru"); this.toggleMobileNav() }}>
                                                    Ru
                                            </NavLink>
                                            </div> */}

                                            <div className="nav-link-icon header-link">
                                                <AuthComponentContainer
                                                    style={{
                                                        fontSize: "1.5rem",
                                                        paddingLeft: "0",
                                                        color: "#722d1d",
                                                        border: "none",
                                                    }}
                                                    view="link"
                                                />
                                            </div>
                                            <Link
                                                className="mobile-nav__link"
                                                to="/drugs/drugs_and_medications"
                                            >
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .drugsFromAtoZ[lang]
                                                }
                                            </Link>
                                            <Link
                                                className="mobile-nav__link"
                                                to="/drugs/side_effects"
                                            >
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .sideEffectsChecker[
                                                        lang
                                                    ]
                                                }
                                            </Link>
                                            <Link
                                                className="mobile-nav__link"
                                                to="/drugs/news/_/types=all"
                                            >
                                                {
                                                    dictionary.newDrugs.allNews[
                                                        lang
                                                    ]
                                                }
                                            </Link>
                                            <Link
                                                className="mobile-nav__link"
                                                to="/drugs/dosage_recomendations"
                                            >
                                                {
                                                    dictionary
                                                        .drugsMainComponent
                                                        .dosageRecomendations[
                                                        lang
                                                    ]
                                                }
                                            </Link>
                                            <Link
                                                className="mobile-nav__link"
                                                onClick={() => {
                                                    if (!forProfessionals) {
                                                        this.setState({
                                                            profConfirm: true,
                                                        });
                                                    } else {
                                                        this.props.setForProfessionals(
                                                            !forProfessionals
                                                        );
                                                    }
                                                    this.setState((state) => ({
                                                        isToggleForProf:
                                                            !state.isToggleForProf,
                                                    }));
                                                }}
                                            >
                                                {this.state.isToggleForProf
                                                    ? //there may be !!forProfessionals
                                                      "Peşəkərlar versiyası"
                                                    : "İstifadəçi versiyası"}
                                            </Link>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                            {/* {this.props.location.pathname === "/" ? (<Container className="header_search_container main">{search_component}</Container>)
                                : (<></>)} */}
                            <Container
                                className={
                                    "header_search_container main" +
                                    (this.props.location.pathname !== "/"
                                        ? " header_inner_search_container"
                                        : "")
                                }
                            >
                                {search_component}
                            </Container>
                        </Container>
                    </Navbar>
                    {this.renderProfConfirmation()}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    headerDropdown: state.drugReducer.headerDropdown,
    forProfessionals: state.drugReducer.forProfessionals,
    user: state.userReducer,
    authData: state.authReducer.authData,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLanguage: (language) => {
            dispatch(setLanguage(language));
        },
        setForProfessionals: (forProfessionals) => {
            dispatch(setForProfessionals(forProfessionals));
        },
        getAdds: (forProfessionals) => {
            dispatch(getAdds(forProfessionals));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugsHeader);
