import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    Row, Col,
    TabContent, TabPane,
    Container, Card, CardTitle,
    Nav, NavLink, NavItem, Button,
    ListGroup, ListGroupItem,
    Label, Input } from 'reactstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { searchATC } from '../../../../redux/actions/adminActions';

const PLPharmacologicalProperties = props => {
    const {lang, dictionary, patientLeaflet} = props;
    const [languageTab, setTab] = useState('az');
    const [pharmacoProp, setPharmacoProp] = useState(patientLeaflet.pharmacologicalProperties ? {
        PharmacologicalPropertiesGeneral: patientLeaflet.pharmacologicalProperties.PharmacologicalPropertiesGeneral ? {
            az: patientLeaflet.pharmacologicalProperties.PharmacologicalPropertiesGeneral.az,
            ru: patientLeaflet.pharmacologicalProperties.PharmacologicalPropertiesGeneral.ru
        } : {az: '', ru: ''},
        ATCCode: patientLeaflet.pharmacologicalProperties.ATCCode,
        pharmacotherapeuticGroup: patientLeaflet.pharmacologicalProperties.pharmacotherapeuticGroup ? {
            az: patientLeaflet.pharmacologicalProperties.pharmacotherapeuticGroup.az,
            ru: patientLeaflet.pharmacologicalProperties.pharmacotherapeuticGroup.ru
        } : {az: '', ru: ''},
        PharmacodynamicProperties: patientLeaflet.pharmacologicalProperties.PharmacodynamicProperties ? {
            az: patientLeaflet.pharmacologicalProperties.PharmacodynamicProperties.az,
            ru: patientLeaflet.pharmacologicalProperties.PharmacodynamicProperties.ru
        } : {az: '', ru: ''},
        PharmacokineticProperties: patientLeaflet.pharmacologicalProperties.PharmacokineticProperties ? {
            az: patientLeaflet.pharmacologicalProperties.PharmacokineticProperties.az,
            ru: patientLeaflet.pharmacologicalProperties.PharmacokineticProperties.ru
        } : {az: '', ru: ''},
        PreclinicalSafetyData: patientLeaflet.pharmacologicalProperties.PreclinicalSafetyData ? {
            az: patientLeaflet.pharmacologicalProperties.PreclinicalSafetyData.az,
            ru: patientLeaflet.pharmacologicalProperties.PreclinicalSafetyData.ru
        } : {az: '', ru: ''},
        ShelfLife: patientLeaflet.pharmacologicalProperties.ShelfLife ? {
            az: patientLeaflet.pharmacologicalProperties.ShelfLife.az,
            ru: patientLeaflet.pharmacologicalProperties.ShelfLife.ru
        } : {az: '', ru: ''}
    } : {});
    const [searchString, setSearchString] = useState('');
    const [suggest, setSuggest] = useState(false);

    const dispatch = useDispatch();
    
    const {atcSearchResult} = useSelector(state => ({
        atcSearchResult: state.adminReducer.atcSearchResult
    }));

    // отображение списка из базы
    useEffect(() => {
        if(searchString && searchString.length > 0) {
            dispatch(searchATC(searchString));
            setSuggest(true);
        } else setSuggest(false);
    }, [searchString]);

    // переключатель языкового таба во время заполнения
    const toggleLang = tab => {
        if (languageTab !== tab) setTab(tab);
    }

    const submitButtonHandler = () => {
        props.changeLeaflet({
            pharmacologicalProperties: {
                PharmacologicalPropertiesGeneral: pharmacoProp.PharmacologicalPropertiesGeneral ? 
                    {
                        ...pharmacoProp.PharmacologicalPropertiesGeneral, 
                        ru: pharmacoProp.PharmacologicalPropertiesGeneral.ru || pharmacoProp.PharmacologicalPropertiesGeneral.az} :
                        {az: '', ru: ''},
                pharmacotherapeuticGroup: pharmacoProp.pharmacotherapeuticGroup ? {
                    ...pharmacoProp.pharmacotherapeuticGroup,
                    ru: pharmacoProp.pharmacotherapeuticGroup.ru || pharmacoProp.pharmacotherapeuticGroup.az} : {az: '', ru: ''},
                PharmacodynamicProperties: pharmacoProp.PharmacodynamicProperties ? {
                    ...pharmacoProp.PharmacodynamicProperties, 
                    ru: pharmacoProp.PharmacodynamicProperties.ru || pharmacoProp.PharmacodynamicProperties.az} : {az: '', ru: ''},
                PharmacokineticProperties: pharmacoProp.PharmacokineticProperties ? {
                    ...pharmacoProp.PharmacokineticProperties, 
                    ru: pharmacoProp.PharmacokineticProperties.ru || pharmacoProp.PharmacokineticProperties.az} : {az: '', ru: ''},
                PreclinicalSafetyData: pharmacoProp.PreclinicalSafetyData ? {
                    ...pharmacoProp.PreclinicalSafetyData,
                    ru: pharmacoProp.PreclinicalSafetyData.ru || pharmacoProp.PreclinicalSafetyData.az} : {az: '', ru: ''},
                ShelfLife: pharmacoProp.ShelfLife ? {
                    ...pharmacoProp.ShelfLife, ru: pharmacoProp.ShelfLife.ru || pharmacoProp.ShelfLife.az} : {az: '', ru: ''},
                ATCCode: pharmacoProp.ATCCode
            }
        });
        props.toggleSave(true);
    }

    const selectATC = atc => {
        setPharmacoProp({...pharmacoProp, ATCCode: atc});
        setSuggest(false);
    }

    // console.log('atces', atcSearchResult);

    return (
        <Container>
            <Row>
                <Col xs='10'>
                    <Row>
                        <Col xs='10'>
                            <Card body>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.notes[lang]} ${dictionary.adminDrugs.az[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setPharmacoProp({
                                                    ...pharmacoProp,
                                                    PharmacologicalPropertiesGeneral: {
                                                        ...pharmacoProp.PharmacologicalPropertiesGeneral,
                                                        az: data
                                                }});
                                            }}
                                            data={pharmacoProp && pharmacoProp.PharmacologicalPropertiesGeneral && pharmacoProp.PharmacologicalPropertiesGeneral.az ? pharmacoProp.PharmacologicalPropertiesGeneral.az : ''} />
                                    </TabPane>
                                    <TabPane tabId='ru'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.notes[lang]} ${dictionary.adminDrugs.ru[lang]}`}</CardTitle>
                                        <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setPharmacoProp({...pharmacoProp,
                                                PharmacologicalPropertiesGeneral: {
                                                    ...pharmacoProp.PharmacologicalPropertiesGeneral,
                                                    ru: data
                                                }});
                                            }}
                                            data={pharmacoProp && pharmacoProp.PharmacologicalPropertiesGeneral && pharmacoProp.PharmacologicalPropertiesGeneral.ru ? pharmacoProp.PharmacologicalPropertiesGeneral.ru : ''} />
                                    </TabPane>
                                </TabContent>
                            </Card>
    
                            <Card body>
                                <CardTitle>{dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacodynamicProperties[lang]}</CardTitle>
                                <Row>
                                    <Col xs='3'>
                                        <Label>{dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.ATCCode[lang]}</Label>
                                    </Col>
                                    <Col xs='9'>
                                        <Input
                                            type='search'
                                            autoComplete='off'
                                            name='atc'
                                            id={`searchATC`}
                                            placeholder={dictionary.search[lang]}
                                            value={
                                                searchString || pharmacoProp.ATCCode || ''
                                            }
                                            onChange={e => {
                                                setPharmacoProp({...pharmacoProp, ATCCode: e.target.value})
                                                setSearchString(e.target.value);
                                            }} />
                                        <ListGroup>
                                            {suggest && atcSearchResult ? atcSearchResult.map(result => { console.log('res', result); return (
                                                <ListGroupItem 
                                                    style={{cursor: 'pointer'}} 
                                                    key={result.id} 
                                                    onClick={() => {
                                                        selectATC(result.code);
                                                        setSearchString(null);
                                                    }}
                                                    tag="a" 
                                                    action>{result.code} | {(result.description || {})[lang] || ''}</ListGroupItem>
                                            )}) : ''}
                                        </ListGroup>
                                    </Col>
                                </Row>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacotherapeuticGroup[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                        <Input
                                            type='textarea'
                                            value={pharmacoProp && pharmacoProp.pharmacotherapeuticGroup ? pharmacoProp.pharmacotherapeuticGroup.az : ''}
                                            onChange={e => {setPharmacoProp({
                                                ...pharmacoProp,
                                                pharmacotherapeuticGroup: {
                                                    ...pharmacoProp.pharmacotherapeuticGroup,
                                                    az: e.target.value
                                                }})}
                                            } />
                                        <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacodynamicProperties[lang]} ${dictionary.adminDrugs.az[lang]}`}</Label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setPharmacoProp({
                                                    ...pharmacoProp,
                                                    PharmacodynamicProperties: {
                                                        ...pharmacoProp.PharmacodynamicProperties,
                                                        az: data
                                                }});
                                            }}
                                            data={pharmacoProp && pharmacoProp.PharmacodynamicProperties && pharmacoProp.PharmacodynamicProperties.az ? pharmacoProp.PharmacodynamicProperties.az : ''} />
                                    </TabPane>
                                    <TabPane tabId='ru'>
                                        <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacotherapeuticGroup[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                        <Input
                                            type='textarea'
                                            value={pharmacoProp && pharmacoProp.pharmacotherapeuticGroup ? pharmacoProp.pharmacotherapeuticGroup.ru : ''}
                                            onChange={e => {setPharmacoProp({
                                                ...pharmacoProp,
                                                pharmacotherapeuticGroup: {
                                                    ...pharmacoProp.pharmacotherapeuticGroup,
                                                    ru: e.target.value
                                                }})}
                                            } />
                                        <Label className='mt-2'>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacodynamicProperties[lang]} ${dictionary.adminDrugs.ru[lang]}`}</Label>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setPharmacoProp({
                                                    ...pharmacoProp,
                                                    PharmacodynamicProperties: {
                                                        ...pharmacoProp.PharmacodynamicProperties,
                                                        ru: data
                                                }});
                                            }}
                                            data={pharmacoProp && pharmacoProp.PharmacodynamicProperties && pharmacoProp.PharmacodynamicProperties.ru ? pharmacoProp.PharmacodynamicProperties.ru : ''} />
                                    </TabPane>
                                </TabContent>
                            </Card>

                            <Card body>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacokineticProperties[lang]} ${dictionary.adminDrugs.az[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setPharmacoProp({
                                                    ...pharmacoProp,
                                                    PharmacokineticProperties: {
                                                        ...pharmacoProp.PharmacokineticProperties,
                                                        az: data
                                                }});
                                        } }
                                        data={pharmacoProp && pharmacoProp.PharmacokineticProperties && pharmacoProp.PharmacokineticProperties.az ? pharmacoProp.PharmacokineticProperties.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.pharmacokineticProperties[lang]} ${dictionary.adminDrugs.ru[lang]}`}</CardTitle>
                                        <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setPharmacoProp({...pharmacoProp,
                                                PharmacokineticProperties: {
                                                    ...pharmacoProp.PharmacokineticProperties,
                                                    ru: data
                                                }});
                                        } }
                                        data={pharmacoProp && pharmacoProp.PharmacokineticProperties && pharmacoProp.PharmacokineticProperties.ru ? pharmacoProp.PharmacokineticProperties.ru : ''} />
                                </TabPane>
                                </TabContent>
                            </Card>

                            <Card body>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.preclinicalSafetyData[lang]} ${dictionary.adminDrugs.az[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setPharmacoProp({
                                                    ...pharmacoProp,
                                                    PreclinicalSafetyData: {
                                                        ...pharmacoProp.PreclinicalSafetyData,
                                                        az: data
                                                }});
                                        } }
                                        data={pharmacoProp && pharmacoProp.PreclinicalSafetyData && pharmacoProp.PreclinicalSafetyData.az ? pharmacoProp.PreclinicalSafetyData.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.preclinicalSafetyData[lang]} ${dictionary.adminDrugs.ru[lang]}`}</CardTitle>
                                        <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setPharmacoProp({...pharmacoProp,
                                                PreclinicalSafetyData: {
                                                    ...pharmacoProp.PreclinicalSafetyData,
                                                    ru: data
                                                }});
                                        } }
                                        data={pharmacoProp && pharmacoProp.PreclinicalSafetyData && pharmacoProp.PreclinicalSafetyData.ru ? pharmacoProp.PreclinicalSafetyData.ru : ''} />
                                </TabPane>
                                </TabContent>
                            </Card>

                            <Card body>
                                <TabContent activeTab={languageTab}>
                                    <TabPane tabId='az'>
                                        <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.shelfLife[lang]} ${dictionary.adminDrugs.az[lang]}`}</CardTitle>
                                        <CKEditor
                                            editor={InlineEditor}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setPharmacoProp({
                                                    ...pharmacoProp,
                                                    ShelfLife: {
                                                        ...pharmacoProp.ShelfLife,
                                                        az: data
                                                }});
                                        } }
                                        data={pharmacoProp && pharmacoProp.ShelfLife && pharmacoProp.ShelfLife.az ? pharmacoProp.ShelfLife.az : ''} />
                                </TabPane>
                                <TabPane tabId='ru'>
                                    <CardTitle>{`${dictionary.adminDrugs.patientLeafletEditPage.pharmacologicalProperties.shelfLife[lang]} ${dictionary.adminDrugs.ru[lang]}`}</CardTitle>
                                        <CKEditor
                                        editor={InlineEditor}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setPharmacoProp({...pharmacoProp,
                                                ShelfLife: {
                                                    ...pharmacoProp.ShelfLife,
                                                    ru: data
                                                }});
                                        } }
                                        data={pharmacoProp && pharmacoProp.ShelfLife && pharmacoProp.ShelfLife.ru ? pharmacoProp.ShelfLife.ru : ''} />
                                </TabPane>
                                </TabContent>
                            </Card>
                        </Col>

                        <Col xs='2'>
                            <Nav tabs vertical pills className='lang-changer'>
                                <NavItem>
                                    <NavLink
                                        className={languageTab === 'az' ? 'active' : ''}
                                        onClick={() => { toggleLang('az'); }}>
                                        Az
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={languageTab === 'ru' ? 'active' : '' }
                                        onClick={() => { toggleLang('ru'); }}>
                                        Ru
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Col>
                <Col xs='2' className='pt-0 pl-5 pr-5 pb-5'>
                    <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default PLPharmacologicalProperties;