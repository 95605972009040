import config from "../../config";
import { LoadingSubject } from "../models/loading-state.enum";
import { getAlert } from "./actions";
import { authFetch, signOut } from "./vd-auth";
import { loadingEnd, loadingStart } from "./loading-actions";

export const SELECT = "SELECT";

export const _select = (select) => {
	return {
		type: SELECT,
		select,
	};
};

export const SUBSCRIPTION = "SUBSCRIPTION";
const _subscription = (subscriptions) => {
	return {
		type: SUBSCRIPTION,
		subscriptions,
	};
};

export const PROFILE_SUBSCRIPTION = "PROFILE_SUBSCRIPTION";
const _profilesubscription = (id) => {
	return {
		type: PROFILE_SUBSCRIPTION,
		id,
	};
};

export const SUBSCRIPTIONS_STORY = "SUBSCRIPTIONS_STORY";

const _subscriptionsStory= (subscriptionsStory) => {
	return {
		type: SUBSCRIPTIONS_STORY,
		subscriptionsStory,
	};
};

export const CLEAN_SUBSCRIPTIONS_STORY = "CLEAN_SUBSCRIPTIONS_STORY";

export const _cleanSubscriptionsStory = () => {
	return {
		type: CLEAN_SUBSCRIPTIONS_STORY,
	};
};

export const _payForSubscription = (redirect) => async (dispatch, getState) => {
	if (redirect) {
		window.location.replace(redirect);
	} else {
		dispatch(getValidSubscriptions());
	}
};

export const VALID_SUBSCRIPTIONS = "VALID_SUBSCRIPTIONS";
export const _validSubscriptions = (validSubscriptions) => {
	return {
		type: VALID_SUBSCRIPTIONS,
		validSubscriptions,
	};
};

export const subscription = () => async (dispatch, getState) => {
	dispatch(_subscription([]));
	dispatch(loadingStart(LoadingSubject.Subscriptions));
	// console.log('config.csharp', config.csharp);

	try {
		const response = await authFetch(getState, dispatch, config.csharp + "api/external/private/Subscription/list", "POST", {
			active: true,
		});

		if (response.status === 200) {
			const json = await response.json();
			dispatch(_subscription(json));
		} else if (response.status === 401) {
			dispatch(signOut());
		} else {
			dispatch(getAlert("Subscription list loading error"));
		}
	} catch (e) {
		dispatch(getAlert("Unknown error. Please, check internet connection"));
	} finally {
		dispatch(loadingEnd(LoadingSubject.Subscriptions));
	}
};

export const subscriptionsStoryList = (page) => async (dispatch, getState) => {
	if (!page) {
		dispatch(_cleanSubscriptionsStory());
	}
	dispatch(loadingStart(page == 0 ? LoadingSubject.Subscriptions : LoadingSubject.SubscriptionsMore));
	try {
		const response = await authFetch(
			getState,
			dispatch,
			config.csharp + "api/external/private/Profile/subscription/history" + `?offset=${page * 10}&limit=40`,
			"POST",
			{},
		);

		if (response.status === 200) {
			const json = await response.json();
			dispatch(_subscriptionsStory(json));
		} else if (response.status === 401) {
			dispatch(signOut());
		} else {
			dispatch(getAlert("Subscription list getting error"));
		}
	} catch (e) {
		console.warn(e);
		dispatch(getAlert("Unknown error. Please, check internet connection"));
	} finally {
		dispatch(loadingEnd(page == 0 ? LoadingSubject.Subscriptions : LoadingSubject.SubscriptionsMore));
	}
};

export const getValidSubscriptions = () => async (dispatch, getState) => {
	dispatch(loadingStart(LoadingSubject.Subscriptions));
	try {
		const response = await authFetch(getState, dispatch, config.csharp + "api/external/private/Profile/subscription/valid", "POST", {});
		if (response.status === 200) {
			const json = await response.json();
			dispatch(_validSubscriptions(json));
		} else if (response.status === 401) {
			dispatch(signOut());
		} else {
			dispatch(getAlert("Subscription list getting error"));
		}
	} catch (e) {
		dispatch(getAlert("Unknown error. Please, check internet connection"));
	} finally {
		dispatch(loadingEnd(LoadingSubject.Subscriptions));
	}
};

export const subscriptionPay = (
	subscriptionReq,
) => async (dispatch, getState) => {
	dispatch(loadingStart(LoadingSubject.Subscriptions));
	try {
		const response = await authFetch(
			getState,
			dispatch,
			config.csharp + "api/external/private/Profile/subscription/pay",
			"POST",
			subscriptionReq,
		);

		if (response.status === 200) {
			const json = await response.json();
			console.warn(json);
			if (json.paid) {
				dispatch(getAlert("Subscription has been successfully issued"));
			} else {
				dispatch(_payForSubscription(json ? json.redirect : undefined));
			}
		} else if (response.status === 401) {
			dispatch(signOut());
		} else {
			dispatch(getAlert("While subscribing an error occurred"));
		}
	} catch (e) {
		dispatch(getAlert("Unknown error. Please, check internet connection"));
	} finally {
		dispatch(loadingEnd(LoadingSubject.Subscriptions));
	}
};
