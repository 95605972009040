import React from "react";

import "./Checkbox.scss"

export default class Change extends React.Component {
    render() {
        return <div onClick={this.props.checkbox_handle} className="checkbox">
            {this.props.checked ? (
                <img src={require("./img/checked.webp")}></img>
            ) : (
                    <img src={require("./img/unchecked.webp")}></img>
                )}
        </div>
    }
}