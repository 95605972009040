import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import config from '../../config';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion ? suggestion.title: '';

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion ? suggestion.title: ''}
    </div>
);


class DrugSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            value: '',
        };
    }

    componentDidMount() {
        const { inputProps, dictionaryReducer, lang } = this.props;
        this.setState({
            value: inputProps.input.value && inputProps.input.value.name && inputProps.input.value.name.length && inputProps.input.value.name[0] && inputProps.input.value.name[0][lang] ? inputProps.input.value.name[0][lang]: ''
        });
    }

    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
        const { inputProps } = this.props;
        inputProps.input.onChange(suggestion.id);
    }

    onSuggestionsFetchRequested = ({ value }) => {
        fetch(`${config.url}search-medical-products/?_q=${value}`, {
            method: 'GET'
        }).then(res => res.json()).then(response => {
            if (response.error) {
                this.setState({ suggestions: [] })
            } else {
                this.setState({ suggestions: response || [] })
            }
        })
            .catch(err => {
                this.setState({ suggestions: [] })
            });
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    render() {
        const { value, suggestions } = this.state;
        const { inputProps } = this.props;
        const _inputProps = {
            placeholder: inputProps.placeholder,
            value,
            onChange: this.onChange
        };
        return <div className="mini-text-input-component">
            <label>{inputProps.label}</label>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={_inputProps}
            />
        </div>
    }
}

const mapStateToProps = (state) => ({
    lang: state.languageReducer.language,
    dictionaryReducer: state.dictionaryReducer,
});

const mapDispatchToProps = {
};

const DrugSelectComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(DrugSelect)
);

export default DrugSelectComponentContainer;