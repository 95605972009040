import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getMedicalProducts, countMedicalProducts, updateRenderHtml} from '../../../redux/actions/adminMedicalProductActions'

import {Table, Button, Container} from 'reactstrap'
import AdminPagination from '../AdmiPagination/AdminPagination';

import moment from 'moment';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import {Link, Redirect} from 'react-router-dom';

const MedicalProducts = props => {
    let page = props.location.search !== '' ? queryString.parse(props.location.search).page : '1';
    const start = (page - 1)*20;
    
    const dispatch = useDispatch();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const {medicalProducts, medicalProductsCount, lang, dictionary} = useSelector(state => ({
        medicalProducts: state.medicalProductsReducer.medicalProducts,
        medicalProductsCount: state.medicalProductsReducer.medicalProductsCount,
        lang: state.languageReducer.language,
        dictionary: state.dictionaryReducer
    }));

    useEffect(() => {
        if (medicalProductsCount === 0) dispatch(countMedicalProducts());     
        if (!medicalProducts || medicalProducts.length === 0) dispatch(getMedicalProducts(start));
    }, [medicalProducts, dispatch]);

    page = parseInt(page);
    const count = parseInt(medicalProductsCount);
    const lastPage = (count - count%20)/20 + 1;

    const updateHtml = id => {
        // console.log('id', id);
        dispatch(updateRenderHtml(id));
    }
    
    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>#id</th>
                        <th>{dictionary.adminDrugs.productsTable.name[lang]}</th>
                        <th>{dictionary.adminDrugs.productsTable.patientLeaflet[lang]}</th>
                        <th>{dictionary.adminDrugs.productsTable.addedDate[lang]}</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {medicalProducts.length > 0 ? 
                        medicalProducts.map(product => 
                            <tr key={product.id}>
                                <td>
                                    {product.id}
                                </td>
                                <td>
                                    {product.name[0][lang]}
                                </td>
                                <td>
                                    {product.patient_leaflet ? <Link to={`/admin/patient-leaflet/edit/${product.patient_leaflet.id}`}>
                                        {product.patient_leaflet.title[lang]}
                                    </Link> : ''}
                                </td>
                                <td>
                                    {moment(product.created_at).format('DD.MM.YYYY, hh:mm')}
                                </td>
                                <td>
                                    <Link to={`medical-product/edit/${product.id}`}>
                                        <Button color='success'><FontAwesomeIcon icon={faEdit}/></Button>
                                    </Link>
                                    <Button onClick={() => {setDeleteId(product.id); setDeleteModal(true);}} style={{marginLeft: 3}} color='danger'><FontAwesomeIcon icon={faTrash}/></Button>
                                    <Button onClick={updateHtml.bind(this, product.id)} color='primary'> <FontAwesomeIcon icon={faSyncAlt} /> </Button>
                                </td>
                            </tr>) 
                    : <tr></tr>}
                </tbody>
            </Table>
            <AdminPagination page={page} lastPage={lastPage} />
        </Container>
    )
}

export default MedicalProducts;