import React from "react";
import { CardHeader, Row, Col, CardBody } from "reactstrap";

import { connect } from "react-redux";

import "./MyDrugs.scss";

import { Link } from "react-router-dom";

import MyDrugBlock from "../MyDrugBlock/MyDrugBlock";
import history from "./../../../history";
import { toggleAuth } from "./../../../redux/actions/vd-auth";

class ConditionsAndDiseases extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { dictionary, lang, newsData, token } = this.props;
        // console.log('token', token);

        return (
            <>
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 drugs-header">
                        {dictionary.myDrugs.title[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="my_drugs_body">
                    <p>{dictionary.myDrugs.body_1[lang]}</p>
                    {/* <div className="my_drugs_blocks">{this.get_blocks()}</div> */}
                    {/* <div className="add_drug_button_wrapper">
                        <button className="add_drug_button">{dictionary.myDrugs.add_drug[lang]}</button>
                    </div> */}
                    <p>{dictionary.myDrugs.body_2[lang]}</p>
                    <p>{dictionary.myDrugs.body_3[lang]}</p>
                    <div className="add_drug_button_wrapper centred">
                        <button
                            className="btn add_drug_button big-button"
                            onClick={() => {
                                if (token) {
                                    history.push("/drugs/my-medicines-list");
                                } else {
                                    this.props.toggleAuth(true);
                                }
                            }}
                        >
                            {dictionary.myDrugs.add_drug[lang]}
                        </button>
                    </div>
                </CardBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
    token: state.vdAuthReducer.userToken,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAuth: (value) => {
            dispatch(toggleAuth(value));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionsAndDiseases);
