import config from "../../config";
import { GET_FREQUENCIES, GET_MY_PREPARATIONS, RESET, RESETED } from "./action-types";
import { authFetch } from "./vd-auth";

export const REGISTER = "REGISTER";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

function registrate(authData) {
    return {
        type: REGISTER,
        authData,
    };
}

function login(authData) {
    return {
        type: SIGN_IN,
        authData,
    };
}

function logout() {
    return {
        type: SIGN_OUT,
    };
}

function myPreparations(preparations) {
    return {
        type: GET_MY_PREPARATIONS,
        payload: preparations
    }
}

export function registerUser(data) {
    return function(dispatch) {
        (async() => {
            const response = await fetch(config.url + 'auth/local/register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const json = await response.json();
                dispatch(registrate(json));
                alert("Success! You're registered now");
            } else {
                alert("HTTP Error: " + response.status);
            }

        })();
    };
}

export function loginUser(data) {
    return function(dispatch) {
        (async() => {
            const response = await fetch(config.url + 'auth/local', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const json = await response.json();
                dispatch(login(json));
                if (json.user.role.type === 'authenticated') getMyPreparations();
            } else {
                alert("HTTP Error: " + response.status);
            }
        })();
    };
}

export function getMyPreparations() {
    return function(dispatch, getState) {
        (async() => {
            const preparationsRes = await authFetch(getState, dispatch, `${config.url}preparations-by-user`, 'GET');
            if (preparationsRes.ok) {
                const preparations = await preparationsRes.json();
                dispatch(myPreparations(preparations));
                dispatch(getFrequencies());
            } else {
                alert("HTTP Error: " + preparationsRes.status);
            }
        })()
    }
}

export function addMyPreparation(data) {
    return function(dispatch, getState) {
        (async() => {
            const res = await authFetch(getState, dispatch, `${config.url}preparations-by-user`, 'POST', data);
            if (res.ok) {
                const preparations = await res.json();
                dispatch(myPreparations(preparations));
                localStorage.setItem('az.drugs.preparations', JSON.stringify(preparations));
            } else {
                alert("HTTP Error: " + res.status);
            }
        })()
    }
}

export function deletePreparation(id) {
    return function(dispatch, getState) {
        (async() => {
            const response = await authFetch(getState, dispatch, `${config.url}preparation-by-user/${id}`, 'POST');

            if (response.ok) {
                const preparations = await response.json();
                dispatch(myPreparations(preparations));
            } else {
                alert("HTTP Error: " + response.status);
            }
        })()
    }
}

export function getFrequencies() {
    return function(dispatch, getState) {
        (async() => {
            const response = await authFetch(getState, dispatch, `${config.url}reception-frequencies`, 'GET');
            if (response.ok) {
                const frequencies = await response.json();
                dispatch({
                    type: GET_FREQUENCIES,
                    payload: frequencies
                });
            }
        })();
    }
}

export function forgotPass(email) {
    return function(dispatch) {
        (async() => {
            const response = await fetch(`${config.url}auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            if (response.ok) {
                dispatch({
                    type: RESET,
                    payload: 'sended'
                });
            } else {
                console.error(response);
            }
        })();
    }
}

export function resetPass(code, password, passwordConfirmation) {
    return function(dispatch) {
        (async() => {
            const response = await fetch(`${config.url}auth/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code, password, passwordConfirmation })
            });
            if (response.ok) {
                dispatch({
                    type: RESETED,
                    payload: 'reseted'
                });
            } else {
                console.error(response);
            }
        })();
    }
}

export function logoutUser() {
    return function(dispatch) {
        (async() => {
            dispatch(logout());
        })();
    };
}