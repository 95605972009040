const initialState = {
    drug: {
        name: {
            az: "Dərman vasitəsinin adı",
            ru: "Название препарата",
        },
        marketingAuthorisation: {
            az: "Satış lisenziyasının sahibi",
            ru: "Владелец торговой лицензии",
        },
        internationalNonPatentedName: {
            az: "Beynəlxalq patentləşdirilməmiş adı",
            ru: "Международное непатентованное наименование",
        },
        ATCCode: {
            az: "ATC kodu",
            ru: "Код ATХ",
        },
        pharmaceuticalForm: {
            az: "Farmasevtik forması",
            ru: "Фармацевтическая форма",
        },
        ingredient: {
            az: "Tərkibi",
            ru: "Состав",
        },
        pharmacotherapeuticGroup: {
            az: "Farmakoterapevtik qrupu",
            ru: "Фармакотерапевтическая группа",
        },
        PharmacodynamicProperties: {
            az: "Farmakodinamik xüsusiyyətləri",
            ru: "Фармакодинамические свойства",
        },
        ShelfLife: {
            az: "İstifadə müddəti",
            ru: "Срок годности",
        },
        SpecialPrecautionsForStorage: {
            az: "Saxlama üçün xüsusi ehtiyat tədbirləri",
            ru: "Особые меры предосторожности при хранении",
        },
        NatureAndContentsOfContainer: {
            az: "Qablaşdırmanın tərkibi",
            ru: "Содержимое упаковки",
        },
        SpecialPrecautionsForDisposalAndOtherHandling: {
            az: "İstifadə etmə və başqa emal vaxtı xüsusi ehtiyat tədbirləri",
            ru: "Особые меры предосторожности при утилизации и другой обработке",
        },
        ingredientDescription: {
            name: {
                az: "Ad",
                ru: "Название",
            },
            quantity: {
                az: "Miqdar",
                ru: "Количество",
            },
            unit: {
                az: "Ölçü vahidi",
                ru: "Ед. измерения",
            },
            clinicalParticulars: {
                az: "Kliniki xüsusiyyətlər",
                ru: "Клинические особенности",
            },
            undesirableEffects: {
                az: "Əlavə təsirlər",
                ru: "Побочные эффекты",
            },
            interactionWithOther: {
                az: "Digər dərman vasitələri ilə qarşılıqlı təsiri",
                ru: "Взаимодействие с другими лекарственными препаратами",
            },
        },
    },
    therapeuticIndications: {
        ru: "Показания к применению",
        az: "Tətbiqinə göstərişlər",
    },
    drugsAlphabet: {
        ru: "Поиск по алфавиту",
        az: "Əlifba sırası ilə axtar",
    },
    drugzAZ: {
        title: {
            ru: "Препараты от А до Я",
            az: "Dərman vasitələri A-dan Z-yə",
        },
        description: {
            ru: "Приведенные ниже ресурсы помогают сузить поиск до конкретной целевой информации о лекарствах. Информация доступна как потребителям, так и медицинским работникам по более чем 24 000 рецептурных и безрецептурных лекарств, доступных в основном в Азербайджане.",
            az: "Aşağıda təqdim edilən resurslar, axtarışı dərmanlar haqda konkret məqsədli məlumatlaradək daraltmağa kömək edir. Əsasən Azərbaycanda əldə edilməsi mümkün olan 24 000-dən artıq reseptlə və reseptsiz buraxılan dərman üzrə məlumat həm istehlakçılar, həm də tibb işçiləri üçün əlçatandır.",
        },
        searchEffects: {
            ru: "Поиск в базе данных лекарств",
            az: "Dərman verilənlər bazasında axtarış aparın",
        },
        searchPlaceholder: {
            ru: "Поиск",
            az: "Axtarış",
        },
    },
    contraindications: {
        ru: "Противопоказания",
        az: "Əks göstərişlər",
    },
    specialWarningsAndPrecautionsForUse: {
        ru: "Особенности и меры предосторожности при использовании",
        az: "Xüsusi göstərişlər və ehtiyat tədbirləri",
    },
    interactionWithOther: {
        az: "Digər dərman vasitələri ilə qarşılıqlı təsiri",
        ru: "Взаимодействие с другими лекарственными препаратами",
    },
    useDuringPregnancyAndLactation: {
        ru: "Применение при беременности и кормлении грудью",
        az: "Hamiləlik və laktasiya dövründə istifadə edilməsi",
    },
    confirmProfessional: {
        ru: "Вы переходите на версию для медработников. Вы уверены, что хотите продолжить?",
        az: "Siz peşəkar versiyasına keçirsiniz. Davam etmək istədiyinizə əminsiniz?",
    },
    confirmAction: {
        ru: "Подтверждение действия",
        az: "Fəaliyyətin təsdiqlənməsi",
    },
    continue: {
        ru: "Продолжить",
        az: "Davam etmək",
    },
    back: {
        ru: "Назад",
        az: "Geri",
    },
    Effects: {
        ru: "Действие препарата",
        az: "Dərman vasitəsinin təsiri",
    },
    undesirableEffects: {
        ru: "Побочные эффекты",
        az: "Yan təsirlər",
    },
    overdose: {
        ru: "Передозировка",
        az: "Doza həddinin aşılması",
    },
    posologyAndMethod: {
        generalText: {
            ru: "Общие указания",
            az: "Ümumi göstərişlər",
        },
        adults: {
            ru: "Взрослые",
            az: "Böyüklər",
        },
        elderly: {
            ru: "Престарелые",
            az: "Qocalar",
        },
        children: {
            ru: "Дети",
            az: "Uşaqlar",
        },
    },
    pharmacologicalProperties: {
        ru: "Фармакологические свойства",
        az: "Farmakologiya xüsusiyyətləri",
    },
    pharmaceuticalParticulars: {
        ru: "Фармацевтические особенности",
        az: "Əczaçılıq xüsusiyyətləri",
    },
    marketingAuthorisationNumber: {
        ru: "Номер торговой лицензии",
        az: "Ticarət lisenziyasının nömrəsi",
    },
    PharmacokineticProperties: {
        ru: "Фармакокинетические свойства",
        az: "Farmakokinetik xüsusiyyətləri",
    },
    PreclinicalSafetyData: {
        ru: "Данные доклинической безопасности",
        az: "Preklinik təhlükəsizlik məlumatları",
    },
    ListOfExcipients: {
        ru: "Список вспомогательных веществ",
        az: "Köməkçi maddələrin siyahısı",
    },
    Incompatibilities: {
        ru: "Несовместимость",
        az: "Uyğunsuzluq",
    },
    frequency: {
        ru: "Частота",
        az: "Tezlik",
    },
    bodySystem: {
        ru: "Система органов",
        az: "Orqanlar sistemi",
    },
    undesirableEffect: {
        ru: "Побочный эффект",
        az: "Əlavə təsir",
    },
    drugDetailsTabs: {
        tabCommonInformation: {
            ru: "Общая информация",
            az: "Ətraflı Məlumat",
        },
        tabUndesirableEffects: {
            ru: "Общие побочные эффекты",
            az: "Ümumi əlavə təsirlər",
        },
        tabMethodOfApplicationAndDosage: {
            ru: "Способ применения и дозировка",
            az: "Tətbiqin və dozanın üsulu",
        },
        tabPregnancy: {
            az: "Hamiləlik və laktasiya",
            ru: "Беременность и кормление грудью",
        },
        tabForProfessionals: {
            ru: "Для профессионалов",
            az: "Peşəkar",
        },
    },
    methodOfApplicationAndDosage: {
        ru: "Способ применения и дозировка",
        az: "Tətbiqin və dozanın üsulu",
    },
    activeSubstance: {
        ru: "Активное вещество",
        az: "Aktiv maddə",
    },
    tags: {
        ru: "Теги",
        az: "Teqlər",
    },
    commonUndesirableEffects: {
        ru: "Общие побочные эффекты",
        az: "Ümumi əlavə təsirlər",
    },
    readCarefully: {
        ru: "Важная информация",
        az: "Vacib məlumat",
    },
    whatIsInLaflet: {
        ru: "Содержание инструкции",
        az: "Təlimatın məzmunu",
    },
    whatIsIt: {
        ru: "Что такое",
        az: "nədir?",
    },
    whatYouNeedToKnow: {
        ru: "Что важно знать?",
        az: "Nəyi bilmək vacibdir?",
    },
    doNotUse: {
        ru: "Не использовать в следующих случаях",
        az: "Aşağıdakı hallarda istifadə etməyin",
    },
    otherMedicines: {
        ru: "Взаимодействие с другими лекарственными средствами",
        az: "Digər dərman vasitələrilə qarşılıqlı təsiri",
    },
    drivingAndUsingMachines: {
        ru: "Вождение автомобиля",
        az: "Avtomobilin sürməsi",
    },
    contains: {
        ru: "Состав",
        az: "Tərkibi",
    },
    howToUse: {
        ru: "Как использовать лекарство",
        az: "Dərmanı necə istifadə etmək lazımdır",
    },
    useInChildren: {
        ru: "Использование у детей",
        az: "Uşaqlarda istifadə etməsi",
    },
    howToTake: {
        ru: "Как принимать",
        az: "Qəbul edilməsi",
    },
    howToStoreIt: {
        ru: "Условия хранения",
        az: "Saxlama şəraiti",
    },
    contentsOfThePack: {
        ru: "Как хранить",
        az: "Saxlama şəraiti",
    },
    whatLooksLike: {
        ru: "Внешний вид лекарства",
        az: "Dərmanın görünüşü",
    },
    headerSearchHeader: {
        ru: "Найти лекарство",
        az: "Dərmanı tap",
    },
    headerDropDown: {
        allResults: {
            ru: "Все результаты",
            az: "Bütün nəticələr",
        },
        forConsumers: {
            ru: "Для пациентов",
            az: "Pasiyentlər üçün",
        },
        forProfessionals: {
            ru: "Для профессионалов",
            az: "Peşəkarlar üçün",
        },
        sideEffects: {
            ru: "Побочные эффекты",
            az: "Yan təsirlər",
        },
        diseases: {
            ru: "Болезни",
            az: "Xəstəliklər",
        },
        drugs: {
            ru: "Новые препараты",
            az: "Yeni dərman vasitələri",
        },
        alerts: {
            ru: "Предупреждения",
            az: "Xəbərdarlıqlar",
        },
        trial: {
            ru: "Клинические испытания",
            az: "Kliniki sınaqlar",
        },
        news: {
            ru: "Медицинские новости",
            az: "Tibbi xəbərlər",
        },
        companies: {
            ru: "Компании",
            az: "İstehsalatçı",
        },
        pregnancy: {
            ru: "Беременность",
            az: "Hamiləlik",
        },
    },
    advancedSearch: {
        ru: "Расширенный поиск",
        az: "Ətraflı axtarış",
    },
    noPhoto: {
        ru: "Фото отсутствует",
        az: "Foto yoxdur",
    },
    search: {
        ru: "Поиск",
        az: "Axtarış",
    },
    searchResults: {
        ru: "Результаты поиска:",
        az: "Axtarış nəticələri:",
    },
    searchNotFound: {
        az: "Sizin sorğunuzla bağlı heç bir nəticə tapılmadı",
        ru: "По вашему запросу ничего не найдено",
    },
    manufacturer: {
        ru: "Производитель",
        az: "İstehsalçı",
    },
    termsOfSale: {
        ru: "Условия продажи",
        az: "Satış şərtləri",
    },
    element: {
        ru: "Вещество",
        az: "Maddə",
    },
    drugsNavBar: {
        categories: {
            ru: "Категории",
            az: "Kateqoriyalar",
        },
        registration: {
            ru: "Регистрация",
            az: "Qeydiyyat",
        },
        login: {
            ru: "Вход",
            az: "Giriş",
        },
        privateCabinet: {
            ru: "Личный кабинет",
            az: "Şəxsi kabinet",
        },
    },
    advancedSearchComponent: {
        allWords: {
            ru: "По всем словам",
            az: "Bütün sözlər üzrə",
        },
        someWords: {
            ru: "По некоторым словам",
            az: "Bəzi sözlər üzrə",
        },
        entire: {
            ru: "Полное соответствие",
            az: "Tam uyğun",
        },
        resultPerPage10: {
            ru: "10 результатов на странице",
            az: "Hər səhifədə 10 nəticə",
        },
        resultPerPage20: {
            ru: "20 результатов на странице",
            az: "Hər səhifədə 20 nəticə",
        },
        searchByAlphabet: {
            ru: "Поиск по алфавиту",
            az: "Əlifba axtarışı",
        },
        searchByCategory: {
            ru: "Поиск по категории",
            az: "Kateqoriyaya görə axtarış",
        },
        linkDrugsUndesirableEffects: {
            ru: "Побочные эффекты лекарств",
            az: "Dərmanların yan təsirləri",
        },
        linkDrugsDosage: {
            ru: "Дозировки лекарств",
            az: "Dərmanların dozası",
        },
        linkPregnancyWarnings: {
            ru: "Предупреждения о беременности",
            az: "Hamiləlik xəbərdarlıqları",
        },
        linkPregnancyFeeding: {
            ru: "Предупреждения о грудном вскармливании",
            az: "Emzirmə xəbərdarlıqları",
        },
        linkVeterinaryDrugs: {
            ru: "Ветеринарные препараты",
            az: "Baytarlıq dərmanları",
        },
        linkDrugsclasses: {
            ru: "Классы лекарств",
            az: "Dərman klassları",
        },
        linkDrugsManufacture: {
            ru: "Препараты по производителям",
            az: "İstehsalçı üzrə dərmanlar",
        },
        linkDrugsNoRec: {
            ru: "Безрецептурные препараты",
            az: "Reseptsiz dərmanlar",
        },
        linkTreatmentType: {
            ru: "Варианты лечения",
            az: "Müalicə variantları",
        },
    },
    drugs: {
        ru: "Лекарства",
        az: "Dərmanlar",
    },
    loginComponent: {
        login: {
            ru: "Вход в систему",
            az: "Sistemə giriş",
        },
        wannaRegister: {
            ru: "У вас уже есть аккаунт?",
            az: "Qeydiyyatdan keçmədiniz?",
        },
        register: {
            ru: "Зарегистрироваться...",
            az: "Qeydiyyat...",
        },
        nicknameOrEmail: {
            ru: "Email адрес",
            az: "Email ünvanı",
        },
        password: {
            ru: "Пароль",
            az: "Parol",
        },
        loginInSystem: {
            ru: "Войти",
            az: "Sistemə girmək",
        },
        forgotPassOrNickname: {
            ru: "Забыли пароль?",
            az: "Parolu unutmusunuz?",
        },
        enterLogin: {
            ru: "Введите логин",
            az: "Loqini daxil edin",
        },
        enterPassword: {
            ru: "Введите пароль",
            az: "Şifrəni daxil edin",
        },
        rememberMe: {
            ru: "Запомнить меня",
            az: "Məni yadda saxla",
        },
    },
    forgotComponent: {
        forgotPassOrNickname: {
            ru: "Забыли пароль?",
            az: "Parolu unutdunuz?",
        },
        instructionForPassReset: {
            ru: "Пожалуйста введите адрес электронной почты для сброса пароля. Вам будет выслала инструкция как это сделать.",
            az: "Parolun atılması üçün elektron poçt ünvanını daxil edin. Sizə təlimat göndərəcək.",
        },
        submit: {
            ru: "Отправить",
            az: "Göndərmək",
        },
        emailAdress: {
            ru: "Email адрес",
            az: "Email ünvanı",
        },
    },
    changeComponent: {
        mainTitle: {
            ru: "Изменение пароля",
            az: "Parolun dəyişməsi",
        },
        description: {
            ru: "Для изменения пароля, пожалуйста заполните поля ниже:",
            az: "Parolu dəyişdirmək üçün, zəhmət olmasa alt sahələri dolduracaqsınız:",
        },
        newPassword: {
            ru: "Новый пароль",
            az: "Yeni parol",
        },
        newPasswordConfirm: {
            ru: "Введите пароль ещё раз",
            az: "Yeni parolu təsdiq edin",
        },
        submit: {
            ru: "Отправить",
            az: "Göndərmək",
        },
    },
    categoriesComponent: {
        mainTitle: {
            ru: "Классы лекарств",
            az: "Dərman klassları",
        },
        description: {
            ru: "Лекарственное средство может быть классифицировано по химическому типу активного ингредиента или по способу его применения для лечения конкретного состояния. Каждый препарат может быть классифицирован в один или несколько классов лекарств.",
            az: "Dərman vasitəsi aktiv inqrediyentin kimya tipi görə və ya konkret vəziyyətin müalicəsi üçün onun tətbiqinin üsulu görə təsnif edilmiş ola bilər. Hər preparat dərmanların bir və ya bir neçə  sinifinə təsnif edilmiş ola bilər.",
        },
    },
    registerComponent: {
        mainTitle: {
            ru: "Регистрация",
            az: "Qeydiyyat",
        },
        mainDescription: {
            ru: "Зарегистрировавшись, вы получите бесплатный доступ к персонализированным инструментам, таким как: Списки моих лекарств, группы поддержки и тд. Также вы сможете контролировать, оценивать и проверять лекарства и их взаимодействия.",
            az: "Qeydiyyatdan keçib, siz çox rahat və fərdiləşdirilmiş alətlərə pulsuz girişi alacaqsınız. Siz mənim dərmanlarımın Siyahısında şəxsi yazıların bizim sisteminin köməyi ilə öz dərmanlarını izləyə, qiymətləndirə və dərmanları yoxlaya, qarşılıqlı təsirlərin siyahılarını saxlaya, dəstək qruplarında iştirak edə, informasiya bülletenlərinə və çox başqa funksiyalara imzalaya bilərsiniz.",
        },
        wannaRegister: {
            ru: "У вас уже есть аккаунт?",
            az: "Qeydiyyatdan keçmədiniz?",
        },
        loginInSystem: {
            ru: "Войти в систему",
            az: "Sistemə girmək",
        },
        emailAdress: {
            ru: "Email адрес",
            az: "Email ünvanı",
        },
        username: {
            ru: "Имя пользователя",
            az: "İstifadəçinin adı",
        },
        password: {
            ru: "Пароль",
            az: "Parol",
        },
        passwordStrengthScore: {
            ru: "Уровень надёжности пароля:",
            az: "Parolun təhlükəsizliyinin səviyyəsi:",
        },
        passwordTips: {
            ru: "Пароль должен состоять минимум из 6 символов. Простые пароли (типа: parol, 123456, abcdefg) недопускаются.",
            az: "Parollar ən azı 6 simvol uzunluq olmalıdır. Ümumi parollar (məsələn: parol, 123456, abcdefg) buraxılmır.",
        },
        ageSelect: {
            ru: "Выберите возраст",
            az: "Yaşı seçin",
        },
        professionals: {
            ru: "Медицинский работник",
            az: "Tibbi işçilər",
        },
        iAmProfessional: {
            ru: "Я медицинский работник",
            az: "Mən tibbi işçiyəm",
        },
        sendMeUpdatesAndNews: {
            ru: "Присылать мне новости и обновления",
            az: "Mənə xəbəri və yeniləmələri göndərmək",
        },
        monthlyNews: {
            ru: "Ежемесячная новости",
            az: "Aylıq göndərilmə",
        },
        weeklyNews: {
            ru: "Еженедельные новости",
            az: "Xəbərlərin həftəlik məlumatı",
        },
        dailyNews: {
            ru: "Ежедневные новости",
            az: "Xəbərlərin gündəlik məlumatı",
        },
        drugSafetyWarnings: {
            ru: "Предупреждения о безопасности лекарств",
            az: "Dərmanların təhlükəsizliyinin xəbərdarlıqları",
        },
        submit: {
            ru: "Зарегистрироваться",
            az: "Qeydiyyatdan keçmək",
        },
        footerDescription: {
            ru: "Если вы подпишетесь на электронное письмо, мы будем отправлять вам только информационные бюллетени, чтобы уведомить вас об услугах на нашем сайте и тех, которые вас интересуют. Эти электронные письма могут содержать случайные спонсорские новости. Письма, отправленные вам, всегда будут иметь четкую ссылку для отказа от подписки.",
            az: "Əgər siz elektron poçtun alınmasına abunə oldunuzsa, biz yalnız sizə bizim saytımızda xidmətlərdən xəbərdarlıq etmək üçün xəbərləri göndərəcəyik hansılara ki, siz marağı göstərdiniz. Bu elektron məktublar sponsorun təsadüfi xəbərlərini özündə saxlaya bilər. Sizə göndərilmiş elektron poçtun xəbərləri həmişə abunədən imtina üçün aydın görülən istinada malik olacaqlar.",
        },
        me: {
            ru: "Я",
            az: "Mən",
        },
        termsOfUse: {
            ru: "Условия использования",
            az: "İstifadə Şərtlərini",
        },
        and: {
            ru: "и",
            az: "və",
        },
        privacyPolicy: {
            ru: "Политику конфиденциальности",
            az: "Məxfiliyin Siyasətini",
        },
        iAccept: {
            ru: "принимаю",
            az: "qəbul edirəm",
        },
        genders: {
            male: {
                ru: "Мужской",
                az: "Kişi",
            },
            female: {
                ru: "Женский",
                az: "Qadın",
            },
        },
        iAgree: {
            az: "Mən <a href='/drugs/terms-of-use' target='_blank'>İstifadə şərtləri</a> və <a href='/drugs/privacy-policy' target='_blank'>Məxfilik siyasəti</a> ilə razılaşıram",
            ru: "Я соглашаюсь с <a href='/drugs/terms-of-use' target='_blank'>Правилами пользования</a> и <a href='/drugs/privacy-policy' target='_blank'>Условиями конфиденциальности</a>",
        },
    },
    aboutUs: {
        ru: "О нас",
        az: "Haqqımızda",
    },
    navBarComponent: {
        forProfessionals: {
            ru: "Для профессионалов",
            az: "Peşəkarlar üçün",
        },
        forAllUsers: {
            ru: "Для всех",
            az: "Hamı üçün",
        },
        new_drugs: {
            ru: "Новые препараты",
            az: "Yeni dərman vasitələri",
        },
        warnings: {
            ru: "Предупреждения",
            az: "Xəbərdarlıqlar",
        },
    },
    drugsMainComponent: {
        alphabetFooter: {
            ru: "Просмотр от А до Я: ",
            az: "A-dan Z-yə baxmaq: ",
        },
        alphabetFooterLink1: {
            ru: "препарат",
            az: "dərman vasitəsi",
        },
        alphabetFooterLink2: {
            ru: "лечение",
            az: "müalicə",
        },
        alphabetFooterLink3: {
            ru: "состояние",
            az: "vəziyyət",
        },
        alphabetFooterLink4: {
            ru: "класс",
            az: "klas",
        },
        alphabetFooterOr: {
            ru: "или",
            az: "yaxud",
        },
        pregnancySections: {
            ru: "Беременность и лактация",
            az: "Hamiləlik və laktasiya",
        },
        newDrugs: {
            ru: "Новые препараты",
            az: "Yeni dərman vasitələri",
        },
        FDAWarnings: {
            ru: "Предупреждения AEM",
            az: "AEM-dən xəbərdarlıqlar",
        },
        medicalNews: {
            ru: "Медицинские новости",
            az: "Tibbi xəbərlər",
        },
        medicalEmployees: {
            ru: "Профессиональная версия",
            az: "Peşəkar versiya",
        },
        mobileApps: {
            ru: "Мобильные приложения",
            az: "Mobil tətbiqlər",
        },
        manageYourDrugs: {
            ru: "Управляйте своими лекарствами",
            az: "Dərmanlarınızı idarə edin",
        },
        dosageRecomendations: {
            ru: "Рекомендации по дозировке",
            az: "Doza üzrə məsləhət",
        },
        sideEffectsChecker: {
            ru: "Проверка побочных эффектов",
            az: "Yan təsirlərin yoxlanılması",
        },
        drugsFromAtoZ: {
            ru: "Препараты от А до Я",
            az: "Dərman vasitələri A-dan Z-yə",
        },
        browseSections: {
            ru: "Просмотр по разделам сайта",
            az: "Saytın bölmələri üzrə baxış",
        },
        drugsReview: {
            ru: "Обзор Лекарств",
            az: "Dərmanlara ümumi baxış",
        },
        basicInformation: {
            ru: "Главная",
            az: "Ana səhifə",
        },
        basicInformationDescription: {
            ru: "Самый простой способ найти информацию о лекарствах, идентифицировать таблетки, проверить взаимодействие и создать свои личные записи о лекарствах. Доступно для устройств Android и iOS.",
            az: "Dərmanlar haqqında məlumatların əldə edilməsi, həblərin identifikasiyası, qarşılıqlı təsirlərin yoxlanılması və dərmanlar haqda şəxsi qeydlərin aparılması üçün ən asan üsul. Android və iOS cihazları üçün mövcuddur.",
        },
        mobileAppTitle: {
            ru: 'Мобильные приложения "Dərmanlar"',
            az: '"Dərmanlar" mobil tətbiqi',
        },
        mobileAppDescription: {
            ru: "Самый простой способ поиска информации о препарате, определения таблеток, проверки взаимодействия и создания собственных личных записей лекарств. Доступно для устройств Android и iOS.",
            az: "Dərman vasitələri haqqında məlumatların axtarılması, həblərin təyin edilməsi, qarşılıqlı təsirlərin yoxlanılması və dərmanlar haqda şəxsi qeydlərin aparılması üçün ən asan üsul. Android və iOS cihazları üçün mövcuddur.",
        },
        mobileAppButtonTitle: {
            ru: "Скачать приложение",
            az: "Tətbiqi yüklə",
        },
        browseDrugs: {
            ru: "Найти лекарства по первым буквам:",
            az: "Birinci hərflər üzrə dərmanları tapmaq:",
        },
        slider: {
            drugsAndMedications: {
                ru: "Лекарства и лечение",
                az: "Dərmanlar və müalicə",
            },
            pillIdentifier: {
                ru: "Распознавание таблеток",
                az: "Tabletləri tanınması",
            },
            interactionsChecker: {
                ru: "Проверка взаимодействий",
                az: "Qarşılıqlı təsiri yoxlama",
            },
            sideEffects: {
                ru: "Побочные эффекты",
                az: "Əlavə təsirləri",
            },
            conditionsDiseases: {
                ru: "Условия и заболевания",
                az: "Vəziyyət və xəstəliklər",
            },
            treatmentGuides: {
                ru: "Руководства по лечению",
                az: "Müalicə təlimatları",
            },
            compareDrugs: {
                ru: "Сравнение лекарств",
                az: "Dərmanları müqayisə edilməsi",
            },
            myMedList: {
                ru: "Список моих лекарств",
                az: "Mənim dərmanlarım",
            },
            discountCard: {
                ru: "Дисконтная карта",
                az: "Endirim kartı",
            },
        },
    },
    conditionsDiseases: {
        indexTitle: {
            ru: "Индекс болезней и состояний",
            az: "Xəstəliklərin və vəziyyətlərin indeksi",
        },
        indexBody: {
            ru: "Найдите подробную информацию о конкретном заболевании или состоянии, включая  симптомы, диагностику и варианты лечения.",
            az: "Konkret xəstəlik üzrə simptomlar, diaqnostika və müalicə üsulları daxil olmaqla ətraflı məlumat alın.",
        },
        searchPlacegolder: {
            ru: "Введите название состояния, например, депрессия",
            az: "Vəziyyətin adını daxil edin, misal üçün, depressiya",
        },
        alphabet: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə baxmaq",
        },
        popularRequests: {
            ru: "Общие болезни и состояния",
            az: "Ümumi xəstəliklər və vəziyyətlər",
        },
    },
    myDrugs: {
        title: {
            ru: "Мой список лекарств",
            az: "Mənim dərmanlarım",
        },
        body_1: {
            ru: "Организуйте свои лекарства в удобном для чтения формате, который предоставляет подробные данные о взаимодействии с лекарствами, новости и предупреждения AEM.",
            az: "Öz dərmanlarınızı AEM-nin dərmanların qarşılıqlı təsiri, xəbər və xəbərdarlıqlar haqda ətraflı məlumatı təqdim etdiyi rahat qaydada təşkil edin",
        },
        body_2: {
            ru: "Получите немедленный доступ к подробной информации о потребителях, данным о взаимодействии с лекарствами (в том числе о взаимодействии с пищей / аллергией / состоянием), историческим новостям и предупреждениям AEM.",
            az: "AEM-nın istehlakçılar haqqında ətraflı məlumat, dərmanların qarşılıqlı təsirləri haqqında məlumat (həmçinin yemək / allergiya / vəziyyət  haqqında), tarixi xəbərlər və xəbərdarlıqlarına dərhal çıxış əldə edin",
        },
        body_3: {
            ru: "Выберите получение уведомлений о выпуске новых предупреждений AEM и о появлении новых лекарств от любого из ваших существующих заболеваний. Используйте My Med List для печати записей о размере кошелька и управления лекарствами для любого количества людей.",
            az: "AEM-nin yeni xəbərdarlıqları barədə və sizdə olan xəstəliklərə aid yeni dərmanların əlavə edilməsi barədə bildiriş əldə etməyi seçin. Pul qabının həcmi barədə yazıların çap edilməsi və istənilən sayda adamlar üçün dərmanların idarə edilməsi üçün My Med List istifadə edin.",
        },
        typeDrug: {
            ru: "Введите название лекарства",
            az: "Dərman adını daxil edin",
        },
        buttons: {
            my_drugs: {
                ru: "Мои лекарства",
                az: "Mənim dərmanlarım",
            },
            my_vaccines: {
                ru: "Мои вакцины",
                az: "Mənim vaksinlərim",
            },
            my_allergies: {
                ru: "Мои аллергии",
                az: "Mənim allergiyalarım",
            },
            my_notes: {
                ru: "Заметки",
                az: "Qeydlər",
            },
            my_surveys: {
                ru: "Мои обследования",
                az: "Mənim yoxlanışlarım",
            },
            my_consultations: {
                ru: "Мои консультации",
                az: "Mənim məsləhətləşmələrim",
            },
            my_analyzes: {
                ru: "Мои анализы",
                az: "Mənim analizlərim",
            },
        },
        add_drug: {
            ru: "Добавить",
            az: "Əlavə et",
        },
        confirmDelete: {
            ru: "Вы уверены, что хотите удалить эту запись?",
            az: "Bu dərmanı silmək istədiyinizə əminsiniz?",
        },
        edit: {
            ru: "Редактирование лекарства",
            az: "Dərmanın redaktəsi",
        },
        newDrug: {
            ru: "Новое лекарство",
            az: "Yeni dərman",
        },
        description: {
            ru: 'В разделе "Мои лекарства" вы можете добавить, удалить или исправить список принимаемых вами лекарств с указанием их дозировок, частоты приема и продолжительности приема.',
            az: '"Dərmanlarım" bölməsində qəbul etdiyiniz dərmanların siyahısını dozaları, tətbiq tezliyi və tətbiq müddəti ilə əlavə edə, silə və ya düzəldə bilərsiniz.',
        },
        allData: {
            ru: "Все данные",
            az: "Bütün məlumatlar",
        },
        delete: {
            ru: "Удалить",
            az: "Silmək",
        },
        cancel: {
            ru: "Отмена",
            az: "İmtina",
        },
        confirm: {
            ru: "Подтвердить",
            az: "Təstiq etmək",
        },
        name: {
            ru: "Имя",
            az: "Adı",
        },
        dosage: {
            ru: "Доза",
            az: "Doza",
        },
        freq: {
            ru: "Частота приема лекарств",
            az: "Dərman qəbulunun tezliyi",
        },
        start: {
            ru: "Дата начала",
            az: "Başlama tarixi",
        },
        end: {
            ru: "Дата завершения",
            az: "Bitmə tarixi",
        },
        status: {
            ru: "Статус",
            az: "Status",
        },
        noData: {
            ru: "Данные недоступны",
            az: "Məlumat yoxdur",
        },
        noEmpty: {
            ru: "Поле не должно быть пустым",
            az: "Sahə boş ola bilməz",
        },
        continue: {
            ru: "Продолжается",
            az: "Davam edir",
        },
        going: {
            ru: "Продолжается",
            az: "Davam edir",
        },
        finished: {
            ru: "Завершён",
            az: "Dayandırılıb/Tamamlanıb",
        },
    },
    drugs_and_medications: {
        drugsByCategories: {
            ru: "Обзор лекарств по категориям",
            az: "Kateqoriyalar üzrə dərmanlara ümumi baxış",
        },
        popularRequests: {
            ru: "Популярные поисковые запросы",
            az: "Ən çox axtarılanlar",
        },
    },
    interactionsChecker: {
        interactionsCheckerTitle: {
            ru: "Проверка взаимодействий",
            az: "Qarşılıqlı təsirlərin yoxlanılması",
        },
        interactionsCheckerBody: {
            ru: "Начните вводить название препарата и выберите наиболее подходящее из списка предложений. Повторите процесс, чтобы добавить несколько препаратов. Как только ваш список будет завершен, вы можете сразу проверить взаимодействие или сохранить его для дальнейшего использования. ",
            az: "Dərman vasitəsinin adını yazmağa başlayın və təklif olunanlar siyahısından ən uyğun gələnini seçin. Bir neçə dərman vasitəsi əlavə etmək üçün prosesi təkrarlayın. Sizin siyahı tamamlanan kimi siz qarşılıqlı təsirləri yoxlaya və gələcəkdə istifadə üçün yadda saxlaya bilərsiniz.",
        },
        searchPlaceholder: {
            ru: "Введите название препарата",
            az: "Dərman vasitəsinin adını daxil edin",
        },
        searchInfo: {
            ru: "Чтобы начать, введите название препарата в поле выше.",
            az: "Başlamaq üçün dərman vasitəsinin adını yuxarıdakı sahəyə daxil edin.",
        },
        whatAreInteractionsTitle: {
            ru: "Что такое лекарственные взаимодействия?",
            az: "Dərmanların qarşılıqlı təsiri nədir?",
        },
        whatAreInteractionsBodyStart: {
            ru: "Каждый раз, когда вы принимаете более одного лекарства или даже смешиваете его с определенными продуктами питания, напитками или безрецептурными лекарствами, вы подвергаетесь риску лекарственного взаимодействия. Большинство лекарственных взаимодействий не являются серьезными, но, поскольку некоторые из них являются серьезны важно понять возможный результат, прежде чем принимать лекарства Всегда лучше спрашивать у своего врача самую последнюю информацию о взаимодействии лекарств. Однако вы также можете использовать нашу ",
            az: "Hər dəfə siz birdən çox dərman qəbul edirkən, hətta müəyyən qida məhsulları, içkilər və reseptsiz dərmanlarla qarışdırarkən, siz dərman təsirinin riskinə məruz qalırsınız. Bir çox dərmanların qarşılıqlı təsiri ciddi deyil, lakin, bəzilərinin təsiri ciddi olduğundan mümkün nəticəni anlamaq vacibdir, dərmanın istifadəsindən öncə həkiminizdən dərmanların qarşılıqlı təsiri haqda ən son məlumatları soruşmaq yaxşıdır. Lakin siz bizim ",
        },
        whatAreInteractionsBodyLink: {
            ru: "онлайн-программу проверки",
            az: "yoxlama üçün olan onlayn-proqramımızdan da istifadə edə bilərsiniz",
        },
        whatAreInteractionsBodyEnd: {
            ru: "Этот инструмент объясняет, что такое взаимодействие, как оно происходит, уровень значимости (большой, средний или незначительный) и, как правило, предлагаемый курс действий. Он также будет отображать любые взаимодействия между выбранным вами лекарствами и едой, напитками или состоянием здоровья.",
            az: "Bu instrument sizə qarşılıqlı təsirin nə olmasını, necə baş verməsini, vacibliyin səviyyəsini (yüksək, orta və kiçik) və, adəti üzrə, təklif olunan atılacaq addımlar kursunu izah edəcək. O, həmçinin istənilən seçdiyiniz dərmanlar və yemək, içkilər və yaxud sağlamlıq durumu arasında qarşılıqlı təsiri əks etdirəcək.",
        },
        whatToDoTitle: {
            ru: "Что мне делать, если я обнаружил лекарственное взаимодействие?",
            az: "Dərmanın qarşılıqlı təsirini aşkar etdikdə nə etməliyəm?",
        },
        whatToDoBody: {
            ru: "Помните - взаимодействия с наркотиками обычно можно предотвратить с помощью ваших активных действий. ­Однако, если вы обнаружите, что подвержены риску возможного взаимодействия с лекарствами, как можно скорее позвоните своему врачу или фармацевту. Они поймут важность взаимодействия и смогут порекомендовать вам следующие наилучшие шаги. Не прекращайте прием лекарств, не посоветовавшись предварительно со своим врачом.",
            az: "Unutmayın, narkotiklərlə qarşılıqlı təsirin qarşısını sizin aktiv fəaliyyətinizlə almaq mümkündür. Lakin, dərmanların mümkün qarşılıqlı təsiri riskinə məruz qaldığınızı aşkar etmisinizsə, mümkün qədər tez bir zamanda öz həkiminizə və ya əczaçınıza zəng vurun. Onlar qarşılıqlı təsirin vacibliyini anlayıb sizə ən doğru ata biləcəyiniz addımı məsləhət görəcəklər. Dərmanın qəbulunu ilkin olaraq həkiminizlə məsləhətləşməmiş dayandırmayın.",
        },
    },
    sideEffects: {
        sideEffectsTitle: {
            ru: "Проверка побочных эффектов",
            az: "Yan təsirlər",
        },
        sideEffectsBodyStart: {
            ru: "Приведенные ниже ресурсы помогают сузить поиск до конкретной целевой информации о лекарствах. Информация доступна как потребителям, так и медицинским работникам по более чем 24 000 рецептурных и безрецептурных лекарств, доступных в основном в Азербайджане.",
            az: "Aşağıda təqdim edilən resurslar, axtarışı dərmanlar haqda konkret məqsədli məlumatlaradək daraltmağa kömək edir. Əsasən Azərbaycanda əldə edilməsi mümkün olan 24 000-dən artıq reseptlə və reseptsiz buraxılan dərman üzrə məlumat həm istehlakçılar, həm də tibb işçiləri üçün əlçatandır.",
        },
        sideEffectsBodyEnd: {
            ru: "или",
            az: "yaxud",
        },
        searchTitle: {
            ru: "Поиск в базе данных о побочных эффектах лекарств",
            az: "Dərmanların yan təsirləri haqda verilənlər bazasında axtarış",
        },
        alphabetTitle: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə axtar",
        },
        infoTitle: {
            ru: "Информация о побочных эффектах лекарств",
            az: "Dərmanların yan təsirləri haqda məlumat",
        },
        infoBody: {
            ru: "Побочный эффект обычно рассматривается как нежелательный вторичный эффект, который возникает в дополнение к желаемому терапевтическому эффекту лекарственного средства или лекарства. Побочные эффекты могут различаться для каждого человека в зависимости от болезненного состояния, возраста, веса, пола, этнической принадлежности и общего состояния здоровья.",
            az: "Əlavə təsir adətən dərman vasitəsi və ya dərmanın arzuolunan terapevtik təsirinə əlavə olaraq meydana çıxan arzuolunmaz ikincili təsir kimi nəzərdən keçirilir. Əlavə təsirlər xəstəlik durumu, yaş, çəki, cinsiyyət, etnik mənsubiyyət və ümumi sağlıq vəziyyətindən asılı olaraq hər bir insan üçün fərqlənə bilər.",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по побочным эффектам",
            az: "Yan təsirlər üzrə ən çox axtarılanlar",
        },
    },
    dosage: {
        dosageTitle: {
            ru: "Рекомендации по дозировке",
            az: "Doza üzrə məsləhət",
        },
        dosageBody: {
            ru: "Обратите внимание - информацию о дозировке, указанную на веб-сайте Dermanlarim.az, следует использовать только в качестве руководства. Перед изменением дозировки любых лекарств обязательно проконсультируйтесь с врачом или специалистом в области здравоохранения.",
            az: "Diqqət edin - Dozalama ilə bağlı Dermanlarim.az veb-saytında qeyd olunan məlumat yalnız rəhbərlik kimi istifadə edilməlidir. İstənilən dərmanların dozalanmasını dəyişməzdən əvvəl mütləq həkim və ya səhiyyə mütəxəssisi ilə konsultasiya edin.",
        },
        infoTitle: {
            ru: "Информация о побочных эффектах лекарств",
            az: "Dərmanların yan təsirləri haqda məlumat",
        },
        infoBody: {
            ru: "Побочный эффект обычно рассматривается как нежелательный вторичный эффект, который возникает в дополнение к желаемому терапевтическому эффекту лекарственного средства или лекарства. Побочные эффекты могут различаться для каждого человека в зависимости от болезненного состояния, возраста, веса, пола, этнической принадлежности и общего состояния здоровья.",
            az: "Əlavə təsir adətən dərman vasitəsi və ya dərmanın arzuolunan terapevtik təsirinə əlavə olaraq meydana çıxan arzuolunmaz ikincili təsir kimi nəzərdən keçirilir. Əlavə təsirlər xəstəlik durumu, yaş, çəki, cinsiyyət, etnik mənsubiyyət və ümumi sağlıq vəziyyətindən asılı olaraq hər bir insan üçün fərqlənə bilər.",
        },
        popularRequestsTitle: {
            ru: "Популярные запросы по дозировке",
            az: "Doza üzrə ən çox axtarılanlar",
        },
        searchTitle: {
            ru: "Поиск в базе данных рекомендаций по дозировкам лекарств",
            az: "Dərman dozası tövsiyələri verilənlər bazasında axtarış aparın",
        },
        alphabetTitle: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə axtar",
        },
    },
    pregnancy: {
        pregnancyTitle: {
            ru: "Беременность и лактация",
            az: "Hamiləlik və laktasiya",
        },
        pregnancyBody: {
            ru: "Приведенные ниже ресурсы помогают сузить поиск до конкретной целевой информации о лекарствах. Информация доступна как потребителям, так и медицинским работникам по более чем 24 000 рецептурных и безрецептурных лекарств, доступных в основном в Азербайджане.",
            az: "Aşağıda təqdim edilən resurslar, axtarışı dərmanlar haqda konkret məqsədli məlumatlaradək daraltmağa kömək edir. Əsasən Azərbaycanda əldə edilməsi mümkün olan 24 000-dən artıq reseptlə və reseptsiz buraxılan dərman üzrə məlumat həm istehlakçılar, həm də tibb işçiləri üçün əlçatandır.",
        },
        infoTitle: {
            ru: "Информация о беременности и лактации",
            az: "Hamiləlik və laktasiya haqqında məlumat",
        },
        infoBody: {
            ru: "Любая беременность и грудное вскармливание, в т. ч. у здоровых женщин, сопровождаются выраженными изменениями со стороны кальциевого обмена. В то время как первичные заболевания скелета и метаболизма кальция (Са) в акушерской практике встречаются достаточно редко, многие случаи патологических нарушений в таких условиях связаны с алиментарной недостаточностью этого минерала.",
            az: "Hər hansı bir hamiləlik və ana südü, o cümlədən sağlam qadınlarda, kalsium mübadiləsində nəzərəçarpacaq dəyişikliklərlə müşayiət olunur. Skelet və kalsium (Ca) mübadiləsinin ilkin xəstəlikləri mamalıq praktikasında olduqca nadir olsa da, belə şəraitdə bir çox patoloji pozğunluqlar bu mineralın qida çatışmazlığı ilə əlaqələndirilir",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по использованию во время беременности и лактациии",
            az: "Hamiləlik və laktasiya dövründə istifadə üçün dərmanlar bazasında ən çox axtarılanlar",
        },
        searchTitle: {
            ru: "Поиск в базе данных рекомендаций по использованию во время беременности и лактациии",
            az: "Hamiləlik və laktasiya dövründə istifadə üçün tövsiyələr bazasında axtarış aparın",
        },
        alphabetTitle: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə axtar",
        },
    },
    lactation: {
        lactationTitle: {
            ru: "Лактация",
            az: "Laktasiya",
        },
        lactationBody: {
            ru: "Приведенные ниже ресурсы помогают сузить поиск до конкретной целевой информации о лекарствах. Информация доступна как потребителям, так и медицинским работникам по более чем 24 000 рецептурных и безрецептурных лекарств, доступных в основном в Азербайджане.",
            az: "Aşağıda təqdim edilən resurslar, axtarışı dərmanlar haqda konkret məqsədli məlumatlaradək daraltmağa kömək edir. Əsasən Azərbaycanda əldə edilməsi mümkün olan 24 000-dən artıq reseptlə və reseptsiz buraxılan dərman üzrə məlumat həm istehlakçılar, həm də tibb işçiləri üçün əlçatandır.",
        },
        infoTitle: {
            ru: "Информация о беременности и лактации",
            az: "Laktasiya haqqında məlumat",
        },
        infoBody: {
            ru: "Любая беременность и грудное вскармливание, в т. ч. у здоровых женщин, сопровождаются выраженными изменениями со стороны кальциевого обмена. В то время как первичные заболевания скелета и метаболизма кальция (Са) в акушерской практике встречаются достаточно редко, многие случаи патологических нарушений в таких условиях связаны с алиментарной недостаточностью этого минерала.",
            az: "Hər hansı bir hamiləlik və ana südü, o cümlədən sağlam qadınlarda, kalsium mübadiləsində nəzərəçarpacaq dəyişikliklərlə müşayiət olunur. Skelet və kalsium (Ca) mübadiləsinin ilkin xəstəlikləri mamalıq praktikasında olduqca nadir olsa da, belə şəraitdə bir çox patoloji pozğunluqlar bu mineralın qida çatışmazlığı ilə əlaqələndirilir",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по использованию во время беременности и лактациии",
            az: "Laktasiya dövründə istifadə üçün dərmanlar bazasında ən çox axtarılanlar",
        },
        searchTitle: {
            ru: "Поиск в базе данных рекомендаций по использованию во время беременности и лактациии",
            az: "Laktasiya dövründə istifadə üçün tövsiyələr bazasında axtarış aparın",
        },
        alphabetTitle: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə axtar",
        },
    },
    internationalDatabase: {
        internationalDatabaseTitle: {
            ru: "Клинико-фармакологический указатель",
            az: "Клинико-фармакологический указатель",
        },
        infoTitle: {
            ru: "",
            az: "",
        },
        infoBody: {
            ru: "",
            az: "",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по клинико-фармакологическому указателю",
            az: "Популярные поисковые запросы по клинико-фармакологическому указателю",
        },
        searchTitle: {
            ru: "Поиск в базе данных по клинико-фармакологическому указателю",
            az: "Поиск в базе данных по клинико-фармакологическому указателю",
        },
    },
    pharmaCompanies: {
        pharmaCompaniesTitle: {
            ru: "Производители лекарств",
            az: "Производители лекарств",
        },
        pharmaCompaniesBody: {
            ru: "Приведенные ниже ресурсы помогают сузить поиск до конкретной целевой информации о лекарствах. Информация доступна как потребителям, так и медицинским работникам по более чем 24 000 рецептурных и безрецептурных лекарств, доступных в основном в Азербайджане.",
            az: "Aşağıda təqdim edilən resurslar, axtarışı dərmanlar haqda konkret məqsədli məlumatlaradək daraltmağa kömək edir. Əsasən Azərbaycanda əldə edilməsi mümkün olan 24 000-dən artıq reseptlə və reseptsiz buraxılan dərman üzrə məlumat həm istehlakçılar, həm də tibb işçiləri üçün əlçatandır.",
        },
        infoTitle: {
            ru: "Информация о производителе лекарств",
            az: "Информация о производителе лекарств",
        },
        infoBody: {
            ru: "Производитель лекарственных средств — организация, осуществляющая производство лекарственных средств в соответствии с требованиями законодательства. Производство лекарственных средств должно соответствовать требованиям правил надлежащей производственной практики, утвержденным уполномоченным федеральным органом исполнительной власти.",
            az: "Производитель лекарственных средств — организация, осуществляющая производство лекарственных средств в соответствии с требованиями законодательства. Производство лекарственных средств должно соответствовать требованиям правил надлежащей производственной практики, утвержденным уполномоченным федеральным органом исполнительной власти.",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по производителям лекарств",
            az: "Популярные поисковые запросы по производителям лекарств",
        },
        searchTitle: {
            ru: "Поиск в базе данных по производителям лекарств",
            az: "Поиск в базе данных по производителям лекарств",
        },
        alphabetTitle: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə axtar",
        },
    },
    treatmentOptions: {
        treatmentOptionsTitle: {
            ru: "Лекарства и лечение",
            az: "Dərmanlar və müalicə",
        },
        infoTitle: {
            ru: "",
            az: "",
        },
        infoBody: {
            ru: "",
            az: "",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по лекарствам",
            az: "Популярные поисковые запросы по лекарствам",
        },
        searchTitle: {
            ru: "Поиск в базе данных по лекарствам",
            az: "Поиск в базе данных по лекарствам",
        },
    },
    withoutConditions: {
        withoutConditionsTitle: {
            ru: "Безрецептурные препараты",
            az: "Безрецептурные препараты",
        },
        withoutConditionsBody: {
            ru: "Приведенные ниже ресурсы помогают сузить поиск до конкретной целевой информации о лекарствах. Информация доступна как потребителям, так и медицинским работникам по более чем 24 000 рецептурных и безрецептурных лекарств, доступных в основном в Азербайджане.",
            az: "Aşağıda təqdim edilən resurslar, axtarışı dərmanlar haqda konkret məqsədli məlumatlaradək daraltmağa kömək edir. Əsasən Azərbaycanda əldə edilməsi mümkün olan 24 000-dən artıq reseptlə və reseptsiz buraxılan dərman üzrə məlumat həm istehlakçılar, həm də tibb işçiləri üçün əlçatandır.",
        },
        infoTitle: {
            ru: "Информация о безрецептурных препаратах",
            az: "Reseptsiz dərmanlar haqqında məlumat",
        },
        infoBody: {
            ru: "Безрецептурные лекарственные препараты (БЛП) можно купить без рецепта. Их применение позволяет людям избавиться от многих раздражающих симптомов и лечить некоторые болезни просто и без затрат на посещение врача. Однако отношение к таким лекарствам, связанное с активным развитием самолечения и поощряемое доступностью безопасных и эффективных безрецептурных средств, требует здравого смысла и ответственности.",
            az: "OTC dərmanları (OTC) reseptsiz alına bilər. Onların istifadəsi insanlara bir çox qıcıqlandırıcı simptomlardan qurtulmağa və bəzi xəstəlikləri həkimə müraciət etmədən müalicə etməyə imkan verir. Bununla belə, özünümüalicənin aktiv inkişafı ilə əlaqəli və təhlükəsiz və effektiv reseptsiz dərmanların mövcudluğu ilə təşviq edilən bu cür dərmanlara münasibət sağlam düşüncə və məsuliyyət tələb edir.",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по безрецептурным препаратам",
            az: "Популярные поисковые запросы по безрецептурным препаратам",
        },
        searchTitle: {
            ru: "Поиск в базе данных рекомендаций по безрецептурным препаратам",
            az: "Поиск в базе данных рекомендаций по безрецептурным препаратам",
        },
        alphabetTitle: {
            ru: "Просматривать в алфавитном порядке",
            az: "Əlifba sırası ilə axtar",
        },
    },
    classes: {
        classesTitle: {
            ru: "Фармако-терапевтические группы",
            az: "Фармако-терапевтические группы",
        },
        infoTitle: {
            ru: "",
            az: "",
        },
        infoBody: {
            ru: "",
            az: "",
        },
        popularRequestsTitle: {
            ru: "Популярные поисковые запросы по фармако-терапевтическим группам",
            az: "Популярные поисковые запросы по фармако-терапевтическим группам",
        },
        searchTitle: {
            ru: "Поиск в базе данных по фармако-терапевтическим группам",
            az: "Поиск в базе данных по фармако-терапевтическим группам",
        },
    },
    warnings: {
        title: {
            ru: "Предупреждения",
            az: "Xəbərdarlıqlar",
        },
    },
    newDrugs: {
        title: {
            ru: "Новые препараты",
            az: "Yeni dərman vasitələri",
        },
        decription: {
            ru: "Следующие препараты были недавно одобрены AEM. Включает недавно одобренные лекарства и новые показания к уже одобренным лекарствам.",
            az: "Aşağıdakı dərman vasitələri AEM tərəfindən yenicə təstiq edilib. Özündə yeni təstiq edilmiş dərmanları və əvvəl təstiq edilmiş dərmanlara yeni göstərişləri cəmləşdirir.",
        },
        viewAlso: {
            ru: "См. Также:",
            az: "Həmçinin baxın:",
        },
        FDAApproval: {
            ru: "Общие утверждения, процесс утверждения, новые показания и формы дозирования",
            az: "Ümumi iddialar, iddia prosesi, yeni göstəricilər və dozalamağın formaları",
        },
        receiveNews: {
            ru: "Получайте новости по",
            az: "Xəbərləri",
        },
        email: {
            ru: "электронной почте",
            az: "elektron poçt vasitəsilə alın",
        },
        orSubscribe: {
            ru: "или подпишитесь на наши",
            az: "və ya bizim",
        },
        newsFeed: {
            ru: "новостные ленты",
            az: "xəbər lentlərinə abunə olun",
        },
        allNews: {
            ru: "Все новости",
            az: "Bütün xəbərlər",
        },
        warnings: {
            ru: "Предупреждения ЦАЭ",
            az: "Xəbərdarlıqlar",
        },
        newDrugs: {
            ru: "Новые препараты",
            az: "Yeni dərman vasitələri",
        },
        medicalNews: {
            ru: "Медицинские новости",
            az: "Tibbi xəbərlər",
        },
        clinicalTrials: {
            ru: "Клинические испытания",
            az: "Kliniki sınaqlar",
        },
    },
    allNews: {
        title: {
            ru: "Фармацевтические новости и статьи",
            az: "Əczaçılıq xəbərləri və məqalələri",
        },
        decription: {
            ru: "На этой странице вы можете просмотреть все новости.",
            az: "Bu səhifədə siz bütün xəbərlərə baxa bilərsiniz.",
        },
        popularRequests: {
            ru: "Популярные поисковые запросы",
            az: "Ən çox axtarılanlar",
        },
        popularRequestsAds: {
            ru: "Популярные мед.препараты",
            az: "Məşhur dərmanlar",
        },
    },
    drugBriefing: {
        company: {
            ru: "Компания: ",
            az: "Şirkət: ",
        },
        date: {
            ru: "Дата утверждения: ",
            az: "Təstiq tarixi: ",
        },
        curing: {
            ru: "Лечение: ",
            az: "Müalicə: ",
        },
    },
    mobileAppComponent: {
        title: {
            ru: '"Dərmanlarım" ваш Гид по лекарствам!',
            az: '"Dərmanlarım" sizin dərman bələdçisidir!',
        },
        description: {
            ru: "Скачайте наше приложение бесплатно, выберите либо App Store либо Google Play!",
            az: "Tətbiqimizi pulsuz yükləyə bilərsiniz, ya App Store, ya da Google Play seçin!",
        },
    },
    userComponent: {
        userContext: {},
        profile: {
            az: "Şəxsi hesab",
            ru: "Личный профиль",
        },
        exit: {
            az: "Hesabdan çıxış",
            ru: "Выйти с профиля",
        },
    },
    adminDrugs: {
        medicalProducts: {
            az: "Tibbi məhsullar",
            ru: "Медицинские продукты",
        },
        patientLeaflets: {
            az: "Pasientlər üçün vərəqələr",
            ru: "Листовки для пациентов",
        },
        productsTable: {
            internationalNonPatentedName: {
                az: "Beynəlxalq patentləşdirilməmiş adı",
                ru: "Международное незапатентованное имя",
            },
            name: {
                az: "Məhsulun adı",
                ru: "Имя продукта",
            },
            addedDate: {
                az: "Sistemə yüklənmə tarixi",
                ru: "Дата добавления в систему",
            },
            editButton: {
                az: "Redaktə",
                ru: "Изменить",
            },
            patientLeaflet: {
                az: "Vərəqə",
                ru: "Листовка",
            },
            medicalProduct: {
                az: "Məhsul",
                ru: "Продукт",
            },
        },
        editorPlaceholder: {
            az: "Mətni daxil edin",
            ru: "Введите текст",
        },
        saveButton: {
            az: "Yadda saxla",
            ru: "Сохранить",
        },
        cancelButton: {
            az: "İmtina",
            ru: "Отмена",
        },
        createPatientLeaflet: {
            az: "Vərəqə yarat",
            ru: "Создать листовку",
        },
        deleteButton: {
            az: "Sil",
            ru: "Удалить",
        },
        deletePatientLeaflet: {
            areYouSure: {
                az: "Əminsiniz?",
                ru: "Вы уверены?",
            },
            deleteLeaflet: {
                az: "Vərəqənin silinməsi",
                ru: "Удаление листовки",
            },
        },
        patientLeafletEditPage: {
            categoryAz: {
                az: "Kateqoriya adı (azərbaycanca)",
                ru: "Название категории (на азербайджанском)",
            },
            categoryRu: {
                az: "Kateqoriya adı (rusca)",
                ru: "Название категории (на русском)",
            },
            categoryPlaceholder: {
                az: "Yoxdur",
                ru: "Отсутствует",
            },
            createCategory: {
                az: "Kateqoriya yarat",
                ru: "Создать категорию",
            },
            generalInfo: {
                title: {
                    az: "Əsas məlumatlar",
                    ru: "Основная информация",
                },
                productTitle: {
                    az: "Məhsulun adı",
                    ru: "Имя продукта",
                },
                internationalNonPatentedName: {
                    az: "Beynəlxalq patentləşdirilməmiş adı",
                    ru: "Международное незапатентованное имя",
                },
                whatIsIt: {
                    az: "Bu nədir?",
                    ru: "Что это?",
                },
            },
            generalFields: {
                title: {
                    az: "Əsas sahələr",
                    ru: "Основные поля",
                },
                readCarefullyAz: {
                    az: "Diqqətlə oxu (azərbaycanca)",
                    ru: "Читать внимательно (на азербайджанском)",
                },
                readCarefullyRu: {
                    az: "Diqqətlə oxu (rusca)",
                    ru: "Читать внимательно (на русском)",
                },
                contentAz: {
                    az: "Vərəqənin məzmunu (azərbaycanca)",
                    ru: "Содержание листовки (на азербайджанском)",
                },
                contentRu: {
                    az: "Vərəqənin məzmunu (rusca)",
                    ru: "Содержание листовки (на русском)",
                },
            },
            whatYouNeedToKnow: {
                title: {
                    az: "Nəyi bilmək vacibdir?",
                    ru: "Что нужно знать?",
                },
                generalText: {
                    az: "İstifadəsinə göstərişlər",
                    ru: "Инструкции по использованию",
                },
                useInChild: {
                    az: "Pediatrik istifadə üçün ehtiyyatlılıq",
                    ru: "Предосторожность для педиатрического использования",
                },
                doNotUse: {
                    az: "Əks göstərişlər",
                    ru: "Противопоказания",
                },
                warningsAndPrecautions: {
                    az: "Xüsusi göstərişlər və ehtiyat tədbirləri",
                    ru: "Особые показания и меры предосторожности",
                },
                otherMedicines: {
                    az: "Digər dərman vasitələri ilə qarşılıqlı təsiri",
                    ru: "Взаимодействие с другими препаратами",
                },
                pregnancyAndBreastFeeding: {
                    az: "Hamiləlik və laktasiya dövründə istifadəsi",
                    ru: "Использование во время беременности и кормления грудью",
                },
                drivingAndUsingMachines: {
                    az: "Nəqliyyat vasitələrini və digər potensial təhlükəli mexanizmləri idarəetmə qabiliyyətinə təsiri",
                    ru: "Влияние на способность управлять транспортными средствами и другой потенциально опасной техникой",
                },
                contains: {
                    az: "Tərkibində olan xüsusi maddə (xəbərdarlıq)",
                    ru: "Особое вещество в составе (предупреждение)",
                },
            },
            howToUse: {
                title: {
                    az: "İstifadə qaydaları",
                    ru: "Правила использования",
                },
                generalText: {
                    az: "İstifadə qaydası və dozası",
                    ru: "Правило использования и доза",
                },
                ifYouAreGivenMore: {
                    az: "Doza həddinin aşılması",
                    ru: "Передозировка",
                },
                ageGroups: {
                    az: "Yaş qrupları",
                    ru: "Возрастные группы",
                },
                useInChildren: {
                    az: "Uşaqlar üçün istifadə qaydaları",
                    ru: "Правила использования для детей",
                },
                adults: {
                    az: "Böyüklər üçün istifadə qaydaları",
                    ru: "Правила использования для взрослых",
                },
                elderly: {
                    az: "Yaşlılar üçün istifadə qaydaları",
                    ru: "Правила использования для пожилых",
                },
                kindOfSick: {
                    az: "Müxtəlif xəstələr üçün istifadə qaydaları",
                    ru: "Правила использования для людей с различными заболеваниями",
                },
                sick: {
                    az: "Xəstəliyin adı",
                    ru: "Название болезни",
                },
                createSick: {
                    az: "İstifadə qaydası əlavə etmək",
                    ru: "Добавить правило использования",
                },
                editSick: {
                    az: "İstifadə qaydasına düzəlişlər etmək",
                    ru: "Редактирование правилы использования",
                },
                howToUse: {
                    az: "Həmin xəstəlik üçün istifadə qaydası",
                    ru: "Правило использования для людей с этим заболеванием",
                },
                using: {
                    az: " dərman vasitəsinin istifadəsi",
                    ru: "Использование ",
                },
            },
            ingredients: {
                title: {
                    az: "Təsiredici maddələr",
                    ru: "Активные вещества",
                },
                ingredient: {
                    az: "Təsiredici maddə",
                    ru: "Активное вещество",
                },
                ingredientAz: {
                    az: "Maddənin adı (azərbaycanca)",
                    ru: "Название вещества (на азербайджанском)",
                },
                ingredientRu: {
                    az: "Maddənin adı (rusca)",
                    ru: "Название вещества (на русском)",
                },
                addButton: {
                    az: "Təsiredici maddə əlavə et",
                    ru: "Добавить активное вещество",
                },
                deleteButton: {
                    az: "Maddəni sil",
                    ru: "Удалить вещество",
                },
                quantity: {
                    az: "Miqdar",
                    ru: "Количество",
                },
                unitTitle: {
                    az: "Ölçü vahidi",
                    ru: "Единица измерения",
                },
                unit: [
                    { az: "mkl", ru: "мкл", id: "mkl" },
                    { az: "ml", ru: "мл", id: "ml" },
                    { az: "Mmol/l", ru: "Ммоль/л", id: "Mmol/l" },
                    { az: "mkg", ru: "мкг", id: "mkg" },
                    { az: "mg", ru: "мг", id: "mg" },
                    { az: "q", ru: "г", id: "g" },
                    { az: "kg", ru: "кг", id: "kg" },
                    {
                        az: "Beynəlxalq vahid (BV)",
                        ru: "Международная единица (МЕ)",
                        id: "BV",
                    },
                    { az: "PV", ru: "PV", id: "PV" },
                    { az: "KİV", ru: "KİV", id: "KİV" },
                    {
                        az: "Təsir vahidi (TV)",
                        ru: "Единица действия (ЕД)",
                        id: "TV",
                    },
                    { az: "TV Avr.F", ru: "TV Avr.F", id: "TV Avr.F" },
                    { az: "LD", ru: "LD", id: "LD" },
                    {
                        az: "Yoluxdurucu vahid (YV)",
                        ru: "Инфекционная единица (ИЕ)",
                        id: "YV",
                    },
                    {
                        az: "D antigen vahidi",
                        ru: "Антигенная единица D",
                        id: "D",
                    },
                    { az: "ELISA vahidi", ru: "Единица ELISA", id: "S" },
                    {
                        az: "Lipoproteinlipaza vahidi (LV)",
                        ru: "Единица липопротеина-липазы (ЕЛЛ)",
                        id: "LV",
                    },
                    {
                        az: "Allerqan vahidi (AV)",
                        ru: "Единица Аллерган",
                        id: "AV",
                    },
                ],
                ingredientsGeneralTextAz: {
                    az: "Ümumi qeydlər (azərbaycanca)",
                    ru: "Общие заметки (на азербайджанском)",
                },
                ingredientsGeneralTextRu: {
                    az: "Ümumi qeydlər (rusca)",
                    ru: "Общие заметки (на русском)",
                },
                ingredientGeneralTextAz: {
                    az: "Bu maddə üçün xüsusi qeydlər (azərbaycanca)",
                    ru: "Особые заметки для данного вещества (на азербайджанском)",
                },
                ingredientGeneralTextRu: {
                    az: "Bu maddə üçün xüsusi qeydlər (rusca)",
                    ru: "Особые заметки для данного вещества (на русском)",
                },
                newIngredient: {
                    az: "Yeni təsiredici maddə",
                    ru: "Новое активное вещество",
                },
                editIngredient: {
                    az: "Təsiredici maddənin dəyişdirilməsi",
                    ru: "Изменение активного вещества",
                },
                addIngredientButton: {
                    az: "Əlavə et",
                    ru: "Добавить",
                },
                editIngredientButton: {
                    az: "Redaktə et",
                    ru: "Изменить",
                },
                cancelButton: {
                    az: "Bağla",
                    ru: "Закрыть",
                },
            },
            excipients: {
                title: {
                    az: "Köməkçi maddələr",
                    ru: "Вспомогательные вещества",
                },
                addButton: {
                    az: "Köməkçi maddələr əlavə et",
                    ru: "Добавить вспомогательные вещества",
                },
                newExcipients: {
                    az: "Yeni köməkçi maddələr",
                    ru: "Новые вспомогательные вещества",
                },
                addExcipientsButton: {
                    az: "Maddələri əlavə et",
                    ru: "Добавить вещества",
                },
            },
            coatings: {
                title: {
                    az: "Örtüklər",
                    ru: "Покрытия",
                },
                addButton: {
                    az: "Örtüklər əlavə et",
                    ru: "Добавить покрытия",
                },
                newCoatings: {
                    az: "Yeni örtüklər",
                    ru: "Новые покрытия",
                },
                addCoatingsButton: {
                    az: "Örtükləri əlavə et",
                    ru: "Добавить покрытия",
                },
            },
            pharmacologicalProperties: {
                title: {
                    az: "Farmakoloji xüsusiyyətlər",
                    ru: "Фармакологические особенности",
                },
                notes: {
                    az: "Ümumi qeydlər",
                    ru: "Общие заметки",
                },
                pharmacodynamicProperties: {
                    az: "Farmakodinamik xüsusiyyətlər",
                    ru: "Фармакодинамические особенности",
                },
                pharmacotherapeuticGroup: {
                    az: "Farmakoterapevtik qrup",
                    ru: "Фармакотерапевтическая группа",
                },
                ATCCode: {
                    az: "ATC kod",
                    ru: "Код АТХ",
                },
                pharmacokineticProperties: {
                    az: "Farmakokinetik xüsusiyyətlər",
                    ru: "Фармакокинетические особенности",
                },
                preclinicalSafetyData: {
                    az: "Preklinik təhlükəsizlik məlumatları",
                    ru: "Данные доклинической безопасности",
                },
                shelfLife: {
                    az: "Yararlılıq müddəti",
                    ru: "Срок годности",
                },
            },
            possibleSideEffects: {
                title: {
                    az: "Mümkün əlavə təsirlər",
                    ru: "Возможные сторонние эффекты",
                },
                generalText: {
                    az: "Ümumi qeydlər",
                    ru: "Общие заметки",
                },
                frequency: [
                    {
                        az: "Çox nadir hallarda",
                        ru: "Очень редко",
                        id: "VeryRare",
                    },
                    { az: "Nadir hallarda", ru: "Редко", id: "Rare" },
                    { az: "Bəzən", ru: "Иногда", id: "Uncommon" },
                    {
                        az: "Tez-tez rast gəlinən",
                        ru: "Часто встречающийся",
                        id: "Common",
                    },
                    {
                        az: "Çox tez-tez rast gəlinən",
                        ru: "Очень часто встречающийся",
                        id: "VeryCommon",
                    },
                ],
                addNewButton: {
                    az: "Yan təsirlər əlavə etmək",
                    ru: "Добавить сторонние эффекты",
                },
                bodySystem: {
                    az: "Orqanlar sistemi",
                    ru: "Система органов",
                },
                enterUndesirableEffects: {
                    az: "Yan təsirləri vergüllə ayıraraq daxil edin",
                    ru: "Введите сторонние эффекты через запятую",
                },
                attentionForEnter: {
                    az: "DİQQƏT! Yalnız eyni orqanlar sisteminə aid və eyni tezlikləri olan təsirləri vergüllə əlavə edin!",
                    ru: "ВНИМАНИЕ! Через запятую добавляйте только те эффекты, у которых общая система органов и частота!",
                },
                frequencyText: {
                    az: "Tezlik",
                    ru: "Частота",
                },
            },
            howToStoreIt: {
                title: {
                    az: "Saxlanma şəraiti",
                    ru: "Условия хранения",
                },
            },
            contentsOfThePack: {
                title: {
                    az: "Təsviri və qutunun tərkibi",
                    ru: "Описание и содержание коробки",
                },
                whatContains: {
                    az: "Qutunun tərkibi",
                    ru: "Содержание коробки",
                },
                whatLooksLike: {
                    az: "Təsviri",
                    ru: "Описание",
                },
            },
            medicalProduct: {
                title: {
                    az: "Tibbi məhsul",
                    ru: "Медицинский продукт",
                },
            },
            manufacturer: {
                title: {
                    az: "İstehsalçı",
                    ru: "Производитель",
                },
                addManufacturer: {
                    az: "İstehsalçı əlavə et",
                    ru: "Добавить производителя",
                },
                country: {
                    az: "Ölkə",
                    ru: "Страна",
                },
                address: {
                    az: "Ünvan",
                    ru: "Адрес",
                },
                number: {
                    az: "Nömrə",
                    ru: "Номер",
                },
                otherInformation: {
                    az: "Digər informasiya",
                    ru: "Другая информация",
                },
            },
            marketingAuthorisation: {
                title: {
                    az: "Lisenziya sahibi",
                    ru: "Владелец лицензии",
                },
                addMarketingAuthorisation: {
                    az: "Lisenziya sahibi əlavə et",
                    ru: "Добавить владельца лицензии",
                },
                holder: {
                    az: "Sahib",
                    ru: "Владелец",
                },
                authorisationNumber: {
                    az: "Qeydiyyat nömrəsi",
                    ru: "Номер авторизации",
                },
            },
            termsOfSale: {
                title: {
                    az: "Aptekdən buraxılma şərtləri",
                    ru: "Условия выпуска с аптеки",
                },
                terms: [
                    {
                        az: "Reseptsiz",
                        ru: "Без рецепта",
                        id: "WithoutPrescription",
                    },
                    {
                        az: "Reseptlə",
                        ru: "С рецептом",
                        id: "WithPrescription",
                    },
                    { az: "Sərbəst", ru: "Свободно", id: "Free" },
                ],
            },
        },
        medicalProductEditPage: {
            name: {
                az: "Adı",
                ru: "Название",
            },
            addNameButton: {
                az: "Ad əlavə et",
                ru: "Добавить имя",
            },
            internationalNonPatentedName: {
                az: "Beynəlxalq patentləşdirilməmiş adı",
                ru: "Международное незапатентованное имя",
            },
            names: {
                az: "1. Məhsulun adı",
                ru: "1. Имя продукта",
            },
            ingredients: {
                az: "2. Təsiredici maddələr",
                ru: "2. Активные вещества",
            },
            pharmaceuticalForm: {
                az: "3. Farmasevtik forması",
                ru: "3. Фармацевтическая форма",
            },
            clinicalParticulars: {
                az: "4. Kliniki xüsusiyyətləri",
                ru: "4. Клинические особенности",
            },
            clinicalParticularsTherapeuticIndications: {
                az: "4.1. Terapevtik göstəricilər",
                ru: "4.1. Терапевтические показатели",
            },
            clinicalParticularsPosologyAndMethod: {
                az: "4.2. Pozologiya və tətbiqetmə üsulu",
                ru: "4.2. Позология и способ применения",
            },
            clinicalParticularsPosologyGeneral: {
                az: "Ümumi mətn",
                ru: "Общий текст",
            },
            clinicalParticularsPosologyAdults: {
                az: "Yetkinlik yaşında olanlar üçün",
                ru: "Для взрослых",
            },
            clinicalParticularsPosologyElderly: {
                az: "Yaşlılar üçün",
                ru: "Для пожилых",
            },
            clinicalParticularsPosologyChildren: {
                az: "Uşaqlar üçün",
                ru: "Для детей",
            },
            clinicalParticularsContraindications: {
                az: "4.3. Əks göstərişlər",
                ru: "4.3. Противопоказания",
            },
            clinicalParticularsSpecialWarnings: {
                az: "4.4. İstifadə zamanı xüsusi göstərişlər və ehtiyat qaydaları",
                ru: "4.4. Специальные показания и меры предосторожности во время использования",
            },
            clinicalParticularsInteractionWithOther: {
                az: "4.5. Digər dərman vasitələrilə qarşılıqlı əlaqə",
                ru: "4.5. Взаимодействие с другими лекарствами",
            },
            addDrug: {
                az: "Dərman vasitəsi əlavə et",
                ru: "Добавить лекарство",
            },
            editDrug: {
                az: "Redaktə et",
                ru: "Редактировать",
            },
            deleteDrug: {
                az: "Silmək",
                ru: "Удалить",
            },
            clinicalParticularsPregnancy: {
                az: "4.6. Hamiləlik və laktasiya dövründə istifadə",
                ru: "4.6. Использование во время беременности и грудного кормления",
            },
            clinicalParticularsEffects: {
                az: "4.7. Nəqliyyat vasitələrini və digər potensial təhlükəli mexanizmləri idarəetmə qabiliyyətinə təsiri",
                ru: "4.7. Влияние на способность управления транспортными средствами и потенциально опасными механизмами",
            },
            clinicalParticularsUndesirableEffects: {
                az: "4.8. Əlavə təsirlər",
                ru: "4.8. Сторонние эффекты",
            },
            clinicalParticularsUndesirableEffect: {
                az: "Yan təsir",
                ru: "Сторонний эффект",
            },
            clinicalParticularsBodySystem: {
                az: "Orqanlar sistemi",
                ru: "Система органов",
            },
            clinicalParticularsFrequency: {
                az: "Tezlik",
                ru: "Частота",
            },
            clinicalParticularsOverdose: {
                az: "4.9. Doza həddinin aşılması",
                ru: "4.9. Передозировка",
            },
            cp: {
                az: "Kliniki farmakoloji göstəriciləri",
                ru: "Клинико-фармокологические указатели",
            },
            icd: {
                az: "Nozologiya",
                ru: "Нозология",
            },
            pharmacologicalProperties: {
                az: "5. Farmakoloji xüsusiyyətləri",
                ru: "5. Фармокологические свойства",
            },
            pharmacologicalPropertiesGeneral: {
                az: "Ümumi qeydlər",
                ru: "Общие заметки",
            },
            pharmacologicalPropertiesATC: {
                az: "ATC kodu",
                ru: "Код ATХ",
            },
            prarmacologicalPropertiesGroup: {
                az: "Farmakoterapevtik qrupu",
                ru: "Фармакотерапевтическая группа",
            },
            pharmacodynamicProperties: {
                az: "5.1. Farmakodinamik xüsusiyyətləri",
                ru: "5.1. Фармакодинамические особенности",
            },
            pharmacokineticProperties: {
                az: "5.2. Farmakokinetik xüsusiyyətləri",
                ru: "5.2. Фармакокинетические особенности",
            },
            preclinicalSafetyData: {
                az: "5.3. Preklinik təhlükəsizlik məlumatları",
                ru: "5.3. Данные о доклинической безопасности",
            },
            pharmaceuticalParticulars: {
                az: "6. Farmatevtik xüsusiyyətləri",
                ru: "6. Фармацевтические особенности",
            },
            listOfExcipients: {
                az: "6.1. Köməkçi maddələrin siyahısı",
                ru: "6.1. Список вспомогательных веществ",
            },
            incompatibilities: {
                az: "6.2. Uyğunsuzluqlar",
                ru: "6.2. Несоответствия",
            },
            shelfLife: {
                az: "6.3. Yararlılıq müddəti",
                ru: "6.3. Срок годности",
            },
            specialPrecautionsForStorage: {
                az: "6.4. Xüsusi saxlanma şəraiti",
                ru: "6.4. Специальные условия хранения",
            },
            natureAndContentsOfContainer: {
                az: "6.5. Buraxılış forması",
                ru: "6.5. Форма выпуска",
            },
            specialPrecautionsForDisposalAndOtherHandling: {
                az: "6.6. Utilizasiya vaxtı xüsusi təhlükəsizlik tədbirləri",
                ru: "6.6. Специальные меры предосторожности во время утилизации",
            },
            marketingAuthorisation: {
                az: "7. Satış lisenziyasının sahibi",
                ru: "7. Владелец лицензии на продажу",
            },
            marketingAuthorisationNumbers: {
                az: "8. Satış lisenziyasının nömrələri",
                ru: "8. Номера лицензии на продажу",
            },
            authorisationDate: {
                az: "9. İlk lisenziyanın tarixi / lisenziyanın uzadılması tarixi.",
                ru: "9. Дата первой авторизации / продления авторизации.",
            },
            ingredientsPage: {
                category: {
                    az: "Kateqoriya",
                    ru: "Категория",
                },
                quantity: {
                    az: "Miqdar",
                    ru: "Количество",
                },
                details: {
                    az: "Ətraflı",
                    ru: "Подробнее",
                },
                pharmacologicalProperties: {
                    az: "Farmakoloji xüsusiyyətlər",
                    ru: "Фармакологические свойства",
                },
                pharmacodynamicProperties: {
                    az: "Farmakodinamik xüsusiyyətlər",
                    ru: "Фармакодинамические свойства",
                },
                pharmacokineticProperties: {
                    az: "Farmakokinetik xüsusiyyətlər",
                    ru: "Фармакокинетические свойства",
                },
                preclinicalSafetyData: {
                    az: "Preklinik təhlükəsizlik üzrə məlumatlar",
                    ru: "Доклинические данные по безопасности",
                },
                undesirableEffects: {
                    az: "Yan təsirlər",
                    ru: "Сторонние эффекты",
                },
            },
            undesirableEffect: {
                effect: {
                    az: "Təsir",
                    ru: "Эффект",
                },
                bodySystem: {
                    az: "Orqanlar sistemi",
                    ru: "Система органов",
                },
                frequency: {
                    az: "Tezlik",
                    ru: "Частота",
                },
                addNew: {
                    az: "Təsir əlavə et",
                    ru: "Добавить эффект",
                },
            },
        },
        tabTitleAz: {
            az: "Azərbaycanca",
            ru: "На азербайджанском",
        },
        tabTitleRu: {
            az: "Rusca",
            ru: "На русском",
        },
        az: {
            az: "(azərbaycanca)",
            ru: "(на азербайджанском)",
        },
        ru: {
            az: "(rusca)",
            ru: "(на русском)",
        },
        leafletCount: {
            az: "Vərəqələrin sayı: ",
            ru: "Количество листовок: ",
        },
        productsCount: {
            az: "Tibbi məhsulların sayı: ",
            ru: "Количество медицинских продуктов: ",
        },
    },
    breadcrumbs: {
        home: {
            az: "Əsas səhifə",
            ru: "Главная страница",
        },
    },
    login: {
        username: {
            az: "İstifadəçi adı və ya e-mail",
            ru: "Имя пользователя или e-mail",
        },
        password: {
            az: "Şifrə",
            ru: "Пароль",
        },
        singIn: {
            az: "Giriş",
            ru: "Вход",
        },
        signUp: {
            az: "Qeydiyyat",
            ru: "Регистрация",
        },
    },
    undefinedField: {
        az: "Qeyd edilməyib",
        ru: "Не указан(а)",
    },
    optionChoose: {
        az: "siyahıdan seçmək",
        ru: "выбрать из списка",
    },
    error: {
        emptyField: {
            az: "Sahə boş ola bilməz!",
            ru: "Поле не может быть пустым!",
        },
    },
    subscribe: {
        title: {
            ru: "Мой профиль",
            az: "Mənim profilim",
        },
        terms: {
            ru: "Условия подписки",
            az: "Abunəlik şərtləri",
        },
        body: {
            ru: "Пользуясь нижними возможностями подписки, вы получаете доступ к самым важным клиническим рекомендациям, алгоритмам и продолжительно обновляющимся клиническим данным.",
            az: "Aşağıdakı abunə imkanlarından yararlanaraq, Siz ən vacib klinik təlimatlar, alqoritmlər və davamlı yenilənən klinik məlumata çıxış əldə etmiş olursunuz.",
        },
        asterisk_1: {
            ru: "Автоматическое обновление подписки.",
            az: "Abunəliyin avtomatik yenilənməsi.",
        },
        asterisk_2: {
            ru: "Вы в любой момент можете отказаться от подписки.",
            az: "Siz istənilən vaxt abunəlikdən imtina edə bilərsiniz.",
        },
        radio_button: [
            {
                ru: "Covid-19. Бесплатная подписка  - 0 AZN",
                az: "Covid-19. Pulsuz abunə  - 0 AZN",
            },
            {
                ru: "Подписка на 1 месяц  - 5 AZN",
                az: "1 aylıq abunə  - 5 AZN",
            },
            {
                ru: "Подписка на 3 месяца  - 15 AZN",
                az: "3 aylıq abunə  - 15 AZN",
            },
            {
                ru: "Подписка на 6 месяцев  - 25 AZN",
                az: "6 aylıq abunə  - 25 AZN",
            },
            {
                ru: "Подписка на 12 месяцев  - 40 AZN",
                az: "12 aylıq abunə  - 40 AZN",
            },
        ],
        submit: {
            ru: "Я принимаю условия подписки",
            az: "Mən abunəliyin şərtlərini qəbul edirəm",
        },
        confirm: {
            ru: "Я ознакомился с условиями подписки",
            az: "Abunəliyin şərtləri ilə tanış oldum",
        },
    },
    audience: {
        ru: "Аудитория",
        az: "Auditoriya",
    },
    footer: {
        textUs: {
            ru: "Пишите нам:",
            az: "Bizə yazın:",
        },
        callUs: {
            ru: "Звоните нам:",
            az: "Bizə zəng edin:",
        },
        allRightsReserved: {
            ru: "Все права защищены.",
            az: "Bütün hüquqlar qorunur.",
        },
        support: {
            title: {
                ru: "ПОДДЕРЖКА",
                az: "DƏSTƏK",
            },
            link_1: {
                ru: "Центр помощи",
                az: "Yardım mərkəzi",
            },
            link_2: {
                ru: "Частые вопросы",
                az: "Tez-tez verilən suallar",
            },
            link_4: {
                ru: "Связаться с нами",
                az: "Bizimlə əlaqə",
            },
        },
        around: {
            title: {
                ru: "О НАС",
                az: "HAQQIMIZDA",
            },
            link_1: {
                ru: "О Dermanlarim.az",
                az: "Dermanlarim.az haqda",
            },
            link_2: {
                ru: "Рекламная политика",
                az: "Reklam siyasəti",
            },
            link_3: {
                ru: "Атрибуция и цитирование",
                az: "Atribusiya və sitat",
            },
            link_4: {
                ru: "Блог Dermanlarim.az",
                az: "Dermanlarim.az bloqu",
            },
        },
        terms: {
            title: {
                ru: "УСЛОВИЯ И КОНФИДЕНЦИАЛЬНОСТЬ",
                az: "ŞƏRTLƏR VƏ MƏXFİLİK",
            },
            link_1: {
                ru: "Редакционная политика",
                az: "Nəşr siyasəti",
            },
            link_2: {
                ru: "Политика конфиденциальности",
                az: "Məxfilik siyasəti",
            },
            link_3: {
                ru: "Правила использования",
                az: "İstifadə şərtləri",
            },
            lastEdited: {
                az: "Sonuncu düzəliş",
                ru: "Последняя правка",
            },
        },
    },
    ads_placeholder: {
        ru: "Тут может быть место для вашей рекламы",
        az: "Burada sizin reklamınız ola bilər",
    },
    account: {
        overview: {
            mainTitle: {
                az: "Şəxsi kabinet",
                ru: "Личный кабинет",
            },
            title: {
                az: "Profilə baxış",
                ru: "Обзор",
            },
        },
        editDetails: {
            mainTitle: {
                az: "Şəxsi məlumatlarınızı yeniləyin",
                ru: "Обновите свои личные данные",
            },
            title: {
                az: "Ətraflı məlumatların redaktəsi",
                ru: "Редактировать детали",
            },
            p_1: {
                az: "Siz bu formadan istifadə edərək şifrənizi və digər şəxsi məlumatlarınızı redaktə edə bilərsiniz.",
                ru: "Вы можете использовать эту форму, чтобы изменить свой пароль или другую личную информацию.",
            },
            firstName: {
                az: "Ad",
                ru: "Имя",
            },
            lastName: {
                az: "Soyad",
                ru: "Фамилия",
            },
            birthdate: {
                az: "Doğum tarixi",
                ru: "Дата рождения",
            },
            sex: {
                az: "Cins",
                ru: "Пол",
            },
            sexChoose: {
                male: {
                    az: "Kişi",
                    ru: "Мужской",
                },
                female: {
                    az: "Qadın",
                    ru: "Женский",
                },
            },
            username: {
                az: "İstifadəçi adı",
                ru: "Имя пользователя",
            },
            updateInfo: {
                az: "Məlumatları yenilə",
                ru: "Обновить инвормацию",
            },
            changePassword: {
                az: "Şifrəni dəyişmək",
                ru: "Изменить пароль",
            },
            didYouForget: {
                az: "Loqin və ya şifrəni unutmusunuz?",
                ru: "Забыли логин или пароль?",
            },
            tapCurrentPassword: {
                az: "Hazırki şifrənizi daxil edin",
                ru: "Введите текущий пароль",
            },
            tapOldPassword: {
                az: "Köhnə şifrəni daxil edin",
                ru: "Введите старый пароль",
            },
            tapNewPassword: {
                az: "Yeni şifrəni daxil edin",
                ru: "Введите новый пароль",
            },
            repeatNewPassword: {
                az: "Yeni şifrəni təkrarlayın",
                ru: "Повторите новый пароль",
            },
            specialization: {
                az: "İxtisas",
                ru: "Специализация",
            },
            medicalInstitution: {
                az: "Tibbi müəssisə",
                ru: "Медицинское учреждение",
            },
        },
        follows: {
            mainTitle: {
                az: "Abunəliklər",
                ru: "Подписки",
            },
            title: {
                az: "Abunəliklər",
                ru: "Подписки",
            },
        },
        myDrugs: {
            mainTitle: {
                az: "Dərmanlarım",
                ru: "Мои лекарства",
            },
            title: {
                az: "Dərmanlarım",
                ru: "Мои лекарства",
            },
        },
        logout: {
            mainTitle: {
                az: "Çıxış",
                ru: "Выход",
            },
            title: {
                az: "Çıxış",
                ru: "Выход",
            },
        },
        buttons: {
            addDrug: {
                az: "Dərman əlavə et",
                ru: "Добавить лекарство",
            },
            editProfileInfo: {
                az: "Ətraflı məlumatların redaktəsi",
                ru: "Редактировать детали",
            },
        },
        drugModal: {
            name: {
                az: "Dərmanı seçin",
                ru: "Выберите лекарство",
            },
            startDate: {
                az: "Qəbula başlama tarixi",
                ru: "Дата начала приёма",
            },
            endDate: {
                az: "Qəbulu bitirmə tarixi",
                ru: "Дата конца приёма",
            },
            dose: {
                az: "Dozası",
                ru: "Дозировка",
            },
            receptionFrequency: {
                az: "Qəbul tezliyi",
                ru: "Частота приёма",
            },
            status: {
                az: "Status",
                ru: "Статус",
            },
            statusType: {
                continue: {
                    az: "Davam edir",
                    ru: "Продолжается",
                },
                ended: {
                    az: "Yekunlaşıb",
                    ru: "Завершён",
                },
            },
        },
    },
    alerts: {
        successSave: {
            az: "Uğurla yadda saxlanıldı",
            ru: "Успешно сохранено",
        },
        undefinedError: {
            az: "Naməlum xəta",
            ru: "Неизвестная ошибка",
        },
    },
    selectDefault: {
        az: "siyahıdan seçin...",
        ru: "выберите из списка...",
    },
    auth: {
        title: {
            az: "Hesabın bərpası",
            ru: "Восстановление аккаунта",
        },
        subTitle: {
            az: "Hesabın bərpası üçün yeni şifrəni və onun təkrarını daxil edin",
            ru: "Для восстановления аккаунта введите новый пароль и его повтор",
        },
        enterNewPass: {
            az: "Yeni şifrə",
            ru: "Новый пароль",
        },
        repeatNewPass: {
            az: "Yeni şifrəni təkrarlayın",
            ru: "Повторите новый пароль",
        },
        successfullUpdated: {
            az: "Hesab uğurla bərpa olundu. Yeni şifrə ilə daxil ola bilərsiniz",
            ru: "Аккаунт успешно восстановлен. Можете войти в систему с новым паролем",
        },
        errorUpdated: {
            az: "Hesab bərpa olunmadı! Təkrar cəhd edin.",
            ru: "Не удалось восстановить аккаунт! Попробуйте заново.",
        },
    },
};

const dictionaryReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default dictionaryReducer;
