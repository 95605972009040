import React from "react";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../models/loading-state.enum";
import { UserRole } from "../models/user-role.enum";
import i18n from "i18next";
import { LoginStage } from "../models/auth-state.enum";
import history from "./../../history";
import { showAlert } from "./../../components/Dialogs";
import { base32Decode } from "@ctrl/ts-base32";
import config from "../../config";

const TOKEN_LIFE_TIME = 4 * 60 * 1000;

function phone(login) {
    if (login) {
        if (login.startsWith("0")) {
            return "+994" + login.substring(1);
        } else {
            if (!login.startsWith("+")) {
                return "+994" + login;
            }
        }
    }
    return login;
}

export const SIGN_IN = "SIGN_IN";

export const _signIn = (userToken) => {
    return {
        type: SIGN_IN,
        userToken,
    };
};

export const TOGGLE_AUTH = "TOGGLE_AUTH";

export const toggleAuth = (value) => {
    return {
        type: TOGGLE_AUTH,
        value,
    };
};

export const SIGN_OUT = "SIGN_OUT";

export const _signOut = () => {
    return {
        type: SIGN_OUT,
    };
};

export const PRE_SIGN_IN = "PRE_SIGN_IN";

export const _preSignIn = (userToken) => ({
    type: PRE_SIGN_IN,
    userToken,
});

export const SIGN_UP = "SIGN_UP";

const _signUp = () => ({ type: SIGN_UP });

export const USER_BY_ID = "USER_BY_ID";

const _userById = (userProfileById) => ({
    type: USER_BY_ID,
    userProfileById,
});

export const PROCESS_STAGE = "PROCESS_STAGE";

export const processStageChange = (processStage) => ({
    type: PROCESS_STAGE,
    processStage,
});

export const SELF_PROFILE = "SELF_PROFILE";

const _fetchSelf = (self) => ({
    type: SELF_PROFILE,
    self,
});

export const SAVE_USER = "SAVE_USER";

const saveUser = (user) => ({
    type: SAVE_USER,
    user,
});

export const AUTH_ERROR_TEXT = "AUTH_ERROR_TEXT";

export const authErrorText = (text) => ({
    type: AUTH_ERROR_TEXT,
    text,
});

// ---- new doctor

export const NEW_DOCTOR = "NEW_DOCTOR";

export const _newDoctor = () => ({ type: NEW_DOCTOR });

export const NEW_DOCTOR1 = "NEW_DOCTOR1";
export const NEW_DOCTOR2 = "NEW_DOCTOR2";

export const SAVE_OPERATION = "SAVE_OPERATION";

const saveOperation = (operation, hasEmail) => ({
    type: SAVE_OPERATION,
    operation,
    hasEmail,
});

export const errorMsgSignUp = (code) => async (dispatch, getState) => {
    if (code === 2020 || code === 107) {
        dispatch(authErrorText("Username already taken"));
    } else if (code === 2021 || code === 108) {
        dispatch(authErrorText("Email address already taken"));
    } else if (code === 2025 || code === 108) {
        dispatch(authErrorText("Email address registered, but not verified"));
    } else if (code === 2022) {
        dispatch(authErrorText("Telefon nömrəsi artıq götürülüb"));
    } else dispatch(authErrorText("Registration error"));
};

export const errorMsgLogin = (code) => async (dispatch, getState) => {
    if (code === 1010) {
        dispatch(authErrorText("Səhv istifadəçi adı və ya şifrə"));
    } else if (code === 2025) {
        dispatch(authErrorText("Email address registered, but not verified"));
        dispatch(processStageChange(LoginStage.EmailVerification));
    } else if (code === 2026) {
        dispatch(authErrorText("Phone number registered, but not verified"));
        dispatch(processStageChange(LoginStage.PhoneVerification));
    } else dispatch(authErrorText("Login error"));
};

export const signIn = (user, closeOnSuccess) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SignIn));
    // console.log('user', user);
    try {
        dispatch(saveUser(user));
        user.phone = phone(user.login);
        const response = await fetch(config.auth + "signIn?longToken=true", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // 'Device-Id': '',
                // 'Device-Type': ''
            },
            body: JSON.stringify(user),
        });
        const json = await response.json();

        if (response.status === 200) {
            const infoResponse = await fetch(`${config.url}profiles/get`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization2: json.auth,
                },
            });
            if (infoResponse.status !== 200) {
                console.warn(infoResponse);
                dispatch(
                    authErrorText(
                        "Unknown error. Please, check internet connection"
                    )
                );
                return;
            }
            const info = await infoResponse.json();
            json.role = info.role;
            json.selfId = info.externalId;

            if (!info.role) {
                dispatch(_signIn());
                dispatch(_preSignIn(json));
                dispatch(processStageChange(LoginStage.VerifyPhoneSuccess));
            } else {
                dispatch(_preSignIn());
                localStorage.setItem(
                    "az.ezgil.dermanlarim.token",
                    JSON.stringify(json)
                );
                dispatch(_signIn(json));
                dispatch(_fetchSelf(info));
                dispatch(processStageChange(LoginStage.VerifyPhoneSuccess));
                dispatch(toggleAuth(false));
            }
        } else if (response.status === 401) {
            dispatch(authErrorText("Səhv istifadəçi adı və ya şifrə"));
        } else {
            dispatch(errorMsgLogin(json.code));
        }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.SignIn));
    }
};

export const signOut = () => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SignOut));
    try {
        const response = await authFetch(
            getState,
            dispatch,
            config.auth + "signOut",
            "POST"
        );
        localStorage.removeItem("az.ezgil.dermanlarim.token");
        dispatch(_signOut());
        history.push("/");
        if (response.status !== 200) {
            dispatch(authErrorText("Logout error"));
        }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.SignOut));
    }
};

export const signUp = (user) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SignUp));

    try {
        if (user.phone) {
            if (user.phone.length == 9) {
                user = { ...user, phone: "+994" + user.phone };
            } else if (user.phone.length > 0) {
                dispatch(authErrorText("Yanlış telefon nömrəsinin formatı!"));
                console.log(user.phone);
                return;
            }
        }

        const response = await fetch(config.auth + "signUp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                phone: user.phone,
                login: user.login,
                email: user.email,
                password: user.password,
                language: null,
            }),
        });

        const json = await response.json();

        if (response.status === 200) {
            dispatch(saveUser(user));
            dispatch(processStageChange(LoginStage.PhoneVerification));
            return;
        }
        // console.log('response', json);
        dispatch(errorMsgSignUp(json.code));
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.SignUp));
    }
};

export const fetchSelf = (token, role) => async (dispatch, getState) => {
    // console.log('fetchSelf role', role);
    try {
        const response = await fetch(`${config.url}profiles/get`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization2: token,
            },
        });
        if (response.status === 200) {
            const json = await response.json();
            // console.log('json self', json);
            dispatch(_fetchSelf(json));
        } else {
            // console.log('response', response);
            dispatch(authErrorText("Error getting data"));
        }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    }
};

export const restorePassword = (emailOrLogin) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.RestorePswd));
    try {
        const response = await fetch(config.auth + "restorePassword", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                login: emailOrLogin,
                email: emailOrLogin,
                phone: phone(emailOrLogin),
            }),
        });
        const json = await response.json();
        console.warn("json", json);

        if (response.status === 200) {
            dispatch(saveUser({ login: emailOrLogin }));
            dispatch(saveOperation(json.operation, json.hasEmail));
            dispatch(processStageChange(6));
        } else {
            console.warn("Error", response);
            dispatch(authErrorText("Error"));
        }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.RestorePswd));
    }
};

const arrayBufferToString = async (buffer) => {
    return new Promise((resolve, reject) => {
        var blob = new Blob([buffer], { type: "text/plain" });
        var reader = new FileReader();
        reader.onload = function (evt) {
            if ((evt.target || {}).result) {
                resolve(evt.target.result);
            } else {
                reject("");
            }
        };
        reader.onerror = function (e) {
            reject("");
        };
        reader.readAsText(blob, "UTF-8");
    });
};

export const verfyFromParams =
    (userdata, code, operation) => async (dispatch, getState) => {
        dispatch(loadingStart(LoadingSubject.VerifyEmail));
        try {
            const base32 = base32Decode(userdata, "Crockford");
            const params = JSON.parse(await arrayBufferToString(base32));
            if (params) {
                if (params.email) {
                    dispatch(saveUser({ login: params.email }));
                    dispatch(processStageChange(2));
                    dispatch(toggleAuth(true));
                    dispatch(verifyEmail(params.email, code));
                    return;
                } else if (
                    params.type &&
                    params.type.indexOf("restore_by_") > -1 &&
                    operation
                ) {
                    dispatch(saveUser({ login: params.type.substring(11) }));
                    dispatch(processStageChange(6));
                    dispatch(toggleAuth(true));
                    dispatch(saveOperation(operation, true));
                    dispatch(verifyOperation(operation, code));
                    return;
                }
            }
        } catch (e) {
            dispatch(loadingEnd(LoadingSubject.VerifyEmail));
        }
        await showAlert("Unknown error. Please, check internet connection");
    };

export const verifyOperation =
    (operation, code) => async (dispatch, getState) => {
        dispatch(loadingStart(LoadingSubject.VerifyEmail));
        try {
            console.warn("operation", operation);
            console.warn("code", code);
            const response = await fetch(
                `${config.auth}verifyOperation?operation=${operation}&code=${code}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: "{}",
                }
            );

            const json = await response.json();
            console.warn("json", json);
            console.warn("response", response);
            if (response.status === 200) {
                //что-то еще, наверно, надо будет тут делать
                if (json.status === 1) {
                    dispatch(authErrorText("Code expired"));
                } else dispatch(processStageChange(7));
            } else {
                console.warn("Error", response);
                dispatch(authErrorText("Error"));
            }
        } catch (e) {
            dispatch(
                authErrorText(
                    "Unknown error. Please, check internet connection"
                )
            );
        } finally {
            dispatch(loadingEnd(LoadingSubject.VerifyEmail));
        }
    };

export const restoreChangePassword =
    (operation, user) => async (dispatch, getState) => {
        dispatch(loadingStart(LoadingSubject.ChangePswd));
        try {
            console.warn("operation", operation);
            console.warn("user", user);
            const response = await fetch(
                `${config.auth}restoreChangePassword?operation=${operation}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        login: user.login || user.email,
                        email: user.email || user.login,
                        phone: phone(user.email || user.login),
                        password: user.password,
                    }),
                }
            );

            const json = await response.json();
            console.warn("json", json);
            console.warn("response", response);
            if (response.status === 200) {
                dispatch(processStageChange(8));
            } else {
                console.warn("Error", response);
                dispatch(authErrorText("Error"));
            }
        } catch (e) {
            dispatch(
                authErrorText(
                    "Unknown error. Please, check internet connection"
                )
            );
        } finally {
            dispatch(loadingEnd(LoadingSubject.ChangePswd));
        }
    };

export const verifyEmail = (username, code) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.VerifyEmail));
    try {
        const response = await fetch(
            `${config.auth}verifyEmail?username=${encodeURIComponent(
                username
            )}&code=${code}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: "{}",
            }
        );

        const json = await response.json();
        if (response.status === 200) {
            if (json.status === 1) {
                dispatch(authErrorText("Code expired"));
            } else dispatch(processStageChange(LoginStage.VerifyEmailSuccess));
        } else {
            console.warn("Error", response);
            dispatch(authErrorText("Error"));
        }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.VerifyEmail));
    }
};

export const verifyPhone =
    (username, code, next = LoginStage.PhoneVerification) =>
    async (dispatch, getState) => {
        dispatch(loadingStart(LoadingSubject.VerifyPhone));
        try {
            const response = await fetch(
                `${config.auth}verifyPhone?username=${encodeURIComponent(
                    username
                )}&phone=${encodeURIComponent(phone(username))}&code=${code}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: "{}",
                }
            );

            const json = await response.json();
            if (response.status === 200) {
                if (json.status === 1) {
                    dispatch(authErrorText("Code expired"));
                } else {
                    dispatch(processStageChange(LoginStage.VerifyPhoneSuccess));
                }
            } else {
                console.warn("Error", response);
                dispatch(authErrorText("Error"));
            }
        } catch (e) {
            dispatch(
                authErrorText(
                    "Unknown error. Please, check internet connection"
                )
            );
        } finally {
            dispatch(loadingEnd(LoadingSubject.VerifyPhone));
        }
    };

export const resendVerification =
    (emailOrUsername, subject, operation) => async (dispatch, getState) => {
        try {
            let url = "";
            if (!operation) {
                url = `emailOrUsername=${emailOrUsername}&subject=${subject}`;
            } else {
                url = `emailOrUsername=${emailOrUsername}&subject=${subject}&operation=${operation}`;
            }
            const response = await fetch(
                `${config.auth}resendVerification?${url}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: "{}",
                }
            );
            if (response.status === 200) {
                dispatch(authErrorText("Kod göndərildi"));
            } else {
                console.warn("Error", response);
                dispatch(authErrorText("Error"));
            }
        } catch (e) {
            console.warn("Error", e);
            dispatch(
                authErrorText(
                    "Unknown error. Please, check internet connection"
                )
            );
        }
    };

export const changePassword = (token, user) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ChangePswd));
    try {
        const response = await authFetch(
            getState,
            dispatch,
            `${config.auth}changePassword`,
            "POST",
            {
                oldPassword: user.oldpassword,
                newPassword: user.password,
            }
        );
        if (response.status === 200) {
            dispatch(
                authErrorText(
                    "Password successfully changed. Please, login with new password"
                )
            );
            dispatch(signOut(token));
        } else {
            console.warn("Error", response);
            dispatch(authErrorText("Error"));
        }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.ChangePswd));
    }
};

export const restoreLogin = (token) => async (dispatch, getState) => {
    const result = await syncRefresh(token, dispatch, getState);
    if (result) {
        dispatch(fetchSelf(result.auth, result.role));
    }
};

const refresh = async (token, dispatch, getState) => {
    try {
        console.log("Refresh token started");
        const response = await fetch(`${config.auth}refresh`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                Authorization: token.refresh,
            },
            redirect: "error", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: JSON.stringify({}),
        });

        if (response.status === 401) {
            localStorage.removeItem("az.ezgil.dermanlarim.token");
            dispatch(_signOut());
            history.push("/");

            console.log("Refresh token expired");
            return undefined;
        }

        if (response.status !== 200) {
            console.log("Refresh token failed");
            return undefined;
        }

        const json = await response.json();
        json.role = token.role;
        json.selfId = token.selfId;
        json.status = token.status;
        json.clientId = token.clientId;

        const preUserToken = getState().vdAuthReducer.preUserToken;
        if (preUserToken) {
            dispatch(_preSignIn(json));
        } else {
            localStorage.setItem(
                "az.ezgil.dermanlarim.token",
                JSON.stringify(json)
            );
            dispatch(_signIn(json));
        }

        return json;
    } catch (e) {
        // Ignore
    }
    return undefined;
};

let refreshPromise = undefined;

export const syncRefresh = (token, dispatch, getState) => {
    if (refreshPromise) {
        return refreshPromise;
    }
    return (refreshPromise = new Promise(async (myResolve, myReject) => {
        const payload = await refresh(token, dispatch, getState);
        myResolve(payload);
        refreshPromise = undefined;
    }));
};

export const authFetch = async (
    getState,
    dispatch,
    url,
    method,
    body = undefined,
    headers = undefined
) => {
    const headers_obj = {
        ...(headers || {}),
        "Content-Type": "application/json",
    };

    const preUserToken = getState().vdAuthReducer.preUserToken;
    let tokens =
        preUserToken ||
        JSON.parse(localStorage.getItem("az.ezgil.dermanlarim.token") || "{}");

    if (url.startsWith(config.url)) {
        headers_obj.Authorization2 = tokens.auth;
    } else {
        headers_obj.Authorization = tokens.auth;
    }
    let response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: headers_obj,
        //  redirect: "error", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: body ? JSON.stringify(body) : undefined,
    });

    if (response.status === 401) {
        if (!tokens || !tokens.refresh) {
            localStorage.removeItem("az.ezgil.dermanlarim.token");
            dispatch(_signOut());
            history.push("/");

            throw new Error("refresh token empty");
        }
        tokens = await syncRefresh(tokens, dispatch, getState);
        if (!tokens) {
            throw new Error("refresh token failed");
        }

        response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                ...(headers || {}),
                "Content-Type": "application/json",
                ...(url.startsWith(config.url)
                    ? { Authorization2: tokens.auth }
                    : { Authorization: tokens.auth }),
            },
            redirect: "error", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: body ? JSON.stringify(body) : undefined,
        });

        if (response.status === 401) {
            localStorage.removeItem("az.ezgil.dermanlarim.token");
            dispatch(_signOut());
            history.push("/");
            throw new Error("auth token failed");
        }
    } else if (response.status === 404) {
        dispatch(signOut());
    }

    return response;
};

export const EDIT_PROFILE = "EDIT_PROFILE";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

const _editProfile = (data) => ({
    type: EDIT_PROFILE,
    payload: data,
});

const _getProfile = (data) => ({
    type: GET_PROFILE,
    payload: data,
});

const _updateProfile = (data) => ({
    type: UPDATE_PROFILE,
    payload: data,
});

export const editProfile = (data) => {
    return function (dispatch, getState) {
        (async () => {
            const response = await authFetch(
                getState,
                dispatch,
                `${config.url}profiles/save`,
                "POST",
                { ...data, confirmed: true }
            );
            if (!response.error) {
                const json = await response.json();
                // console.log('json', json);
                dispatch(_editProfile(json));
            }
        })();
    };
};

export const getProfile = () => {
    return function (dispatch, getState) {
        (async () => {
            const response = await authFetch(
                getState,
                dispatch,
                `${config.url}profiles/get`,
                "GET"
            );
            if (!response.error) {
                const json = await response.json();
                dispatch(_getProfile(json));
            }
        })();
    };
};

export const updateProfile = (data) => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveUploadProfile));
    try {
        // return function (dispatch, getState) {
        (async () => {
            const response = await authFetch(
                getState,
                dispatch,
                `${config.url}profiles/edit`,
                "POST",
                { ...data, confirmed: true }
            );

            if (!response.error) {
                const json = await response.json();
                dispatch(_updateProfile(json));
                showAlert("UpdatedSuccessfully");
            }
        })();
        // }
    } catch (e) {
        dispatch(
            authErrorText("Unknown error. Please, check internet connection")
        );
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveUploadProfile));
    }
};
