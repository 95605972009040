import React from "react";
import { CardHeader, CardBody } from "reactstrap";

import { connect } from "react-redux";

import "./TreatmentOptions.scss";

import SearchField from "../SearchField/SearchField";
import AdsBlock from "../AdsBlock/AdsBlock";
import { PopularRequests } from "../PopularRequest/PopularRequests";

class TreatmentOptions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dictionary, lang, newsData } = this.props;

        return (
            <div className="treatment_options_page">
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 treatment_options_title">
                        {dictionary.treatmentOptions.treatmentOptionsTitle[lang]}
                    </h1>
                </CardHeader>
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 treatment_optionss_title">
                        {dictionary.treatmentOptions.searchTitle[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="treatment_options_body search_card">
                    <SearchField searchTypes="treatment_options"></SearchField>
                </CardBody>
                <CardHeader className="bg-white border-0">
                    <h1 className="mb-0 treatment_options_title">
                        {dictionary.treatmentOptions.infoTitle[lang]}
                    </h1>
                </CardHeader>
                <CardBody className="treatment_options_body">
                    {dictionary.treatmentOptions.infoBody[lang]}
                </CardBody>
                <div className="main_content_ads_wrapper">
                    <div className="main_content">
                        <CardHeader className="bg-white border-0">
                            <h1 className="mb-0 treatment_options_title">
                                {dictionary.treatmentOptions.popularRequestsTitle[lang]}
                            </h1>
                        </CardHeader>
                        <CardBody className="treatment_options_body">
                            <PopularRequests type="treatment_options"/>
                        </CardBody>
                    </div>
                    <AdsBlock></AdsBlock>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

export default connect(mapStateToProps)(TreatmentOptions);
