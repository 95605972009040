import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./NewsBlock.scss";
import { withTranslation } from "react-i18next";

class NewsBlock extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t, lang } = this.props;
        return (
            <div className="news-block__content">
                <Link
                    to={`/drugs/one_news/${this.props.id}`}
                    className="news-title"
                    dangerouslySetInnerHTML={{
                        __html: this.props.title[lang],
                    }}
                ></Link>
                <Link
                    to={`/drugs/one_news/${this.props.id}`}
                    className="news-title"
                >
                    <div
                        className="news-text"
                        dangerouslySetInnerHTML={{
                            __html: this.props.miniText[lang],
                        }}
                    ></div>
                </Link>
                <p className="news-date">
                    {this.props.Datetime && moment(this.props.Datetime)
                        ? moment(this.props.Datetime).format("DD.MM.YYYY")
                        : t("Empty date")}{" "}
                    <Link
                        to={
                            "/drugs/news/_/types=" +
                            (this.props.EntityType
                                ? this.props.EntityType.toLowerCase()
                                : "")
                        }
                        className="link"
                        style={this.props.style}
                    >
                        {t(this.props.EntityType)}
                    </Link>
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    lang: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(NewsBlock)
);
