import React, {useState} from 'react';
import {Button, Row, Col, 
    Card, CardHeader, CardBody, 
    Label, Collapse, TabContent, TabPane,
    Input, ListGroup, ListGroupItem,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Nav, NavItem, NavLink, Table} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const MPPharmaceutical = props => {
    const {lang, dictionary, medicalProduct} = props;
    const [pharmaceuticalForms, setPharmaceuticalForms] = useState(medicalProduct.pharmaceuticalForm || []);

    const submitButtonHandler = () => {
        // убираем пустышки
        const cleanForm = pharmaceuticalForms.filter(n => n.az);
        props.changeProduct({
            pharmaceuticalForm: cleanForm
        });
        props.toggleSave(true);
    }
 
    const addPharmaceuticalForm = () => {
        setPharmaceuticalForms([...pharmaceuticalForms, {az: '', ru: ''}]);
    }

    const deletePharmaceuticalForm = id => {
        if (id) return setPharmaceuticalForms(n => n.filter(e => e.id !== id));
    }
 
    return (
        <Row>
            <Col xs='10' className='pt-0 pl-5 pr-5 pb-5'>
                {pharmaceuticalForms.map((n, i) => (
                    <PharmaceuticalFormArrayElement
                        key={n.id}
                        pharmaceuticalForms={pharmaceuticalForms} id={n.id} index={i} 
                        setPharmaceuticalForms={setPharmaceuticalForms} 
                        deletePharmaceuticalForm={deletePharmaceuticalForm}
                        dictionary={dictionary} lang={lang} />
                ))}
                <Button onClick={addPharmaceuticalForm} color='primary'><FontAwesomeIcon icon={faPlus}/> {dictionary.adminDrugs.medicalProductEditPage.addNameButton[lang]}</Button>
            </Col>
            <Col xs='2' className='pt-0 pr-5 pb-5'>
                <Button onClick={submitButtonHandler} color='success'>{dictionary.adminDrugs.saveButton[lang]}</Button>
            </Col>
        </Row>
    )
}

const PharmaceuticalFormArrayElement = props => {
    const {id, dictionary, lang, pharmaceuticalForms, setPharmaceuticalForms, index, deletePharmaceuticalForm} = props;
    return (
        <>
            <Row>
                <Col xs="5">
                    <Label>{index+1}. {dictionary.adminDrugs.medicalProductEditPage.name[lang]} {dictionary.adminDrugs.az[lang]}</Label>
                    <CKEditor
                        editor={InlineEditor}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            pharmaceuticalForms[index].az = data;
                            setPharmaceuticalForms(pharmaceuticalForms);
                        }}
                        data={((pharmaceuticalForms || [])[index] || {}).az || ''} />
                </Col>
                <Col xs="5">
                    <Label>{dictionary.adminDrugs.medicalProductEditPage.name[lang]} {dictionary.adminDrugs.ru[lang]}</Label>
                    <CKEditor
                        editor={InlineEditor}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            pharmaceuticalForms[index].ru = data;
                            setPharmaceuticalForms(pharmaceuticalForms);
                        }}
                        data={((pharmaceuticalForms || [])[index] || {}).ru || ''} />
                </Col>
                <Col xs="2">
                    <Button onClick={deletePharmaceuticalForm.bind(this, id)} style={{position: 'absolute', bottom: 1}} color="danger"><FontAwesomeIcon icon={faTrash} /></Button>
                </Col>
            </Row>
            <hr/>
        </>
    )
}

export default MPPharmaceutical;