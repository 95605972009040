import { combineReducers } from 'redux';

import drugReducer from './drugReducer';
import dictionaryReducer from './dictionaryReducer';
import languageReducer from './languageReducer';
import authReducer from './auth-reducer';
import mainPageReducer from './mainPageReducer';
import footerReducer from './footerReducer';
import searchByLetterReducer from './searchByLetterReducer';
import adminReducer from './adminReducer';
import medicalProductsReducer from './medicalProductsReducer';
import { patientMedicineReducer } from './patient-medicine-reducer';
import userReducer from './userReducer';
import errorReducer from './errorReducer';
import { loadingReducer } from './loadingReducer';
import { vdAuthReducer } from './vd-auth-reducer';
import { reducer as form } from 'redux-form';
import { adsReducer } from "./adsReducer";
import { subscriptionReducer } from "./subscription-reducer";
import { classifications } from './classificationsReducer';

export default combineReducers({
    drugReducer,
    dictionaryReducer,
    languageReducer,
    authReducer,
    mainPageReducer,
    footerReducer,
    searchByLetterReducer,
    adminReducer,
    medicalProductsReducer,
    userReducer,
    errorReducer,
    loadingReducer,
    vdAuthReducer,
    adsReducer,
    subscriptionReducer,
    patientMedicine: patientMedicineReducer,
    classifications,
    form,
});