import { AZ_ALPHABET, RU_ALPHABET, NUMBERS_0_9 } from "./alphabets";

export const alphabeticalLoop = (
    lang,
    jsx,
    method,
    selectedLetter,
    lettersCount
) => {
    if (selectedLetter !== "0-9") {
        switch (lang) {
            case "ru":
                return RU_ALPHABET.map((letter) => {
                    return jsx(
                        letter,
                        method,
                        selectedLetter,
                        lang,
                        lettersCount
                    );
                });
            default:
                return AZ_ALPHABET.map((letter) => {
                    return jsx(
                        letter,
                        method,
                        selectedLetter,
                        lang,
                        lettersCount
                    );
                });
        }
    } else {
        return NUMBERS_0_9.map((letter) => {
            return jsx(letter, method, selectedLetter, lang, lettersCount);
        });
    }
};
