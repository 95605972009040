import React from "react";

import { connect } from "react-redux";
import config from "../../../config";
import { setLanguage } from "../../../redux/actions/simpleActions";
import "./DrugFooter.scss";

class DrugsFooter extends React.Component {
    render() {
        let language = this.props.language;
        let dictionary = this.props.dictionary;

        return (
            <>
                <footer className="footer">
                    <div className="footer_container">
                        <div className="footer_row">
                            <div className="logo_block">
                                <img
                                    className="logo_img"
                                    src={require("./img/logo.webp")}
                                    width={142.4}
                                    height={116}
                                    alt="Dermanlarim logo"
                                />
                                <div className="contacts_block_wrapper">
                                    <span>
                                        {dictionary.footer.callUs[language]}
                                    </span>
                                    <div className="contacts_block">
                                        <span>(+994) 51-206-38-13</span>
                                    </div>
                                </div>
                                <div>
                                    <span>
                                        {dictionary.footer.textUs[language]}
                                    </span>
                                    <div className="contacts_block">
                                        <a href="mailto:Ezgilmedical@gmail.com">
                                            Ezgilmedical@gmail.com
                                        </a>
                                        <a href="mailto:Info@ezgilmedical.az">
                                            Info@ezgilmedical.az
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="brands_and_links">
                                <div className="brands_block">
                                    <div>
                                        <img
                                            className="sn"
                                            src={require("./img/snlogo.png")}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="isim"
                                            src={require("./img/isim.webp")}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="em"
                                            src={require("./img/em.webp")}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="undp"
                                            src={require("./img/undp.png")}
                                        />
                                    </div>
                                </div>
                                <div className="links_block">
                                    <div>
                                        <h3>
                                            {
                                                dictionary.footer.support.title[
                                                    language
                                                ]
                                            }
                                        </h3>
                                        <a href="/drugs/faq">
                                            {
                                                dictionary.footer.support
                                                    .link_2[language]
                                            }
                                        </a>
                                        <a href="/drugs/contacts">
                                            {
                                                dictionary.footer.support
                                                    .link_4[language]
                                            }
                                        </a>
                                    </div>
                                    <div>
                                        <h3>
                                            {
                                                dictionary.footer.around.title[
                                                    language
                                                ]
                                            }
                                        </h3>
                                        <a href="/drugs/about-us">
                                            {
                                                dictionary.footer.around.link_1[
                                                    language
                                                ]
                                            }
                                        </a>
                                        {/* <a>{dictionary.footer.around.link_2[language]}</a>
                                        <a>{dictionary.footer.around.link_3[language]}</a> */}
                                        <a href="/drugs/news/_/types=all">
                                            {
                                                dictionary.footer.around.link_4[
                                                    language
                                                ]
                                            }
                                        </a>
                                    </div>
                                    <div>
                                        <h3>
                                            {
                                                dictionary.footer.terms.title[
                                                    language
                                                ]
                                            }
                                        </h3>
                                        {/* <a>{dictionary.footer.terms.link_1[language]}</a> */}
                                        <a href="/drugs/privacy-policy">
                                            {
                                                dictionary.footer.terms.link_2[
                                                    language
                                                ]
                                            }
                                        </a>
                                        <a href="/drugs/terms-of-use">
                                            {
                                                dictionary.footer.terms.link_3[
                                                    language
                                                ]
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer_row non-centered">
                            <div className="copyright_block desktop tablet_large tablet_small">
                                <img
                                    src={require("./img/ezgil_logo@2x.webp")}
                                    width={45.6}
                                    height={63.2}
                                    alt="EZGIL logo"
                                />
                                <div className="copyright_text_block">
                                    <span className="copyright_text">
                                        EZGIL medical technologies ©{" "}
                                        {new Date().getFullYear()}.{" "}
                                        {
                                            dictionary.footer.allRightsReserved[
                                                language
                                            ]
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright_block mobile">
                        <img
                            src={require("./img/ezgil_logo@2x.webp")}
                            width={45.6}
                            height={63.2}
                            alt="EZGIL logo"
                        />
                        <div className="copyright_text_block">
                            <span className="copyright_text">
                                EZGIL medical technologies ©{" "}
                                {new Date().getFullYear()}.{" "}
                                {dictionary.footer.allRightsReserved[language]}
                            </span>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.dictionaryReducer,
    language: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLanguage: (language) => {
            dispatch(setLanguage(language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugsFooter);
