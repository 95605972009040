import { LOAD_ADDS } from "./../actions/action-types";

const initialState = {
    ads: []
};

export function adsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ADDS:
            return {...state, ... { ads: action.payload } };
        default:
            return state;
    }
}